import {
  TICKET_LOADING,
  GET_TICKET,
  GET_TICKETS
} from '../actions/types';

const INITIAL_STATE = {
  ticket: null,
  tickets: null,
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TICKET_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_TICKET:
      return {
        ...state,
        ticket: action.payload,
        loading: false
      };
    case GET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
        loading: false
      };
    default:
      return state;
  }
}