import React, { useEffect } from 'react';
import Modal from 'react-modal';
import YouTube from 'react-youtube';

const VideoModal = ({modalIsOpen, closeModal, videoId}) => {
  useEffect(() => {
    Modal.setAppElement('body');
  }, [])

  const renderContent = () => {
    const opts = {
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    }

    const _onReady = (event) => {
      // access to player in all event handlers via event.target
      event.target.pauseVideo();
    }

    return (
      <YouTube
        videoId={videoId}
        opts={opts}
        onReady={_onReady}
      />
    )
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      className="modal"
      overlayClassName="overlay">
      <button className="close-button" onClick={closeModal}>Close Window</button>
      {renderContent()}
    </Modal>
  )
}

export default VideoModal;