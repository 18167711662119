import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DateStamp from './DateStamp';

import { friendlyDate, toTitleCase } from '../../helpers';

import * as custom from '../../styles/Variables';
import ImageWrapper from './ImageWrapper';

const ImageCardStyled = styled(Link)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin: ${custom.SPACE_medium} auto;
  background: ${custom.BACKGROUND_COLOR_WHITE};
  box-shadow: ${custom.BOX_SHADOW_TIGHTER};
  border-radius: ${custom.BORDER_RADIUS};
  transition: all 0.4s ease;
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: ${custom.BOX_SHADOW};
      cursor: pointer;
    }
  }
`

const ImageCard = ({ event }) =>
  <ImageCardStyled to={`/event/${event.id}`}>
    <ImageWrapper>
      <img src={event.large_image && `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${event.large_image}.jpg`} alt="" />
    </ImageWrapper>
    <div className="flex flex-row flex-align-center padding--small paddingTop--none" style={{ height: '100%' }}>
      <div className="flex flex-align-center">

        <DateStamp startDate={event.start_date} />
        <div className="flex flex-column">
          {event.event_type && <p className="text-pink">{toTitleCase(event.event_type)}</p>}
          {event.name && <h3 className="text-bold">{event.name}</h3>}
          <p>{friendlyDate(event.start_date, 'MMM DD')} to {friendlyDate(event.end_date, 'MMM DD, YYYY')}</p>
        </div>
      </div>
    </div>
  </ImageCardStyled>

export default ImageCard;