import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { login } from '../../actions/authActions';

import TextField from '../utilities/formfields/TextField';

import { required } from '../../helpers/formValdation';
import { setCookie, getCookie } from '../../helpers/cookies';

import {
  FlexContainer,
  FormStyle,
  ButtonStyled,
  StyledCard
} from '../../styles';

export class Login extends Component {
  state = {
    errors: {}
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/account');
    }
    if (this.props.location.search) {
      setCookie('affiliations', this.props.location.search, 365);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.errors).length > 0) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit = (formValues) => {    
    // check if url params exists
    let parsedURLParams = queryString.parse(this.props.location.search);
    
    // check if cookie for event exists
    if (getCookie('affiliations')) {
      parsedURLParams = queryString.parse(getCookie('affiliations'));
    }
    this.props.login(formValues, parsedURLParams, (message) => {
      if (getCookie('affiliations')) {
        setCookie('affiliations', "", 0);
      }
      
      this.props.history.push({
        pathname: '/account',
        state: { message: message }
      });
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { errors } = this.state;

    return (
      <FlexContainer marginTopBottom>
        <div className="flex-grid-thirds flex-justify-center">
          <div className="col">
            <StyledCard className="margin--small">
              <h3 className="text-center">Login</h3>
              <p className="marginBottom--medium text-center">Welcome Back!</p>

              <FormStyle onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  name="email"
                  type="text"
                  placeholder="Email"
                  validate={required}
                  autoComplete="off"
                  error={errors.email}
                  component={TextField} />
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  validate={required}
                  error={errors.password}
                  component={TextField} />
                <ButtonStyled className="button--blue marginTop--small marginBottom--small">Sign In</ButtonStyled>
              </FormStyle>
            </StyledCard>
            <p className="text-center">Don't have an account? <Link to="/sign-up" className="text-center text-blue">Signup</Link> ➞</p>
          </div>
        </div>
      </FlexContainer>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default reduxForm({ form: 'login' })(connect(mapStateToProps, { login })(Login));