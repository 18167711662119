import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { getInstructors, deleteInstructor } from '../../actions/instructorActions';
import { IoIosList, IoMdAdd } from 'react-icons/io';
import { FiUsers } from 'react-icons/fi';
import FontAwesome from 'react-fontawesome';

import InstructorList from './InstructorList';
import InstructorView from './InstructorView';
import InstructorCreate from './InstructorCreate';
import InstructorEdit from './InstructorEdit';
import InstructorAddToEvent from './InstructorAddToEvent';

import IconFilled from '../utilities/IconFilled';

import { ButtonStyled } from '../../styles';
import { toTitleCase } from '../../helpers';

class InstructorShow extends React.Component {
  state = {
    updating: false,
    instructorID: "",
    instructor: {},
    instructors: [],
    modalContent: {},
    modalHeight: "",
    currentView: "list",
    modalIsOpen: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, updating: false });
    }
  }

  componentDidMount() {
    this.props.getInstructors();
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleView = (e) => {
    this.setState({ currentView: e.currentTarget.dataset.view }, () => { this.closeModal(); });
  }

  handleModalSwitch = (e, instructor) => {
    this.setState({
      instructorID: instructor._id,
      instructor: instructor,
      modalIsOpen: true,
      modalContent: e.currentTarget.dataset.modal,
      modalHeight: e.currentTarget.dataset.modalh
    });
  }

  handleClearState = (instructors = []) => {
    this.setState({
      modalIsOpen: false,
      modalContent: "",
      currentView: "list",
      instructorID: "",
      instructor: {},
      instructors: instructors
    });
  }

  handleRemoveInstructor = (instructorID) => {
    this.setState({ updating: true }, () => {
      this.props.deleteInstructor(instructorID, (instructors) => {
        this.setState({ updating: false }, () => {
          this.handleClearState(instructors);
        });
      });
    })
  }

  renderModalContent = (name) => {
    switch (name) {
      case 'view':
        return <InstructorView
          instructor={this.state.instructor}
          closeModal={this.closeModal}
          handleModalSwitch={this.handleModalSwitch}
          handleView={this.handleView} />
      case 'remove':
        return (
          <div
            style={{ height: "100%" }}
            className="flex flex-column padding--medium rounded flex-grow-1">
            {
              this.state.updating ?
                <div className="flex flex-align-center flex-justify-center">
                  <FontAwesome size="2x" spin name="spinner" />
                </div>
                :
                <React.Fragment>
                  <div className="flex flex-grow-1 flex-align-center marginBottom--medium">
                    <p>Are you sure you want to remove {this.state.instructor.name}?</p>
                  </div>
                  <div className="flex flex-row flex-align-center flex-justify-between">
                    <ButtonStyled onClick={this.closeModal}>Cancel</ButtonStyled>
                    <ButtonStyled className="button--red" onClick={() => this.handleRemoveInstructor(this.state.instructor._id)}>Yes, I am sure</ButtonStyled>
                  </div>
                </React.Fragment>
            }
          </div>
        )
      default:
        break;
    }
  }

  renderView = (name, { instructors, loading }) => {
    switch (name) {
      case 'add':
        return <InstructorAddToEvent eventID={this.props.match.params.id} instructors={instructors} />
      case 'list':
        return <InstructorList instructors={instructors} handleModalSwitch={this.handleModalSwitch} />
      case 'create':
        return <InstructorCreate loading={loading} handleClearState={ this.handleClearState} />
      case 'edit':
        return <InstructorEdit loading={loading} instructor={this.state.instructor} handleClearState={this.handleClearState} />
      default:
        break;
    }
  }

  render() {
    const { instructor } = this.props;
    const { currentView, modalContent, modalIsOpen, modalHeight } = this.state;

    const customStyles = {
      content: {
        height: modalHeight, borderRadius: '4px'
      }
    };

    return (
      <React.Fragment>
        <div className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall paddingTop--small paddingBottom--small">
          <p className="text-grey-blue">{toTitleCase(currentView)}</p>

          <div className="flex flex-row">

            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="add"
              className="marginRight--small" onClick={(e) => this.handleView(e)}>
              <FiUsers size="16px" />
            </IconFilled>

            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="list"
              className="marginRight--small" onClick={(e) => this.handleView(e)}>
              <IoIosList size="16px" />
            </IconFilled>

            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="create"
              onClick={(e) => this.handleView(e)}>
              <IoMdAdd size="16px" />

            </IconFilled>
          </div>
        </div>

        {this.renderView(currentView, instructor)}

        <Modal
          isOpen={modalIsOpen}
          className="modal"
          overlayClassName="overlay"
          style={customStyles}
          contentLabel="Add Instructor">
          {this.renderModalContent(modalContent)}
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  instructor: state.instructor
})

export default connect(mapStateToProps, { getInstructors, deleteInstructor })(withRouter(InstructorShow));