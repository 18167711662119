import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { updateAccount } from '../../actions/authActions';

import TextField from '../utilities/formfields/TextField';
import DropDownListField from '../utilities/formfields/DropDownField';

import { required } from '../../helpers/formValdation';

import { FlexContainer, FormStyle, ButtonStyled, StyledCard, ButtonStyledLink } from '../../styles';

import stripe_codes from '../../data/stripe_currency_codes';

class AccountEdit extends Component {
  state = {
    errors: {}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  beforeSubmit = (formValues) => {
    this.props.updateAccount(formValues, () => {
      this.props.history.push('/account');
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { errors } = this.state;

    return (
      <FlexContainer>
        <div className="flex-grid-thirds flex-justify-center marginTop--xLarge marginBottom--xLarge">
          <div className="col">
            <StyledCard>
              <h2 className="marginTop--small marginBottom--small text-center ">Edit Account</h2>
              <FormStyle onSubmit={handleSubmit(this.beforeSubmit)} autoComplete="off">
                <Field
                  label="First Name"
                  name="first_name"
                  type="text"
                  className="marginBottom--small"
                  placeholder="First Name"
                  validate={required}
                  error={errors.first_name}
                  component={TextField} />
                <Field
                  label="Currency"
                  name="currency.code"
                  type="text"
                  className="marginBottom--small"
                  placeholder="Canadian Dollars C$"
                  valueField="code"
                  textField="currency"
                  data={stripe_codes}
                  validate={required}
                  error={errors.code}
                  component={DropDownListField} />
                <div className="flex flex-row flex-align-center flex-justify-between marginTop--xLarge">
                  <ButtonStyledLink to="/account">Cancel</ButtonStyledLink>
                  <ButtonStyled className="button--blue" type="submit">Update Account</ButtonStyled>
                </div>
              </FormStyle>
            </StyledCard>
          </div>
        </div>
      </FlexContainer>
    )
  }
}

const mapStateToProps = state => ({
  initialValues: state.auth.user, // pull initial values from account reducer
  errors: state.errors,
})

export default compose(
  connect(
    mapStateToProps,
    { updateAccount } // bind account loading action creator
  ),
  reduxForm({
    enableReinitialize: true,
    form: 'editAccount' // a unique identifier for this form
  })
)(AccountEdit);
