import React from 'react'
import styled from 'styled-components';
import { FiTwitter, FiFacebook, FiMail } from "react-icons/fi";

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'source sans pro', sans-serif;
    font-size: 18px;
    color: #262626;
  }
`

const shareFacebook = () => {
  console.log('location')
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(process.env.NODE_ENV === 'production' ? `${window.location.href}` : `https://www.attndnce.com/event/5c88b3fd9df9930017910c2b`)}`,
    'facebook-share-dialog',
    'width=626,height=436');
  return false;
}
const ShareLinks = ({ event }) =>
  <Links>
      <a href="#" onClick={() => shareFacebook()}>
        <FiFacebook size="24px" className="marginRight--xSmall" />
        Facebook</a>
      <a
        href={`https://twitter.com/intent/tweet?url=https%3a%2f%2fwww.attndnce.com%2fevent%2f${event._id}&text=What%20do%20you%20think?%20Let's%20go%20together%20via%20@attndnce&related=festivals,congresses,parties,events&hashtags=attndnce, ${event.event_type.toLowerCase()},${event.tags && event.tags.map((tag) => tag).toString()}`}
        target="_blank"
        rel="noopener noreferrer">
        <FiTwitter size="24px" className="marginRight--xSmall" />
        Twitter</a>
    <a 
      href={`mailto:username@example.com?subject=${event.name}&body=What do you think? Should we go together? https%3a%2f%2fwww.attndnce.com%2fevent%2f${event._id}`}
      target="_blank"
      rel="noopener noreferrer">
      <FiMail size="24px" className="marginRight--xSmall" />
      Email</a>
  </Links>

export default ShareLinks