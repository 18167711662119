import React from 'react';
import { friendlyDate } from '../../../helpers';

const PaymentStatus = ({ event }) => {
  const { subscribed_payment_option: { name, payment_charge } } = event;

  return (
      <div className="flex flex-column flex-justify-center padding--small background-white border rounded-m shadow">

        <div className="flex flex-row flex-align-center flex-justify-between paddingBottom--xSmall border-bottom">
          <p>Selected Plan</p>
          <p className="text-small text-spacing-1">{name.toUpperCase()}</p>
        </div>
        {payment_charge && payment_charge.payment_method_details &&
          <React.Fragment>
            <div className="flex flex-row flex-align-center flex-justify-between paddingTop--xSmall paddingBottom--xSmall border-bottom">
              <p>Payment Method</p>
            <p className="text-small">{payment_charge.payment_method_details.brand} credit card ending in ****{payment_charge.payment_method_details.last4}</p>
            </div>
            <div className="flex flex-row flex-align-center flex-justify-between paddingTop--xSmall">
              <p>Date Paid</p>
            <p className="text-small">{friendlyDate(payment_charge.created_at, "MMM DD, YYYY @ h:mmA")}</p>
            </div>
          </React.Fragment>
        }
      </div>

  )
}

export default PaymentStatus;