import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reset, reduxForm } from 'redux-form';
import { FiCalendar, FiArrowLeft } from "react-icons/fi";

import Tags from '../utilities/Tags';
import DateStamp from '../utilities/DateStamp';
import ImageCover from '../utilities/ImageCover';

import { friendlyDate, toTitleCase, preserveLines, getPlaceholderImage } from '../../helpers';

import { FormStyle, ButtonStyled } from '../../styles';

const EventCreateSecondPage = props => {

  const {
    handleSubmit,
    beforeSubmit,
    previousPage,
    pristine, // redux-form method
    submitting, // redux-form method
    formValues // redux-form form state
  } = props;

  return (
    <div className="item">
      <div className="flex flex-row flex-align-center paddingTop--small" onClick={previousPage} style={{ cursor: "pointer" }}>
        <FiArrowLeft className="marginRight--xSmall" size="2rem" style={{ width: "32px" }} /><p>Back</p>
      </div>
      <ImageCover>
        {localStorage.getItem('image') !== null ?
          <img src={"data:image/jpg;base64," + localStorage.getItem('image')} alt="event image" />
          :
          <img src={getPlaceholderImage(formValues.event_type && formValues.event_type)} alt="event image" />
        }
      </ImageCover>
      
      <div className="padding--medium background-white border rounded-m shadow">
        <div className="flex flex-row flex-justify-between"><p>How are fees handled:</p> <p>{formValues.pass_fees_on && formValues.pass_fees_on.name}</p></div>
        <div className="flex flex-row flex-justify-between"><p>Payout country:</p> <p>{formValues.payout_country && formValues.payout_country.name}</p></div>
        <div className="flex flex-row flex-justify-between"><p>Payout currency:</p> <p>{formValues.payout_currency && formValues.payout_currency.code}</p></div>
      </div>
      <div className="padding--medium background-white border rounded-m shadow">
        <div className="flex flex-row flex-align-center">
          <DateStamp startDate={formValues.start_date} />
          <div className="flex flex-column">
            {formValues.event_type && <p className="text-small text-pink">{toTitleCase(formValues.event_type)}</p>}
            {formValues.name && <h3>{formValues.name}</h3>}
            {formValues.location && <p>{formValues.location.formatted_address}</p>}
          </div>
        </div>
      </div>

      <div className="padding--medium background-white border rounded-m shadow">
        <div className="marginBottom--medium">
          <p>Description</p>
        </div>

        {formValues.desc && preserveLines(formValues.desc)}

        <div className="flex flex-row marginBottom--medium">
          <FiCalendar className="marginRight--large" style={{ width: "32px", margin: '5px 32px 0 0' }} />
          <div className="flex flex-column">
            <p>Date and Time</p>
            <p className="text-small">{`${friendlyDate(formValues.start_date, "MMM DD")} - ${friendlyDate(formValues.end_date, "MMM DD, YYYY")}`}</p>
            <p className="text-small">{`${friendlyDate(formValues.start_date, 'hh:mm A')} - ${friendlyDate(formValues.end_date, 'hh:mm A')}`}</p>
          </div>
        </div>
        {typeof formValues.tags !== 'undefined' && <Tags tags={(formValues.tags.replace(/\s*,\s*/g, ",").split(',')).filter(tag => tag !== "")} />}
      </div>

      <FormStyle onSubmit={handleSubmit(beforeSubmit.bind(this))}>
        <ButtonStyled className="button--blue" disabled={pristine || submitting}>Save as Draft</ButtonStyled>
      </FormStyle>
    </div>
  )
}

const mapStateToProps = state => ({
  formValues: state.form.wizard.values
});

const afterSubmit = (result, dispatch) => //https://stackoverflow.com/questions/42551955/clear-redux-form-fields-after-submitting/42569890
  dispatch(reset('wizard'));

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'wizard', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    onSubmitSuccess: afterSubmit
  }),
)(EventCreateSecondPage);
