import styled from 'styled-components'
import * as custom from './Variables';

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  label {
    display: flex;
    align-items: center;
    min-width: 100px;
    padding: ${custom.SPACE_xSmall};
    padding-left: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: #12263F;
  }

  input {
    padding: 9px 2px;
    background: #FFFFFF;
    border-radius: ${custom.BORDER_RADIUS};
    border: 1px solid ${custom.COLOR_BORDER};
    font-family: 'Roboto', sans-serif;
    font-size: ${custom.FONTSIZE_medium};
    color: ${custom.COLOR_DARK};
    line-height: 26px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  input::placeholder, textarea::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: ${custom.FONTSIZE_medium};
    color: ${custom.COLOR_PLACEHOLDER_TEXT};
    line-height: 26px;
    opacity: 0.5;
  }

  input[type="text"]:disabled {
    background: ${custom.COLOR_PLACEHOLDER};
    cursor: not-allowed;
  }

  /* input[type="text"] { 
    width: 100%;
  } */

  select:disabled {
    background: ${custom.COLOR_PLACEHOLDER};
    cursor: not-allowed;
  }

  input[type="text"]:disabled::placeholder {
    color: ${custom.COLOR_PLACEHOLDER_TEXT};
  }

  input.error {
    border: 1px solid red;
  }

  input:focus {
    box-shadow: 0 0 0 0.2rem rgba(52,144,220,.25);
    outline: 0;
  }

  select:focus {
    box-shadow: 0 0 0 0.2rem rgba(52,144,220,.25);
    outline: 0;
  }

  textarea:focus {
    box-shadow: 0 0 0 0.2rem rgba(52,144,220,.25);
    outline: 0;
  }

  input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  
  textarea {
    flex-grow: 1;
    min-height: 150px;
    width: 100%;
    padding: 8px;
    border-radius: ${custom.BORDER_RADIUS};
    background: #FFFFFF;
    border: 1px solid ${custom.COLOR_BORDER};
    font-family: 'Roboto', sans-serif;
    font-size: ${custom.FONTSIZE_medium};
    line-height: 32px;
    overflow: auto;
    outline: none;
    word-wrap: break-word;
    resize: vertical;
    box-sizing: border-box;
  }
  
  select {
    min-width: 84px;
    height: 42px;
    padding: 5px 35px 5px 5px;
    background: #FFFFFF;
    border-radius: ${custom.BORDER_RADIUS};
    border: 1px solid ${custom.COLOR_BORDER};
  }

  textarea.error {
    border: 1px solid crimson;
  }

  .rw-widget > .rw-widget-container {
    border: 1px solid ${custom.COLOR_BORDER};
  }

  .rw-dropdown-list.rw-widget:focus {
    border-radius: 4px;
    border: 1px solid ${custom.COLOR_BLUE};
  }
  
  .rw-datetime-picker.rw-widget:focus {
    border-radius: 4px;
    border: 1px solid ${custom.COLOR_BLUE};
  }
  
  .error_select_rw_container .rw-widget-container {
    border: 1px solid red;
  }

  .rw-select > * {
    width: 43px;
  }

  /* DropDownField */
  .rw-placeholder {
    color: ${custom.COLOR_PLACEHOLDER_TEXT};
  }

  .rw-dropdown-list-input {
    padding: 9px 12px;
    border-radius: ${custom.BORDER_RADIUS};
    font-family: 'Roboto', sans-serif;
    font-size: ${custom.FONTSIZE_medium};
    color: #000000;
    line-height: 26px;
  }
  
  .rw-widget-input {
    box-shadow: none;
  }

  .rw-dropdown-list.rw-widget.rw-state-disabled {
    background: #EEEEEE;
    outline: none;
  }

  
  .rw-state-disabled:focus {
    border: none!important;
  }
  
  /* DateTime */
  .rw-widget>.rw-widget-container > input {
    border-radius: 4px;
    border: none;
  }
  
  .rw-multiselect .rw-input-reset {
    padding: 0 8px;
  }

  .rw-list-option {
    padding: 8px 4px;
    font-size: ${custom.FONTSIZE_medium};
  }

  .rw-widget-container input {
    font-size: ${custom.FONTSIZE_medium};
  }
  /* MultiSelectField */
`


