import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";

import Logo from './utilities/Logo';

import NavStyles from '../styles/NavStyles';
 // <NavLink key={4} activeClassName="selected" onClick={toggleMainMenu} to="/sign-up">Signup</NavLink>,
 // <NavLink key={5} activeClassName="selected" onClick={toggleMainMenu} to="/admin/login">Log In</NavLink>

const renderNav = (isAuthenticated, handleLogoutClick, toggleMainMenu) => {
  switch (isAuthenticated) {
    case null:
      return;
    case false:
      return [
        <NavLink key={1} activeClassName="selected" onClick={toggleMainMenu} to="/features">Features</NavLink>,
        <NavLink key={2} activeClassName="selected" onClick={toggleMainMenu} to="/pricing">Pricing</NavLink>
      ];
    default:
      return [
        <NavLink key={2323} activeClassName="selected" onClick={toggleMainMenu} to="/account">Account</NavLink>,
        <NavLink key={2343} activeClassName="selected" onClick={toggleMainMenu} to="/event/create">Create Event</NavLink>,
        <a href="#" onClick={handleLogoutClick}>Sign Out</a>
      ]
  }
}

const Nav = ({ open, toggleMainMenu, handleLogoutClick, isAuthenticated }) => (
  <NavStyles open={open}>
    <header>
      <IoIosClose
        className="pullRight"
        title="close"
        style={{
          width: '32px',
          height: '32px',
          border: '0',
          position: 'absolute',
          zIndex: '2',
          right: '20px',
          top: '20px'
        }}
        onClick={toggleMainMenu} />
    </header>
    <div className="content flex flex-column">
      {renderNav(isAuthenticated, handleLogoutClick, toggleMainMenu)}
    </div>
    <footer>
      <div className="flex flex-column flex-justify-center text-center">
        <Link to={`/?ref=nav_logo`} className="marginBottom--small text-center"><Logo/></Link>
        <p className="text-small">Registration on or use of this site constitutes acceptance of our</p>
        <div className="flex flex-row flex-align-center flex-justify-center">
          <Link className="text-small padding--none" to="/tos">Terms and Conditions</Link> | <Link className="text-small padding--none" to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  </NavStyles>
)

export default Nav;