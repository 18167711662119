import axios from 'axios';

export const createCustomField = (eventID, formValues) => async dispatch => {
  try {
    console.log('actions', eventID, formValues);
    const res = await axios.post(`/api/event/${eventID}/create/custom-field`, formValues);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export const updateOrderForm = (eventID, formValues) => async dispatch => {
  try {
    console.log('actions', eventID, formValues);
    const res = await axios.post(`/api/event/${eventID}/update/order-form`, formValues);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}