import React from 'react';
import styled from 'styled-components';
import { FiMapPin } from "react-icons/fi";

import OrganizerInfo from './OrganizerInfo';
import Location from './Location';
import TagPart from './TagPart';
import DatePart from './DatePart';
import DescPart from './DescPart';
import DateStamp from './DateStamp';
import ShareLinks from './ShareLinks';

import { ButtonStyled, media } from '../../../styles';
import * as custom from '../../../styles/Variables';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 960px;
  margin: 50px auto;
  
  &.card {
    background: #FFFFFF;
    border: 0 solid ${custom.COLOR_BORDER};
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(155,155,155,0.23);
  }

  h3.important {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 54px;
    color: #575757;
    line-height: 40px;
    margin-bottom: 16px;
  }

  p.headline {
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    font-family: 'source sans pro', sans-serif;
    font-size: 18px;
    color: #262626;
    line-height: 30px;
  }
  h2 {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 30px;
    color: #4D4D4D;
    line-height: 40px;
  }
`

const InfoHeader = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: space-between;
  padding: 24px;
  border-bottom: 1px solid ${custom.COLOR_BORDER};
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  `}
  button {
    margin-left: auto!important;
    ${media.handheld`
      margin: 0 auto;
      width: 100%;
    `}
  }
`

const InfoHeaderContent = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
`

const InfoContent = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}
`

const InfoPart = styled.div`
  margin-bottom: 50px;
`

const MainContent = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  padding: 48px 24px;
  ${media.handheld`
    padding: 24px 24px;
  `}
`

const SubInfo = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  margin: 24px;
  padding: 24px;
  &.subCard {
    background: #FBFCFD;
    border-radius: 8px;
    border: 1px solid ${custom.COLOR_BORDER};
  }
`

const Section = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #10071B;
  background-position: 0% 0%;
  position: relative;
  overflow: hidden;
  ${media.handheld`
    background-size: 400%;
    background-position: -800px -65px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 960px;
  margin: 0 auto;
  padding: 200px 16px;
  h1 {
    color: white;
    text-align: center;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 64px;
    color: #FFFFFF;
    line-height: 72px;
  }
`

const EventInfo = ({ event, user, scrollToRef, refProps }) => {
  return (
    <React.Fragment>
      <Section style={{ backgroundImage: event.large_image ? `url("https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${event.large_image}.jpg")` : `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/v1569957890/assets/attndnce-hero.jpg`}}>
        <GridLayout>
          <h1>{event.name}</h1>
        </GridLayout>
      </Section>
      <GridContainer className="card">
        {user && user.id === event.created_by && 
        <div className="flex flex-column flex-align-center flex-justify-center paddingTop--large padding--medium border-bottom">
          <h2 className="text-center">This event is set to {event.published ? "Published" : "Draft"}.</h2><p className="text-center">Only you can see this message right now.</p>
        </div>
        }
        <InfoHeader>
          <InfoHeaderContent>
            <DateStamp start_date={event.start_date} end_date={event.end_date} />
            <div>
              <FiMapPin size="40px" style={{ marginBottom: "12px" }} />
              <p>{event.location && event.location.formatted_address}</p>
            </div>
          </InfoHeaderContent>
          <ButtonStyled className="button--blue" onClick={() => scrollToRef(refProps)}>Select Your Tickets</ButtonStyled>
        </InfoHeader>

        <InfoContent>
          <MainContent>
            <InfoPart>
              <p className="headline">{event.event_type}</p>
              <h2>{event.name}</h2>
              <DatePart start_date={event.start_date} end_date={event.end_date} />
            </InfoPart>

            <p className="headline">Description</p>
            <DescPart desc={event.desc} />

          </MainContent>

          <SubInfo className="subCard">
            <InfoPart>
              <p className="headline">Location</p>
              <Location location={event.location} />
            </InfoPart>
            <InfoPart>
              <p className="headline">Tags</p>
              <TagPart tags={event.tags} />
            </InfoPart>
            <InfoPart>
              <p className="headline">About the Organizer</p>
              <OrganizerInfo profile={event.profile} />
            </InfoPart>
            <p className="headline">Share Event</p>
            <ShareLinks event={event}/>
          </SubInfo>
        </InfoContent>
      </GridContainer>
    </React.Fragment>
  )

}
export default EventInfo;