import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import FontAwesome from 'react-fontawesome';

import { createProfile } from '../../actions/profileActions';

import TextField from '../utilities/formfields/TextField';
import TextAreaField from '../utilities/formfields/TextAreaField';
import UploadImageContainer from '../utilities/formfields/UploadImageContainer';

import Spinner from '../utilities/Spinner';

import { required } from '../../helpers/formValdation';

import {
  FormStyle,
  ButtonStyled,
  ButtonStyledLink
} from '../../styles';

class CreateProfile extends Component {
  state = {
    displaySocialInputs: false,
    errors: {}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  beforeSubmit = (formValues) => {
    this.props.createProfile(formValues, () => {
      this.props.history.push(`/account`);
    });
  }

  handleSocialMedia = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      displaySocialInputs: !prevState.displaySocialInputs
    }));
  }

  render() {
    const { handleSubmit, profile: { loading } } = this.props
    const { errors, displaySocialInputs } = this.state;

    let socialInputs;

    if (displaySocialInputs) {
      socialInputs = (
        <React.Fragment>
          <Field
            label="Twitter"
            type="text"
            name="social.twitter"
            className="marginBottom--small"
            error={errors.twitter}
            component={TextField} />
          <Field
            label="Facebook"
            type="text"
            name="social.facebook"
            className="marginBottom--small"
            error={errors.facebook}
            component={TextField} />
          <Field
            label="Instagram"
            type="text"
            name="social.instagram"
            className="marginBottom--small"
            error={errors.instagram}
            component={TextField} />
        </React.Fragment>
      );
    } else if (loading) return <Spinner />

    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <Field
          label="Profile Handle"
          type="text"
          name="handle"
          error={errors.handle}
          validate={required}
          component={TextField} />

        <UploadImageContainer imageType="profile" />

        <Field
          label="Company Name"
          type="text"
          name="company"
          error={errors.company}
          validate={required}
          component={TextField} />
        <Field
          label="Website"
          type="text"
          name="website"
          error={errors.website}
          validate={required}
          component={TextField} />
        <Field
          label="Email"
          type="email"
          name="email"
          error={errors.email}
          validate={required}
          component={TextField} />
        <Field
          label="Location"
          type="text"
          name="location.formatted_address"
          error={errors.location}
          validate={required}
          component={TextField} />
        <Field
          label="Short Bio"
          type="text"
          name="desc"
          error={errors.desc}
          validate={required}
          component={TextAreaField} />

        <ButtonStyled className="marginTop--large marginBottom--large" onClick={this.handleSocialMedia}>
          Add Social Network Links
        </ButtonStyled>
        {socialInputs}
        <div className="flex flex-row flex-align-center flex-justify-between marginTop--large">
          <ButtonStyledLink to="/account">Cancel</ButtonStyledLink>

          <ButtonStyled className="button--blue" disabled={loading}>
            {loading ?
              <div className="flex flex-align-center flex-justify-center">
                <FontAwesome size="2x" spin name="spinner" />
              </div> : "Create Profile"
            }
          </ButtonStyled>
        </div>
      </FormStyle>
    );
  }
}

CreateProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors
});

export default compose(
  reduxForm({ form: 'createProfile' }),
  connect(mapStateToProps, { createProfile })
)(CreateProfile)