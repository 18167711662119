import React from 'react';
import { ButtonStyled } from '../../../styles';

import { friendlyDate } from '../../../helpers';
import isEmpty from '../../../validation/isEmpty';

const MailchimpActivity = ({ user, handleFetchAudience }) =>
  <React.Fragment>
    <p className="marginBottom--xSmall text-grey-blue">Your most recent sync</p>
    <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small padding--medium background-white rounded border shadow">
      <p>{!isEmpty(user.mailchimp.members_last_synced) && user.mailchimp.members_last_synced} attendees was last synced on {friendlyDate(user.mailchimp.last_synced)}.</p>
      <ButtonStyled className="button--blue" onClick={handleFetchAudience}>Check Sync Status</ButtonStyled>
    </div>
  </React.Fragment>

export default MailchimpActivity;