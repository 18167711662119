import React from 'react';

import Comparison from './comparison';
import PriceTable from './price-table';
import Faqs from './faqs';
import SectionFour from '../home-page/SectionFour';
import { FlexContainer } from '../../../styles';

const PricingPage = () =>
  <React.Fragment>
    <div className="background-white">
      <FlexContainer>
        <PriceTable />
        <Comparison />
        <Faqs />
      </FlexContainer>
    </div>
    <SectionFour />
  </React.Fragment>
  
export default PricingPage;