import React from 'react'
import styled from 'styled-components';
import { FlexContainer, media } from '../../../styles';
import { FiLayout, FiTag, FiShoppingCart, FiFilm, FiUsers, FiPercent, FiList, FiPlusSquare } from "react-icons/fi";
import { TiTicket } from "react-icons/ti";

import Event from "../images/undraw_mobile_user_7.svg";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubText = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  color: #262626;
  line-height: 38px;
`

const SubTextHeader = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  color: #262626;
  line-height: 38px;
  ${media.handheld`
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 575px 340px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 120px;
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-bottom: 50px;
    margin-left: 24px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: 45px;
  align-items: start;
  margin-bottom: 120px;

  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const featureList = () => {
  return (
    <FlexContainer>
      <GridHeader>
        <Title>Features that Event Organizers love</Title>
        <div>
          <img src={Event} alt="" />
        </div>
      </GridHeader>
      <GridLayout>
        <div>
          <SubTextHeader>Convenience at your fingertips</SubTextHeader>
        </div>

        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiLayout size="24px" className="marginRight--small" />
            <SubText>Export to CSV</SubText>
          </div>
          <p>Take your ticket sales data and export them into your own Google or Microsoft spreadsheets.</p>
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiList size="24px" className="marginRight--small" />
            <SubText>Custom Order Forms</SubText>
          </div>
          <p>Learn more about your customer and where they came from, such as: age, gender, and location.</p>

        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <TiTicket size="24px" className="marginRight--small" />
            <SubText>Flexible Ticket Types</SubText>
          </div>
          <p>Create, limit, and track any types of tickets you create: Early Bird, VIP, Student, and etc - you name it.</p>
        </div>


        <div>
          <SubTextHeader>More Payment Features</SubTextHeader>
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiShoppingCart size="24px" className="marginRight--small" />
            <SubText>Stripe Payments</SubText>
          </div>
          <p>We use Stripe to ensure you get paid fast and securely.</p>
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiTag size="24px" className="marginRight--small" />
            <SubText>Discount Codes</SubText>
          </div>
          <p>Provide more incentive for customers and promotors with coupon codes.</p>
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiPercent size="24px" className="marginRight--small" />
            <SubText>Tax Calculations</SubText>
          </div>
          <p>Need to apply taxes to your tickets? No problem we have that too.</p>
        </div>

        <div>
          <SubTextHeader>Highlight your event even more</SubTextHeader>
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiPlusSquare size="24px" className="marginRight--small" />
            <SubText>Sales Form Widget</SubText>
          </div>
          <p>Integrate your ticketing service directly in your website HTML page wherever you want.</p>
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiUsers size="24px" className="marginRight--small" />
            <SubText>Instructor Spotlight</SubText>
          </div>
          <p>Let your attendees know teaching, performaning, and presenting at your events.</p>
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--xSmall">
            <FiFilm size="24px" className="marginRight--small" />
            <SubText>Youtube Video Integration</SubText>
          </div>
          <p>Showcase your event further by adding youtube videos to your event page.</p>
        </div>
      </GridLayout>
    </FlexContainer>
  )
}
export default featureList;