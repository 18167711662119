import React from 'react';
import moment from 'moment';
import currencySymbol from '../data/currency-symbol';
const zero_decimal_currencies = ["JPY", "KMF", "KRW", "UGX", "VND", "VUV", "XAF", "XOF", "XPF"];


export const friendlyDate = (date, format = 'MMM DD, YYYY') => {
  return moment(date).format(format);
};

export const fromNow = (date) => {
  return moment.utc(date).from();
};

export const isAfter = (date) => {
  return moment(date).isBefore(new Date());
};

export const isAfter7Days = (date) => {
  if (moment(new Date()).isAfter(moment(date).add(7, "days"))) {
    return true;
  } else {
    return false;
  }
};

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export const toTitleCase = (str) => {
  return str.replace(/\.*\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const truncate = (str, len) => {
  if (str) {
    if (str.length > len && str.length > 0) {
      let new_str = str + "";
      new_str = str.substr(0, len);
      new_str = str.substr(0, new_str.lastIndexOf(" "));
      new_str = (new_str.length > 0) ? new_str : str.substring(0, len);
      return new_str + '...';
    }
    return str;
  }
};

export const urlify = (text) => {
  let result = (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    function (match, space, url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      // TODO remove period (dot) if followed by link ie www.attndnce.com. Hey 
      return space + '<a href="' + hyperlink + '" class="text-blue" target="_blank" rel="nofollow">' + url + '</a>';
    }
  );

  const preserved = preserveLines(result);
  return preserved;
}

export const preserveLines = (text) => {
  return (
    text.replace(/\r\n|\r|\n/g,"</br>")
    // text.split('\n') || []).map((item, index) => <p className="marginBottom--small" key={index}>{item}</p>
  );
};

export const formatPrice = (cents, code, language) => {
  const options = {
    style: "currency",
    currency: code,
    minimumFractionDigits: zero_decimal_currencies.includes(code) ? 0 : 2
  };
  
  if (zero_decimal_currencies.includes(code)) {
    // If its a whole number, dollar amount, leave off the .00
    if (cents % 100 === 0) options.minimumFractionDigits = 0;
    const formatter = new Intl.NumberFormat(language, options);
    return formatter.format(cents);
  } else {
    // If its a whole number, dollar amount, leave off the .00
    if (cents % 100 === 0) options.minimumFractionDigits = 0;
    const formatter = new Intl.NumberFormat(language, options);
    return formatter.format(cents / 100);
  }
}

export const formatCentsToDollars = function (value) {
  value = (value + '').replace(/[^\d.-]/g, '');
  value = parseFloat(value);
  return (value ? value / 100 : 0);
}

export const formatDollarsToCents = function (value, code) {
  value = (value + '').replace(/[^\d.-]/g, '');
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3);
  }

  if (zero_decimal_currencies.includes(code)) {
    return value ? Math.round(parseFloat(value)) : 0;
  } else {
    return value ? Math.round(parseFloat(value) * 100) : 0;
  }
}

// https://halistechnology.com/2015/05/28/use-javascript-to-export-your-data-as-csv/
export const toCsv = (arr) => {
  var data, filename, link;

  let csv = arr.map(row =>
    row.map(val => isNaN(val) ? JSON.stringify(val) : +val).join(',')
  ).join('\n');

  if (csv == null) return;

  filename = arr.filename || 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  data = encodeURI(csv);

  link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', filename);
  link.click();
}

// https://stackoverflow.com/questions/41028114/how-to-convert-array-of-nested-objects-to-csv
export const pivot = (arr) => {
  var mp = new Map();
  function setValue(a, path, val) {
    if (Object(val) !== val) { // primitive value
      var pathStr = path.join('.');
      var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
      a[i] = val;
    } else {
      for (var key in val) {
        setValue(a, key === '0' ? path : path.concat(key), val[key]);
      }
    }
    return a;
  }

  var result = arr.map(obj => setValue([], [], obj));
  return [[...mp.keys()], ...result];
}

export const getPlaceholderImage = (event_type) => {
  switch (event_type) {
    case "workshop":
      return "https://res.cloudinary.com/dksc4gf1v/image/upload/v1554457404/assets/event%20type/workshop.jpg"
    case "social dance":
      return "https://res.cloudinary.com/dksc4gf1v/image/upload/v1554457402/assets/event%20type/social_event.jpg"
    case "dance class":
      return "https://res.cloudinary.com/dksc4gf1v/image/upload/v1554457402/assets/event%20type/dance_class.jpg"
    case "congress":
      return "https://res.cloudinary.com/dksc4gf1v/image/upload/v1554457398/assets/event%20type/congress.jpg"
    default:
      return "https://res.cloudinary.com/dksc4gf1v/image/upload/v1554457402/assets/event%20type/social_event.jpg"
  }
}

export const getSymbolFromCurrency = (currencyCode) => {
  if (typeof currencyCode !== 'string') return undefined
  let code = currencyCode.toUpperCase()
  if (!currencySymbol.hasOwnProperty(code)) return undefined
  // console.log('currency match', currencySymbol[code]);
  return currencySymbol[code];
}

