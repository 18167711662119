import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getOrderHooks } from '../../actions/billingActions';

import DateStamp from '../utilities/DateStamp';

import {
  calculateSubTotal,
  calculateDiscount,
  calculateTax,
  calculateTotalFees,
  calculateTotal
} from '../../helpers/calculations';
import { friendlyDate, formatPrice, toTitleCase, getSymbolFromCurrency } from '../../helpers';
import isEmpty from '../../validation/isEmpty';

import { ButtonStyledLink, media } from '../../styles';
import Spinner from '../utilities/Spinner';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 960px;
  margin: 50px auto;
`

const GridSections = styled.div`
  display: grid;
  grid-template-rows: 130px 1fr auto;
`

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 390px;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  .main, .sidebar {
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header'
      'content'
      'footer';
    min-height: 768px;
    /* height: 100vh; */
  }

  .sidebar {
    background: #FAFAFA;
    ${media.handheld`
      display: none;
    `}
  }

  .header {
    grid-area: header;
    padding: 16px 24px 0 24px;
  }

  .content {
    grid-area: content;
    padding: 16px 24px;
  }

  .footer-side {
    grid-area: footer;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin: 24px 0;
    padding: 0 24px;
    > * {
      text-align: center;
    }
  }
  .footer-main {
    grid-area: footer;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 24px 0;
    padding: 0 24px;
  }
`

const OrderReceipt = (props) => {
  const [payment, setPayment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const dispatch = useDispatch();
    useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(getOrderHooks(props.match.params.id));
        setPayment(data);
      } catch (err) {
        setError(err)
      }
      setIsLoading(false);
    };
    runEffect();
  }, [dispatch]);

  const subTotal = items => {
    return (items || []).reduce((prev, { quantity_sold, price }) => {
      let itemTotal = quantity_sold * price;
      prev = prev += itemTotal;
      return prev;
    }, 0);
  }; 
  if (payment === null || isLoading) {
    return <Spinner/>
  } else {
    if (payment && !isEmpty(payment)) {
      return (
        <GridContainer>
          <GridSections className="background-white border">
            <header className="flex flex-column flex-align-center flex-justify-center border-bottom">
              <h1 className="text-bold text-xLarge">Order Receipt</h1>
            </header>
            <GridColumns>
              <div className="main">
                <div className="content">
                  <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small border-bottom">
                    <h3>Buyer Info</h3>
                  </div>
                  <div className="flex flex-row flex-justify-between paddingBottom--small">
                    <p>Name:</p>
                    <p>{toTitleCase(payment.customer_info.first_name)} {toTitleCase(payment.customer_info.last_name)}</p>
                  </div>

                  <div className="flex flex-row flex-justify-between paddingBottom--small">
                    <p>Email:</p>
                    <p>{payment.customer_info.email}</p>
                  </div>

                  <div className="flex flex-row flex-justify-between">
                    <p>Order #</p>
                    <p className="text-number text-bold text-spacing-1">{payment.order_number}</p>
                  </div>

                  <div className="marginTop--xLarge">
                    <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small border-bottom">
                      <h3>Event Details</h3>
                    </div>
                    <div className="flex flex-row flex-align-center marginBottom--medium">
                      <DateStamp startDate={new Date()} />
                      <div className="flex flex-column">
                        <p className="text-small text-pink">{toTitleCase(payment._event.event_type)}</p>
                        <h2 className="text-large text-bold">{payment._event.name}</h2>
                        <p className="text-small">{`${friendlyDate(payment._event.start_date, "MMM DD")} - ${friendlyDate(payment._event.end_date, "MMM DD, YYYY")}`}</p>
                      </div>
                    </div>
                  </div>

                <div className="marginTop--xLarge">

                  <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small border-bottom">
                    <h3>Next Steps</h3>
                  </div>

                  <p className="text-bold">Check your Email: E-Ticket</p>
                  <div className="flex flex-column marginTop--xSmall marginBottom--large padding--small background-lightBlue border border--blue rounded-m">
                    <p>You should receive an email with all the details soon. Be sure to check your Inbox. Print out the email and present the QR Code at the entrance. In case you have difficulty accessing your E-Ticket, please contact contact the event organizer.</p>
                  </div>

                  <div className="flex flex-column">
                    <p className="marginBottom--small text-bold">Things you should know:</p>
                    <ul>
                      <li className="marginBottom--small">1. Holder of ticket must present the related ID and ticket at the entrance.</li>
                      <li>2. As a sales service provider, attndnce is not responsible for the changes in contents, quality, and offers of the events. Please contact the event organizers for the latest announcements and instructions.</li>
                    </ul>
                  </div>

                </div>
              </div>
              <div className="footer-main flex flex-row flex-justify-end">
                <div>&nbsp;</div>
                <ButtonStyledLink
                  target="_blank"
                  to={`/event/${payment._event.id}`}
                  className="rounded-m text-bold text-uppercase pullRight">Go to Event Listing</ButtonStyledLink>
              </div>
            </div>
            <div className="sidebar border-left">
              <div className="header">
                <h3 className="paddingBottom--small border-bottom">Order Summary</h3>
              </div>
              <div className="content">
                <ul>
                  {(payment.order_info.order_items || []).map((item, index) =>
                    <li key={index} className="flex flex-row flex-justify-between paddingBottom--small">
                      <span>{item.quantity_sold}x ({item.ticket_type}) {item.name}</span>
                      <span className="text-number">{formatPrice(item.price, payment._event.payout_currency.code, payment._event.payout_country.language)}</span>
                    </li>
                  )}
                </ul>

                  {!isEmpty(payment.order_info.discount) && !isEmpty(payment.order_info.discount.amount_off) &&
                  <div className="flex flex-row flex-align-center flex-justify-between paddingBottom--small">
                    <p>Discount Applied {`${getSymbolFromCurrency(payment._event.payout_currency.code) + payment.discount.amount_off} OFF`}</p>
                    <p className="text-number">-{calculateDiscount(subTotal(payment.order_info.order_items), payment.order_info.discount, payment._event.payout_currency.code, payment._event.payout_country.language, true)}</p>
                  </div>
                }

                  {!isEmpty(payment.order_info.discount) && !isEmpty(payment.order_info.discount.percent_off) &&
                  <div className="flex flex-row flex-align-center flex-justify-between paddingBottom--small">
                    <p>Discount Applied {payment.order_info.discount.percent_off}% OFF</p>
                    <p className="text-number">{calculateDiscount(subTotal(payment.order_info.order_items), payment.order_info.discount, payment._event.payout_currency.code, payment._event.payout_country.language, true)}</p>
                  </div>
                }

                <div className="flex flex-row flex-align-center flex-justify-between paddingBottom--small">
                  <p>Sub-Total</p>
                    <p className="text-number">{calculateSubTotal(subTotal(payment.order_info.order_items), payment.order_info.discount, payment._event.payout_currency.code, payment._event.payout_country.language, true)}</p>
                </div>

                {(payment._event.taxes || []).map((tax, index) =>
                  <div key={index} className="flex flex-row flex-align-center flex-justify-between paddingBottom--small">
                    <p>{`${tax.tax_name} ${tax.tax_percent}%`}</p>
                    <p className="text-number">{calculateTax(subTotal(payment.order_info.order_items), payment.order_info.discount, tax, payment._event.payout_currency.code, payment._event.payout_country.language, true)}</p>
                  </div>
                )}

                {payment._event.pass_fees_on &&
                  <div className="flex flex-row flex-justify-between paddingBottom--small">
                    <p>Service Charge</p>
                    <p className="text-number">{calculateTotalFees(subTotal(payment.order_info.order_items), payment.order_info.discount, payment._event.taxes, payment._event.pass_fees_on, payment._event.subscribed_payment_option.paid, payment._event.payout_currency.code, payment._event.payout_country.language, true)}</p>
                  </div>
                }

                <div className="flex flex-row flex-align-center flex-justify-between">
                  <p>Total</p>
                    <p className="text-number">{calculateTotal(subTotal(payment.order_info.order_items), payment.order_info.discount, payment._event.taxes, payment._event.pass_fees_on, payment._event.subscribed_payment_option.paid, payment._event.payout_currency.code, payment._event.payout_country.language, true)}</p>
                </div>
              </div>
            </div>
            </GridColumns>
          </GridSections>
        </GridContainer>
      )
    }
    else return <p className="text-center">No Order Found</p>;
  }
}

export default OrderReceipt;