
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { FlexInputField, ButtonStyled } from '../../../styles';

const UploadField = ({
  input,
  multiple = false,
  error,
  uploading,
  handleFileUpload,
  handleFileSelected
}) =>
  <FlexInputField>
    <div className="flex flex-column flex-column--onMobile flex-justify-between">
      <input
        {...input}
        type="file"
        name={input.name}
        value={undefined}
        multiple={multiple}
        onChange={handleFileSelected} />
        
        <ButtonStyled className="marginTop--small" disabled={uploading} onClick={(e) => handleFileUpload(e)}>
          {uploading ?
            <div className="flex flex-align-center flex-justify-center">
              <FontAwesome size="2x" spin name="spinner" />
            </div> : "Upload Image"
          }
        </ButtonStyled>
    </div>
    <p className="marginTop--small text-center text-error">&nbsp;{error && error}</p>
  </FlexInputField>

export default UploadField;