import React from 'react';
import styled from 'styled-components';

const PaymentGridStyled = styled.label`
  display: grid;
  grid-template-columns: 100px 1fr 80px;
  grid-column-gap: 16px;
  grid-template-rows: 100%;
  justify-items: start;
  align-items: center;
`

const SelectPaymentType = ({ event, checked, showAmount, handleRadioSelect }) =>
  <React.Fragment>
    {event.subscribed_payment_option && !event.subscribed_payment_option.paid &&
    <PaymentGridStyled 
      className="marginBottom--small padding--small background-white border rounded-m shadow">
      <input
        type="radio"
        name='myPrice'
        value="pay as you go"
        className="item"
        checked={checked === 'pay as you go'}
        onChange={handleRadioSelect} />

      <div className="item" style={{ justifySelf: "start" }}>
        <p className="text-bold">Pay As You Go</p>
        <p className="text-grey">Attndnce's fee will be applied to each transaction.</p>
      </div>
      <div className="item">
        <h3 className="text-number text-bold">65¢</h3>
        <p className="text-small text-grey">USD per transaction</p>
      </div>
    </PaymentGridStyled>
    }

    <PaymentGridStyled 
      className="marginBottom--small padding--small background-white border rounded-m shadow">
      <input
        type="radio"
        name='myPrice'
        value='pay no fees'
        className="item"
        checked={checked === 'pay no fees'}
        onChange={handleRadioSelect} />

      <div className="item">
        <p className="text-bold">Pay No Fees</p>
        <p className="text-grey">You won't be charged 65¢ per transaction made for this event.</p>
      </div>
      <div className="item">
        <h3 className="text-number text-bold">{showAmount}</h3>
        <p className="text-small text-grey">USD per event</p>
      </div>
    </PaymentGridStyled>
  </React.Fragment>

export default SelectPaymentType;