import React from 'react';

const InstructorList = ({ instructors, handleModalSwitch }) =>
  instructors.map(instructor => {
    return (
      <div
        key={instructor._id}
        className="flex flex-column marginBottom--small padding--small background-white border rounded-m shadow"
        data-modal="view"
        style={{ cursor: "pointer" }}
        onClick={(e) => handleModalSwitch(e, instructor)}>
        <div className="flex flex-row flex-align-center flex-justify-between flex-column--onMobile flex-align-start--onMobile">
          <div className="flex flex-column">
            <p>{instructor.name}</p>
            <p className="text-small">{instructor.company}</p>
          </div>
          <div className="flex flex-column">
            <p className="text-small">{instructor.country}</p>
          </div>
        </div>
        {instructor.desc && <div className="border-top marginTop--small paddingTop--small">
          <p className="text-small">{instructor.desc}</p>
        </div>}
      </div>
    )
  })

export default InstructorList;