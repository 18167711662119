import React from 'react';

import Nav from '../Nav';
import Hero from './hero';
import SectionMain from './SectionMain';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import FormWizardPromo from './FormWizardPromo';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { FlexContainer } from '../../../styles';

class Page extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 2000
    })
  }
  render() {
    return (
      <div className="flex flex-column">
        <Nav />
        <Hero />
        <div className="background-white">
          <FlexContainer>
            <SectionMain />
            <SectionOne />
            <FormWizardPromo />
            <SectionTwo />
            <SectionThree />
          </FlexContainer>
        </div>
        <FlexContainer>
          <SectionFour />
        </FlexContainer>
      </div>
    )
  }
}

export default Page;