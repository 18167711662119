
import React from 'react'
import { FlexInputField } from '../../../styles';

const TextField = ({
  id,
  label,
  type,
  input,
  placeholder,
  style,
  className,
  autoFocus = false,
  disabled = false,
  error: apiError,
  meta: { touched, error, valid }
}) =>
  <FlexInputField className={className} style={style}>
    <div className="flex flex-row flex-align-center flex-justify-between">
      {label && <label>{label}</label>}
      <p className={`pullRight ${touched && error || apiError ? "text-error" : "text-error text-grey-blue"}`}>
        &nbsp;{touched && error || apiError ? error || apiError : ""}
        {!valid && !touched && <span>Required</span>}
      </p>
    </div>
    <input
      {...input} // contains input.name
      id={id}
      type={type}
      autoFocus={autoFocus}
      autoComplete="off"
      disabled={disabled}
      placeholder={placeholder} />
  </FlexInputField>

export default TextField;