import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getEventHook } from '../../../actions/eventActions';

import EventInfo from './EventInfo';
import Artists from './Artists';
import Videos from './Videos';
import Schedule from './Schedule';
import OrderForm from '../../checkout/OrderForm';

import isEmpty from '../../../validation/isEmpty';
import { media } from '../../../styles';
import Spinner from '../../utilities/Spinner';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 960px;
  margin: 0 auto;
  padding: 200px 16px;
  h1 {
    color: white;
    text-align: center;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 64px;
    color: #FFFFFF;
    line-height: 40px;
    ${media.handheld`
      line-height: 70px;
    `}
  }
`

const EventPage = (props) => {
  const [event, setEvent] = useState({});
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [sessions, setSessions] = useState([]);
  const myRef = useRef(null);

  const { user } = useSelector(state => state.auth)

  const dispatch = useDispatch();
  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop)
  }

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(getEventHook(props.match.params.id));
        setEvent(data);
      } catch (err) {
        setError(err)
      }
      setIsLoading(false);
    };
    runEffect();
  }, [dispatch]);

  useEffect(() => {

    if (!isEmpty(event) && !isEmpty(event.artists)) {
      setImages(event.artists);
    }
    if (!isEmpty(event) && !isEmpty(event.videos)) {
      setVideos(event.videos);
    }
    if (!isEmpty(event) && !isEmpty(event.sessions)) {
      setSessions(event.sessions);
    }

  }, [event]);

  if (event === null || isLoading) return <Spinner />
  else {
    if (!isEmpty(event)) {
      if (event.published || user && user.id === event.created_by) {
        return (
          <React.Fragment>
            <EventInfo event={event} user={user} isLoading={isLoading} scrollToRef={scrollToRef} refProps={myRef} />
            {!isEmpty(images) && <Artists artists={images} />}
            {!isEmpty(videos) && <Videos ytVideos={videos} />}
            {!isEmpty(sessions) && <Schedule eventName={event.name} eventSessions={sessions} />}
            {/* {event && <OrderForm refProp={myRef} />} */}
          </React.Fragment>
        )
      } else {
        return (
          <GridLayout>
            <p className="text-center">The event is not available at the moment.</p>
          </GridLayout>
        )
      }
    } else {
      return (
        <GridLayout>
          <p className="text-center">No Event Found.</p>
        </GridLayout>
      )
    }
  }
}

export default EventPage;