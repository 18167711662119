import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import profile from './profile';
import event from './event';
import guest from './guest';
import billing from './billing';
import discount from './discount';
import instructor from './instructor';
import sale from './sale';
import error from './error';
import session from './session';
import ticket from './ticket';
import tax from './tax';
import affiliate from './affiliate';
import mailchimp from './mailchimp';

export default combineReducers({
  form: formReducer,
  auth: auth,
  profile: profile,
  event: event,
  guest: guest,
  billing: billing,
  discount: discount,
  sale: sale,
  instructor: instructor,
  errors: error,
  session: session,
  ticket: ticket,
  tax: tax,
  affiliate: affiliate,
  mailchimp: mailchimp
});