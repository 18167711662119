
export const calculateRevenueTotal = (sales) => {
  let total = 0;
  sales.map(order => {
    total += order.total;
  });
  return total;
}

export const calculateCommission = (sales, aff) => {
  let total = 0;
  sales.reduce((prev, sale) => {
    total += sale.total;
  }, 0);

  if (aff.percent_off === parseInt(aff.percent_off, 10)) {
    return total * (aff.percent_off / 100);

  } else {
    const qty = calculateTicketQty(sales);
    return qty * aff.amount_off * 100;
  }
}

export const calculateTicketQty = (sales) => {
  let quantity = 0;
  sales.map(order => {
    order.items.reduce((prev, item) => {
      quantity += item.quantity_sold;
    }, 0);
  });
  return quantity;
}