import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import FontAwesome from 'react-fontawesome';

import { fetchSaleProgress, fetchTicketsSold } from '../../../actions/eventSaleActions';

import ProgressBar from '../../utilities/ProgressBar';
import { friendlyDate } from '../../../helpers';
import { subtractDay, startOf, endOf } from '../../../helpers/time_and_date';

const OVTicketProgress = ({ event, eventID }) => {
  const [ticketSalesProgress, setTicketSalesProgress] = useState({});
  const [ticketsSold, setTicketsSold] = useState({});

  const [ticketSalesProgressIsLoading, setTicketSalesProgressIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTicketSalesProgressIsLoading(true);

    dispatch(fetchSaleProgress(eventID, (arr) => {
      setTicketSalesProgress(arr);
      setTicketSalesProgressIsLoading(false);
    }));

    dispatch(fetchTicketsSold(eventID, (data) => {
      setTicketsSold(data)
    }))
  }, [dispatch]);

  const getTicketMax = (event) => {
    return (event.tickets || []).reduce((amount, ticket) => {
      amount = amount += ticket.quantity_available;
      return amount;
    }, 0);
  }

  // const getTicketsSold = (items) => {
  //   let match = (items || []).filter(item => event._id === item.event);
  //   if (match.length > 0) {
  //     return match[0].total_tickets_sold;
  //   } else {
  //     return 0;
  //   }
  // }

  const spinner = (
    <div className="flex flex-align-center flex-justify-center marginTop--small marginBottom--small">
      <FontAwesome size="2x" spin name="spinner" />
    </div>
  )


  return (
    <div className="background-white rounded-m border padding--small">
      <div className="overviewcard__icon"><p>Ticket Sales Progress</p></div>

      <div className="flex flex-column marginBottom--small padding--xSmall background-inputFill rounded-m">
        <p className="text-bold">{`${ticketsSold.total_tickets_sold ? ticketsSold.total_tickets_sold : "0"}`}/{getTicketMax(event)}</p>
        <ProgressBar width={`${ticketsSold.total_tickets_sold ? ((ticketsSold.total_tickets_sold / getTicketMax(event)) * 100) : "0"}%`} />
      </div>
      <div className="overviewcard__info">
        <div className="flex flex-row flex-justify-between padding--xSmall">
          <div className="flex flex-column">

          <p className="text-grey-blue">Today</p>
          <p className="text-small">{friendlyDate(new Date(), "MM/DD/YY")}</p>
          </div>
          {ticketSalesProgress === null || ticketSalesProgressIsLoading ? spinner : <p className="text-number">{ticketSalesProgress.today}</p>}
        </div>
        <div className="flex flex-row flex-align-center flex-justify-between padding--xSmall">
          <div className="flex flex-column">
            <p className="text-grey-blue">Yesterday</p>
            <p className="text-small">{friendlyDate(startOf(subtractDay(1), "day"), "MM/DD/YY")}</p>
          </div>
          {ticketSalesProgress === null || ticketSalesProgressIsLoading ? spinner : <p className="text-number">{ticketSalesProgress.yesterday}</p>}
        </div>
        <div className="flex flex-row flex-align-center flex-justify-between padding--xSmall">
          <div className="flex flex-column">
            <p className="text-grey-blue">Week</p>
            <p className="text-small">{friendlyDate(subtractDay(7), "MM/DD/YY")} - {friendlyDate(new Date(), "MM/DD/YY")}</p>
          </div>
          {ticketSalesProgress === null || ticketSalesProgressIsLoading ? spinner : <p className="text-number">{ticketSalesProgress.week}</p>}
        </div>
        <div className="flex flex-row flex-align-center flex-justify-between padding--xSmall">
          <div className="flex flex-column">
            <p className="text-grey-blue">Month</p>
            <p className="text-small">{friendlyDate(startOf(new Date(), "month"), "MM/DD/YY")} - {friendlyDate(endOf(new Date(), "month"), "MM/DD/YY")}</p>
          </div>
          {ticketSalesProgress === null || ticketSalesProgressIsLoading ? spinner : <p className="text-number">{ticketSalesProgress.month}</p>}
        </div>
      </div>
    </div>
  )
}

export default OVTicketProgress;