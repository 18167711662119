export default [
  {
    question: "Can I use ATTNDNCE for free?",
    answer: "Yes! You can create a free account, create your first event, and add the Sales Form on your own website. But you won't be able to accept any payments for your event until you setup a Stripe account and connect it with Attndnce.",
  },
  {
    question: "Do you save Credit/Bank Details?",
    answer: "No. We are never able to see card details. When they are entered in the payment area, they get sent to Stripe in real time and they will send us a random token that we interact with, never with the actual card. We do not store any credit/bank card information.",
  },
  {
    question: "Do you have a Widget",
    answer: "Yes, once you create your first event - the option will be available. Just copy the code provided and paste it anywhere on your HTML website.",
  },
  {
    question: "Why you over others?",
    answer: "3 reasons why you should use Attndnce for your events managagment and ticketing service. 1. Consolidated everything you need to accept payment online for your events. 2. Great user expereince. 3. Save on Fees.",
  },
  {
    question: "Do you have custom Forms?",
    answer: "Yes! Currently, you can request from the user, their: age, gender, Accept terms  services, and add a note when purchasing their tickets.",
  },
  {
    question: "What are my attendees Payment Options?",
    answer: "Visa ,Mastercard, American Express, Discover, JCB Diners Club, China UnionPay credit and debit cards, Apple Pay, and Google Pay.",
  },
  {
    question: "What happens when I switch from Pay As You Go -> No Fees Payment Plan?",
    answer: "Any new ticket purchases made for your event(s) will not be charged the 65¢service fee by Attndnce.",
  },
]