import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FiTrash2, FiPlus } from 'react-icons/fi';

import { createCustomField } from '../../../actions/orderFormActions';

import { FormStyle, ButtonStyled, media } from '../../../styles'
import { toTitleCase } from '../../../helpers';

const InputGridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 32px;
  align-items: center;
  justify-items: space-between;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: flex-end;
  & > button {
    width: 200px;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    & > button {
      width: auto;
    }
  `}
`

const CreateCustomFieldForm = ({ eventID }) => {
  const [inputs, setOptions] = useState(['input-option-0']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [customFieldIsValid, setCustomFieldIsValid] = useState(false);
  const [customField, setCustomField] = useState({
    question_prompt: "",
    question_type: "",
    question_options: [""],
    additional_terms: ""
  });

  const dispatch = useDispatch();

  const handleChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    const inputPattern = /\b(\w*input-option\w*)\b.+/;

    if (inputPattern.test(name)) {
      // https://stackoverflow.com/questions/55987953/how-do-i-update-states-onchange-in-an-array-of-object-in-react-hooks
      let newArr = [...customField.question_options]; // copying the old datas array
      newArr[index] = e.target.value; // replace e.target.value with whatever you want to change it to
      setCustomField((prevState) => ({
        ...prevState,
        question_options: newArr
      }));
    } else {
      setCustomField((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  }

  const removeInput = (index) => {
    const cloneInputs = [...inputs];
    cloneInputs.splice(index, 1);
    setOptions(cloneInputs);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submit me');
    setIsLoading(true);
    try {
      await dispatch(createCustomField(eventID, customField));
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  }
  
  const appendInput = () => {
    let newInput = `input-option-${inputs.length}`;
    setOptions(prevInputs => prevInputs.concat([newInput]));
  }

  const listOfInputs = inputs.map((element, index) => {
    return (
      <InputGridRow key={index}>
        <input
          type="text"
          name={element}
          onChange={(e) => handleChange(e, index)}
          value={customField.question_options[index]} />
        <div style={{ margin: "0 auto" }}>
          <FiTrash2 size="16px" onClick={() => removeInput(index)} />
        </div>
      </InputGridRow>
    )
  });

  const renderOptions = (question_type) => {
    return (
      <>
        <div className="flex flex-row flex-align-center flex-justify-between">
          <label>{toTitleCase(question_type)} Options</label>
          <p className="text-blue" onClick={() => appendInput()}>
            <FiPlus size="16px" className="marginRight-small" /><span>Another Option</span>
          </p>
        </div>

        <div className="flex flex-column marginBottom--small padding--small border rounded">
          <label>Option Name</label>
          {listOfInputs}
        </div>
      </>
    )
  }

  const renderView = (question_type) => {
    switch (question_type) {
      case 'radio':
        return renderOptions(question_type);
      case 'dropdown':
        return renderOptions(question_type);
      case 'additional_terms':
        return (
          <>
            <label>Additional Terms</label>
            <textarea
              type="text"
              name="additional_terms"
              cols="40"
              className="marginBottom--small"
              onChange={handleChange}
              value={customField.additional_terms}
              placeholder="The customer agrees that their participation in YOUR EVENT voluntary." />
          </>
        )
      default:
        return;
    }
  }

  return (
    <div className="marginTop--xSmall padding--small border rounded">
      <FormStyle onSubmit={handleSubmit}>
        <label>Question Prompt</label>
        <input
          type="text"
          name="question_prompt"
          className="marginBottom--small"
          value={customField.question_prompt}
          onChange={handleChange} />

        <label>Question Type</label>
        <select
          name="question_type"
          value={customField.question_type}
          className="marginBottom--small select-css"
          onChange={handleChange}>
          <option defaultValue value="text">Text</option>
          <option value="textarea">Paragraph Text</option>
          <option value="radio">Radio Buttons</option>
          <option value="dropdown">Dropdown</option>
          <option value="additional_terms">Additional Terms</option>
        </select>

        {renderView(customField.question_type)}

        <ButtonContainer>
          <div className="hideOnMobile">&nbsp;</div>
          <ButtonStyled disabled={customFieldIsValid || isLoading } className="button--blue">Add Custom Field</ButtonStyled>
        </ButtonContainer>
      </FormStyle>
    </div>
  )
}

export default CreateCustomFieldForm;