import React from 'react';
import styled from 'styled-components';
import { FlexContainer, media, ButtonStyled, ButtonStyledLink } from '../../../styles';
import { FiArrowRight } from "react-icons/fi";

import Skating from "../images/undraw_skating.svg";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 50px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 32px;
  color: #3A4557;
  line-height: 40px;
   ${media.handheld`
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 75px;
  align-items: center;
  margin-top: 100px;
  padding: 16px 16px 100px 16px;
  border-bottom: 1px solid #E5EAFA;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    padding-right: 24px;
    padding-left: 24px;
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  max-width: 210px;
  ${media.handheld`
    margin: 0;
    align-items: center;
    justify-content: flex-start;
  `}
`

const SectionThree = () =>
  <FlexContainer>

    <GridLayout>
      <div>
        <img src={Skating} className="marginBottom--large" alt="" />
      </div>
      <div>
        <SubTitle>Fees shouldn’t  be this expensive.</SubTitle>
        <Title>Keep it low with Attndnce</Title>
        <ButtonContainer>
          <ButtonStyledLink to="/sign-up" className="button--blue">
            <span className="marginRight--small">Try it for Free</span><FiArrowRight size="24px" />
          </ButtonStyledLink>
        </ButtonContainer>
      </div>
    </GridLayout>
  </FlexContainer>

export default SectionThree;