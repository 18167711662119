import React from 'react';

import { ButtonStyled } from '../../../styles';
import { formatPrice } from '../../../helpers';

const calculateTotalSales = (aff) => {
  let total = 0;
  aff.referrers.map(ref => {
    return ref.sale.reduce((prev, sale) => {
      total += sale.total;
    }, 0)
  });
  return total;
}

const AffiliateRemove = ({ aff, handleViewChange, handleRemoveAffiliate }) =>
  <div className="flex flex-column">
    <ul>
      <li className="marginBottom--xSmall"><strong>{aff.referrers.length > 0 ? aff.referrers.length : 0}</strong> Referrer is connected to this affiliate program.</li>
      <li className="marginBottom--xSmall"><strong>{formatPrice(calculateTotalSales(aff), "USD", "en-US")}</strong> of sales has been generated with this affiliate program.</li>
      <li className="marginBottom--xSmall">Once you delete this affiliate you will lose all the affiliate and referral sales data associated with {aff.affiliate_name}.</li>
    </ul>
    <div className="flex flex-grow-1 flex-align-center marginBottom--medium">
      <h3>Are you sure you want to remove <strong>{aff.affiliate_name}</strong>?</h3>
    </div>
    <div className="flex flex-row flex-justify-between">
      <ButtonStyled
        data-view="view"
        onClick={(e) => handleViewChange(e, aff)}>Cancel</ButtonStyled>
      <ButtonStyled className="button--red" onClick={() => handleRemoveAffiliate(aff)}>Yes, I am sure</ButtonStyled>
    </div>
  </div>

export default AffiliateRemove;