import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form'
import { FiCamera } from "react-icons/fi";

import { uploadImage } from '../../../actions';

import ImageCover from '../../utilities/ImageCover';
import UploadField from '../../utilities/formfields/UploadField';

class UploadImageContainer extends Component {
  state = {
    errors: {},
    loading: false
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ loading: false, errors: this.props.errors });
    }
  }

  handleFileSelected = (e) => {
    // const name = e.target.name;
    const file = e.target.files[0];
    this.setState({ loading: true }, () => {
      this.props.uploadImage(file, () => {
        this.setState({ loading: false });
      });
    });
  }

  handleFileUpload = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const file = this.state[name];
    this.setState({ loading: true }, () => {
      this.props.uploadImage(file, () => {
        this.setState({ loading: false });
      });
    });
  }
  
  render() {
    const { errors, loading } = this.state;

    return (
      <div className="flex flex-column flex-align-center flex-justify-center marginTop--small marginBottom--small padding--medium background-white border rounded-m">
        {localStorage.getItem('image') !== null ?
          <ImageCover>
            <img src={"data:image/jpg;base64," + localStorage.getItem('image')} alt="event image" />
          </ImageCover> :
          <div className="flex flex-column flex-align-center text-center">
            <FiCamera className="marginBottom--small" style={{ width: '32px', height: '32px' }} />
            <h3>Add {this.props.imageType} Image</h3>
            <p>Choose an image that brings your {this.props.imageType} to life.</p>
          </div>
        }
        <Field
          name="image"
          error={errors.image}
          uploading={loading}
          handleFileSelected={this.handleFileSelected}
          handleFileUpload={this.handleFileUpload}
          component={UploadField} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(mapStateToProps, { uploadImage })(UploadImageContainer);