import React, { Component } from 'react'
import StripeCheckout from 'react-stripe-checkout';
import { withRouter } from 'react-router-dom';

import { formatPrice } from '../../helpers';
import { ButtonStyled } from '../../styles';

const Payments = ({
  orderForm,
  formIsDisabled,
  code,
  country,
  order,
  amount,
  _handleToken
}) =>
  <StripeCheckout
    name="ATTNDNCE"
    description={`${formatPrice(amount, code, country)} will be paid to ATTNDNCE`}
    email={orderForm.email}
    amount={amount}
    stripeKey={process.env.REACT_APP_STRIPE_KEY}
    token={_handleToken}>
    <ButtonStyled
      className="button--green rounded-m text-bold text-uppercase pullRight"
      disabled={order.length === 0 || formIsDisabled}>Pay {formatPrice(amount, code, country)}
    </ButtonStyled>
  </StripeCheckout>

export default withRouter(Payments);