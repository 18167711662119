import React from 'react';
import { urlify } from '../../../helpers';

const createMarkup = (text) => {
  return { __html: text };
}

const DescPart = ({desc}) => 
  <p dangerouslySetInnerHTML={createMarkup(urlify(desc))} />
  
export default DescPart;