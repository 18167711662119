import React from 'react';
import FontAwesome from 'react-fontawesome';
import { ButtonStyled, FormStyle } from '../../../styles';
import Payments from './Payments';

const PaymentForm = ({ loading, amount, handleToken, email }) => {
  return (
    <Payments loading={loading} amount={amount} handleToken={handleToken} email={email}/>
  )
}

export default PaymentForm;