import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getPublicEvents } from '../../actions/eventActions';
import { getProfileByHandle } from '../../actions/profileActions';

import Spinner from '../utilities/Spinner';
import ImageCard from '../utilities/ImageCard';

import { FlexContainer } from '../../styles';

class Events extends Component {
  componentDidMount() {
    if (this.props.match.params.handle) {
      this.props.getProfileByHandle(this.props.match.params.handle, (userID) => {
        this.props.getPublicEvents(userID);
      });
    }
  }

  renderEvents = (event) => {
    return (
      <div className="col" key={event._id}>
        <ImageCard event={event} key={event.name} />
      </div>
    )
  };

  render() {
    const { event: { events, loading } } = this.props;
    let content;
    if (events === null || loading) {
      content = <Spinner />;
    } else {
      if (events.length > 0) {
        content = (
          <div className="flex flex-grid-thirds flex-justify-between">
            {events.map(event => (this.renderEvents(event)))}
          </div>
        )
      } else {
        content = <p className="text-center">No Events Found.</p>
      }
    }
    return (
      <FlexContainer>
        {content}
      </FlexContainer>
    )
  }
}

const mapStateToProps = state => ({
  event: state.event,
  profile: state.profile,
  errors: state.errors
})

export default compose(
  connect(mapStateToProps, { getProfileByHandle, getPublicEvents })
)(Events);