import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { FiCornerDownRight } from "react-icons/fi";

import { getTicketTotalBreakdown } from '../../../actions/eventSaleActions';

const OVTIcketBreakdownCard = ({ event, eventID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState({});
  const [error, setError] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(getTicketTotalBreakdown(eventID));
        setTickets(data)
      } catch (err) {
        setError(err)
      }
      setIsLoading(false);
    };
    runEffect();
  }, [dispatch]);

  const listTicketsBreakdown = (tickets) => {
    return _.map(tickets, (value, key) => {

      let ticketTypeQty = value.reduce((total, item) => {
        total = total += item.quantity_sold;
        return total;
      }, 0);

      const subTickets = _.groupBy(value, 'name');

      return (
        <React.Fragment key={key}>
          <div className="flex flex-row flex-align-center flex-justify-between marginTop--xSmall marginBottom--xSmall paddingBottom--xSmall border-bottom">
            <p>{key}</p>
            <p className="text-number">{ticketTypeQty}</p>
          </div>
          {_.map(subTickets, (subValue, subKey) => {
            return (
              <div key={subKey} className="flex flex-row flex-align-center flex-justify-between marginTop--xxSmall marginBottom--xxSmall marginLeft--xSmall">
                <div className="flex flex-row flex-align-center">
                  <FiCornerDownRight size="12px" className="marginRight--xSmall" />
                  <p className="text-grey-blue">{subKey}</p>
                </div>
                <p className="text-number">{subValue.length}</p>
              </div>
            )
          }
          )}
        </React.Fragment>
      )
    })
  }

  const spinner = <div className="flex flex-align-center flex-justify-center marginTop--small marginBottom--small"><FontAwesome size="2x" spin name="spinner" /></div>

  return (
    <div className="background-white rounded-m border padding--small">
      <div className="overviewcard__icon"><p>Ticket Types Breakdown</p></div>
      <div className="overviewcard__info" style={{ height: "100%" }}>
        {tickets === null || isLoading ? spinner :
          <React.Fragment>{Object.keys(tickets).length > 0 ? listTicketsBreakdown(tickets) : 
            <div className="flex flex-align-center flex-justify-center" style={{ height: "100%" }}>
              <p>No Tickets to breakdown.</p>
            </div>
            }
          </React.Fragment>
        }
      </div>
    </div>
  )
}
export default OVTIcketBreakdownCard;