import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { IoIosMenu } from "react-icons/io";
import { TiTicket } from "react-icons/ti";
import {
  FiX,
  FiSidebar,
  FiCreditCard,
  FiUsers,
  FiEdit,
  FiArrowLeft,
  FiList,
  FiFileText,
  FiCalendar,
  FiYoutube,
  FiBarChart2,
  FiDollarSign,
  FiBox
} from "react-icons/fi";

import { getEvent } from '../../actions/eventActions';
import { clearCurrentProfile } from '../../actions/profileActions';
import { signout } from '../../actions/authActions';

import Nav from '../Nav';
import EventEdit from './EventEdit';
import SalesReportShow from './reports/SalesReportShow';
import EventGuests from './EventGuests';

import SessionShow from './session/SessionShow';
import TicketShow from './ticket/TicketShow';
import TaxShow from './tax/TaxShow';
import VideoUpdate from './multimedia/VideoUpdate';
import OrderFormView from './order-form/';
import EventPricePlan from './plan/EventPricePlan';
import AffilateShow from './affiliate/AffiliateShow';
import EventWidget from './EventWidget';

import InstructorShow from '../instructor/InstructorShow';

import IconFilled from '../utilities/IconFilled';

import DashboardStyles from '../../styles/DashboardStyles';

import { toTitleCase, truncate } from '../../helpers'
class EventDashboard extends Component {
  state = {
    component: 'sales',
  }

  componentWillMount() {
    this.props.getEvent(this.props.match.params.id, () => {});  
  }

  toggleSubMenu = () => {
    this.setState(prevState => ({
      isSubMenuOpen: !prevState.isSubMenuOpen
    }));
  }

  toggleMainMenu = () => {
    this.setState(prevState => ({
      isMainMenuOpen: !prevState.isMainMenuOpen
    }));
  }

  setSidenavListeners = (e) => {
    const SUBHEADING_OPEN_CLASS = 'navList--subheading-open';
    const SUBLIST_HIDDEN_CLASS = 'subList--hidden';
    const selected = e.target.parentNode.nextSibling
    if (selected.classList.contains("subList--hidden")) {
      selected.classList.remove(SUBLIST_HIDDEN_CLASS);
      selected.classList.add(SUBHEADING_OPEN_CLASS);
      e.target.parentNode.classList.add(SUBHEADING_OPEN_CLASS);
    } else {
      selected.classList.add(SUBLIST_HIDDEN_CLASS);
      selected.classList.remove(SUBHEADING_OPEN_CLASS);
      e.target.parentNode.classList.remove(SUBHEADING_OPEN_CLASS);
    }
  }

  selectComponent = (e) => {
    e.preventDefault();
    this.setState(({
      component: e.currentTarget.dataset.name,
      isSubMenuOpen: !this.state.isSubMenuOpen
    }));
  }

  handleLogoutClick = (e) => {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.signout();
  }

  renderSwitch = (component, event, user) => {
    switch (component) {
      case "event info":
        return <EventEdit />
      case "tickets":
        return <TicketShow />
      case "instructors":
        return <InstructorShow />
      case "sessions":
        return <SessionShow />
      case "taxes":
        return <TaxShow />
      case "videos":
        return <VideoUpdate event={event} eventID={this.props.match.params.id} />
      case "order form":
        return <OrderFormView eventID={this.props.match.params.id} event={event} />
      case "guests":
        return <EventGuests eventID={this.props.match.params.id} />
      case "sales":
        return <SalesReportShow event={event} eventID={this.props.match.params.id} />
      case "payment plan":
        return <p>Not available at the moment.</p>
        // return <EventPricePlan user={user} eventID={this.props.match.params.id} />
      case "affiliate program":
        return <AffilateShow
              language={event.payout_country.language}
              code={event.payout_currency.code} />
      case "website integration":
        return <EventWidget event={event} />
      default:
        break;
    }
  }

  render() {
    const { event } = this.props.event;
    const { isAuthenticated, user } = this.props.auth;
    const { component, isSubMenuOpen, isMainMenuOpen } = this.state;

    return (
      <DashboardStyles>
        <div className="grid-container">
          <FiSidebar className="menu-icon" onClick={this.toggleSubMenu} />
          <header className="header">
            <div className="header__search"><p className="text-spacing-1">{truncate(event.name)}</p></div>
            <IoIosMenu onClick={this.toggleMainMenu} style={{ width: '32px', height: '32px' }} />
            <Nav
              open={isMainMenuOpen}
              toggleMainMenu={this.toggleMainMenu}
              handleLogoutClick={this.handleLogoutClick}
              isAuthenticated={isAuthenticated} />
          </header>

          <aside className={`sidenav ${isSubMenuOpen && "active"}`}>
            <FiX className="sidenav__close-icon" onClick={this.toggleSubMenu} />
            <ul className="navList">
              <div className="flex flex-row">

                {/* <BackArrow title={`${truncate(event.name, 30)}`} /> */}
                </div>
              <li className="navlist--heading text-spacing-1">
                <Link to="/account" className="flex flex-row flex-align-center">
                  <FiArrowLeft className="marginRight--xSmall" style={{ width: "24px", height: "24px" }} />
                  Account
                </Link>
              </li>

              <li
                onClick={this.selectComponent}
                className={`${component === "event info" ? "selected" : ""}`}
                data-name="event info">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiEdit size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Event Info</span>
                </div>
              </li>

              <li
                onClick={this.selectComponent}
                className={`${component === "tickets" ? "selected" : ""}`}
                data-name="tickets">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <TiTicket size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Tickets</span>
                </div>
              </li>

              <li
                onClick={this.selectComponent}
                className={`${component === "instructors" ? "selected" : ""}`}
                data-name="instructors">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiUsers size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Instructors</span>
                </div>
              </li>

              <li
                onClick={this.selectComponent}
                className={`${component === "sessions" ? "selected" : ""}`}
                data-name="sessions">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiCalendar size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">sessions</span>
                </div>
              </li>
              
              <li
                onClick={this.selectComponent}
                className={`${component === "taxes" ? "selected" : ""}`}
                data-name="taxes">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiDollarSign size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">taxes</span>
                </div>
              </li>
              
              <li
                onClick={this.selectComponent}
                className={`${component === "videos" ? "selected" : ""}`}
                data-name="videos">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiYoutube size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">videos</span>
                </div>
              </li>
              <li
                onClick={this.selectComponent}
                className={`${component === "order form" ? "selected" : ""}`}
                data-name="order form">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiFileText size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">order form</span>
                </div>
              </li>
              <li
                onClick={this.selectComponent}
                className={`${component === "guests" ? "selected" : ""}`}
                data-name="guests">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiList size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Guest List</span>
                </div>
              </li>
              <li
                onClick={this.selectComponent}
                className={`${component === "sales" ? "selected" : ""}`}
                data-name="sales">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiBarChart2 size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Sales Reporting</span>
                </div>
              </li>
              <li
                onClick={this.selectComponent}
                className={`${component === "payment plan" ? "selected" : ""}`}
                data-name="payment plan">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiCreditCard size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Payment Plan</span>
                </div>
              </li>
              <li
                onClick={this.selectComponent}
                className={`${component === "affiliate program" ? "selected" : ""}`}
                data-name="affiliate program">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiUsers size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Affiliate Program</span>
                </div>
              </li>
              <li
                onClick={this.selectComponent}
                className={`${component === "website integration" ? "selected" : ""}`}
                data-name="website integration">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiBox size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Website Integration</span>
                </div>
              </li>
            </ul>
          </aside>
          <main className="main">
            <div className="flex flex-row flex-align-center flex-justify-between padding--small background-white border-bottom">
              <p>{toTitleCase(this.state.component || "")}</p>
            </div>
            {/* <div className="main-header">
              <div className="main-header__heading">Hello User</div>
              <div className="main-header__updates">Recent Items</div>
            </div>
            <div className="main-overview">
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
            </div> */}
            <div className="main-content">
              {this.renderSwitch(this.state.component, event, user)}
            </div>
            {/* <div className="main-cards">
              <div className="card">
                
              </div>
              <div className="card">Card</div>
              <div className="card">Card</div>
            </div> */}

          </main>
          <footer className="footer">
            <div className="footer__copyright"><p>&copy; 2019 Attndnce</p></div>
            {/* <div className="footer__signature"><p>Made with love by touringlong</p></div> */}
          </footer>
        </div>
      </DashboardStyles>
    )
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  auth: state.auth,
  event: state.event,
  billing: state.billing
});

export default connect(mapStateToProps, { getEvent, clearCurrentProfile, signout })(EventDashboard);