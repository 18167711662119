import React from 'react';
import FontAwesome from 'react-fontawesome';

import { friendlyDate, formatPrice } from '../../../helpers';

const getFees = (fee, code, language) => {
  switch (fee.description) {
    case 'Stripe processing fees':
      return <span key={fee.amount}>Stripe Fee {formatPrice(fee.amount, code, language)}</span>
    case 'ATTNDNCE application fee':
      return <span key={fee.amount}>Attndnce Fee {formatPrice(fee.amount, code, language)}</span>
    default:
      return;
  }
}

const getSubTotal = (items) => {
  return items.reduce((prev, { quantity_sold, price }) => {
    return prev + (quantity_sold * price);
  }, 0);
}

const getOrderItems = (items) => {
  let orderQtyTotal = 0;
  items.reduce((init, item) => {
    orderQtyTotal += item.quantity_sold;
  }, 0)

  return <p>{orderQtyTotal}x Items</p>
}

const getTaxType = (taxes) => {
  return (taxes || []).map((tax) => {
    return (

      <p key={tax.tax_name}>{tax.tax_name} {tax.tax_percent}%</p>
    )

  });
}

const getTaxAmount = (taxes, items, code, language) => {
  return (taxes || []).map((tax) => <p key={tax.tax_name}>{formatPrice(getSubTotal(items) * ((tax.tax_percent) / 100), code, language)}</p>);
}

const getExtendedOrderItems = (items, code, language) => {
  return (items || []).map((item, index) => <p key={index} classNameName="marginBottom--xSmall">{`${item.quantity_sold}x ${item.name}`} ({formatPrice(item.price, code, language)})</p>)
}

const renderRows = (orders, code, language) => {
  return (orders|| []).map((order, index) =>
    <li className="item item-container" key={order._id}>
      <div className="attribute" data-name="#">{index + 1}</div>
      <div className="attribute-container order-information">
        <div className="attribute-container order-id">
          <div className="attribute" data-name="Order Number">{order.order_number}</div>
          <div className="attribute" data-name="Purchased Date">{friendlyDate(order.created_at, "MMM DD, YYYY")}</div>
        </div>
        <div className="attribute-container vendor-information">
          <div className="attribute" data-name="Customer Name">{order.first_name} {order.last_name}</div>
          <div className="attribute" data-name="Customer Email">{order.email}</div>
        </div>
      </div>
      <div className="attribute-container quantity">
        <div className="attribute" data-name="Order Qty">{getOrderItems(order.order_items)}</div>
        <div className="attribute" data-name="Receive Qty">{getExtendedOrderItems(order.order_items, code, language)}</div>
      </div>
      <div className="attribute-container cost">
        <div className="attribute" data-name="Amount">{formatPrice(order.amount, code, language)}</div>
        {/* <div className="attribute" data-name="Extended Amount">$2.00</div> */}
      </div>
      <div className="attribute-container tax">
        <div className="attribute" data-name="Tax %">{order.taxes && order.taxes.length > 0 ? getTaxType(order.taxes) : <p className="text-center"> — </p>}</div>
        <div className="attribute" data-name="Tax">{order.taxes && order.taxes.length > 0 ? getTaxAmount(order.taxes, order.order_items, code, language) : <p className="text-center"> — </p>}</div>
      </div>
      <div className="attribute-container fees">
        <div className="attribute" data-name="Fees">{formatPrice(order.fee, code, language)}</div>
        <div className="attribute" data-name="Fees Details">{order.fee_details && (order.fee_details || []).map(fee => getFees(fee, code, language))}</div>
      </div>
      <div className="attribute" data-name="Net">{formatPrice(order.net, code, language)}</div>
    </li>
  )
}

const spinner = (
  <div className="flex flex-align-center flex-justify-center marginTop--large marginBottom--large">
    <FontAwesome size="2x" spin name="spinner" />
  </div>
)

const ReportTableRow = ({ eventSales, isLoading, event }) => {
  if (eventSales === null || isLoading) return spinner;
  else if (eventSales.length === 0) return <p>No orders found.</p>
  else {
    const { user: { currency: { code }, language } } = event;
    return renderRows(eventSales, code, language);
  }
}

export default ReportTableRow;