import React from 'react';
import styled from 'styled-components';
import * as custom from '../../styles/Variables';
import { media } from '../../styles'

const TagStyled = styled.div`
  flex-grow: 1;
  background: #f5f6f7;
  border-radius: 24px;
  border: 1px solid #e8e8e8;
  margin-right: ${custom.SPACE_medium};
  padding: ${custom.SPACE_small} ${custom.SPACE_xSmall};
  &:last-child {
    margin-right: 0;
    ${media.handheld`
      margin: 0;
    `}
  }
`

const Tags = ({ tags }) =>
  <div className="flex flex-row">
    {(tags || []).map((tag, index) => <TagStyled key={index} className="text-center"><p className="text-bold text-small text-uppercase text-spacing-1">{tag}</p></TagStyled>)}
  </div>

export default Tags;