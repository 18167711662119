import axios from 'axios';

import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_EVENT
} from './types';

export const uploadImage = (file, callback) => async dispatch => {
  try {
    let data = new FormData();
    data.append('image', file);
    const res = await axios.post('/api/upload/image', data);
    window.localStorage.setItem('image', res.data.image);
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
};

export const updateVideos = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/upload/video/${eventID}`, formValue);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_EVENT,
      payload: []
    });
  }
};

// export const uploadMultiImage = (file, eventID) => async dispatch => {
//   try {
//     let fd = new FormData();
//     fd.append('multi', file[0]);
//     fd.append('multi', file[1]);
//     fd.append('eventID', eventID); // Event ID
//     const res = await axios.post('/api/upload/multiple_uploads', fd);
//   } catch (err) {
//     dispatch({
//       type: GET_ERRORS,
//       payload: err.response.data
//     })
//   }
// };

export const getCurrentCustomerCurrency = (callback) => async dispatch => {
  try {
    const res = await axios.get('/api/event/customer-ip');
    callback(res.data.data.code);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};