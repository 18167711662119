import React from 'react';

import { formatPrice, truncate, fromNow } from '../../../helpers';

const TicketList = ({ event, tickets, handleModalSwitch }) => {
  const { payout_currency: { code }, payout_country: { language } } = event;

  if (tickets.length < 1) {
    return (
      <div style={{ height: "100vh" }} className="flex flex-column marginBottom--small padding--small background-white border rounded-m shadow">
        <p>No tickets found.</p>
      </div>
    )
  }
  return (tickets || []).map(ticket => {
    return (
      <div
        key={ticket._id}
        className="flex flex-column marginBottom--small padding--small background-white border rounded-m shadow"
        data-modal="view"
        style={{ cursor: "pointer" }}
        onClick={(e) => handleModalSwitch(e, ticket)}>

        <div className="flex flex-row flex-align-center flex-justify-between">
          <div className="flex flex-column">
            <p className="marginRight--xSmall text-bold text-spacing-1">{truncate(ticket.name.toUpperCase(), 49)}</p>
            <p className="text-small">Released {fromNow(ticket.start_date)}</p>
          </div>

          <div className="flex flex-column">
            <p className="text-number text-right">{formatPrice(ticket.price, code, language)}</p>
            <p className="text-small text-right">Incl. Tax and Service Fees</p>
          </div>
        </div>
        {ticket.desc && <div className="border-top marginTop--small paddingTop--small">
          <p className="text-small text-grey">{ticket.desc}</p>
      </div>}
      </div>
    )
  })
}

export default TicketList;