import React from 'react';
import FontAwesome from 'react-fontawesome';

import ImageWrapper from '../utilities/ImageWrapper';

import festivities from './images/undraw_festivities_tvvj.svg';

import { StyledCard, ButtonStyledLink } from '../../styles';

const NoEvent = () =>
  <div className="flex-grid">
    <div className="col-4">
      <StyledCard>
        <ImageWrapper>
          <img src={festivities} alt="" />
        </ImageWrapper>
        <h3>Create your First Event</h3>
        <p>It'll take less than 5mins to setup.</p>
        <ul className="marginTop--large">
          <li className="flex flex-row flex-align-center marginBottom--small paddingBottom--small border-bottom">
            <FontAwesome name="align-justify" className="marginRight--small" />
            Add Event Details
          </li>
          <li className="marginBottom--small paddingBottom--small border-bottom">
            <FontAwesome name="ticket" className="marginRight--small" />
            Set Ticket Prices
          </li>
          <li className="marginBottom--small paddingBottom--small">
            <FontAwesome name="search" className="marginRight--small" />
            Review and Publish
          </li>
        </ul>
        <ButtonStyledLink to="/event/create" className="button--blue">Create Event</ButtonStyledLink>

      </StyledCard>
    </div>
  </div>

export default NoEvent;