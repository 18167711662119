import axios from 'axios';

import {
  GET_ERRORS,
  SALE_LOADING
} from './types'

export const getRevenue = (eventID) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/revenue/${eventID}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export const fetchTicketsSold = (eventID, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/tickets-sold/${eventID}`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}

export const fetchSaleProgress = (eventID, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/progress/${eventID}`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}

export const getTicketTotalBreakdown = (eventID) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/ticket-breakdown/${eventID}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export const getEventSales = (eventID) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/event/${eventID}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

// Set loading state
export const setSaleLoading = () => {
  return {
    type: SALE_LOADING
  };
};