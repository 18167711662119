import isEmpty from '../../validation/isEmpty'

const validate = (fields) => {
  let errors = {};
  let isValid;
  let fieldsValid = {};

  fields.first_name = !isEmpty(fields.first_name) ? fields.first_name : '';
  fields.last_name = !isEmpty(fields.last_name) ? fields.last_name : '';
  fields.email = !isEmpty(fields.email) ? fields.email : '';
  fields.gender = !isEmpty(fields.gender) ? fields.gender : '';
  fields.age = !isEmpty(fields.age) ? fields.age : '';
  fields.notes = !isEmpty(fields.notes) ? fields.notes : '';

  // first_name
  isValid = fields.first_name.length > 0 && fields.first_name.length >= 3 ? true : false;
  errors.first_name = isValid ? "" : "First Name is too short";
  fieldsValid = {
    ...fieldsValid,
    first_name: isValid
  }

  // last_name
  isValid = fields.last_name.length > 0 && fields.last_name.length >= 3 ? true : false;
  errors.last_name = isValid ? "" : "Last Name is too short";
  fieldsValid = {
    ...fieldsValid,
    last_name: isValid
  }
  // email
  isValid = fields.email.length > 0 && fields.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
  errors.email = isValid ? "" : "Email address is not valid";
  fieldsValid = {
    ...fieldsValid,
    email: isValid
  }


  // age
  isValid = fields.age.length > 0 && fields.age.length === 2 ? true : false;
  errors.age = isValid ? "" : "Please add your age";
  fieldsValid = {
    ...fieldsValid,
    age: isValid
  }

  // notes
  isValid = fields.notes.length >= 5 || fields.notes.length === 0 ? true : false;
  errors.notes = isValid ? "" : "Please add some more details.";
  fieldsValid = {
    ...fieldsValid,
    notes: isValid
  }

  // gender
  isValid = fields.gender === "" ? false : true;
  errors.gender = isValid ? "" : "Please choose a gender";
  fieldsValid = {
    ...fieldsValid,
    gender: isValid
  }


  if (Array.isArray(fields.custom_fields) && fields.custom_fields.length > 0) {
    (fields.custom_fields || []).map((cf, i) => {
      if (cf.require) {

        if (cf.question_type === "additional_terms") {
          isValid = cf.response ? true : false;
          errors[`custom_field_${i}`] = isValid ? "" : "Agree to the terms to proceed.";
          fieldsValid = {
            ...fieldsValid,
            [`custom_field_${i}`]: isValid
          }
        } else {
          isValid = !isEmpty(cf.response) ? true : false;
          errors[`custom_field_${i}`] = isValid ? "" : "Field is required.";
          fieldsValid = {
            ...fieldsValid,
            [`custom_field_${i}`]: isValid
          }
        }
      }
      else {
        errors[`custom_field_${i}`] = "";
      }

    });
  }

  return {
    errors,
    fieldsValid: fieldsValid
  }
};

export default validate;