import React, { useState } from 'react';
import styled from 'styled-components';
import { FiCreditCard, FiUserX } from "react-icons/fi";
import { IoMdBarcode } from "react-icons/io";

import { media } from '../../../styles';

import Event from "../images/iphone-checkin.png";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  align-items: center;
  padding: 24px;
  margin-bottom: 10px;
  background: #FAFAFA;
  border-radius: 8px;

  &.highlight {
    background: #E5F1FA;
  }
`

const ListItemHeadline = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 16px;
  align-items: center;
  p {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
  }
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 75px;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const SectionTwo = () => {
  const [active, setActive] = useState("check-in");

  const toggleClass = (clicked) => {
    setActive(active === clicked ? null : clicked)
  }

  return (

      <GridLayout>
        <div>
          <Title>Impress your Attendees</Title>
          <SubTitle>We're focused on great user experiences; Attndnce is secure, professional, and fast.</SubTitle>
          <div>
            <ListItem className={active === "online-payments" && "highlight"}>

              <ListItemHeadline onClick={() => toggleClass("online-payments")}>

                <FiCreditCard size="24px" />
                <p>Online Payments</p>
              </ListItemHeadline>
              {active === "online-payments" ?
                <p className="marginTop--xSmall">We teamed up with Stripe to provide you with one of the lowest fees per transaction.</p>
                :
                <div></div>
              }
            </ListItem>
            <ListItem className={active === "no-account" && "highlight"}>

              <ListItemHeadline onClick={() => toggleClass("no-account")}>
                <FiUserX size="24px" />
                <p>No Forced Account Creation</p>
              </ListItemHeadline>
              {active === "no-account" ?
                <p className="marginTop--xSmall">Your customers just want to attend your event - we make that experience real simple.</p>
                :
                <div></div>
              }
            </ListItem>
            <ListItem className={active === "check-in" && "highlight"}>

              <ListItemHeadline onClick={() => toggleClass("check-in")}>
                <IoMdBarcode size="24px" />
                <p>QR Mobile Check-In</p>
              </ListItemHeadline>
              {active === "check-in" ?
                <p className="marginTop--xSmall">Scan and register your customers on the day of the event with Attndnce Android and iOS QR app scanner.</p>
                :
                <div></div>
              }
            </ListItem>
          </div>
        </div>
        <div>
          <img src={Event} alt="" />
        </div>
      </GridLayout>

  )
}
export default SectionTwo;