import axios from 'axios';
import {
  GET_ERRORS,
  GET_AFFILIATES,
  DELETE_AFFILIATE,
  AFFILIATE_LOADING
} from './types'

export const fetchAffiliates = (eventID, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/affiliate/all/${eventID}`);
    dispatch({
      type: GET_AFFILIATES,
      payload: res.data
    });
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_AFFILIATES,
      payload: []
    });
  }
};

export const fetchAffiliateNull = (eventID, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/affiliate/null/${eventID}`);
    dispatch({
      type: GET_AFFILIATES,
      payload: res.data
    });
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_AFFILIATES,
      payload: []
    });
  }
};

export const fetchAffiliations = () => async dispatch => {
  try {
    const res = await axios.get(`/api/affiliate/affiliations`);
    dispatch({
      type: GET_AFFILIATES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_AFFILIATES,
      payload: []
    });
  }
};

export const createAffiliate = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/affiliate/create/${eventID}`, formValue);
    dispatch({
      type: GET_AFFILIATES,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_AFFILIATES,
      payload: []
    });
  }
}

export const updateAffiliate = (affID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/affiliate/edit/${affID}`, formValue);
    dispatch({
      type: GET_AFFILIATES,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_AFFILIATES,
      payload: []
    });
  }
}

export const deleteAffiliate = (affID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/affiliate/remove/${affID}`, formValue);
    dispatch({
      type: DELETE_AFFILIATE,
      payload: affID
    })
    callback(res.data);
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
};

// Set loading state
export const setAffiliateLoading = () => {
  return {
    type: AFFILIATE_LOADING
  };
};