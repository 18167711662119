import React from 'react';
import styled from 'styled-components';
import Spinner from '../utilities/Spinner';

import "../../css/stripe-button.css";
import NoStripe from '../empty-state/NoStripe';
import YesStripe from '../empty-state/YesStripe';

import { media } from '../../styles'

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`

const StripeInfo = ({ stripe, loading }) => {
  let content;
  if (stripe === null || loading) {
    return <Spinner />
  } else {
    if (Object.keys(stripe).length > 0) {
      content = <YesStripe />
    } else {
      content = (
        <div>
          <div className="marginBottom--small">
            <p>Connect to Stripe</p>
            <p>Currently not accepting new customers due to pandemic.</p>
            {/* <p className="text-small text-grey">We use Stripe to ensure you get paid fast and securely.</p> */}
          </div>
          {/* <NoStripe /> */}
        </div>
      )
    }
  }
  return (
    <GridLayout>
      {content}
    </GridLayout>
  )
    
}

export default StripeInfo