import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { addToEvent, getInstructors } from '../../actions/instructorActions';
import { fetchEvent } from '../../actions/eventActions';

import MultiSelectField from '../utilities/formfields/MultiSelectField';
import SnackBar from '../utilities/snackbar/SnackBar';
import Spinner from '../utilities/Spinner';

import { FormStyle, ButtonStyled, StyledCard } from '../../styles';

class InstructorAddToEvent extends Component {

  state = {
    errors: {},
    message: "",
    messageType: "",
    loading: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidMount() {
    this.props.getInstructors();
    this.props.fetchEvent(this.props.eventID, () => { });
  }

  handleCloseSnack = () => {
    this.setState({ message: null });
  }

  beforeSubmit = (formValues) => {
    this.setState({ loading: true }, () => {
      this.props.addToEvent(this.props.eventID, formValues, ({ success }) => {
        this.setState({ loading: false, message: success, messageType: "success" });
      });
    });
  }

  render() {
    const { handleSubmit, instructors } = this.props;
    const { errors, message, messageType, loading } = this.state;
    if (loading) return <Spinner />
    return (
      <StyledCard>
        <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
          <Field
            isInfinite
            label="Add Instructors to Event"
            name="_instructors"
            placeholder="Adolfo, Terry, Fadi Fusion"
            textField="name"
            valueField="_id"
            data={instructors}
            errors={errors._instructors}
            component={MultiSelectField} />

          <div className="flex flex-row flex-align-center flex-justify-between marginTop--small">
            {message && <SnackBar message={message} type={messageType} handleCloseSnack={this.handleCloseSnack} />}
            <div className="flex flex-column pullRight">
              <ButtonStyled type="submit" className="button--blue">Add to Event</ButtonStyled>
            </div>
          </div>
        </FormStyle>
      </StyledCard>
    )
  }
}

const mapStateToProps = state => ({
  initialValues: state.event.event,
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { addToEvent, getInstructors, fetchEvent }),
  reduxForm({
    enableReinitialize: true,
    form: 'addToEventInstructor'
  })
)(InstructorAddToEvent);