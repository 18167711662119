import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FiMail } from "react-icons/fi";

import { FlexContainer, media } from '../../../styles';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: auto;
  grid-template-rows: auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  position: absolute;
  top: 0;
  z-index: 100;
`

const Logo = styled.div`
  color: #FFFFFF;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 8px;
  ${media.handheld`
    margin-left: 16px;
    font-size: 20px;
    line-height: 32px;
  `}
`

const ButtonText = styled.span`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1px;
  ${media.handheld`
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
  `}
`

const Nav = () =>
  <FlexContainer>
    <GridLayout>
      <div className="flex flex-column flex-justify-center flex-justify-start"><Link to={`/?ref=nav_logo`}><Logo>ATTNDNCE</Logo></Link></div>
        <a className="flex flex-row flex-align-center flex-justify-end text-right text-white hideOnMobile" href="mailto:support@attndnce.com?subject=inquiry">
          <FiMail className="text-white marginRight--small" style={{ width: "32px", height: "32px" }} />
          <ButtonText style={{ textShadow: '0 2px 5px rgba(25,0,96,1)' }}>support@attndnce.com</ButtonText>
        </a>
    </GridLayout>
  </FlexContainer>


export default Nav;