import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import _ from 'lodash';

import { fetchSessions, deleteSession } from '../../../actions/sessionActions';

import SessionCreate from './SessionCreate';
import SessionEdit from './SessionEdit'
import SessionSchedule from './SessionSchedule';
import SessionList from './SessionList';

import IconFilled from '../../utilities/IconFilled';
import Spinner from '../../utilities/Spinner';

import { IoMdAdd, IoIosList, IoIosCalendar } from 'react-icons/io'
import { FiEdit, FiTrash, FiX } from 'react-icons/fi'

import { StyledCard, ButtonStyled } from '../../../styles';
import { friendlyDate, toTitleCase } from '../../../helpers';

class SessionShow extends React.Component {
  state = {
    loading: false,
    message: "",
    modalIsOpen: false,
    session: {},
    sessions: [],
    sessionID: "",
    selectedDay: "",
    modalContent: "",
    currentView: "list",
    modalHeight: "",
    isScheduleInit: false
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentDidMount() {
    this.props.fetchSessions(this.props.match.params.id);
  }

  openModal = (e) => {
    e.preventDefault()
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleSessionState = (sessions, selectedDay, scheduleInit = true) => {
    this.setState({ sessions: sessions, selectedDay: selectedDay, isScheduleInit: scheduleInit });
  }

  handleRemoveSession = (eventID, session) => {
    this.props.deleteSession(eventID, session, (sessions) => {
      this.handleClearState(sessions);
    });
  }

  handleModalSwitch = (e, session = {}) => {
    this.setState({
      sessionID: session._id,
      session: session,
      modalIsOpen: true,
      modalContent: e.currentTarget.dataset.modal,
      modalHeight: e.currentTarget.dataset.modalh
    });
  }

  handleView = (e, sessions) => {
    this.setState({
      currentView: e.currentTarget.dataset.view,
      sessions: sessions,
      isScheduleInit: false,
      modalIsOpen: false
    });
  }

  handleCompleteCreate = (sessions) => {
    this.setState({
      loading: false,
      modalIsOpen: false,
      modalContent: "",
      sessionID: "",
      session: {},
      sessions: sessions,
      selectedDate: "",
    });
  }

  handleClearState = (sessions) => {
    this.setState({
      loading: false,
      currentView: "list",
      modalIsOpen: false,
      modalContent: "",
      sessionID: "",
      session: {},
      sessions: sessions,
      selectedDate: "",
      isScheduleInit: false
    });
  }
  // handleClearState = (session, sessionID) => {
  //   if (!_.isEqual(this.state.session, session)) {
  //     console.log('if', !_.isEqual(this.state.session, session));
  //     console.log('sessions', this.state.sessions)
  //     console.log('session', session)
  //     var foundIndex = this.state.sessions.findIndex(x => x._id == sessionID);
  //     let sessionsCopy = this.state.sessions;
  //     sessionsCopy[foundIndex] = session;
  //     console.log('sessionsCopy', sessionsCopy);
  //     this.setState({
  //       loading: false,
  //       modalIsOpen: false,
  //       modalContent: "",
  //       sessionID: "",
  //       session: {},
  //       sessions: sessionsCopy,
  //       selectedDate: ""
  //     });
  //   };
  //   console.log('final', this.state);
  // }

  handleLoading = (callback) => {
    this.setState({ loading: true }, () => {
      callback();
    });
  }

  renderView = (name, sessions) => {
    switch (name) {
      case 'calendar':
        return <div className="padding--medium background-white border rounded-m shadow"><SessionSchedule
          sessions={sessions}
          stateSessions={this.state.sessions}
          isScheduleInit={this.state.isScheduleInit}
          handleModalSwitch={this.handleModalSwitch}
          handleSessionState={this.handleSessionState} /></div>
      case 'list':
        return <SessionList
          sessions={sessions}
          handleModalSwitch={this.handleModalSwitch} />
      case 'create':
        return <SessionCreate
          eventID={this.props.match.params.id}
          handleClearState={this.handleClearState}
          closeModal={this.closeModal} />
      case 'edit':
        return <SessionEdit
          eventID={this.props.match.params.id}
          loading={this.props.session.loading}
          session={this.state.session}
          sessions={this.props.session.sessions}
          handleClearState={this.handleClearState}
          handleLoading={this.handleLoading}
          closeModal={this.closeModal} />
      default:
        break;
    }
  }

  renderModalContent = (name) => {
    switch (name) {
      case 'view':
        return (
          <div
            style={{ height: "100%" }}
            className="flex flex-column flex-justify-between padding--small paddingBottom--large paddingTop--small rounded flex-grow-1">
            <div className="flex flex-column">
              <div className="flex flex-row flex-align-center flex-justify-end">
                <IconFilled 
                  isPointer={true}
                  isHover={true}
                  cHeight="32px"
                  cWidth="32px"
                  data-view="edit"
                  className="marginRight--small" 
                  onClick={(e) => this.handleView(e, this.state.session)}>
                  <FiEdit size="1rem" />
                </IconFilled>

                <IconFilled 
                  isPointer={true}
                  isHover={true}
                  cHeight="32px"
                  cWidth="32px"
                  data-modalh=""
                  data-modal="remove"
                  className="marginRight--small"
                  onClick={(e) => this.handleModalSwitch(e, this.state.session)}>
                  <FiTrash size="1rem" />
                </IconFilled>

                <IconFilled 
                  isPointer={true}
                  isHover={true}
                  cHeight="32px"
                  cWidth="32px"
                  onClick={this.closeModal}>
                  <FiX ize="1rem"/>
                </IconFilled>
              </div>

              <h3>{this.state.session.name}</h3>
              <p className="marginBottom--small text-small">{`${friendlyDate(this.state.session.start_date, 'ddd, MMM DD ')} ${friendlyDate(this.state.session.start_date, 'HH:mm')} - ${friendlyDate(this.state.session.end_date, 'HH:mma')}`}</p>

              <div className="flex flex-row">
                {(this.state.session.tracks || []).map((tr, index) => {
                  return (
                    <div className="marginRight--xSmall" key={index}>
                      <p className="text-small">{tr}</p>
                    </div>
                  )
                })
                }
              </div>
              <p className="text-small">{this.state.session.session_type}</p>
            </div>
          </div>
        )
      case 'remove':
        return (
          <div
            style={{ height: "100%" }}
            className="flex flex-column padding--medium rounded flex-grow-1">
            <div className="flex flex-grow-1 flex-align-center marginBottom--medium">
              <p>Are you sure you want to remove {this.state.session.name}?</p>
            </div>
            <div className="flex flex-row flex-justify-between">
              <ButtonStyled onClick={this.closeModal}>Cancel</ButtonStyled>
              <ButtonStyled className="button--red" onClick={() => this.handleRemoveSession(this.props.match.params.id, this.state.session)}>Yes, I am sure</ButtonStyled>
            </div>
          </div>
        )
      default:
        break;
    }
  }

  render() {
    const { event: { event } } = this.props;
    const { session, sessions } = this.props.session;
    const { message, currentView, modalIsOpen, modalContent, modalHeight } = this.state;

    const customStyles = {
      content: {
        height: modalHeight, borderRadius: '4px'
      }
    };

    if (sessions === null || this.props.match.params.id === "") return <Spinner />

    return (
      <React.Fragment>
        {message !== "" &&
          <div className="flex flex-align-center flex-justify-center marginBottom--small padding--medium background-green shadow rounded-m">
            <p className="text-small text-white">{message}</p>
          </div>
        }
        <div className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall paddingTop--small paddingBottom--small">

          <p className="text-grey-blue">{toTitleCase(currentView)}</p>

          <div className="flex flex-row">
            <IconFilled 
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              className="marginRight--small">
              <IoIosList
                data-view="list"
                size="1rem"
                style={{ cursor: "pointer" }}
                onClick={(e) => this.handleView(e, sessions)} />
            </IconFilled>

            <IconFilled 
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              className="marginRight--small">
              <IoIosCalendar
                data-view="calendar"
                size="1rem"
                style={{ cursor: "pointer" }}
                onClick={(e) => this.handleView(e, sessions)} />
            </IconFilled>

            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="create"
              onClick={(e) => this.handleView(e)}>
              <IoMdAdd size="1rem" style={{ cursor: "pointer" }} />
            </IconFilled>
          </div>
        </div>
        {this.renderView(currentView, sessions)}
        <Modal
          isOpen={modalIsOpen}
          className="modal"
          overlayClassName="overlay"
          style={customStyles}
          contentLabel="Add Session">
          <div className="modal-guts">
            {this.renderModalContent(modalContent)}
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  event: state.event,
  session: state.session
});

export default connect(mapStateToProps, { fetchSessions, deleteSession })(withRouter(SessionShow));

