import React from 'react';
import styled from 'styled-components';

import { media } from '../../styles';
import * as custom from '../../styles/Variables';

const ImageWrapperStyled = styled.div`
/* even more control with max-width */
  max-width: 960px;
	height: 25vh;
  background: white;
  border-radius: ${custom.BORDER_RADIUS};
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
  overflow: hidden;
  ${media.handheld`
    margin: 0 16px;
    height: 200px;
  `}
`

const ImageCover = ({ classNames, children }) =>
  <ImageWrapperStyled className={classNames}>
    {children}
  </ImageWrapperStyled>

export default ImageCover;