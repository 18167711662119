import React from 'react';

import { StyledCard, GridStyledCard, FormStyle, ButtonStyled } from '../../../styles';

import { formatPrice } from '../../../helpers';
import { calculateRevenueTotal, calculateCommission, calculateTicketQty } from '../../../helpers/affiliateCalculations';

const ReferrerView = ({ aff, referrer, eventID, language, code }) => {
  console.log('aff', aff)
  return (
    <div className="flex flex-column flex-justify-between">
      <StyledCard>
        <GridStyledCard>
          <div className="flex flex-column flex-justify-between">
            <div className="flex flex-row flex-justify-between marginBottom--small paddingBottom--small border-bottom">
              <p className="text-small">Name:</p>
              <p className="text-small">{referrer.referrer_info.first_name}</p>
            </div>
            <div className="flex flex-row flex-justify-between">
              <p className="text-small">Email:</p>
              <p className="text-small">{referrer.referrer_info.email}</p>
            </div>
          </div>
          <div className="item">

            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Tickets Sold</p>
              <h3>{calculateTicketQty(referrer.sale)}</h3>
            </div>
            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Revenue</p>
              <h3>{formatPrice(calculateRevenueTotal(referrer.sale), code, language)}</h3>
            </div>
            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Commission</p>
              <h3>{formatPrice(calculateCommission(referrer.sale, aff), code, language)}</h3>
            </div>
          </div>
        </GridStyledCard>
      </StyledCard>
      <StyledCard>
        <p>Get {aff.percent_off ? `${aff.percent_off}%` : `$${aff.amount_off}`} commission for every ticket sold.</p>
      </StyledCard>
      <StyledCard>
        <FormStyle>
          <div className="flex flex-row flex-align-center">
            <label>Refer friends</label>
            <input
              id="unique_url"
              type="text"
              readOnly={true}
              value={`${process.env.NODE_ENV === 'production' ? 'https://www.attndnce.com' : 'localhost:3000'}/event/${eventID}?affname=${aff.affiliate_name}&affid=${aff._id}&refid=${referrer.referrer_info._user}`} />
            <ButtonStyled className="marginLeft--xSmall" onClick={e => {
              e.preventDefault();
              /* Get the text field */
              var copyText = document.getElementById("unique_url");

              /* Select the text field */
              copyText.select();

              /* Copy the text inside the text field */
              document.execCommand("copy");

            }}>Copy</ButtonStyled>
          </div>
        </FormStyle>
      </StyledCard>
    </div>
  )
}
export default ReferrerView;