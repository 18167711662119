import React from 'react'
import styled from 'styled-components';
import { friendlyDate } from '../../helpers';
import * as custom from '../../styles/Variables';

const StyledDate = styled.div`
  display: flex;
  width: 34px;
  height: 78px;
  padding: 0px 22px;
  margin-right: ${custom.SPACE_medium};
  background: #FFFFFF;
  border: 1px solid ${custom.COLOR_BORDER};
  border-radius: ${custom.BORDER_RADIUS};
  text-align: center;
  /* JAN: */
  p {
    color: ${custom.COLOR_DARK};
    line-height: 24px;
    word-break: normal;
    .month {
      text-align: center;
      display: block;
    }
    /* 05: */
    .day {
      text-align: justify;
    }
  }

`

const DateStamp = ({ startDate }) => {
  return (
    <StyledDate className="flex-align-center flex-justify-center">
      <p className="text-number">
        <span className="month">{friendlyDate(startDate, 'MMM')}</span>
        <span className="day">{friendlyDate(startDate, 'DD')}</span>
      </p>
    </StyledDate>
  )
}

export default DateStamp;
