import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import FontAwesome from 'react-fontawesome';

import { createDiscount } from '../../actions/discountActions';

import TextField from '../utilities/formfields/TextField';
import TextAreaField from '../utilities/formfields/TextAreaField';
import DateTimePickerField from '../utilities/formfields/DateTimePickerField';
import DropDownListField from '../utilities/formfields/DropDownField';

import { required } from '../../helpers/formValdation';
import isEmpty from '../../validation/isEmpty';

import { FormStyle, ButtonStyled } from '../../styles';

class DiscountCreate extends Component {
  state = {
    errors: {},
    loading: false
  }

  componentDidMount() {
    this.props.initialize({
      is_unlimited: { value: true },
      does_expire: { value: false },
      desc: "Discount will be applied during the checkout process.",
      is_percent: true,
      is_active: true
    });
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors, loading: false }, () => {
        if (this.state.errors.code === 11000) {
          this.setState({ errors: { code: "A discount code by this name is already in use" } });
        }
      });
    }
  }

  handleClearAmount = (e, newValue, previousValue, name) => {
    // console.log(e, newValue, previousValue, name);
    this.props.updateField('amount_off', '');

  }

  handleClearPercent = (e, newValue, previousValue, name) => {
    console.log(e, newValue, previousValue, name);
    this.props.updateField('percent_off', '');
  }

  beforeSubmit = (formValues) => {
    this.setState({ loading: true }, () => {
      this.props.createDiscount(formValues, () => {
        this.setState({ loading: false }, () => {
          this.props.handleClearState();
        });
      });
    });

  }

  render() {
    const { handleSubmit, percent_off, amount_off, is_unlimited, does_expire } = this.props;
    const { errors, loading } = this.state;

    return (
      <div className="flex flex-column marginBottom--small padding--medium background-white border rounded-m shadow">

        <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>

          <Field
            label="Code"
            type="text"
            name="code"
            placeholder="E.g. Members, Child, Senior, Military etc."
            error={errors.code}
            validate={required}
            component={TextField} />

          <div className="flex flex-grid-half flex-align-center flex-justify-between">

            <Field
              label="Amount"
              type="text"
              name="amount_off"
              placeholder="$10"
              className="col"
              error={errors.amount_off}
              validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : undefined}
              onChange={this.handleClearPercent}
              component={TextField} />

            <Field
              label="Percent"
              type="text"
              name="percent_off"
              placeholder="45%"
              className="col"
              error={errors.amount_off || percent_off && amount_off > 100 && "Has to be less than or equal to 100%"}
              validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : undefined}
              onChange={this.handleClearAmount}
              component={TextField} />

          </div>
          <div>{errors.amount_off}</div>
          <label>Uses</label>
          <div className="flex flex-grid-half flex-justify-between">
            <Field
              type="text"
              name="is_unlimited"
              placeholder="Please select use amount"
              className="col"
              valueField="value"
              textField="name"
              data={[{ name: 'limited to', value: false }, { name: 'unlimited', value: true }]}
              error={errors.limit}
              validate={required}
              component={DropDownListField} />
            {is_unlimited === false &&
              <Field
                type="text"
                name="quantity_available"
                placeholder="50"
                className="col"
                validate={required}
                error={errors.quantity_available}
                component={TextField} />
            }
          </div>

          <label>Expires on</label>
          <div className="flex flex-grid-half flex-justify-between">
            <Field
              type="text"
              name="does_expire"
              placeholder="Please select expiration type"
              className="col"
              valueField="value"
              textField="name"
              data={[{
                name: 'Does not expire',
                value: false
              }, {
                name: 'Expires on specific date',
                value: true
              }]}
              validate={required}
              error={errors.does_expire}
              component={DropDownListField} />
            {does_expire &&
              <Field
                type="text"
                name="expire_date"
                placeholder="10/03/2019"
                className="col"
                validate={required}
                error={errors.expire_date}
                component={DateTimePickerField} />
            }
          </div>

          <Field
            label="Description"
            type="text"
            name="desc"
            placeholder="10% discount will be applied during checkout"
            error={errors.desc}
            validate={required}
            component={TextAreaField} />

          <div className="flex flex-row flex-align-center marginBottom--small">
            <Field
              name="is_active"
              type="checkbox"
              component="input" />
            <p className="marginRight--xSmall">Active</p>
          </div>

          <div className="flex flex-row marginTop--small pullRight">
            <ButtonStyled disabled={loading} className="flex flex-row flex-align-center pullRight button--blue">
              {loading && <FontAwesome spin name="spinner" className="marginRight--small text-white" />}
              Create Discount
            </ButtonStyled>
          </div>

        </FormStyle>
      </div>
    )
  }
}

const selector = formValueSelector('createDiscount')

const mapStateToProps = state => ({
  errors: state.errors,
  is_unlimited: selector(state, 'is_unlimited.value'),
  does_expire: selector(state, 'does_expire.value'),
  amount_off: selector(state, 'amount_off'),
  percent_off: selector(state, 'percent_off')
});

export default compose(
  reduxForm({ form: 'createDiscount' }),
  connect(mapStateToProps, { createDiscount, updateField: (field, newValue) => change('createDiscount', field, newValue) })
)(DiscountCreate);