import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  fetchTodaySale,
  fetchWeekSale,
  fetchYesterdaySale,
  getEventsTicketLimit
} from '../../actions/saleActions';

import NoEvent from '../empty-state/NoEvent';
import Spinner from '../utilities/Spinner';
import ProgressBar from '../utilities/ProgressBar';

import { media, LinkPillStyled } from '../../styles';

import { friendlyDate, fromNow, truncate, isAfter7Days } from '../../helpers';

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;

  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
  `}
`

const GridItem = styled.div`
`

class EventInfo extends React.Component {
  state = {
    tickets: [],
    today: [],
    yesterday: [],
    week: []
  }

  componentDidMount() {
    this.props.getEventsTicketLimit((arr) => {
      this.setState({ tickets: arr })
    });

    this.props.fetchTodaySale((arr) => {
      this.setState({ today: arr })
    });

    this.props.fetchYesterdaySale((arr) => {
      this.setState({ yesterday: arr })
    });

    this.props.fetchWeekSale((arr) => {
      this.setState({ week: arr })
    });
  }

  renderEvents = (event, events) => {
    let ticketTotal = 0;
    let todayTotal = 0;
    let value;
    let yesterdayTotal = 0;
    let weekTotal = 0;

    if (this.state.tickets) {
      let match = this.state.tickets.filter(item => event._id === item.event);
      if (match.length > 0) {
        value = match[0].ticketsTotal;
      }

      ticketTotal = (event.tickets || []).reduce((amount, ticket) => {
        amount = amount += ticket.quantity_available;
        return amount;
      }, ticketTotal);
    }

    if (this.state.today) {
      this.state.today.filter(day => {
        if (day.event === event._id) {
          return day.orders.map(order => {
            order.order_info.order_items.reduce((qty, tkt) => {
              return todayTotal += tkt.quantity_sold;
            }, 0);
          });
        }
      });
    }

    if (this.state.yesterday) {
      this.state.yesterday.filter(day => {
        if (day.event === event._id) {
          return day.orders.map(order => {
            order.order_info.order_items.reduce((qty, tkt) => {
              return yesterdayTotal += tkt.quantity_sold;
            }, 0);
          });
        }
      });
    }

    if (this.state.week) {
      this.state.week.filter(day => {
        if (day.event === event._id) {
          return day.orders.map(order => {
            order.order_info.order_items.reduce((qty, tkt) => {
              return weekTotal += tkt.quantity_sold;
            }, 0);
          });
        }
      });
    }

    return (
      <GridItem key={event._id} className="flex flex-row flex-justify-between background-white border rounded-m shadow">
        <div className="flex flex-column flex-justify-between flex-grow-1 padding--small">
          <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small">
            <div className="flex flex-column">
              <p>{truncate(event.name, 40)}</p>
              <p className="text-small">{friendlyDate(event.start_date, 'MMM DD')} to {friendlyDate(event.end_date, 'MMM DD, YYYY')}</p>
            </div>

            <div className="flex flex-column padding--xxSmall paddingLeft--xSmall paddingRight--xSmall background-lightBlue rounded-m">
              <p className="text-small">{fromNow(event.start_date)}</p>
            </div>
          </div>
          <div className="flex flex-row marginBottom--small">
            <LinkPillStyled
              className="marginRight--xSmall"
              to={`/event/${event._id}`}>View</LinkPillStyled>
            <LinkPillStyled
              to={`/account/event/${event._id}`}>Manage</LinkPillStyled>
          </div>
          <div className="flex flex-column marginBottom--small paddingBottom--small border-bottom">
            <div className="flex flex-row flex-justify-between marginBottom--xSmall">
              <p className="text-small text-grey">Ticket Sales</p>
              <p className="text-small text-grey">{`${value ? value : "0"}`}/{ticketTotal}</p>
            </div>
            <ProgressBar quantity_available={ticketTotal} width={`${value ? ((value / ticketTotal) * 100) : "0"}%`} />

          </div>
          <div className="flex flex-row flex-justify-between">
            <div className="flex flex-column flex-align-center flex-justify-center">
              <p className="text-bold text-grey text-small text-uppercase text-spacing-1 text-center">Past Week</p>
              <h3 className={`${weekTotal > 0 ? "" : "text-ghost"}`}>{weekTotal}</h3>
            </div>
            <div className="flex flex-column flex-align-center flex-justify-center">
              <p className="text-bold text-grey text-small text-uppercase text-spacing-1 text-center">Yesterday</p>
              <h3 className={`${yesterdayTotal > 0 ? "" : "text-ghost"}`}>{yesterdayTotal}</h3>
            </div>
            <div className="flex flex-column flex-align-center flex-justify-center">
              <p className="text-bold text-grey text-small text-uppercase text-spacing-1 text-center">Today</p>
              <h3 className={`${todayTotal > 0 ? "" : "text-ghost"}`}>{todayTotal}</h3>
            </div>
          </div>
        </div>
      </GridItem>
    )
  }

  render() {
    const { events, loading, user } = this.props;

    if (events === null || loading) {
      return <Spinner />
    } else {
      if (user && isAfter7Days(user.created_at) && !user.confirmed) {
        return (
          <div className="padding--small background-white border rounded-m">
            <p>You haven't confirmed your email address. Before you can view your event. Please go to Account > Email > Resend Email to activate your account.</p>
          </div>        
        )
      } else if (events.length === 0) {
        return <NoEvent/>
      } else {
          return (
            <GridRow>
              {(events || []).map(event => this.renderEvents(event))}
            </GridRow>
          );
      }
    }
  }
}

export default connect(null, { getEventsTicketLimit, fetchTodaySale, fetchYesterdaySale, fetchWeekSale })(EventInfo);