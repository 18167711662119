import React from 'react'
import Payments from '../Payment/Payments';

import { calculateTotal } from '../../helpers/calculations';

const CheckoutSubmit = ({
  event: { user: { currency: { code }, language }, taxes, pass_fees_on, subscribed_payment_option: { paid } },
  order,
  orderForm,
  formIsDisabled,
  authDiscountCode,
  _previousPage,
  _handleToken
}) => {
  const getOrderSubTotal = order => {
    return order.reduce((initVal, { ticket, quantity }) => {
      return initVal + (quantity * ticket.price);
    }, 0);
  }

  return (
    <React.Fragment>
      <div className="footer-main flex flex-row flex-align-center">
        <div className="flex flex-align-center flex-justify-start padding--small">
          <p className="text-grey-blue" onClick={_previousPage}>Back</p>
        </div>
        <Payments
          orderForm={orderForm}
          formIsDisabled={formIsDisabled}
          order={order}
          code={code}
          country={language}
          amount={calculateTotal(getOrderSubTotal(order), authDiscountCode, taxes, pass_fees_on, paid, code, language)}
          _handleToken={_handleToken}/>
      </div>
    </React.Fragment>
  )
}

export default CheckoutSubmit;