import axios from 'axios';

import {
  GET_ERRORS,
  SALE_LOADING,
  GET_RECENT_ORDERS,
  GET_TICKETS_TOTAL,
  GET_EVENTS,
} from './types'

export const getRecentOrders = (id, callback) => async dispatch => {
  try {
    dispatch(setSaleLoading());
    const res = await axios.get(`/api/sale/recent-orders/${id}`);
    dispatch({
      type: GET_RECENT_ORDERS,
      payload: res.data
    });
    callback();
  } catch (err) {
    
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}

export const getTicketTotal = (id, callback) => async dispatch => {
  try {
    dispatch(setSaleLoading());
    const res = await axios.get(`/api/sale/ticket-total/${id}`);
    dispatch({
      type: GET_TICKETS_TOTAL,
      payload: res.data
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  }
}

export const getEventsTicketLimit = callback => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/events-ticket-limit`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}

export const fetchTodaySale = (callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/today`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}

export const fetchYesterdaySale = (callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/yesterday`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}

export const fetchWeekSale = (callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/week`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}
export const fetchMonthSale = (callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/month`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
}

export const getEventsSales = () => async dispatch => {
  try {
    const res = await axios.get(`/api/sale/events`);
    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: {}
    });
  }
};

// Set loading state
export const setSaleLoading = () => {
  return {
    type: SALE_LOADING
  };
};