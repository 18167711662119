import styled from 'styled-components'
import { media } from './Media';
import * as custom from './Variables';

export const TableStyled = styled.table`
  border: 1px solid ${custom.COLOR_BORDER};
  border-radius: ${custom.BORDER_RADIUS};
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  display: table;

  caption {
    font-size: ${custom.FONTSIZE_medium};
    margin: .5em 0 .75em;
  }

  tr {
    border: 1px solid ${custom.COLOR_BORDER};
    padding: ${custom.SPACE_small};
  }

  th,
  td {
    padding: ${custom.SPACE_small};
    font-family: 'Poppins';
    font-weight: 700;
    text-align: left;
    &:last-child {
      /* text-align: center; */
    }
  }

  th {
    font-size: ${custom.FONTSIZE_medium};
    font-weight: 400;
    letter-spacing: 1px;
  }

  ${media.handheld`
    border: 0;
  
    caption {
      font-size: ${custom.FONTSIZE_medium};
    }
    
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    tr {
      margin-bottom: ${custom.SPACE_medium}
      border-bottom: 3px solid ${custom.COLOR_BORDER};
    }
    
    td {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${custom.COLOR_BORDER};
      font-size: ${custom.FONTSIZE_medium};
      text-align: center;
    }
    
    td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      display: flex;
      align-items: center;
      width: 50px;
      text-align: left;
      font-weight: 400;
    }
    
    td:last-child {
      border-bottom: 0;
    }
  `}
`