import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { billUserEventFee, getStripe } from '../../../actions/billingActions';
import { getEvent } from '../../../actions/eventActions';

import Info from './Info';
import SelectPaymentType from './SelectPaymentType';
import PaymentStatus from './PaymentStatus';
import PaymentForm from './PaymentForm';

import NoStripe from '../../empty-state/NoStripe';

import isEmpty from '../../../validation/isEmpty';

import { FormStyle, media } from '../../../styles';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  `}

  .item {
    display: flex;
    flex-direction: column;
  }
`

class EventPricePlan extends React.Component {
  state = {
    errors: "",
    loading: false,
    checked: "",
    discountCode: "",
    allowDiscount: false,
    amount: 30000,
  }

  componentDidMount() {
    const { eventID } = this.props;
    this.props.getEvent(eventID, (event) => {
      if (event.subscribed_payment_option && event.subscribed_payment_option.paid) {
        this.setState({ checked: event.subscribed_payment_option.name.toLowerCase() });
      } else {
        this.setState({ checked: "pay as you go" });
      }
    });
    this.props.getStripe();
  }

  handleRadioSelect = (e) => {
    this.setState({ checked: e.target.value });
  }

  handleToken = async (token) => {
    const { event: { event }, user: { email }, billUserEventFee } = this.props;
    const { allowDiscount } = this.state;

    token.eventID = event._id;
    token.email = email;
    token.allowDiscount = allowDiscount;

    if (!isEmpty(token.email) && !isEmpty(token.eventID) && !isEmpty(token.allowDiscount)) {
      this.setState({ loading: true }, () => {
        billUserEventFee(token, () => {
          this.setState({ loading: false });
        });
      });
    } else {
      this.setState({ errors: "Sorry we're unable to process your order. Please try again later." })
    }
  }

  handleChangeDiscount = (e) => {
    let formatText = e.target.value.toUpperCase();
    this.setState({ discountCode: formatText });
  }

  showAmount = () => {
    // const { event: { subscribed_payment_option: { paid } } } = this.props.event;
    const { allowDiscount } = this.state;
    if (allowDiscount) return "$150";
    else return "$300"
  }

  handleSubmitCode = (e) => {
    e.preventDefault();
    const { discountCode } = this.state;
    if (discountCode === "") {
      this.setState({ errors: "Field cannot be blank" });
    } else if (discountCode !== "SPRINGBREAK") {
      this.setState({ errors: "Discount has expired." })
    } else if (discountCode === 'SPRINGBREAK') {
      this.setState({
        allowDiscount: true,
        amount: 15000,
        errors: "Discount has been applied."
      });
    }
  }

  renderSummary = (name) => {
    switch (name) {
      case 'pay as you go':
        return (
          <div className="flex flex-column marginBottom--small background-white border rounded-m shadow">
            <div className="padding--small border-bottom">
              <p>Current Plan</p>
            </div>
            <div className="padding--small">
              <p>A 65¢ service fee will be applied for each transaction made for this event.</p>
            </div>
          </div>
        )
      case 'pay no fees':
        return (
          <React.Fragment>
            <div className="flex flex-column marginBottom--small background-white border rounded-m shadow">
              <div className="padding--small border-bottom">
                <p>Pay No Fees</p>
              </div>
              <div className="flex flex-row flex-justify-between padding--small">
                <p>You are about to be charged {this.showAmount()} USD for this event. <br />This purchase is non-refundable after your first paid ticket is sold.</p>
              </div>
            </div>
            {this.renderPaymentForm()}
          </React.Fragment>
        )
      default:
        break;
    }
  }

  renderDiscountForm = () => {
    const { discountCode, errors } = this.state;
    return (
      <FormStyle>
        <input
          name="code"
          type="text"
          placeholder="Enter discount code"
          value={discountCode}
          onChange={this.handleChangeDiscount} />
        <div className="flex flex-row flex-justify-between marginTop--xSmall">
          <p className="text-pink">{errors}</p>
          <a href="#nowhere" className="text-blue pullRight" onClick={this.handleSubmitCode}>Apply Code</a>
        </div>
      </FormStyle>
    )
  }

  renderPaymentForm = () => {
    const { loading, amount } = this.state;
    const { stripe } = this.props.billing;
    const { email } = this.props.user;

    if (isEmpty(stripe)) {
      return (
        <div className="flex flex-column marginBottom--small background-white border rounded-m shadow">
          <div className="flex flex-row flex-align-center flex-justify-between padding--small border-bottom">
            <p>Connect to Stripe</p>
            <NoStripe />
          </div>
          <div className="padding--small">
            <p>In order to make this purchase. You need to first connect Stripe with Attndnce.</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex flex-column marginBottom--small background-white border rounded-m shadow">
          <div className="padding--small border-bottom">
            <p>Confirm Payment</p>
          </div>
          <GridLayout className="padding--small">
            <div>{this.renderDiscountForm()}</div>

            <div className="flex flex-row flex-align-center flex-justify-between">
              <div>&nbsp;</div>
              <PaymentForm
                loading={loading}
                amount={amount}
                handleToken={this.handleToken}
                email={email} />

            </div>
          </GridLayout>
        </div>
      )
    }
  }

  renderMain = (event) => {
    switch (event.subscribed_payment_option && event.subscribed_payment_option.paid) {
      case true:
        return <PaymentStatus event={event} />
      case false:
        return (
          <React.Fragment>
            <Info />
            {this.renderSummary(this.state.checked)}
          </React.Fragment>
        )
      default:
        return (
          <React.Fragment>
            <Info />
            {this.renderSummary(this.state.checked)}
          </React.Fragment>
        )
    }
  }

  render() {
    const { checked } = this.state;
    const { event } = this.props.event;

    return (
      <React.Fragment>
        <p className="marginBottom--xSmall text-grey-blue">Select Your Plan</p>
        <GridLayout>
          <div>
            <SelectPaymentType
              event={event}
              checked={checked}
              handleRadioSelect={this.handleRadioSelect}
              showAmount={this.showAmount()} />
            {this.renderMain(event)}
          </div>
          <div></div>
        </GridLayout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  billing: state.billing,
  event: state.event
})

export default connect(mapStateToProps, { billUserEventFee, getStripe, getEvent })(EventPricePlan);