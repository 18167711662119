import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { updateTicket } from '../../../actions/ticketActions';

import TextField from '../../utilities/formfields/TextField';
import TextAreaField from '../../utilities/formfields/TextAreaField';
import DropDownListField from '../../utilities/formfields/DropDownField';
import DateTimePickerField from '../../utilities/formfields/DateTimePickerField';
import Spinner from '../../utilities/Spinner';

import { required } from '../../../helpers/formValdation';
import { calculateFeesForBuyer } from '../../../helpers/calculations';

import isEmpty from '../../../validation/isEmpty';

import { ButtonStyled, FormStyle } from '../../../styles';

const zero_decimal_currencies = ["JPY", "KMF", "KRW", "UGX", "VND", "VUV", "XAF", "XOF", "XPF"];

class TicketEdit extends React.Component {
  state = {
    errors: {},
    loading: false
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors, loading: false });
    }
  }

  componentDidMount() {
    const { event, ticket } = this.props;

    this.props.initialize({
      ...ticket,
      show_quantity_available: ticket.show_quantity_available ? { value: true } : { value: false },
      ...zero_decimal_currencies.includes(event.payout_currency.code) ? { price: ticket.price } : { price: ticket.price / 100 }
    });
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState } = this.props;
    console.log('formValues', formValues)
    this.setState({ loading: true }, () => {
      this.props.updateTicket(eventID, formValues, () => {
        this.setState({ loading: false }, () => {
          handleClearState();
        })
      });
    });
  }

  render() {
    const { errors, loading } = this.state;
    const {
      event: {
        pass_fees_on,
        payout_country: { language },
        payout_currency: { code },
        subscribed_payment_option: { paid }
      },
      ticket,
      handleSubmit
    } = this.props;

    if (loading) return <Spinner />
    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <div className="padding--small background-white border rounded-m shadow">

          <Field
            label="Name"
            type="text"
            name="name"
            validate={required}
            error={errors.name}
            component={TextField} />
          <Field
            label="Type ie. General Admission, Early Bird, VIP"
            type="text"
            name="ticket_type"
            placeholder="Choose Ticke Type"
            data={['General Admission', 'Early Bird', 'VIP']}
            validate={required}
            error={errors.ticket_type}
            component={DropDownListField} />
          <Field
            label="Quantity ie. 100"
            type="number"
            name="quantity_available"
            placeholder="100"
            validate={required}
            error={errors.quantity_available}
            component={TextField} />
          <Field
            label="Show Audience Ticket Quantity"
            type="text"
            name="show_quantity_available"
            placeholder="Choose to Hide or Show Ticket Quantity"
            valueField="value"
            textField="name"
            data={[{ name: 'Hide Quantity', value: false }, { name: 'Show Quantity', value: true }]}
            validate={required}
            error={errors.show_quantity_available}
            component={DropDownListField} />

          <div className="flex flex-grid-half flex-row--onMobile flex-justify-between">

            <Field
              label={`Ticket Price $63.00`}
              type="number"
              name="price"
              placeholder="$63.00"
              validate={required}
              error={errors.price}
              className="col"
              component={TextField} />

            {pass_fees_on &&
              <div className="flex flex-column col marginBottom--small">
                <label>Buyer Pays</label>
                <input type="text" disabled={true} value={calculateFeesForBuyer(zero_decimal_currencies.includes(code) ? ticket.price : (ticket.price / 100), [], pass_fees_on, paid, code, language, true)} />
              </div>
            }
          </div>

          <div className="flex flex-grid-half flex-row--onMobile flex-justify-between">
            <Field
              min={new Date()}
              name="start_date"
              label="Sales Starts"
              error={errors.start_date}
              validate={required}
              className="col"
              component={DateTimePickerField} />
            <Field
              min={new Date()}
              name="end_date"
              label="Sale Ends"
              error={errors.end_date}
              validate={required}
              className="col"
              component={DateTimePickerField} />
          </div>

          <Field label="Description" name="desc" component={TextAreaField} />
          <div className="flex flex-column pullRight marginTop--small">
            <ButtonStyled className="button--blue">Apply Changes</ButtonStyled>
          </div>
        </div>
      </FormStyle>
    )
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { updateTicket }),
  reduxForm({
    form: 'editTicket',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
)(TicketEdit);