import React, { useState } from 'react';
import styled from 'styled-components';
import { FiChevronUp, FiChevronDown } from "react-icons/fi";

import TicketItem from './TicketItem';

import { friendlyDate } from '../../helpers';

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`

const TicketList = ({event, selected_items, _handleCartOrder}) => {
  const [activeIndex, setActiveIndex] = useState(null)
 
  const toggleClass = (index) => {
    setActiveIndex((prevState) => {
      return prevState === index ? null : index
    })
  }

  const moreLess = (index) => {
    if (activeIndex === index) return <FiChevronUp size="16px" className="marginLeft--xSmall" />
    else return <FiChevronDown size="16px" className="marginLeft--xSmall" />
  }

  return (
    <React.Fragment>
      <div className="header">
        <h3 className="marginBottom--small paddingBottom--small border-bottom">Choose your tickets</h3>
        <GridColumns className="paddingBottom--small border-bottom">
          <p>Event Date</p>
          <div className="text-right pullRight">
            <p>{`${friendlyDate(event.start_date, "MMM DD")} - ${friendlyDate(event.end_date, "MMM DD, YYYY")}`}</p>
            <p className="text-small">From {`${friendlyDate(event.start_date, "h:mm A")} to ${friendlyDate(event.end_date, "h:mm A")}`}</p>
          </div>
        </GridColumns>
      </div>
      <div className="content">
        {(event.tickets || []).map((ticket, index) =>
          <TicketItem
            key={index}
            index={index}
            taxes={event.taxes}
            ticket={ticket}
            currencyInfo={event.user}
            activeIndex={activeIndex}
            selected_qty={Object.keys(selected_items).includes(ticket.name) ? selected_items[ticket.name] : ""}
            _moreLess={moreLess}
            _toggleClass={toggleClass}
            _handleCartOrder={_handleCartOrder} />
        )}
      </div>
    </React.Fragment>
  )
}

export default TicketList;