import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import { createTicket } from '../../../actions/ticketActions';

import Spinner from '../../utilities/Spinner';

import TextField from '../../utilities/formfields/TextField';
import TextAreaField from '../../utilities/formfields/TextAreaField';
import DropDownListField from '../../utilities/formfields/DropDownField';
import DateTimePickerField from '../../utilities/formfields/DateTimePickerField';

import { required } from '../../../helpers/formValdation';
import { calculateFeesForBuyer } from '../../../helpers/calculations';

import { ButtonStyled, FormStyle } from '../../../styles';

class TicketCreate extends React.Component {
  state = {
    loading: false,
    errors: {}
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors, loading: false });
    }
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState } = this.props;
    this.setState({ loading: true }, () => {
      this.props.createTicket(eventID, formValues, () => {
        this.setState({ loading: false }, () => {
          handleClearState();
        });
      });
    });
  }

  render() {
    const { loading, errors } = this.state;
    const {
      event: {
        pass_fees_on,
        payout_country: { language },
        payout_currency: { code },
        subscribed_payment_option: { paid }
      },
      price,
      handleSubmit
    } = this.props;

    if (loading) return <Spinner />
    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <div className="padding--small background-white border rounded-m shadow">
          <Field
            label="Name"
            type="text"
            name="name"
            error={errors.name}
            validate={required}
            component={TextField} />
          <Field
            label="Type ie. General Admission, Early Bird, VIP"
            type="text"
            name="ticket_type"
            placeholder="Choose Ticket Type"
            data={['General Admission', 'Early Bird', 'VIP']}
            error={errors.ticket_type}
            
            component={DropDownListField} />
          <Field
            label="Quantity Available ie. 100"
            type="number"
            name="quantity_available"
            error={errors.quantity_available}
            validate={required}
            component={TextField} />

          <Field
            label="Show Audience Ticket Quantity"
            type="text"
            name="show_quantity_available"
            placeholder="Audience can see Quantity"
            valueField="value"
            textField="name"
            data={[{ name: 'Hide Quantity', value: false }, { name: 'Show Quantity', value: true }]}
            error={errors.show_quantity_available}
            validate={required}
            component={DropDownListField} />
          <div className="flex flex-grid-half flex-row--onMobile flex-justify-between">
            <Field
              label={`Ticket Price $63.00`}
              type="number"
              name="price"
              placeholder="$"
              error={errors.price}
              validate={required}
              className="col"
              component={TextField} />

            {pass_fees_on &&
              <div className="flex flex-column col marginBottom--small">
                <label>Buyer Pays</label>
                <input type="text" disabled={true} value={calculateFeesForBuyer(price || 0, [], pass_fees_on, paid, code, language, true)} />
              </div>
            }
          </div>

          <div className="flex flex-grid-half flex-row--onMobile flex-justify-between">
            <Field
              min={new Date()}
              name="start_date"
              label="Sales Starts"
              className="col"
              error={errors.start_date}
              validate={required}
              component={DateTimePickerField} />
            <Field
              min={new Date()}
              name="end_date"
              label="Sale Ends"
              className="col"
              error={errors.end_date}
              validate={required}
              component={DateTimePickerField} />
          </div>

          <Field
            label="Description"
            name="desc"
            error={errors.desc}
            validate={required}
            component={TextAreaField} />
          <div className="flex flex-column pullRight marginTop--small">
            <ButtonStyled className="button--blue">Add Ticket</ButtonStyled>
          </div>
        </div>
      </FormStyle>
    )
  }
}

const selector = formValueSelector('createTicket');

const mapStateToProps = state => ({
  price: selector(state, 'price'),
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { createTicket }),
  reduxForm({
    form: 'createTicket',
    initialValues: {
      show_quantity_available: { name: 'Hide', value: false },
      start_date: new Date()
    },
  })
)(TicketCreate);