import {
  GET_EVENT,
  GET_EVENTS,
  DELETE_EVENT,
  EVENT_LOADING
} from '../actions/types';

const INITIAL_STATE = {
  event: {},
  events: [],
  loading: false
}

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_EVENT:
      return {
        ...state,
        event: action.payload,
        loading: false
      }
    case GET_EVENTS:
      return {
        ...state,
        events: action.payload,
        loading: false
      }
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter(event => event._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
}