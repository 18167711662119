import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import styled from 'styled-components';
import { fetchAudience, createAudience, syncAudience, removeMailchimp } from '../../../actions/mailchimpActions';
import { withRouter } from 'react-router-dom';

import MailchimpCreateAudience from './MailchimpCreateAudience';
import MailchimpActivity from './MailchimpActivity';
import MailchimpMembers from './MailchimpMembers';
import MailchimpActions from './MailchimpActions';

import ErrorMessage from '../../utilities/ErrorMessage';
import SnackBar from '../../utilities/snackbar/SnackBar';

import isEmpty from '../../../validation/isEmpty';
import { media, ButtonStyled } from '../../../styles'

const GridLayout = styled.div`
  display: grid;
  grid-column-gap: 16px;
  
  margin: 0 auto;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 3fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  & > * {
    display: flex;
    flex-direction: column;
  }
`

const ButtonGridLayout = styled.div`
  display: grid;
  grid-column-gap: 16px;
  justify-content:space-between;
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    & > .cancel {
      grid-row: 2;
    }
    & > .submit {
      grid-row: 1;
    }
  `}

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}
`

class Mailchimp extends React.Component {
  state = {
    errors: {},
    message: "",
    isLoading: false,
    isSyncing: false,
    isProcessing: false,
    modalIsOpen: false
  }

  componentDidMount() {
    const { mailchimp: { last_synced } } = this.props.user;
    if (isEmpty(last_synced)) {
      this.setState({ isLoading: true }, () => {
        this.props.fetchAudience(() => {
          this.setState({ isLoading: false });
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors !== this.props.errors) {
      if (nextProps.errors.error.status === 404 && nextProps.errors.error.title === "Resource Not Found") {
        this.setState({
          errors: { noListFound: "No list found on Mailchimp to sync. Please disconnect and reconnect your account." },
          isLoading: false,
          isProcessing: false // TODO  check if this is needed here
        }, () => console.log("errors", this.state.errors));
      } else if (nextProps.errors.error.status === 403 && nextProps.errors.error.title === "User action not permitted") {
        this.setState({
          errors: { reachedAudienceLimit: "You already have the max amount of audiences allowed in your Mailchimp plan. If you want to add another audience, you'll need to upgrade your Mailchimp plan first." },
          isLoading: false,
          isProcessing: false
        }, () => console.log("errors", this.state.errors));
      } else {
        this.setState({
          errors: nextProps.errors.error,
          isLoading: false,
          isProcessing: false // TODO  check if this is needed here
        }, () => console.log("errors", this.state.errors));
      }
    }
  }

  beforeSubmit = (formValues) => {
    this.setState({ isProcessing: true, errors: {} }, () => {
      this.props.createAudience(formValues, (message) => {
        this.setState({
          isLoading: true,
          isProcessing: false,
          message: message
        }, () => {
          this.props.fetchAudience(() => {
            this.setState({ isLoading: false });
          });
        });
      });
    });
  }

  handleSync = () => {
    this.setState({ isSyncing: true }, () => {
      this.props.syncAudience(this.props.mailchimp.audience, (message) => {
        this.setState({
          isSyncing: false,
          isLoading: true,
          message: message
        }, () => {
          this.props.fetchAudience(() => {
            this.setState({ isLoading: false });
          });
        });
      });
    });
  }

  handleFetchAudience = () => {
    this.setState({ isLoading: true }, () => {
      this.props.fetchAudience(() => {
        this.setState({ isLoading: false });
      });
    });
  }

  handleDelete = () => {
    this.setState({ isProcessing: true }, () => {
      this.props.removeMailchimp((message) => {
        this.setState({ modalIsOpen: false, message: message, isProcessing: false }, () => {
          this.props.history.push('/account');
        });
      });
    });
  }

  handleCloseSnack = () => {
    this.setState({ message: null });
  }

  openModal = (e, object) => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { user, mailchimp: { audience } } = this.props;
    const { errors, message, isLoading, isSyncing, isProcessing, modalIsOpen } = this.state;

    return (
      <GridLayout>
        <div className="flex flex-column">
          {Object.keys(errors).length > 0 && <ErrorMessage showCloseButton={false} errors={errors} />}

          {message && <SnackBar
            message={message}
            type="success"
            classes="marginBottom--small"
            handleCloseSnack={this.handleCloseSnack} />}

          {user.hasOwnProperty('mailchimp') && user.mailchimp.hasOwnProperty('last_synced') && 
            <MailchimpActivity user={user} handleFetchAudience={this.handleFetchAudience} />
          }

          {user.hasOwnProperty('mailchimp') && user.mailchimp.hasOwnProperty('audience_id') &&
            <MailchimpMembers
              members={audience}
              isLoading={isLoading}
              isSyncing={isSyncing}
              isProcessing={isProcessing}
              openModal={this.openModal}
              handleSync={this.handleSync} />
          }
          {user.hasOwnProperty('mailchimp') && !user.mailchimp.hasOwnProperty('audience_id') &&
            <MailchimpCreateAudience
              errors={errors}
              isProcessing={isProcessing}
              beforeSubmit={this.beforeSubmit} />
          }
          {!user.hasOwnProperty('mailchimp') &&
            <div className="marginBottom--small padding--medium flex flex-column background-white rounded border shadow">
              <p>Please connect to Mailchimp.</p>
            </div>
          }
          {user.hasOwnProperty('mailchimp') && <MailchimpActions
            isSyncing={isSyncing}
            isProcessing={isProcessing}
            openModal={this.openModal} />}
          <Modal
            isOpen={modalIsOpen}
            contentLabel="Disconnect Mailchmp"
            style={customStyles.content}
            className="modal"
            overlayClassName="overlay">
            <div className="padding--medium">
              <div className="flex flex-column marginBottom--medium">
                <h3 className="marginBottom--small">Disconnect Mailchimp</h3>
                <p className="text-bold marginBottom--xSmall">Are you sure you want to disconnect from Mailchimp?</p>
                <p>If your account is not connected your Attndnce customers will no longer update in your Mailchimp account. You also won't be able to reconnect to your Attndnce audience if you change your mind.</p>
              </div>
              <ButtonGridLayout>
                <ButtonStyled onClick={() => this.closeModal()} className="cancel">Cancel</ButtonStyled>
                <ButtonStyled onClick={() => this.handleDelete()} className="submit button--red">Disconnect Mailchimp</ButtonStyled>
              </ButtonGridLayout>
            </div>
          </Modal>
        </div>
      </GridLayout>
    )
  }
}

const customStyles = {
  content: {
    height: '', borderRadius: '12px'
  }
};

const mapStateToProps = state => ({
  mailchimp: state.mailchimp,
  errors: state.errors
});

export default connect(mapStateToProps, { fetchAudience, createAudience, syncAudience, removeMailchimp })(withRouter(Mailchimp));