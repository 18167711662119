import React from 'react';
import SocialLinks from './SocialLinks';
import isEmpty from '../../../validation/isEmpty';

const OrganizerInfo = ({ profile }) => {

  if (!isEmpty(profile)) {

    return (
      <React.Fragment>
        {profile.desc &&
          <p className="marginBottom--medium">{profile.desc}</p>
        }
        {/* <SocialLinks profile={profile} /> */}
      </React.Fragment>
    )
  }
  else { 
    return <div>nothing here</div>
  }
}
export default OrganizerInfo;