import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

import { confirmEmail } from '../../actions/authActions';

import Spinner from './Spinner';
import ImageWrapper from './ImageWrapper';

import * as custom from '../../styles/Variables';
import { FlexContainer, StyledCard, ButtonStyledLink } from '../../styles';

import emailSVG from '../empty-state/images/undraw_message_sent_1030.svg';

const CircleStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: #23D160;
  border-radius: 24px;
  margin-right: ${custom.SPACE_medium};
`

class Confirm extends React.Component {

  // A bit of state to give the user feedback while their email
  // address is being confirmed on the User model on the server.
  state = {
    errors: {},
    confirming: true
  }

  // When the component mounts the mongo id for the user is pulled  from the 
  // params in React Router. This id is then sent to the server to confirm that 
  // the user has clicked on the link in the email. The link in the email will 
  // look something like this: 
  // 
  // http://localhost:3000/confirm/5c40d7607d259400989a9d42
  // 
  // where 5c40d...a9d42 is the unique id created by Mongo
  componentDidMount = () => {
    const { id } = this.props.match.params;

    this.props.confirmEmail(id, () => {
      this.setState({ confirming: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors, confirming: false });
    }
  }

  // While the email address is being confirmed on the server a spinner is 
  // shown that gives visual feedback. Once the email has been confirmed the 
  // spinner is stopped and turned into a button that takes the user back to the 
  // <Landing > component so they can confirm another email address.
  render() {
    const { confirming, errors } = this.state;

    if (confirming) return <Spinner />

    return (
      <FlexContainer>
        <div className="flex flex-justify-center">
          <div className="flex-grid-half flex-justify-center">
            <div className="col">
              <StyledCard>
                <ImageWrapper>
                  <img src={emailSVG} alt=""/>
                </ImageWrapper>

                {Object.keys(errors).length > 0 && <div className="marginTop--small marginBottom--small padding--small border-error rounded text-center">
                  {_.map(errors, (value, key) => <p className="text-pink" key={key}>{value}</p>)}
                </div>
                }
                <div className="flex flex-row flex-align-center flex-justify-center marginBottom--large">
                  <CircleStyle>
                    <FontAwesome name="check" size="2x" className="text-white" />
                  </CircleStyle>
                  <h2 className="text-bold">Email is Confirmed</h2>
                </div>
                <ButtonStyledLink to="/account" className="button--blue">Go Back to Account</ButtonStyledLink>
              </StyledCard>
            </div>
          </div>
        </div>
      </FlexContainer>
    )
  }
}

const mapStateToProps = state => ({
  errors: state.errors
})

export default connect(mapStateToProps, { confirmEmail })(Confirm);