import { formatDollarsToCents, formatPrice } from './';
import isEmpty from '../validation/isEmpty';

const fees = {
  USD: { percent: 2.9, fixed: 0.30 },
  GBP: { percent: 2.4, fixed: 0.20 },
  EUR: { percent: 2.4, fixed: 0.24 },
  CAD: { percent: 2.9, fixed: 0.30 },
  AUD: { percent: 2.9, fixed: 0.30 },
  NOK: { percent: 2.9, fixed: 2 },
  DKK: { percent: 2.9, fixed: 1.8 },
  SEK: { percent: 2.9, fixed: 1.8 },
  JPY: { percent: 3.6, fixed: 0 },
  MXN: { percent: 3.6, fixed: 3 }
};

const getFeeRef = code => {
  let _fee = fees[code];
  _fee = _fee ? _fee : { percent: 2.9, fixed: 0.30 };
  return _fee;
};

export const calculateTotal = (subTotal, discount, taxes, pass_fees_on, isFlatFee, code, language, isDollars = false) => {
  let total = 0; // cents
  total = applyDiscount(subTotal, discount, code);
  // console.log('Discount applied ', total);
  
  total = applyTaxes(total, taxes);
  // console.log('Taxes applied ', total);
  
  total = applyServiceFees(total, pass_fees_on, isFlatFee, getFeeRef(code));
  // console.log('Fees applied ', total);

  total = Math.round(total);
  // console.log('Total Rounded ', total);

  if (!isDollars) { return total; }
  return formatPrice(total, code, language);
}

export const calculateSubTotal = (subTotal, discount, code, language, isDollars = false) => {
  let total;

  total = applyDiscount(subTotal, discount, code);

  // console.log('SubTotal Amount ', total);
  if (!isDollars) { return total; }
  return formatPrice(total, code, language);
}

export const calculateDiscount = (subTotal, discount, code, language, isDollars = false) => {
  let total;
  if (!isEmpty(discount)) {
    if (discount.percent_off) {
      total = subTotal * (discount.percent_off / 100);
    } else {
      total = formatDollarsToCents(discount.amount_off, code);
    }
  }
  
  if (!isDollars) { return total; }
  return formatPrice(total, code, language);
}

export const calculateTax = (subTotal, discount, tax, code, language, isDollars = false) => {
  let total;
  
  total = applyDiscount(subTotal, discount, code);

  total = applyTax(total, tax);
  // console.log('Tax Amount ', total);
  
  if (isDollars) { return formatPrice(total, code, language); } // dollars
  return total; // cents
}

export const calculateTotalFees = (subTotal, discount, taxes, pass_fees_on, isFlatFee, code, language, isDollars = false) => {
  let total;

  total = applyDiscount(subTotal, discount, code);
  // console.log('Total w/ discount applied ', total);
  total = applyTaxes(total, taxes);
  // console.log('Total w/ tax applied ', total);

  total = applyServiceFees(total, pass_fees_on, isFlatFee, getFeeRef(code)) - total;
  // console.log('Total w/ Service Fees applied ', total);

  total = Math.round(total);
  // console.log('Total Rounded ', total);

  if (isDollars) { return formatPrice(total, code, language); } // dollars
  return total; // cents
}

// PASS_FEES_ON = true / Customer Pay Fees
export const calculateFeesForBuyer = (dollars, taxes, pass_fees_on, isFlatFee, code, language, isDollars = false) => {
  
  let total = parseFloat(dollars, 10); // ie $60
  // console.log('total', total, code);
  
  if (dollars === 0) return formatPrice(total, code, language);

  total = formatDollarsToCents(dollars, code);
  // console.log('formatted to cents', total);

  total = applyTaxes(total, taxes);
  // console.log('Taxes applied ', total);

  total = applyServiceFees(total, pass_fees_on, isFlatFee, getFeeRef(code));
  // console.log('Total after service fees applied', total);

  total = Math.round(total);
  // console.log('Total Rounded ', total);

  total = total.toFixed(2);

  if (!isDollars) { return total; }
  return formatPrice(total, code, language);
}

// PASS_FEES_ON = False / User Pay Fees
export const calculateFeesforUser = (dollars, code, language, isDollars = false) => {
  let total = parseFloat(dollars, 10); // amount $ Dollar value != cents && "String"
  let _fee = getFeeRef(code);
  let stripeFees = (total * (_fee.percent / 100)) + _fee.fixed;
  let attndnceFees = total * (2 / 100);
  let feeTotal = stripeFees + attndnceFees;
  total = feeTotal.toFixed(2);

  if (!isDollars) { return total; }
  return formatPrice(total, code, language);
}

const applyDiscount = (subTotal, discount, code) => {
  let discounted;
  if (!isEmpty(discount)) {
    discounted = subTotal - calculateDiscount(subTotal, discount, code);
    // Check if negative value
    if (discounted <= 0) {
      return subTotal; // discount not applied. Customer will be charged by subtotal
    } else {
      return discounted; // discount applied.
    }
  } else {
    return subTotal;
  }
}

const applyTaxes = (total, taxes) => {
  if (taxes.length > 0) {
    total = (taxes || []).reduce((prev, { tax_percent }) => {
      let fee = total * (tax_percent / 100);
      prev = prev += fee;
      return prev;
    }, total);
    return total;
  }
  return total;
}

const applyTax = (total, tax) => {
  if (Object.keys(tax).length > 0) {
    return total * (tax.tax_percent / 100);
  }
  return total;
}

const applyServiceFees = (total, pass_fees_on, isFlatFee, _fee) => {
  // in cents
  if (pass_fees_on) {
    let stripeFees = total * (_fee.percent / 100) + _fee.fixed * 100;
    // console.log('stripeFees', stripeFees)
    let attndnceFees = isFlatFee ? 0 : 65;
    // console.log('attndnceFees', attndnceFees)
    let grossTotal = total + stripeFees + attndnceFees;
    // console.log('grossTotal', grossTotal)

    stripeFees = grossTotal * (_fee.percent / 100) + _fee.fixed * 100;
    attndnceFees = isFlatFee ? 0 : 65;
    total = total + stripeFees + attndnceFees;
    return total;
  } 
  return total;
}