import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { createInstructor } from '../../actions/instructorActions';

import TextField from '../utilities/formfields/TextField';
import TextAreaField from '../utilities/formfields/TextAreaField';
import UploadImageContainer from '../utilities/formfields/UploadImageContainer';

import Spinner from '../utilities/Spinner';

import { required } from '../../helpers/formValdation';

import { FormStyle, ButtonStyled } from '../../styles';

class InstructorCreate extends Component {
  state = {
    errors: {},
    displaySocialInputs: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  beforeSubmit = (formValues) => {
    this.props.createInstructor(formValues, () => {
      this.props.handleClearState();
    });
  }

  render() {
    const { handleSubmit, loading } = this.props;
    const { errors: { name, company, country, desc } } = this.state;

    if (loading) return <Spinner />
    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <div className="padding--small background-white border rounded-m shadow">

          <UploadImageContainer imageType="instructor" />

          <div className="flex flex-grid-half flex-justify-between">

            <Field
              label="Name"
              type="text"
              name="name"
              className="col"
              error={name}
              validate={required}
              component={TextField} />

            <Field
              label="Company"
              type="text"
              name="company"
              className="col"
              error={company}
              validate={required}
              component={TextField} />
          </div>
          <Field
            label="Country"
            type="text"
            name="country"
            error={country}
            validate={required}
            component={TextField} />
          <Field
            label="Bio"
            type="text"
            name="desc"
            error={desc}
            validate={required}
            component={TextAreaField} />

          <div className="flex flex-row pullRight marginTop--small">
            <ButtonStyled className="button--blue" disabled={loading}>Add Instructor</ButtonStyled>
          </div>
        </div>
      </FormStyle>
    )
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default compose(
  reduxForm({ form: 'createInstructor' }),
  connect(mapStateToProps, { createInstructor })
)(InstructorCreate);