import isEmpty from '../../validation/isEmpty'

const validateForm = (fieldsIsValid, event, orderForm) => {
  let isValid = false;

  let firstNameIsValid = false;
  let lastNameIsValid = false;
  let emailIsValid = false;

  let genderIsValid = false;
  let ageIsValid = false;
  let notesIsValid = false;

  let orderFormIsValid = true;
  let customFieldsIsValid = false;

  if (!isEmpty(fieldsIsValid)) {

    const { order_form } = event;

    if (fieldsIsValid.first_name) {
      firstNameIsValid = fieldsIsValid.first_name;
    }

    if (fieldsIsValid.last_name) {
      lastNameIsValid = fieldsIsValid.last_name;
    }

    if (fieldsIsValid.email) {
      emailIsValid = fieldsIsValid.email;
    }

    if (order_form) {
      if (order_form.age && order_form.age.include && order_form.age.require) {
        if (!isEmpty(fieldsIsValid.age)) {
          ageIsValid = fieldsIsValid.age;
        }
      } else {
        ageIsValid = true;
      }

      if (order_form.notes && order_form.notes.include && order_form.notes.require) {
        if (!isEmpty(fieldsIsValid.notes)) {
          notesIsValid = fieldsIsValid.notes;
        }
      } else {
        notesIsValid = true;
      }

      if (order_form.gender && order_form.gender.include && order_form.gender.require) {
        if (!isEmpty(fieldsIsValid.gender)) {
          genderIsValid = fieldsIsValid.gender;
        }
      } else {
        genderIsValid = true;
      }

      orderFormIsValid = genderIsValid && ageIsValid && notesIsValid;
      
      if (Array.isArray(orderForm.custom_fields) && orderForm.custom_fields.length > 0) {
          (orderForm.custom_fields || []).map((cf, index) => {
            if (cf.include && cf.require) {
              if (!isEmpty(fieldsIsValid[`custom_field_${index}`])) {
                customFieldsIsValid = fieldsIsValid[`custom_field_${index}`]
              }
            }
          });
      } else {
        customFieldsIsValid = true;
      }
    }
  }

  isValid = firstNameIsValid && lastNameIsValid && emailIsValid && orderFormIsValid && customFieldsIsValid;

  return isValid;
}

export default validateForm;