import React from 'react';
import styled from 'styled-components';
import * as custom from '../../../styles/Variables';
import { media } from '../../../styles';

const CardHeaderStyled = styled.div`
  padding-bottom: ${custom.SPACE_xSmall};
  ${media.handheld`
    padding: ${custom.SPACE_small} ${custom.SPACE_medium} ${custom.SPACE_xSmall} ${custom.SPACE_medium};
  `}
`

const CardHeader = ({title, children, className}) =>
  <CardHeaderStyled className={`flex flex-row flex-align-center flex-justify-between ${className}`}>
    <p className="text-grey-blue">{title}</p>
    {children}
  </CardHeaderStyled>

export default CardHeader;