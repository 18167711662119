import React from 'react'
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form'

import TextField from '../utilities/formfields/TextField';
import TextAreaField from '../utilities/formfields/TextAreaField';
import DropDownListField from '../utilities/formfields/DropDownField';
import DateTimePickerField from '../utilities/formfields/DateTimePickerField';
import PayoutCurrencyFields from '../utilities/formfields/PayoutCurrencyFields';
import UploadImageContainer from '../utilities/formfields/UploadImageContainer';
import CardHeader from '../utilities/card/CardHeader';
import BackArrow from '../utilities/BackArrow';

import {
  required,
  maxLength,
  minLength
} from '../../helpers/formValdation';

import { FormStyle, ButtonStyled, StyledCard } from '../../styles';

const EventCreateFirstPage = props => {

  const { handleSubmit, errors } = props;
  return (
    <div className="item">
      <BackArrow title="Back" />

      <FormStyle onSubmit={handleSubmit}>
        <CardHeader title="Event Details" />
        <StyledCard className="text-center">
          <p>Once you fill out this form. You will be able to add ticket types, custom forms, scheduling, and more.</p>
        </StyledCard>
        <StyledCard>
          <UploadImageContainer imageType="event" />
          <Field
            label="Event Title"
            type="text"
            name="name"
            error={errors.name}
            validate={[required, minLength(3), maxLength(100)]}
            component={TextField} />

          <Field
            label="Event Type ie. Festival, COngress, Workshop, Bootcamp"
            type="text"
            name="event_type"
            data={["workshop", "festival", "congress", "dance class", "social dance"]}
            error={errors.event_type}
            validate={required}
            component={DropDownListField} />

          <Field
            label="Start Date and Time"
            name="start_date"
            min={new Date()}
            showTime={true}
            formatDate="MM/DD/YY h:mmA"
            type="date"
            error={errors.start_date}
            validate={required}
            component={DateTimePickerField} />

          <Field
            label="End Date and Time"
            name="end_date"
            min={new Date()}
            showTime={true}
            formatDate="MM/DD/YY h:mmA"
            type="date"
            error={errors.end_date}
            validate={required}
            component={DateTimePickerField} />

          <Field
            label="Tags ie. Salsa, Bachata, Bootcamp"
            name="tags"
            error={errors.tags}
            validate={required}
            component={TextField} />

          <Field
            label="Location ie. New York, New York"
            type="text"
            name="location.formatted_address"
            error={errors.location}
            validate={required}
            component={TextField} />

          <Field
            label="Event Description"
            type="text"
            name="desc"
            className="marginBottom--small"
            error={errors.desc}
            validate={required}
            component={TextAreaField} />

        </StyledCard>
        
        <CardHeader title="Ticket Details" />
        
        <StyledCard>
          <Field
            label="Fees"
            type="text"
            name="pass_fees_on"
            textField="name"
            valueField="value"
            placeholder="What to do with Fees"
            data={[{
              name: 'Pass fees on',
              value: true
            }, {
              name: 'Absorb fees',
              value: false
            }]}
            error={errors.pass_fees_on}
            validate={required}
            component={DropDownListField} />
            
          <PayoutCurrencyFields errors={errors} />
        </StyledCard>

        <ButtonStyled className="button--blue">Review Event</ButtonStyled>

      </FormStyle>
    </div>
  )

}

const month = new Date();
month.setDate(month.getDate() + 30);

export default compose(
  reduxForm({
    initialValues: {
      start_date: new Date(),
      end_date: month,
      event_type: "Festival",
      tags: "Salsa, Bachta, Kizomba",
      pass_fees_on: { name: 'Pass fees on', value: true }
    },
    form: 'wizard', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
  })
)(EventCreateFirstPage);