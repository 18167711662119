import React from 'react';
import FontAwesome from 'react-fontawesome';
import { FiMail, FiUserX, FiUserCheck } from "react-icons/fi";

import { ButtonStyled } from '../../styles';

const AccountInfo = ({ user, sendingEmail, handleSendingEmail }) => 
  <div className="flex flex-column flex-align-start">
    <div className="marginBottom--small">
      <p>Email</p>
      <p className="marginBottom--small"><FiMail className="marginRight--xSmall" style={{ width: "32px" }}/> {user.email}</p>
    </div>
    <div className="marginBottom--small">
      <p>Account Status</p>
      {user.confirmed ? 
      <p className="marginBottom--small">
        <FiUserCheck className="marginRight--xSmall" style={{ width: "32px" }}/>
        <span className="text-green">Verified</span>
      </p> : 
      <p className="marginBottom--small">
        <FiUserX className="marginRight--xSmall" style={{ width: "32px" }}/>
          <span className="text-pink">Not Verified</span>
      </p>
      }
      {!user.confirmed &&
        <ButtonStyled disabled={sendingEmail} onClick={handleSendingEmail}>
          {sendingEmail && <FontAwesome size="2x" spin name="spinner" />}
          Resend Email
        </ButtonStyled>
      }
    </div>
  </div>

export default AccountInfo;