import React from 'react';
import { Multiselect } from 'react-widgets';
import { FlexInputField } from '../../../styles';

class CreateMultiSelectField extends React.Component {
  state = {
    value: [],
    tracks: [],
  }

  handleCreate = (name, data) => {
    let { tracks, value } = this.state;

    let newOption = name;

    this.setState({
      value: [...value, newOption],  // select new option
      tracks: [...tracks, ...data, newOption] // add new option to our dataset
    })
  }

  render() {
    let { value, tracks } = this.state;
    const { data, input, label, className } = this.props;
    return (
      <FlexInputField className={className}>
        <label>{label}</label>
        <Multiselect
          data={tracks.length < 1 ? data : tracks}
          value={value}
          name="tracks"
          allowCreate="onFilter"
          value={input.value || []} // requires the value to be an array
          onCreate={(name) => {
            this.handleCreate(name, data)
          }}
          onChange={value => {
            this.setState({ value })
            input.onChange(value)
          }}
          textField="name"
        />
      </FlexInputField>
    )
  }
}

export default CreateMultiSelectField;