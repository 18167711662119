import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { IoMdPlay } from "react-icons/io";
import VideoModal from './VideoModal';

const Headline = styled.div`
  h2 {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 30px;
    color: #4D4D4D;
    line-height: 40px;
  }
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 960px;
  margin: 50px auto;
  ${media.handheld`
    margin-left: 24px;
    margin-right: 24px;
  `}
`

const ButtonLayout = styled.div`
  display: grid;
  grid-template-columns: 24px 24px;
  grid-column-gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px auto;
  .rounded {
    width: 24px;
    height: 24px;
    padding: 0;
    background: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }
`

const Thumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background: black;
  border-radius: 8px;
  height: 170px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.05);
      z-index: 300;
    }
  }
  h3, p {
    color: white;
  }
`

const Overlay = styled.div`
  position: absolute;
  background: #333333;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  opacity: 0.4;
  transition: all 0.3s ease-in-out;

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0;
    }
  }
`

const VideosLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: 170px;
  width: 100%;
  transition: transform 800ms ease-in-out;
  ${media.handheld`
    grid-template-rows: auto;
  `}
`

const ThumbnailImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #333333;
  border-radius: 10px;
`

const Videos = ({ ytVideos }) => {
  const [videos, setVideos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState();
  const [videoId, setVideoId] = useState();

  const openModal = (videoId) => {
    setModalIsOpen(true);
    setVideoId(videoId);
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const [currentImageIdx, setCurrentImagIdx] = useState(0);

  const prevSlide = () => {
    // find out whether currentImageIdx eqals 0 and thus user reached beginning of carousel
    const resetToVeryBack = currentImageIdx === 0;

    const index = resetToVeryBack ? videos.length - 1 : currentImageIdx - 1;

    // assign the logical index to current image index that will be used in render method
    setCurrentImagIdx(index);
  };

  const nextSlide = () => {
    // check if we need to start over from the first index
    const resetIndex = currentImageIdx === videos.length - 1;

    const index = resetIndex ? 0 : currentImageIdx + 1;

    // assign the logical index to current image index that will be used in render method
    setCurrentImagIdx(index);
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    setVideos(ytVideos);
  }, [ytVideos])

  // create a new array with 3 elements from the source videos
  const activeImageSourcesFromState = videos.slice(currentImageIdx, currentImageIdx + videos.length);

  // check the length of the new array (it’s less than 3 when index is at the end of the imagge sources array)
  const imageSourcesToDisplay = activeImageSourcesFromState.length < videos.length
    // if the imageSourcesToDisplay's length is lower than 3 videos than append missing videos from the beginning of the original array
    ? [...activeImageSourcesFromState, ...videos.slice(0, videos.length - activeImageSourcesFromState.length)]
    : activeImageSourcesFromState;


  const renderVideos = (yt) => {
    return yt.map((url, index) => {
      const videoId = url.split('=')[1];
      return (
        <Thumbnail key={index} onClick={() => openModal(videoId)}>
          <Overlay />
          <ThumbnailImg src={`https://img.youtube.com/vi/${videoId}/0.jpg`} alt="" />
        </Thumbnail>
      )
    })
  }

  return (
    <React.Fragment>

      <GridLayout>
        <Headline>
          <h2>Videos</h2>
          <p style={{ marginBottom: "10px" }}>Bachata is a type of latin dance and.</p>
        </Headline>
        <VideosLayout>
          {renderVideos(imageSourcesToDisplay)}
        </VideosLayout>
        <ButtonLayout>
          <button className="rounded shadow-s" onClick={prevSlide}><FiChevronLeft size="16px" /></button>
          <button className="rounded shadow-s" onClick={nextSlide}><FiChevronRight size="16px" /></button>
        </ButtonLayout>
      </GridLayout>
      <VideoModal videoId={videoId} modalIsOpen={modalIsOpen} closeModal={closeModal}/>
    </React.Fragment>
  )
}

export default Videos;