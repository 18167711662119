import React from 'react'
import { DropdownList } from 'react-widgets';
import { FlexInputField } from '../../../styles';

const DropDownListField = ({
  label,
  input,
  data,
  className,
  disabled = false,
  placeholder,
  valueField,
  textField,
  error: apiError,
  meta: { touched, error }
}) => 
  <FlexInputField className={className}>
    <div className="flex flex-row flex-align-center flex-justify-between">
      <label>{label}</label>
      <p className={`${touched && error || apiError ? "text-error" : "text-error text-grey-blue"}`}>&nbsp;{touched && error || apiError ? error || apiError : "Required"}</p>
    </div>
    <DropdownList {...input}
      data={data}
      valueField={valueField}
      textField={textField}
      disabled={disabled}
      placeholder={placeholder}
      onChange={input.onChange} />
  </FlexInputField>

export default DropDownListField;