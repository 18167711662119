import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { IoMdAdd, IoIosList } from 'react-icons/io'

import { getDiscounts, deleteDiscount } from '../../actions/discountActions';

import DiscountCreate from './DiscountCreate';
import DiscountEdit from './DiscountEdit';
import DiscountList from './DiscountList';
import DiscountRemove from './DiscountRemove';
import IconFilled from '../utilities/IconFilled';

import { media } from '../../styles'
import { toTitleCase } from '../../helpers'

const GridLayout = styled.div`
  display: grid;
  grid-column-gap: 16px;
  margin: 0 auto;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 3fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  & > * {
    display: flex;
    flex-direction: column;
  }
`

class DiscountShow extends React.Component {
  state = {
    discount: {},
    modalIsOpen: false,
    selectedView: "list"
  }

  componentWillMount() {
    this.props.getDiscounts();
  }

  openModal = (e) => {
    e.preventDefault()
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleViewChange = (e, discount) => {
    this.setState({
      selectedView: e.currentTarget.dataset.view,
      discount: discount
    });
  }

  handleClearState = () => {
    this.setState({
      discount: {},
      selectedView: "list"
    });
  }

  handleDeleteDiscount = (id) => {
    this.props.deleteDiscount(id, () => {
      this.handleClearState();
    });
  }

  renderView = (name, discounts) => {
    switch (name) {
      case 'list':
        return (
          <DiscountList
            discounts={discounts}
            handleViewChange={this.handleViewChange}
            handleClearState={this.handleClearState} />
        )
      case 'create':
        return <DiscountCreate handleClearState={this.handleClearState}/>
      case 'edit':
        return <DiscountEdit handleClearState={this.handleClearState} discountID={this.state.discount._id} />
      case 'remove':
        return <DiscountRemove
          handleClearState={this.handleClearState}
          handleViewChange={this.handleViewChange}
          handleDeleteDiscount={this.handleDeleteDiscount}
          discount={this.state.discount} />
      default:
        break;
    }
  }

  render() {
    const { discounts } = this.props.discount;
    const { selectedView } = this.state;

    return (
      <GridLayout>
        <div className="item">
          <div className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall paddingTop--small paddingBottom--small">
            <p className="text-grey-blue">Discount Codes {toTitleCase(selectedView)}</p>
            <div className="flex flex-row">
              <IconFilled
                isPointer={true}
                isHover={true}
                cHeight="32px"
                cWidth="32px"
                data-view="list"
                className="marginRight--small"
                onClick={(e) => this.handleViewChange(e)}>
                <IoIosList size="1rem" />
              </IconFilled>

              <IconFilled
                isPointer={true}
                isHover={true}
                cHeight="32px"
                cWidth="32px"
                data-view="create"
                onClick={(e) => this.handleViewChange(e)}>
                <IoMdAdd size="1rem" />
              </IconFilled>

            </div>
          </div>
          {this.renderView(selectedView, discounts)}
        </div>
      </GridLayout>
    )
    
  }
}

const mapStateToProps = state => ({
  discount: state.discount
});

export default connect(mapStateToProps, { getDiscounts, deleteDiscount })(DiscountShow);