import React from 'react';

import Hero from './hero';
import SectionOne from './sectionOne';
import SectionTwo from './sectionTwo';
import SectionThree from './sectionThree';
import SectionFour from '../home-page/SectionFour';
import FeatureList from './feature-list';
import { FlexContainer } from '../../../styles';

const FeaturePage = () =>
  <React.Fragment>
    <div className="background-white">
      <Hero />
      <FlexContainer>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <FeatureList />
      </FlexContainer>
    </div>
    <SectionFour />
  </React.Fragment>

export default FeaturePage;