import React from 'react';
import { FiX, FiEdit, FiTrash } from 'react-icons/fi'
import IconFilled from '../../utilities/IconFilled';

import { formatPrice, truncate, friendlyDate, fromNow } from '../../../helpers';

const TaxView = ({ event, tax, closeModal, handleModalSwitch, handleViewChange }) => {
  return (
    <div
      style={{ height: "100%" }}
      className="flex flex-column flex-justify-between padding--small paddingTop--small-1">
      <div className="flex flex-column">
        <div className="flex flex-row flex-align-center flex-justify-end">
          <IconFilled
            isPointer={true}
            isHover={true}
            cHeight="32px"
            cWidth="32px"
            data-view="edit"
            className="marginRight--small"
            onClick={(e) => handleViewChange(e, tax)}>
            <FiEdit size="1rem" />
          </IconFilled>

          <IconFilled
            isPointer={true}
            isHover={true}
            cHeight="32px"
            cWidth="32px"
            data-modal="remove"
            className="marginRight--small"
            onClick={(e) => handleModalSwitch(e, tax)}>
            <FiTrash size="1rem" />
          </IconFilled>

          <IconFilled
            isPointer={true}
            isHover={true}
            cHeight="32px"
            cWidth="32px"
            onClick={closeModal}>
            <FiX size="1rem" />
          </IconFilled>
        </div>

        <div className="flex flex-column">
          <p className="marginRight--xSmall text-bold text-spacing-1">{truncate(tax.tax_name.toUpperCase(), 49)}</p>
          <p className="text-small">{tax.tax_percent}</p>
          <p className="text-small">{tax.tax_id}</p>
        </div>

      </div>
    </div>
  )
}
export default TaxView;