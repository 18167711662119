import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import { FiUser, FiCopy } from 'react-icons/fi';

import { getEventHook } from '../../../actions/eventActions';
import { updateOrderForm } from '../../../actions/orderFormActions';

import OrderForm from './OrderForm';
import OrderFormPreview from './OrderFormPreview';

import { media } from '../../../styles';
import * as custom from '../../../styles/Variables';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 60px;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  
  ${media.handheld`
    max-width: 100%;
    width: auto;
    grid-row-gap: 32px;
  `}
`

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #FFFFFF;
  padding: 24px;
  border: 1px solid ${custom.COLOR_BORDER};
  border-radius: ${custom.BORDER_RADIUS};
  & > :first-child {
    border-right: 1px solid #EBEBEB;
    padding-right: 24px;
    margin-right: 24px;
    ${media.handheld`
      border-right: none;
      padding-right: 0; 
      margin-right: 0; 
    `}
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  `}
`

const OrderFormView = ({ eventID, event }) => {
  const [orderForm, setOrderForm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [touched, setTouched] = useState([]); // NEW: stores all touched field names

  const dispatch = useDispatch();

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(getEventHook(eventID));
        console.log('data', data)

        setOrderForm(data.order_form);
      } catch (err) {
        setError(err)
      }
      setIsLoading(false);
    };
    runEffect();
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await dispatch(updateOrderForm(eventID, orderForm));
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  }

  const handleChange = (e, index) => {
    let name = e.target.name;
    let value = e.target.checked;
    let arr = name.split('.');

    setOrderForm((prevState) => {
      let orderFormCopy = Object.assign({}, prevState);
      if (!isNaN(index)) {
        let newObject = {
          ...prevState.custom_fields[index],
          [`${arr[1]}`]: value
        }
        orderFormCopy.custom_fields.splice(index, 1, newObject);
        return {
          ...prevState,
          custom_fields: [
            ...orderFormCopy.custom_fields,
          ]
        }
      } else {
        return {
          ...prevState,
          [`${arr[0]}`]: { // age
            ...prevState[`${arr[0]}`],
            [`${arr[1]}`]: value // include: value || require: value
          }
        }
      }
    });

    if (!touched.includes(e.target.name)) {
      setTouched([
        ...touched,
        e.target.name
      ])
    } else {
      setTouched(touched.filter((_, i) => _ !== e.target.name))
    }
  }

  const spinner = (
    <div className="flex flex-align-center flex-justify-center marginTop--small marginBottom--small">
      <FontAwesome size="2x" spin name="spinner" />
    </div>
  )

  return (
    <GridLayout>
      <GridColumns>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--medium">
            <FiUser size="32px" className="marginRight--small" />
            <h2 className="text-bold">Collected Information</h2>
          </div>
          <div className="marginBottom--small padding--small background-inputFill border rounded-m">
            <p className="text-small">Feature is Beta. You can still collect information about your attendees. But results won't be displayed until a later date.</p>
          </div>
          {isLoading ? spinner : <OrderForm eventID={eventID} orderForm={orderForm} isLoading={isLoading} touched={touched} handleChange={handleChange} handleSubmit={handleSubmit} />}
        </div>
        <div>
          <div className="flex flex-row flex-align-center marginBottom--medium">
            <FiCopy size="32px" className="marginRight--small" />
            <h2 className="text-bold">Preview Form</h2>
          </div>
          {isLoading ? spinner : <OrderFormPreview orderForm={orderForm} isLoading={isLoading} />}
        </div>
      </GridColumns>
    </GridLayout>
  )
}

export default OrderFormView;