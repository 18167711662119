import React, { useState } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types'
import { sendPromoCode } from '../../../actions/mailActions';
import { FlexContainer, media, ButtonStyled } from '../../../styles';
import { useDispatch } from 'react-redux';

import Wall from "../images/wall-painting.svg";

const Title = styled.div`
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  color: #FFFFFF;
  line-height: 79px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 38px;
  margin-bottom: 50px;
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  ${media.handheld`
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  `}
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #0091FF;
  background-image: url(${Wall});
  background-position: 92% 50%;
  background-repeat: no-repeat;
  border-radius: 16px;
  padding: 100px 70px;
  margin-bottom: 120px;
  min-height: 240px;
  ${media.handheld`
    margin-right: 24px;
    margin-left: 24px;
    padding: 24px;
    background-image: none;
  `}
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 8px;
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  `}
`

const FormGridLayout = styled.form`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 8px;
  grid-template-rows: auto;
  align-items: center;
  input { 
    height: 34px;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  `}
`

const FormWizardPromo = () => {
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setIsStatus] = useState("");
  const [software, setIsSoftware] = useState("");
  const [softwareSwitch, setSoftwareSwitch] = useState("");

  const dispatch = useDispatch();
  
  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const nextPage = (e) => {
    if (e.currentTarget.dataset.status) {
      setIsStatus(e.currentTarget.dataset.status);
      setPage(page + 1);
    } else if (e.currentTarget.dataset.software) {
      setIsSoftware(e.currentTarget.dataset.software);
      setPage(page + 1);
    } else if (e.currentTarget.dataset.switch) {
      setSoftwareSwitch(e.currentTarget.dataset.switch);
      setPage(page + 1);
    }
  }

  const previousPage = () => {
    this.setState({ page: page - 1 })
  }

  const beforeSubmit = (e) => {
    e.preventDefault();
    
    if (e.target.value === "" || email === "") {
      setError("Please enter an email address.");
    } else {
      setIsLoading(true);
      const formValue = {
        email: email,
        status: status,
        software: software,
        softwareSwitch: softwareSwitch,
      }
      dispatch(sendPromoCode(formValue, () => {
        setIsLoading(false);
        setPage(page + 1);
      }))
    }
  }

  const WizardFormFirstPage = (
    <React.Fragment>
      <SubTitle>How are you doing your event management today?</SubTitle>
      <ButtonsContainer>
        <ButtonStyled
          data-status="I'm already using software"
          onClick={(e) => nextPage(e)}>
          <p>I'm already using software</p>
        </ButtonStyled>

        <ButtonStyled
          data-status="I'm not yet"
          onClick={(e) => nextPage(e)}>
          <p>I'm not yet</p>
        </ButtonStyled>

      </ButtonsContainer>
    </React.Fragment>
  )

  const WizardFormSecondPage = (
    <React.Fragment>
      <SubTitle className="text-white">Which software are you using?</SubTitle>
      <ButtonsContainer>
        <ButtonStyled
          data-software="Eventbrite"
          onClick={(e) => nextPage(e)}>
          <p>Eventbrite</p>
        </ButtonStyled>
        <ButtonStyled
          data-software="Eventzilla"
          onClick={(e) => nextPage(e)}>
          <p>Eventzilla</p>
        </ButtonStyled>
        <ButtonStyled
          data-software="TicketTailor"
          onClick={(e) => nextPage(e)}>
          <p>TicketTailor</p>
        </ButtonStyled>
        <ButtonStyled
          data-software="TicketSpice"
          onClick={(e) => nextPage(e)}>
          <p>TicketSpice</p>
        </ButtonStyled>
      </ButtonsContainer>
    </React.Fragment>
  )

  const WizardFormThirdPage = (
    <React.Fragment>
      <SubTitle className="text-white">Are you thinking of switching?</SubTitle>
      <ButtonsContainer>
        <ButtonStyled
          data-switch="Yes"
          onClick={(e) => nextPage(e)}>
          <p>Yes, immediately</p>
        </ButtonStyled>
        <ButtonStyled
          data-software="No"
          onClick={(e) => nextPage(e)}>
          <p>No thanks</p>
        </ButtonStyled>
        <ButtonStyled
          data-software="Maybe"
          onClick={(e) => nextPage(e)}>
          <p>Maybe in the future</p>
        </ButtonStyled> 
      </ButtonsContainer>
    </React.Fragment>
  )

  const WizardFormFourthPage = (
    <React.Fragment>
      <SubTitle className="text-white">50% off if you switch this month</SubTitle>
      <FormGridLayout onSubmit={beforeSubmit}>
        <input
          name="email"
          type="text"
          className=""
          placeholder="john@gmail.com"
          onChange={handleEmail} />
        <ButtonStyled type="submit" disabled={isLoading}>
          {isLoading ? <FontAwesome size="2x" spin name="spinner" style={{ color: "#1C87E4" }} /> : "Send Code"}
        </ButtonStyled>
      </FormGridLayout>
      <SubTitle>{error}</SubTitle>
    </React.Fragment>
  )
  const WizardFormFifthPage = (
    <SubTitle className="text-white">Thanks! Check your inbox</SubTitle>
  )

  return (
    <FlexContainer>
      <StyledDiv>
        <Title>How Attndnce can help you.</Title>
        <GridLayout>
          {page === 1 && WizardFormFirstPage}
          {page === 2 && WizardFormSecondPage}
          {page === 3 && WizardFormThirdPage}
          {page === 4 && WizardFormFourthPage}
          {page === 5 && WizardFormFifthPage}
        </GridLayout>
      </StyledDiv>
    </FlexContainer>
  )
}


FormWizardPromo.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default connect(null, { sendPromoCode })(FormWizardPromo);
