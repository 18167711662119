import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { getRevenue } from '../../../actions/eventSaleActions';
import { formatPrice } from '../../../helpers';

const OVRevenue = ({ eventID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [revenue, setRevenue] = useState({});
  const [error, setError] = useState(false);
  
  const dispatch = useDispatch();

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(getRevenue(eventID));
        setRevenue(data);
      } catch (err) {
        setError(err)
      }
      setIsLoading(false);
    };
    runEffect();
  }, [dispatch]);

  return (
    <div className="background-white rounded-m border padding--small">
      <p>Revenue</p>
      {revenue === null || isLoading ?
        <div className="flex flex-align-center flex-justify-center marginTop--small marginBottom--small">
          <FontAwesome size="2x" spin name="spinner" />
        </div>
        :
        <React.Fragment>
          <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small padding--small background-inputFill rounded-m">
            <div className="flex flex-column">
              <p className="text-bold">Net</p>
            </div>
            <div className="flex flex-column flex-align-center flex-justify-center">
              <p className="text-bold">{formatPrice(revenue.net, "USD", "en-US")}</p>
            </div>
          </div>
          <div className="overviewcard__info">
            {/* <div className="flex flex-row flex-justify-between padding--xSmall"><p className="text-grey-blue">Taxes</p><p>{formatPrice(revenue.tax, "USD", "en-US")}</p></div> */}
            <div className="flex flex-row flex-justify-between padding--xSmall">
              <p className="text-grey-blue">Fees</p>
              <p className="text-number">{formatPrice(revenue.fees, "USD", "en-US")}</p>
            </div>
            <div className="flex flex-row flex-justify-between padding--xSmall">
              <p className="text-grey-blue">Gross</p>
              <p className="text-number">{formatPrice(revenue.gross, "USD", "en-US")}</p>
            </div>
          </div>
        </React.Fragment>
      }
    </div>
  )
}
export default OVRevenue;