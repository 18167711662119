import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import { getEvent } from '../../../actions/eventActions';
import { fetchTaxes, deleteTax } from '../../../actions/taxActions';

import TaxList from './TaxList';
import TaxView from './TaxView';
import TaxRemove from './TaxRemove';
import TaxCreate from './TaxCreate';
import TaxEdit from './TaxEdit';

import Spinner from '../../utilities/Spinner';
import IconFilled from '../../utilities/IconFilled';

import { IoMdAdd, IoIosList } from 'react-icons/io'

import { StyledCard } from '../../../styles';
import { toTitleCase } from '../../../helpers';

class TaxShow extends React.Component {
  state = {
    selectedView: 'list',
    modalIsOpen: false,
    modalContent: "",
    modalHeight: "",
    event: {}
  }

  componentDidMount() {
    this.props.fetchTaxes(this.props.match.params.id);
    this.props.getEvent(this.props.match.params.id, (data) => {
      this.setState({ event: data })
    });
  }

  openModal = (e) => {
    e.preventDefault()
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleModalSwitch = (e, tax = {}) => {
    this.setState({
      taxID: tax._id,
      tax: tax,
      modalIsOpen: true,
      modalContent: e.currentTarget.dataset.modal,
      modalHeight: e.currentTarget.dataset.modalh
    });
  }

  handleViewChange = (e, tax) => {
    this.setState({
      selectedView: e.currentTarget.dataset.view,
      tax: tax,
      modalIsOpen: false,
    });
  }

  handleClearState = () => {
    this.setState({
      selectedView: "list",
      modalIsOpen: false,
      modalContent: ""
    });
  }

  handleRemoveTax = (tax) => {
    this.props.deleteTax(this.props.match.params.id, tax, () => {
      this.handleClearState();
    });
  }

  renderView = (name, event, taxes) => {
    switch (name) {
      case 'list':
        return <TaxList
          event={event}
          taxes={taxes}
          handleModalSwitch={this.handleModalSwitch} />
      case 'create':
        return <TaxCreate
          eventID={this.props.match.params.id}
          handleClearState={this.handleClearState} />
      case 'edit':
        return <TaxEdit
          event={event}
          eventID={this.props.match.params.id}
          tax={this.state.tax}
          handleClearState={this.handleClearState} />
      default:
        return (
          <div>
            <p>Taxes are the smaller events that make up your schedule. They can be anything from panels to parties, or workshops and performances.Help attendees discover what makes your event special by adding detailed information to each session.</p>
          </div>
        )
    }
  }

  renderModalContent = (name) => {
    switch (name) {
      case 'view':
        return <TaxView
          event={this.state.event}
          tax={this.state.tax}
          closeModal={this.closeModal}
          handleViewChange={this.handleViewChange}
          handleModalSwitch={this.handleModalSwitch} />
      case 'remove':
        return <TaxRemove
          event={this.state.event}
          tax={this.state.tax}
          closeModal={this.closeModal}
          handleRemoveTax={this.handleRemoveTax} />
      default:
        break;
    }
  }

  render() {
    const { event: { event } } = this.props;
    const { tax, taxes } = this.props.tax;
    const { selectedView, modalIsOpen, modalContent, modalHeight } = this.state;

    const customStyles = {
      content: {
        height: modalHeight, borderRadius: '4px'
      }
    };

    if (event === null || taxes === null || this.props.match.params.id === "") return <Spinner />

    return (
      <React.Fragment>
        <div className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall paddingTop--small paddingBottom--small">

          <p className="text-grey-blue">{toTitleCase(selectedView)}</p>

          <div className="flex flex-row">
            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="list"
              className="marginRight--small"
              onClick={(e) => this.handleViewChange(e)}>
              <IoIosList size="1rem" />
            </IconFilled>

            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="create"
              onClick={(e) => this.handleViewChange(e)}>
              <IoMdAdd size="1rem" />
            </IconFilled>

          </div>

        </div>
        {this.renderView(selectedView, event, taxes)}
        <Modal
          isOpen={modalIsOpen}
          className="modal"
          overlayClassName="overlay"
          style={customStyles}
          contentLabel="taxes">
          {this.renderModalContent(modalContent)}
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  event: state.event,
  tax: state.tax
});

export default connect(mapStateToProps, { getEvent, fetchTaxes, deleteTax })(withRouter(TaxShow));


