import styled from 'styled-components'
import * as custom from './Variables';
import { media } from './Media';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${custom.SPACE_medium};
  padding: ${custom.SPACE_xLarge};
  background: ${custom.BACKGROUND_COLOR_WHITE};
  border-radius: ${custom.BORDER_RADIUS};
  border: 1px solid ${custom.COLOR_BORDER};
  box-shadow: 0 2px 0 0 rgba(0,0,0,.03);
  min-width: ${props => props.large ? '800px' : '320px'};
  
  &.selected-ticketType {
    background: ${custom.COLOR_BLUE_DARK};
  }
  ${media.handheld`
    min-width: 0;
    margin: 0 ${custom.SPACE_medium} ${custom.SPACE_medium} ${custom.SPACE_medium};
  `}
`

export const ImagePreview = styled.div`
  display: flex;
  max-width: ${props => props.imageMaxW ? props.imageMaxW : "184px"};
  height: ${props => props.imageH ? props.imageH : "134px"};
  background: #000000;
  border-radius: ${custom.BORDER_RADIUS};
  ${media.handheld`
    max-width: 134px;
  `}
  
  img {
    width: ${props => props.imageMaxW ? props.imageMaxW : "100%"};
    height: ${props => props.imageH ? props.imageH : "134px"};
    border-radius: ${custom.BORDER_RADIUS};
    overflow: hidden;
    object-fit: cover;
  }
`

export const GridStyledCard = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 16px;
  grid-template-rows: auto;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid ${custom.COLOR_BORDER};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  `}

  .item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: none;
  }
`
