import React from 'react'
import styled from 'styled-components';
import { media } from '../../../styles';
import { WiMoonAltNew } from "react-icons/wi";

import ChartImage from "../images/chart.png";

const Title = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  ${media.handheld`
    margin-bottom: 24px;
    width: 100%;
    font-size: 40px;
    line-height: 50px;
  `}
`

const SubTitle = styled.div`
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #262626;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  ${media.handheld`
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 450px 450px;
  grid-column-gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
  .item {
    display: flex;
    flex-direction: column;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  `}
`

const Graph = ({ selectedPlan, _calculateStripe, _calculateEventbrite }) =>
  <GridLayout>
    <GridColumns>
      <div className="item">
        <div>
          <Title>Savings you can count on</Title>
          <SubTitle>What you see is what you are charged. We have no hidden fees.</SubTitle>
        </div>
        <div>
          <Title className="marginBottom--medium">
            Event Savings
            {Math.floor((((_calculateEventbrite() - (selectedPlan === "option1" ? _calculateStripe() : 300)) / _calculateEventbrite()) * 100)) < 0 ?
              <span className="marginLeft--medium text-number text-bold">0</span>
              :
              <span className="marginLeft--medium text-number text-bold">${_calculateEventbrite() - (selectedPlan === "option1" ? _calculateStripe() : 300) + ".00"}</span>
            }
          </Title>
          <div className="flex flex-row flex-align-center marginBottom--medium">
            <div className="flex flex-row flex-align-center flex-justify-between marginRight--medium">
              <WiMoonAltNew className="marginRight--small" size="16px" style={{ width: "16px" }} />
              <p>Eventbrite</p>
            </div>
            <div className="flex flex-row flex-align-center flex-justify-between">
              <WiMoonAltNew className="marginRight--small text-blue" size="16px" style={{ width: "16px" }} />
              <p className="marginRight--small text-blue">Attndnce</p>
            </div>
          </div>
          <p className="text-small">As of Jan 1st, 2020, Eventbrite’s Professional Package are priced at 3.5% + US$1.59 per sold ticket with a 2.5% per order for processing Fees. Compared to Attndnce’s Pay-as-you-go plan priced of just 65¢ per transaction.</p>
        </div>

      </div>
      <div className="item">
        <img src={ChartImage} alt="" />
      </div>
    </GridColumns>
  </GridLayout>

export default Graph;