import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { friendlyDate } from '../../../helpers';

const sort_unique_tracks = (arr) => {
  if (arr.length < 1) {
    return {};
  }
  const reduced = (arr || []).reduce((emptyArray, session) => {
    emptyArray = [...emptyArray, ...session.tracks];
    return emptyArray;
  }, []);

  const seen = new Set();

  const unique = reduced.filter(el => {
    const duplicate = seen.has(el);
    seen.add(el);
    return !duplicate;
  });

  let objects = {};
  unique.sort();

  unique.map((item, index) => {
    let increment = index;
    objects = { ...objects, [item]: increment += 1 };
  });

  return objects;
}

const addRows = (arr) => {
  // let cssRows = ""
  // let index = 0;
  // for (index; index < arr.length; index++) {
  //   let gridRow = "[time-" + arr[index] + "] 1fr "
  //   cssRows += gridRow;
  // }
  // return cssRows += ";"

  let squares = [];

  for (let i = 0; i < arr.length; i++) {
    squares.push(friendlyDate(arr[i].start_date, 'HHmm'));
    squares.push(friendlyDate(arr[i].end_date, 'HHmm'));
  }

  const seen = new Set();

  const unique = squares.filter(el => {
    const duplicate = seen.has(el);
    seen.add(el);
    return !duplicate;
  });

  unique.sort();

  let cssRows = ""
  let index = 0;
  for (index; index < unique.length; index++) {
    let gridRow = "[time-" + unique[index] + "] 1fr "
    cssRows += gridRow;
  }
  return cssRows += ";"
}

const addColumns = (arr) => {
  let sorted = sort_unique_tracks(arr);

  const values = Object.values(sorted);

  let sortedMin = 0;
  let sortedMax = values.length;
  let gridSpans = "";

  gridSpans += "[times] " + `100px` // times column width Original {sortedMax}em
  gridSpans += "[track-" + (sortedMin += 1) + "-start] 1fr ";

  for (let index = 0; index < values.length; index++) {
    let increment = index;
    let limit = sortedMax - 1;
    if (index !== limit) {
      let cols = "[track-" + (increment += 1) + "-end" + " track-" + (increment += 1) + "-start] 1fr "
      gridSpans += cols;
    }
  }
  gridSpans += "[track-" + sortedMax + "-end];"
  return gridSpans;
}

const ScheduleStyled = styled.div`
/*************************
* GRID SCHEDULE LAYOUT
  *************************/

  .time-slot {
    grid-column: times;
  }

  .track-slot {
    display: none; /* hidden on small screens and browsers without grid support */
  }

  @supports( display:grid ) {
    @media screen and (min-width:700px) {
      .track-slot {
        display: block;
        padding: 10px 5px 5px;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: rgba(255,255,255,.9);
      }
    }
  }

  /* Small-screen & fallback styles */
  .session {
    margin-bottom:  1em;
  }

  @supports( display:grid ) {
    @media screen and (min-width: 700px) {
      .session {
        margin: 0;
      }   
    }
  }

  /*************************
  * VISUAL STYLES
  * Design-y stuff ot particularly important to the demo
  *************************/

  body {
    padding: 50px;
    max-width: 1100px;
    margin: 0 auto;
    line-height: 1.5;
  }

  .session {
    padding: .5em;
    border-radius: 2px;
    font-size: 14px;
    /* box-shadow:
      rgba(255,255,255,.6) 1px 1px 0,
      rgba(0,0,0,.3) 4px 4px 0; */
      box-shadow: 4px 4px 0 #E8EBFA;
  }

  .session-title,
  .session-time text-small text-white,
  .session-track,
  .session-presenter {
    display: block;
  }

  .session-title,
  .time-slot {
    margin: 0;
    font-size: 1em;
  }

  .session-title {
    text-decoration-style: dotted;
    
    &:hover {
      font-style: italic;
      cursor: pointer;
    }
    
    &:focus {
      outline: 2px dotted rgba(255,255,255,.8);
    }
  }

  /* .track-slot,
  .time-slot {
    font-weight: bold;
    font-size:.75em;
  } */

  .track-1 {
    background: #E5EFFD;
    border: 1px solid #3365B0;
    border-radius: 8px;
    > * {
      color: #3365B0;
    }
  }

  .track-2 {
    background: #E5EFFD;
    border: 1px solid #3365B0;
    border-radius: 8px;
    > * {
      color: #3365B0;
    }
  }

  .track-3 {
    background: #E5EFFD;
    border: 1px solid #3365B0;
    border-radius: 8px;
    > * {
      color: #3365B0;
    }
  }

  .track-4 {
    background: #E5EFFD;
    border: 1px solid #3365B0;
    border-radius: 8px;
    > * {
      color: #3365B0;
    }
  }

  .track-5 {
    background: #E5EFFD;
    border: 1px solid #3365B0;
    border-radius: 8px;
    > * {
      color: #3365B0;
    }
  }

  .track-all {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccc;
    color: #000;
    box-shadow: none;
  }

  .text {
    max-width: 750px;
    font-size: 18px;
    margin: 0 auto 50px;
  }

  .meta {
    color: #555;
    font-style: italic;
  }

  .meta a {
    color: #555;
  }
  hr {
    margin: 40px 0;
  }
}`

class SessionSchedule extends Component {
  state = {
    tracks: {}
  }

  componentDidUpdate(prevProps) {
    const { sessions, isScheduleInit, handleSessionState } = this.props;
    if (prevProps.isScheduleInit !== isScheduleInit) {
      this.initSchedule(sessions, handleSessionState);
      var sheet = (function () {
        // Create the <style> tag
        var style = document.createElement("style");
        style.setAttribute("type", "text/css");

        // Add a media (and/or media query) here if you'd like!
        // style.setAttribute("media", "screen")
        // style.setAttribute("media", "only screen and (max-width : 1024px)")

        let styleContent = `
      @media screen and (min-width:700px) {
        .schedule {
          display: grid;
          grid-gap: 1em;
          grid-template-rows:
            [tracks] auto
            ${addRows(sessions)}
            
            /* Note 1:
            Use 24hr time for gridline names for simplicity

            Note 2: Use "auto" instead of "1fr" for a more compact schedule where height of a slot is not proportional to the session length. Implementing a "compact" shortcode attribute might make sense for this!
            Try 0.5fr for more compact equal rows. I don't quite understand how that works :)
            */
          
        grid-template-columns:
          ${addColumns(sessions)}
        }
    }`

        style.textContent = styleContent;

        // WebKit hack :(
        style.appendChild(document.createTextNode(""));

        // Add the <style> element to the page
        document.head.appendChild(style);

        return style.sheet;
      })();

      let sorted = sort_unique_tracks(sessions);

      this.setState({ tracks: sorted });
    }
  }


  componentDidMount() {
    const { sessions, isScheduleInit, handleSessionState } = this.props;
    if (sessions.length > 0 && isScheduleInit === false) {
      this.initSchedule(sessions, handleSessionState);
    }

    var sheet = (function () {
      // Create the <style> tag
      var style = document.createElement("style");
      style.setAttribute("type", "text/css");

      // Add a media (and/or media query) here if you'd like!
      // style.setAttribute("media", "screen")
      // style.setAttribute("media", "only screen and (max-width : 1024px)")

      let styleContent = `
      @media screen and (min-width:700px) {
        .schedule {
          display: grid;
          grid-gap: 1em;
          grid-template-rows:
            [tracks] auto
            ${addRows(sessions)}
            
            /* Note 1:
            Use 24hr time for gridline names for simplicity

            Note 2: Use "auto" instead of "1fr" for a more compact schedule where height of a slot is not proportional to the session length. Implementing a "compact" shortcode attribute might make sense for this!
            Try 0.5fr for more compact equal rows. I don't quite understand how that works :)
            */
          
        grid-template-columns:
          ${addColumns(sessions)}
        }
    }`

      style.textContent = styleContent;

      // WebKit hack :(
      style.appendChild(document.createTextNode(""));

      // Add the <style> element to the page
      document.head.appendChild(style);

      return style.sheet;
    })();

    let sorted = sort_unique_tracks(sessions);

    this.setState({ tracks: sorted });
  }

  renderSession = (sessions, handleModalSwitch) => {

    const result = _.chain(sessions)
      .groupBy("start_date")
      .toPairs()
      .map(session => {
        return _.zipObject(["start_date", "sessions"], session);
      })
      .value();

    return result.map((row, index) => {
      return (
        <React.Fragment key={index}>
          <h2 className="time-slot" style={{ gridRow: `time-${friendlyDate(row.start_date, 'HHmm')}` }}>{friendlyDate(row.start_date, 'HH:mm')}</h2>
          {this.renderClasses(row.sessions, index + 1, handleModalSwitch)}
        </React.Fragment>
      )
    });
  }

  renderClasses = (classes, row, handleModalSwitch) => {
    // const joinSplit = (array) => { return array[0] + "-" + array[1]; }

    return classes.map((cl, index) => {

      return (
        /* <div className="session session-6 track-1" style={{ gridColumn: "track-1-start / track-2-end", gridRow: "time-0900 / time-1000" }}> */
        <div
          key={index}
          className={`session session-${row} ${Object.keys(this.state.tracks).length > 0 && this.renderTrackSpan(cl.tracks, false)}`}
          style={{ gridColumn: Object.keys(this.state.tracks).length > 0 && this.renderTrackSpan(cl.tracks, true), gridRow: `time-${friendlyDate(cl.start_date, 'HHmm')} / time-${friendlyDate(cl.end_date, 'HHmm')}` }}>
          <h3 className="session-title" data-modal="view" data-modalh="250px" onClick={(e) => handleModalSwitch(e, cl)} >{cl.name}</h3>
          <p className="session-presenter text-small">{friendlyDate(cl.start_date, 'MMM DD')}</p>
          <p className="session-time text-small">{`${friendlyDate(cl.start_date, 'HH:mm')} - ${friendlyDate(cl.end_date, 'HH:mm')}`}</p>
          {cl.tracks.sort().map((tr, index) => { return <p key={index} className="session-track text-small marginRight--xSmall">{tr}</p> })}
          {/* <span className="session-presenter">Presenter</span> */}
        </div>
      );
    })
  }


  renderTrackSpan = (arr, double) => {
    const obj = this.state.tracks;
    let arrays = [];

    arr.map((item) => {
      if (item in obj) {
        arrays = [...arrays, obj[item]];
      }
    });

    if (arrays.length > 1) {

      const trackMin = Math.min(...arrays);
      const trackMax = Math.max(...arrays);
      if (double) {
        return `track-${trackMin} / track-${trackMax}`
      } else {
        return `track-${trackMin}`
      }
    } else {
      return `track-${arrays[0]}`
    }
  }

  renderScheduleHeader = (sessions) => {
    if (sessions.length > 0) {

      let sorted = sort_unique_tracks(sessions);
      const keys = Object.keys(sorted)
      const values = Object.values(sorted)

      return (
        <React.Fragment>
          {keys.map((val, index) => {
            return <span key={index} className="track-slot text-bold text-small" aria-hidden="true" style={{ gridColumn: `track-${values[index]}`, gridRow: "tracks" }}>{val}</span>
          })}
        </React.Fragment>
      )
    }

    return;
  }

  initSchedule = (sessions = [], handleSessionState) => {
    function reduceSession(sessions) {
      return (sessions || []).reduce(function (r, e) {
        let check = friendlyDate(e.start_date, 'MMM DD');
        e.day_of_session = check;
        r.push(e);
        return r;
      }, []);
    }

    function groupByDays(sessions) {
      return _.chain(sessions)
        .groupBy("day_of_session")
        .toPairs()
        .map(currentItem => {
          return _.zipObject(["start_date", "sessions"], currentItem);
        })
        .value()
    }

    const reduced = reduceSession(sessions);
    const groupedByDays = groupByDays(reduced);

    const filtered = groupedByDays.filter(day => day.start_date === groupedByDays[0].start_date)
    handleSessionState(filtered[0].sessions, filtered[0].start_date, true);
  }

  selectText = (index) => {
    if (index === 0) {
      return "text-blue";
    }
  }

  renderButtons = (sessions, handleSessionState) => {
    function reduceSession(sessions) {
      return sessions.reduce(function (r, e) {
        let check = friendlyDate(e.start_date, 'MMM DD');
        e.day_of_session = check;
        r.push(e);
        return r;
      }, []);
    }

    function groupByDays(sessions) {
      return _.chain(sessions)
        .groupBy("day_of_session")
        .toPairs()
        .map(currentItem => {
          return _.zipObject(["start_date", "sessions"], currentItem);
        })
        .value()
    }

    const reduced = reduceSession(sessions);
    const groupedByDays = groupByDays(reduced);

    return groupedByDays.map((day, index) => {
      return (

        <p
          key={index}
          style={{ cursor: "pointer" }}
          className={`${!this.props.isScheduleInit ? "" : this.selectText(index)} marginRight--small`}
          onClick={(e) => {

            const elems = document.querySelectorAll(".text-blue");

            [].forEach.call(elems, function (el) {
              el.classList.remove("text-blue");
            });

            e.target.className = "text-blue marginRight--small";

            const items = [];

            day.sessions.map((cl, index) => {
              const b = {};

              const map = {
                name: "title",
                location: "group",
                start_date: "start_time",
                end_date: "end_time",
              };

              _.each(cl, function (value, key) {
                key = map[key] || key;
                b[key] = value;
              });
              b.group = parseFloat(index + 1);
              b['id'] = cl._id;
              b.start_time = friendlyDate(cl.start_date, 'x')
              b.end_time = friendlyDate(cl.end_date, 'x')
              items.push(b);

            })

            handleSessionState(day.sessions, day.start_date);
          }}>
          {day.start_date}
        </p>
      )
    });
  }

  render() {
    const { sessions, stateSessions, isScheduleInit, handleModalSwitch, handleSessionState } = this.props;

    if (Array.isArray(sessions) && sessions.length > 0) {
      return (
        <React.Fragment>
          <div className="flex flex-row flex-justify-between marginBottom--medium paddingBottom--xSmall border-bottom">
            <p className="text-grey-blue">Schedule</p>
            <div className="flex flex-row">
              {this.renderButtons(sessions, handleSessionState)}
            </div>
          </div>
          <ScheduleStyled>
            <div className="schedule" aria-labelledby="schedule-heading">
              {this.renderScheduleHeader(sessions)}
              {this.renderSession(stateSessions, handleModalSwitch)}
            </div>
          </ScheduleStyled>
        </React.Fragment>
      );
    }
    return (
      <div style={{ height: "100%" }} className="padding--small background-white border rounded-m shadow">
        <p>No scheduled sessions found. Please check back again later.</p>
      </div>
    )
  }
};

export default SessionSchedule;