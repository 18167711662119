import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IoIosMenu } from "react-icons/io";

import { signout } from '../actions/authActions';
import { clearCurrentProfile, getCurrentProfile } from '../actions/profileActions';

import Logo from '../components/utilities/Logo';

import Nav from './Nav';

import { Section, FlexContainer } from '../styles';


class Header extends Component {
  state = {
    open: false
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  handleLogoutClick = (e) => {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.signout();
  }

  toggleNav = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { open } = this.state;

    if (!this.props.history.location.pathname.includes("event")) {
      return (
        <Section bgColor="#FFFFFF">
          <FlexContainer>
            <div className="flex flex-row flex-align-center flex-justify-between paddingTop--small paddingBottom--small">
              <NavLink to={isAuthenticated ? "/account" : "/?ref=nav_logo"}>
                <Logo/>
              </NavLink>

              <IoIosMenu onClick={this.toggleNav} style={{ width: '32px', height: '32px' }}/>

              <Nav
                open={open}
                toggleMainMenu={this.toggleNav}
                handleLogoutClick={this.handleLogoutClick}
                isAuthenticated={isAuthenticated} />
            </div>
          </FlexContainer>
        </Section>
      )
    } else {
      return <div></div>
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
})

export default withRouter(connect(mapStateToProps, { clearCurrentProfile, getCurrentProfile, signout })(Header));
