import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FontAwesome from 'react-fontawesome';

import TextField from '../../utilities/formfields/TextField';

import { required } from '../../../helpers/formValdation';

import { FormStyle, ButtonStyled } from '../../../styles';

class MailchimpCreateAudience extends React.Component {

  render() {
    const { handleSubmit, pristine, isProcessing, errors } = this.props;
    return (
      <FormStyle onSubmit={handleSubmit(this.props.beforeSubmit)}>
        <p className="text-grey-blue">Audience Title</p>
        <div className="marginBottom--small padding--medium background-white border rounded-m shadow">
          <p>Choose a name for your Mailchimp audience</p>
          <Field
            type="text"
            name="name"
            placeholder="Attndnce"
            error={errors.name}
            validate={required}
            component={TextField} />
        </div>
        <p className="text-grey-blue">Ownership Information</p>
        <div className="marginBottom--medium padding--medium background-white border rounded-m shadow">
          <p className="marginBottom--small">Mailchimp requires your address and information to setup a new audience</p>
          <Field
            label="Company"
            type="text"
            name="company"
            placeholder="The company name for the list."
            error={errors.company}
            validate={required}
            component={TextField} />
          <Field
            label="Address Line 1"
            type="text"
            name="address1"
            placeholder="447 Brant Ave"
            error={errors.address1}
            validate={required}
            component={TextField} />
          <Field
            label="Address Line 2"
            type="text"
            name="address2"
            placeholder="Address 2"
            error={errors.address2}
            component={TextField} />
          <Field
            label="City"
            type="text"
            name="city"
            placeholder="Los Angeles"
            error={errors.city}
            validate={required}
            component={TextField} />
          <Field
            label="State/Province"
            type="text"
            name="state"
            placeholder="CA"
            error={errors.state}
            validate={required}
            component={TextField} />
          <Field
            label="Zip/Area Code"
            type="text"
            name="zip"
            placeholder="90210"
            error={errors.zip}
            validate={required}
            component={TextField} />
          <Field
            label="Country"
            type="text"
            name="country"
            placeholder="US"
            error={errors.country}
            validate={required}
            component={TextField} />
          <Field
            label="Phone Number"
            type="text"
            name="phone"
            placeholder="Phone Number"
            error={errors.phone}
            validate={required}
            component={TextField} />
          <ButtonStyled className="button--blue marginBottom--small" disabled={pristine || isProcessing}>
            {isProcessing ?
              <div className="flex flex-align-center flex-justify-center">
                <div className="flex flex-row flex-align-center flex-justify-center" style={{ width: "16px", height: "16px" }}><FontAwesome size="1x" spin name="spinner" /></div>
                <span className="marginLeft--small">Creating Audience</span>
              </div> : "Create Audience"
            }
          </ButtonStyled>
          <p className="text-small text-grey-blue">This will setup your Mailchimp account with a default Audience, Segment and Campaign</p>
        </div>
      </FormStyle>
    )
  }
}

export default reduxForm({ form: 'createAudience' })(MailchimpCreateAudience);

