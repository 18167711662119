import React from 'react';
import styled from 'styled-components';

const ImageWrapperStyled = styled.div`
/* even more control with max-width */
  max-width: 720px;
  background: #F5F5F5;
  border: 1px solid #E5EAFA;
  border-radius: 8px;
  min-height: 215px;
  & img {
    height: auto;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
  }
`

const ImageWrapper = (props) =>
  <ImageWrapperStyled {...props}>
    {props.children}
  </ImageWrapperStyled>

export default ImageWrapper;