import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FiPlus, FiChevronDown, FiChevronUp } from 'react-icons/fi';

import CreateCustomFieldForm from './CreateCustomFieldForm';

import { FormStyle, media, ButtonStyled } from '../../../styles';
import Spinner from '../../utilities/Spinner';

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 100px;
  & > :first-child {
    border-right: 1px solid #EBEBEB;
    ${media.handheld`
      border-right: none;
    `}
  }
  & > :nth-child(2), & > :last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    grid-row-gap: 32px;
  `}
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  align-items: center;
  & > button {
    margin-left: auto;
    width: 200px;
  }
  ${media.handheld`
    & > button {
      width: auto;
    }
  `}
`

const OrderForm = ({ eventID, orderForm, isLoading, touched, handleChange, handleSubmit }) => { 
  const [toggleCF, setToggleCF] = useState(false);

  const handleToggle = () => {
    setToggleCF((prevState) => !prevState);
  }

  const moreLess = (selected) => {
    if (selected) return <FiChevronUp size="16px" className="marginLeft--xSmall" onClick={handleToggle} />
    else return <FiChevronDown size="16px" className="marginLeft--xSmall" onClick={handleToggle} />
  }

  const renderCustomFields = (customFields) =>
    (customFields || []).map((cf, index) => {
      return (
        <GridRow className="marginBottom--small paddingBottom--small background-white border-bottom">
          <div className="flex flex-column">
            <p className="text-bold">{cf.question_prompt}</p>
            {cf.question_options.length > 0 && <p className="text-small text-grey-blue"><span className="text-grey-blue text-bold"><em>{cf.question_type} - Options:</em></span> {cf.question_options.map((option, index) => renderOptions(option, index, cf.question_options.length))}</p>}
          </div>
          <div><input type="checkbox" name={`custom_fields[${index}].include`} checked={cf.include} onChange={(e) => handleChange(e, index)} /></div>
          <div><input type="checkbox" name={`custom_fields[${index}].require`} checked={cf.require} onChange={(e) => handleChange(e, index)} /></div>
        </GridRow>
      )
    })

  const renderOptions = (opt, index, length) => {
    if (index + 1 !== length) {
      return <em key={index}>{opt}, </em>
    } else {
      return <em key={index}>{opt}</em>
    }
  }
 
  if (orderForm === null || isLoading === true) return <Spinner />
  else {
    return (
      <div>
        <GridRow className="marginBottom--small paddingBottom--small background-white border-bottom">
          <p className="text-grey-blue text-bold">Order Form</p>
          <p>Include</p>
          <p>Require</p>
        </GridRow>
        <FormStyle onSubmit={handleSubmit} className="marginBottom--small">

          {orderForm && orderForm.gender &&
            <GridRow className="marginBottom--small paddingBottom--small background-white border-bottom">
              <p>Gender</p>
              <div><input type="checkbox" name="gender.include" checked={orderForm.gender.include} onChange={handleChange} /></div>
              <div><input type="checkbox" name="gender.require" checked={orderForm.gender.require} onChange={handleChange} /></div>
            </GridRow>}
          {orderForm && orderForm.age &&
            <GridRow className="marginBottom--small paddingBottom--small background-white border-bottom">
              <p>Age</p>
              <div><input type="checkbox" name="age.include" checked={orderForm.age.include} onChange={handleChange} /></div>
              <div><input type="checkbox" name="age.require" checked={orderForm.age.require} onChange={handleChange} /></div>
            </GridRow>}
          {orderForm && orderForm.notes &&
            <GridRow className="marginBottom--small paddingBottom--small background-white border-bottom">
              <p>Add a Note</p>
              <div><input type="checkbox" name="notes.include" checked={orderForm.notes.include} onChange={handleChange} /></div>
              <div><input type="checkbox" name="notes.require" checked={orderForm.notes.require} onChange={handleChange} /></div>
            </GridRow>}
          {orderForm && orderForm.custom_fields.length > 0 && renderCustomFields(orderForm.custom_fields)}
          <ButtonContainer>
            <div className="flex flex-row flex-align-center">
              <FiPlus size="16px" className="marginRight--xSmall"/>
              <p onClick={handleToggle} className="marginRight--small text-blue">Custom Questions</p>
              {moreLess(toggleCF)}
            </div>
            <ButtonStyled className="button--blue" disabled={touched.length === 0}>Update Order Form</ButtonStyled>
          </ButtonContainer>
        </FormStyle>
        {toggleCF && <CreateCustomFieldForm eventID={eventID} />}
      </div>
    )
  }
}

export default OrderForm;