import React from 'react';
import styled from 'styled-components';

import SalesReportTable from './SalesReportTable';

import { media } from '../../../styles';
import OVRevenueCard from './OVRevenue';
import OVTicketBreakdownCard from './OVTicketBreakdown';
import OVTicketProgress from './OVTicketProgress';
import ExportToCSV from './ExportToCSV';

const MainOverView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  margin-bottom: 24px;
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  `}
`

const SalesReportShow = ({event, eventID}) => {
  return (
    <React.Fragment>
      <p className="text-grey-blue">Sales Overview</p>
      <MainOverView>
        <OVRevenueCard eventID={eventID}/>
        <OVTicketBreakdownCard event={event} eventID={eventID} />
        <OVTicketProgress event={event} eventID={eventID} />
      </MainOverView>
      <div className="flex flex-row flex-align-center flex-justify-between">

        <p className="text-grey-blue">Orders</p>
        <ExportToCSV eventID={eventID}/>
      </div>
      <SalesReportTable event={event} eventID={eventID} />
    </React.Fragment>
  )
}

export default SalesReportShow;