import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { FiUser, FiUsers, FiX, FiPlus, FiSidebar, FiPercent, FiList, FiGrid } from "react-icons/fi";
import { IoIosMenu, IoIosClose } from "react-icons/io";
import { TiTicket } from "react-icons/ti";
import { FaStripeS } from "react-icons/fa";

import { getStripe } from '../../actions/billingActions';
import { getCurrentProfile, clearCurrentProfile } from '../../actions/profileActions';
import { resendConfirmationEmail, addMailchimpToUser, signout } from '../../actions/authActions';
import { getEvents } from '../../actions/eventActions';
import { fetchAffiliations } from '../../actions/affiliateActions';

import EventInfo from './EventInfo';
import AccountInfo from './AccountInfo';
import CurrencyInfo from './CurrencyInfo';
import PasswordInfo from './PasswordInfo';
import StripeInfo from './StripeInfo';
import ProfileForm from './ProfileForm';
import DiscountShow from '../discount-code/DiscountShow';
import AffiliateInfo from './AffiliateInfo';
import AppMarketplace from './AppMarketplace';
import MailchimpShow from './integrated-apps/MailchimpShow';
import Nav from '../Nav';

import IconFilled from '../utilities/IconFilled';
import Logo from '../utilities/Logo';

import { toTitleCase } from '../../helpers';

import DashboardStyles from '../../styles/DashboardStyles';
import { ButtonStyledLink } from '../../styles';

class Dashboard extends Component {
  state = {
    message: "",
    component: 'events',
    sendingEmail: false,
    isSubMenuOpen: false,
    isMainMenuOpen: false
  }

  toggleSubMenu = () => {
    this.setState(prevState => ({
      isSubMenuOpen: !prevState.isSubMenuOpen
    }));
  }

  toggleMainMenu = () => {
    this.setState(prevState => ({
      isMainMenuOpen: !prevState.isMainMenuOpen
    }));
  }

  handleLogoutClick = (e) => {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.signout();
  }

  setSidenavListeners = (e) => {
    const SUBHEADING_OPEN_CLASS = 'navList--subheading-open';
    const SUBLIST_HIDDEN_CLASS = 'subList--hidden';
    const selected = e.target.parentNode.nextSibling
    if (selected.classList.contains("subList--hidden")) {
      selected.classList.remove(SUBLIST_HIDDEN_CLASS);
      selected.classList.add(SUBHEADING_OPEN_CLASS);
      e.target.parentNode.classList.add(SUBHEADING_OPEN_CLASS);
    } else {
      selected.classList.add(SUBLIST_HIDDEN_CLASS);
      selected.classList.remove(SUBHEADING_OPEN_CLASS);
      e.target.parentNode.classList.remove(SUBHEADING_OPEN_CLASS);
    }
  }

  componentWillMount() {
    this.props.getEvents();
    this.props.getStripe();
    this.props.getCurrentProfile();
    this.props.fetchAffiliations();

    const parsedURLParams = queryString.parse(this.props.location.search);

    if (this.props.location.state && this.props.location.state.message) { // will trigger if affiliate
      console.log('message', this.props.location.state.message);
      this.setState({ message: this.props.location.state.message });
    }

    if (parsedURLParams.payload) {
      this.props.addMailchimpToUser(parsedURLParams.payload, (message) => {
        this.props.history.push({
          pathname: '/account',
          search: '',
          state: { message: message }
        });
      });
    }
  }

  selectComponent = (e) => {
    e.preventDefault();
    this.setState(({
      message: "",
      component: e.currentTarget.dataset.name,
      isSubMenuOpen: !this.state.isSubMenuOpen
    }));
  }

  handleSendingEmail = () => {
    this.setState({ sendingEmail: true }, () => {
      this.props.resendConfirmationEmail(({ message }) => {
        this.setState({ sendingEmail: false, message: message });
      });
    })
  }

  handleClearMessage = (e) => {
    e.preventDefault();
    this.setState({ message: "" });
  }

  renderSwitch = (
    component,
    user,
    stripe,
    profile,
    events,
    affiliates,
    stripeLoading,
    profileIsLoading,
    eventsIsLoading
  ) => {
    switch (component) {
      case "events":
        return <EventInfo user={user} events={events} loading={eventsIsLoading} />
      case "stripe":
        return <StripeInfo stripe={stripe} loading={stripeLoading} />
      case "currency":
        return <CurrencyInfo user={user} stripe={stripe} />
      case "account":
        return <AccountInfo sendingEmail={this.state.sendingEmail} handleSendingEmail={this.handleSendingEmail} user={user} />
      case "contact information":
        return <ProfileForm profile={profile} loading={profileIsLoading} history={this.props.history} />
      case "password":
        return <PasswordInfo user={user} history={this.props.history} />
      case "discount":
        return <DiscountShow />
      case "affiliate program":
        return <AffiliateInfo affiliates={affiliates} />
      case "app marketplace":
        return <AppMarketplace user={user} />
      case "mailchimp":
        return <MailchimpShow user={user} />
      default:
        return <EventInfo events={events} loading={eventsIsLoading} />
    }
  }

  render() {
    const { isAuthenticated, user } = this.props.user;

    const { affiliates } = this.props.affiliate;
    const { stripe, loading: stripeLoading } = this.props.billing;
    const { profile, loading: profileIsLoading } = this.props.profile;
    const { events, loading: eventsIsLoading } = this.props.event;

    const { component, isSubMenuOpen, isMainMenuOpen, message } = this.state;

    return (
      <DashboardStyles>
        <div className="grid-container">
          <FiSidebar className="menu-icon" onClick={this.toggleSubMenu} />
          <header className="header">
            <div className="header__search">
              <Logo />
            </div>
            <IoIosMenu onClick={this.toggleMainMenu} style={{ width: '32px', height: '32px' }} />
            <Nav
              open={isMainMenuOpen}
              toggleMainMenu={this.toggleMainMenu}
              handleLogoutClick={this.handleLogoutClick}
              isAuthenticated={isAuthenticated} />
          </header>

          <aside className={`sidenav ${isSubMenuOpen && "active"}`}>
            <FiX className="sidenav__close-icon" onClick={this.toggleSubMenu} />
            <ul className="navList">
              <li className="navlist--heading text-spacing-1">Dashboard</li>

              <li
                onClick={this.selectComponent}
                className={`${component === "events" ? "selected" : ""}`}
                data-name="events">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <TiTicket size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Event</span>
                </div>
              </li>

              <li>
                <div className="navList--subheading" onClick={this.setSidenavListeners}>
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FaStripeS size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Stripe</span>
                </div>

                <ul className="subList subList--hidden">
                  <li
                    onClick={this.selectComponent}
                    className={`subList--item sub-item ${component === "stripe" ? "selected" : ""}`}
                    data-name="stripe">Status</li>
                  {/* <li
                    onClick={this.selectComponent}
                    className={`subList--item sub-item ${component === "currency" ? "selected" : ""}`}
                    data-name="currency">Currency</li> */}
                </ul>
              </li>

              <li>
                <div className="navList--subheading" onClick={this.setSidenavListeners}>
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiUser size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Account</span>
                </div>

                <ul className="subList subList--hidden">
                  <li
                    onClick={this.selectComponent}
                    className={`subList--item sub-item ${component === "contact information" ? "selected" : ""}`}
                    data-name="contact information">Profile</li>
                  <li
                    onClick={this.selectComponent}
                    className={`subList--item sub-item ${component === "account" ? "selected" : ""}`}
                    data-name="account">Email
                  </li>
                  <li
                    onClick={this.selectComponent}
                    className={`subList--item sub-item ${component === "password" ? "selected" : ""}`}
                    data-name="password">Password
                  </li>
                </ul>
              </li>


              <li
                onClick={this.selectComponent}
                className={`${component === "discount" ? "selected" : ""}`}
                data-name="discount">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiPercent size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Discount Codes</span>
                </div>
              </li>

              <li
                onClick={this.selectComponent}
                className={`${component === "affiliate program" ? "selected" : ""}`}
                data-name="affiliate program">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiUsers size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Affiliates</span>
                </div>
              </li>
              <li
                onClick={this.selectComponent}
                className={`${component === "app marketplace" ? "selected" : ""}`}
                data-name="app marketplace">
                <div className="navList--subheading parent">
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiList size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">App Marketplace</span>
                </div>
              </li>


              {user.mailchimp && user.mailchimp.hasOwnProperty("id") && <li>
                <div className="navList--subheading" onClick={this.setSidenavListeners}>
                  <span className="navList--subheading-icon">
                    <IconFilled isPointer={true} cHeight="32px" cWidth="32px">
                      <FiGrid size="1rem" />
                    </IconFilled>
                  </span>
                  <span className="navList--subheading-title">Connected Apps</span>
                </div>

                <ul className="subList subList--hidden">
                  <li
                    onClick={this.selectComponent}
                    className={`subList--item sub-item ${component === "mailchimp" ? "selected" : ""}`}
                    data-name="mailchimp">Mailchimp</li>
                </ul>
              </li>
              }

            </ul>
          </aside>
          <main className="main">
            <div className="flex flex-row flex-align-center flex-justify-between padding--small background-white border-bottom">
              <p>{toTitleCase(this.state.component || "")}</p>
              <ButtonStyledLink to="/event/create" className="button--blue"><FiPlus size="16px" className="marginRight--xSmall" />Create Event</ButtonStyledLink>
            </div>
            {/* <div className="main-header">
              <div className="main-header__heading">Hello User</div>
              <div className="main-header__updates">Recent Items</div>
            </div>
            <div className="main-overview">
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
              <div className="overviewcard">
                <div className="overviewcard__icon">Overview</div>
                <div className="overviewcard__info">Card</div>
              </div>
            </div> */}
            <div className="main-content">
              {message &&
                <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small padding--medium background-white border rounded-m shadow">
                  <p className="text-pink marginRight--xSmall">{message}</p>
                  <IconFilled
                    isPointer={true}
                    isHover={true}
                    cHeight="32px"
                    cWidth="32px"
                    onClick={e => this.handleClearMessage(e)}>
                    <IoIosClose size="1rem" />
                  </IconFilled>
                </div>}


              {this.renderSwitch(
                this.state.component,
                user,
                stripe,
                profile,
                events,
                affiliates,
                stripeLoading,
                profileIsLoading,
                eventsIsLoading
              )}
            </div>
            {/* <div className="main-cards">
              <div className="card">
                
              </div>
              <div className="card">Card</div>
              <div className="card">Card</div>
            </div> */}

          </main>
          <footer className="footer">
            <div className="footer__copyright"><p>&copy; 2020 Attndnce</p></div>
            {/* <div className="footer__signature"><p>Made with love by touringlong</p></div> */}
          </footer>
        </div>
      </DashboardStyles>
    )
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  user: state.auth,
  profile: state.profile,
  billing: state.billing,
  event: state.event,
  affiliate: state.affiliate,
});

export default connect(mapStateToProps, {
  getStripe,
  getEvents,
  fetchAffiliations,
  getCurrentProfile,
  clearCurrentProfile,
  resendConfirmationEmail,
  addMailchimpToUser,
  signout
})(Dashboard);