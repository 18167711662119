import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCSV } from '../../../actions/billingActions';
import { toCsv, pivot } from '../../../helpers';

const ExportToCSV = ({ eventID }) => {
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const dispatch = useDispatch();

  const handleCSV = () => {
    setIsLoadingCSV(true);

    dispatch(getCSV(eventID, (array)=> {
      toCsv(pivot(array));
      setIsLoadingCSV(false);
    }));
  }

  return <p onClick={handleCSV}>{isLoadingCSV ? "loading..." : "Export to CSV"}</p>
};

export default ExportToCSV;