import React from 'react';
import { FlexInputField } from '../../../styles';

const TextArea = ({
  input,
  label,
  type,
  placeholder,
  name,
  className,
  style,
  error: apiError,
  meta: { touched, error } }) => (
    <FlexInputField style={style} className={className}>
      <div className="flex flex-row flex-align-center flex-justify-between">
        <label>{label}</label>
        <p className={`${touched && error || apiError ? "text-error" : "text-error text-grey-blue"}`}>&nbsp;{touched && error || apiError ? error || apiError : "Required"}</p>
      </div>
      <textarea
        {...input}
        type={type}
        name={name}
        placeholder={placeholder} />
    </FlexInputField>
  )

export default TextArea;