import axios from 'axios';
import {
  GET_DISCOUNT,
  GET_DISCOUNTS,
  DELETE_DISCOUNT,
  DISCOUNT_LOADING,
  GET_ERRORS
} from './types'

export const createDiscount = (formValues, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/discount/create`, formValues);
    dispatch({
      type: GET_DISCOUNTS,
      payload: res.data
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const editDiscount = (formValues, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/discount/edit`, formValues);
    dispatch({
      type: GET_DISCOUNTS,
      payload: res.data
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const deleteDiscount = (id, callback) => async dispatch => {
  try {
    dispatch(setDiscountLoading());
    await axios.delete(`/api/discount/delete/${id}`);
    dispatch({
      type: DELETE_DISCOUNT,
      payload: id
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const getDiscount = (id, callback) => async dispatch => {
  try {
    dispatch(setDiscountLoading());
    const res = await axios.get(`/api/discount/code/${id}`);
    dispatch({
      type: GET_DISCOUNT,
      payload: res.data
    });
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_DISCOUNT,
      payload: {}
    });
  }
};

export const getDiscounts = () => async dispatch => {
  try {
    dispatch(setDiscountLoading());
    const res = await axios.get(`/api/discount/all`);
    dispatch({
      type: GET_DISCOUNTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_DISCOUNTS,
      payload: []
    });
  }
};

export const confirmDiscount = (code, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/discount/confirm-code/${code}`);
    dispatch({
      type: GET_DISCOUNT,
      payload: res.data
    });
    
    if (res.data) {
      window.localStorage.setItem('discountCode', res.data.id);
    }

    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

// Set loading state
export const setDiscountLoading = () => {
  return {
    type: DISCOUNT_LOADING
  };
};