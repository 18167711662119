import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';

import { updateSession } from '../../../actions/sessionActions';

import TextField from '../../utilities/formfields/TextField';
import TextAreaField from '../../utilities/formfields/TextAreaField';
import MultiSelectField from '../../utilities/formfields/MultiSelectField';
import CreateMultiSelectField from '../../utilities/formfields/CreateMultiSelectField';
import DropDownListField from '../../utilities/formfields/DropDownField';
import DateTimePickerField from '../../utilities/formfields/DateTimePickerField';
import Spinner from '../../utilities/Spinner';

import { required } from '../../../helpers/formValdation';
import { friendlyDate } from '../../../helpers';

import { ButtonStyled, FormStyle } from '../../../styles';

class SessionEdit extends React.Component {
  state = {
    errors: {}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  sort_unique_tracks = (arr) => {

    const reduced = (arr || []).reduce((emptyArray, session) => {
      emptyArray = [...emptyArray, ...session.tracks]
      return emptyArray;
    }, []);

    const seen = new Set();

    const unique = reduced.filter(el => {
      const duplicate = seen.has(el);
      seen.add(el);
      return !duplicate;
    });

    let objects = {};
    unique.sort();
    return unique;
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState, handleLoading } = this.props;
    handleLoading(() => {
      this.props.updateSession(eventID, formValues, (sessions) => {
        handleClearState(sessions);
      });
    });
  }

  render() {
    const { errors } = this.state;
    const { sessions, loading, handleSubmit, closeModal } = this.props;
    let dateString = new Date();
    let m = moment(dateString, 'ddd MMM D YYYY HH:mm:ss ZZ');
    // Use moment(Date) if your input is a JS Date
    //var m = moment(date);
    m.set({ h: parseInt("00", 10), m: parseInt("00", 10) });

    if (loading) {
      return <Spinner />
    } else {
      return (
        <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
          <div className="padding--small background-white border rounded-m shadow">

            <Field
              label="Title"
              type="text"
              name="name"
              placeholder="Class Title"
              validate={required}
              component={TextField} />

            <div className="flex flex-grid-half flex-justify-between">
              <Field
                label="Session Type"
                type="text"
                name={`session_type`}
                placeholder="Registration, Bootcamp, Competition, Workshop, Social"
                className="col flex-grow-1"
                data={[
                  'registration',
                  'Bootcamp',
                  'Competition',
                  'Workshop',
                  'Social'
                ]}
                validate={required}
                component={TextField} />
              <Field
                label="Tags"
                type="text"
                name={`tags`}
                placeholder="Salsa, Bachata, Kizomba, Beginner, Intermediate"
                className="col"
                data={[
                  "beginner",
                  "intermediate",
                  "advance",
                  'afro-latin',
                  "Salsa",
                  "Bachata",
                  "Kizomba",
                  'zouk',
                  'tango',
                  'samba',
                  'cha-cha',
                  'cuban-salsa',
                  'flamenco'
                ]}
                validate={required}
                component={TextField} />
            </div>

            <Field
              label="Tracks"
              type="text"
              name="tracks"
              placeholder="Tracks"
              validate={required}
              className="marginBottom--small"
              data={this.sort_unique_tracks(sessions)}
              component={CreateMultiSelectField} />

            <Field
              min={new Date(m.format())}
              name={`start_date`}
              label="Start Date and Time"
              formatDate="MMM DD YYYY HH:mm"
              placeholder={friendlyDate(new Date(m.format()), "MMM DD YYYY HH:mm")}
              validate={required}
              showTime={true}
              date={true}
              component={DateTimePickerField} />

            <Field
              min={new Date(m.format())}
              name={`end_date`}
              label="End Date and Time"
              formatDate="MMM DD YYYY HH:mm"
              placeholder={friendlyDate(new Date(m.format()), "MMM DD YYYY HH:mm")}
              validate={required}
              showTime={true}
              date={true}
              component={DateTimePickerField} />

            <Field
              label="Description"
              name="desc"
              placeholder="3 nights of astonishing performances."
              className="flex-grow-1"
              validate={required}
              component={TextAreaField} />
            <div className="flex flex-row pullRight marginTop--small">
              <ButtonStyled type="submit" className="button--blue">Apply Changes</ButtonStyled>
            </div>
          </div>
        </FormStyle>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.session,
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { updateSession }),
  reduxForm({
    enableReinitialize: true,
    form: 'editSession',
    keepDirtyOnReinitialize: true
  })
)(SessionEdit);