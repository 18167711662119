import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { confirmDiscount } from '../../actions/discountActions';
import { createPaymentHooks } from '../../actions/billingActions';
import { getEventHook } from '../../actions/eventActions';

import BreadCrumbs from "./BreadCrumbs";
import OrderSummary from "./OrderSummary";
import TicketList from "./TicketList";
import CheckoutForm from "./CheckoutForm";
import Footer from "./Footer";
import OrderTotal from "./OrderTotal";
import NoTicketSale from './NoTicketSale';

import Spinner from "../utilities/Spinner";
import ErrorMessage from "../utilities/ErrorMessage";

import { isAfter } from '../../helpers';
import validate from './validate';
import isEmpty from '../../validation/isEmpty';

import { media } from '../../styles';
import validateForm from './validateForm';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
`

const GridSections = styled.div`
  display: grid;
  grid-template-rows: 130px 1fr auto;
`

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 390px;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  .main, .sidebar {
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header'
      'content'
      'footer';
    min-height: 768px;
    /* height: 100vh; */
  }

  .sidebar {
    background: #FAFAFA;
    ${media.handheld`
      display: none;
    `}
  }

  .header {
    grid-area: header;
    padding: 24px 24px 0 24px;
  }

  .content {
    grid-area: content;
    padding: 16px 24px;
  }

  .footer-side {
    grid-area: footer;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin: 24px 0;
    padding: 0 24px;
    > * {
      text-align: center;
    }
  }
  .footer-main {
    grid-area: footer;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin: 24px 0;
    padding: 0 24px;
  }
`

const OrderForm = (props) => {
  const [event, setEvent] = useState({});
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [order, setOrder] = useState([]);
  const [selItems, setSelItems] = useState([]);
  const [discount_code, setDiscount] = useState("");

  const [authorizedDiscountCode, setAuthorizedDiscountCode] = useState({});

  const [orderForm, setOrderForm] = useState({});

  const [touched, setTouched] = useState([])
  const [formIsDisabled, setFormIsDisabled] = useState(true);
  const [fieldsIsValid, setFieldsIsValid] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(getEventHook(props.match.params.id));
        setEvent(data);
      } catch (err) {
        setErrors(err)
      }
      setIsLoading(false);
    };
    runEffect();
  }, [dispatch]);

  useEffect(() => {
    const eventCopy = Object.assign({}, event);
    const { order_form } = eventCopy;
    setOrderForm({
      first_name: "",
      last_name: "",
      email: "",
      ...!isEmpty(order_form && order_form.gender && order_form.gender.include) && { gender: "" },
      ...!isEmpty(order_form && order_form.age && order_form.age.include) && { age: "" },
      ...!isEmpty(order_form && order_form.notes && order_form.notes.include) && { notes: "" },
      ...!isEmpty(order_form && order_form.custom_fields.length > 0) && {
        custom_fields: order_form.custom_fields.filter(cf => cf.include).map(filtered => {
          return {
            question_prompt: filtered.question_prompt,
            question_type: filtered.question_type,
            require: filtered.require,
            include: filtered.include,
            response: ""
          }
        })
      }
    });

    setErrors({
      first_name: "",
      last_name: "",
      email: "",
      ...!isEmpty(order_form && order_form.gender && order_form.gender.include) && { gender: "" },
      ...!isEmpty(order_form && order_form.age && order_form.age.include) && { age: "" },
      ...!isEmpty(order_form && order_form.notes && order_form.notes.include) && { notes: "" },
      ...!isEmpty(order_form && order_form.custom_fields) && {
        custom_fields: order_form.custom_fields.filter((cf, index) => {
          if (cf.include) {
            return { [`custom_field_${[index]}`]: "" }
          }
        })
      }
    })
  }, [event]);

  useEffect(() => {
    const fieldsIsValidated = validateForm(fieldsIsValid, event, orderForm);
    setFormIsDisabled(!fieldsIsValidated);
  }, [fieldsIsValid, event]);

  useEffect(() => {
    if (!isEmpty(orderForm)) {
      const validationErrors = validate(orderForm);

      const touchedErrors = Object.keys(validationErrors.errors) // NEW
        .filter(key => touched.includes(key)) // get all touched keys - expected output: ["name", "email", "age", "gender"]
        .reduce((acc, key) => {
          if (!acc[key]) {
            acc[key] = validationErrors.errors[key]
          }
          return acc
        }, {});

      setErrors(touchedErrors);
      setFieldsIsValid(validationErrors.fieldsValid);
    }
  }, [touched, orderForm]);

  const handleUserInput = (e, index) => {
    const name = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setOrderForm((prevState) => {
      let orderFormCopy = Object.assign({}, prevState); // expected { first_name: "Bob", email: "Boe.xue@gmail.com"}
      if (!isNaN(index)) { // custom_field w/ index
        const newObject = {
          ...orderFormCopy.custom_fields[index],
          response: value
        }
        orderFormCopy.custom_fields.splice(index, 1, newObject);
        return {
          ...orderFormCopy
        }
      } else {
        return {
          ...prevState,
          [name]: value
        }
      }
    });
  }

  const handleBlur = (e) => {
    // if touched hasn't been touched before add it to the array
    if (!touched.includes(e.target.name)) {
      setTouched([
        ...touched,
        e.target.name
      ])
    }
  }

  const previousPage = () => {
    setPage(page - 1);
  }

  const nextPage = () => {
    setPage(page + 1);
  }

  const submitDiscountCode = (e) => {
    e.preventDefault();
    if (discount_code === "") {
      setErrors({ discount: "Discount Code input field cannot be blank." });
    } else {
      dispatch(confirmDiscount(discount_code, (authorized_code) => {
        setErrors("");
        setDiscount("");
        setAuthorizedDiscountCode(authorized_code);
      }));
    }
  }

  const removeDiscountCode = (e) => {
    e.preventDefault();
    setDiscount("");
    setAuthorizedDiscountCode({});
  }

  const handleDiscountCode = (e) => {
    setDiscount(e.target.value);
  }

  const handleCartOrder = (e, ticket) => {
    const qty = parseInt(e.target.value, 10);
    const name = e.target.name;
    const newOrder = [...order];

    // SetState tickets Values
    setSelItems(Object.assign({}, selItems, { [name]: qty }))

    const newOrderItem = {
      id: ticket._id,
      ticket: ticket,
      quantity: qty
    }

    // Find Existing Order in Order Array
    const isOrderFound = newOrder.filter(item => item.id === newOrderItem.id);

    if (isOrderFound.length > 0) {
      console.log('An order with that item already exists');
    }

    // Order exists and the selected Qty === 0
    if (isOrderFound.length > 0 && qty === 0) {
      console.log('An order item already exists and the user selected a qty of 0');

      let removeIndex = newOrder.map(item => {
        return item.id;
      }).indexOf(isOrderFound[0].id);

      newOrder.splice(removeIndex, 1);

      setOrder(newOrder);
      console.log(`order ${isOrderFound[0].ticket.name} was removed`, newOrder);
    }

    // Order exists and selected order item Qty > 0
    if (isOrderFound.length > 0 && qty > 0) {
      console.log('the specific order exists and the user selected a qty > 0');
      for (var i in newOrder) {
        if (newOrder[i].id == newOrderItem.id) {
          newOrder[i] = newOrderItem;
          break; //Stop this loop, we found it!
        }
      }
      setOrder(newOrder);
      console.log('The order has been updated', newOrder);
    }

    // New Order was created
    if (isOrderFound.length === 0) {
      newOrder.push(newOrderItem);
      setOrder(newOrder);
      console.log(`A new item was added to your order.`, newOrder);
    }
  }

  const handleToken = async (token) => {
    const getOrderQtyTotal = order => {
      return order.reduce((initVal, { quantity }) => {
        return initVal += quantity;
      }, 0);
    }
    const { _id, payout_country: { language }, payout_currency: { code } } = event;
    setErrors({});
    setIsLoading(true);
    try {
      await dispatch(createPaymentHooks(token, order, _id, getOrderQtyTotal(order), orderForm, code, language, {}, (orderID) => {
        props.history.push(`/order-confirmed/${orderID}`);
      }));
    } catch (err) {
      if (err.code === "parameter_invalid_integer") {
        setErrors({ invalidAmount: "Order amount cannot be less than or equal to zero." });
      } else {
        setErrors(err);
      }
    }
    setIsLoading(false);
  }

  if (event === null || isLoading) return <Spinner />
  else {
    return (
      <div ref={props.refProp}>

        <GridContainer>
          <GridSections className="background-white border">
            <header className="flex flex-column flex-align-center flex-justify-center border-bottom">
              <h1 className="text-bold text-xLarge">Choose Your Ticket</h1>
              <BreadCrumbs page={page} />
              {Object.keys(errors).lengths > 0 && <ErrorMessage showCloseButton={false} errors={errors} />}
            </header>
            <GridColumns>
              <div className="main">
                {page === 1 &&
                  <React.Fragment>
                    {isAfter(event && event.end_date) ? <NoTicketSale event={event} /> :
                      <TicketList
                        event={event}
                        selected_items={selItems}
                        _handleCartOrder={handleCartOrder} />
                    }
                    <div className="footer-main flex flex-row">
                      <OrderTotal
                        event={event}
                        order={order}
                        authDiscountCode={authorizedDiscountCode}
                        _nextPage={nextPage} />
                    </div>
                  </React.Fragment>
                }

                {page === 2 &&
                  <CheckoutForm
                    event={event}
                    order={order}
                    touched={touched}
                    orderForm={orderForm}
                    formIsDisabled={formIsDisabled}
                    errors={errors}
                    authDiscountCode={authorizedDiscountCode}
                    _handleUserInput={handleUserInput}
                    _handleBlur={handleBlur}
                    _previousPage={previousPage}
                    _handleToken={handleToken} />
                }
              </div>
              <div className="sidebar border-left">
                <OrderSummary
                  event={event}
                  order={order}
                  discount_code={discount_code}
                  authDiscountCode={authorizedDiscountCode}
                  _handleDiscountCode={handleDiscountCode}
                  _removeDiscountCode={removeDiscountCode}
                  _submitDiscountCode={submitDiscountCode} />
              </div>
            </GridColumns>
          </GridSections>
          <Footer />
        </GridContainer>
      </div>
    )
  }
}

export default withRouter(OrderForm);