import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray } from 'redux-form';
import styled from 'styled-components';

import { updateVideos } from '../../../actions';

import Spinner from '../../utilities/Spinner';
import FlexYoutube from '../../utilities/FlexYoutube';

import TextField from '../../utilities/formfields/TextField';

import { required } from '../../../helpers/formValdation';

import { ButtonStyled, FormStyle } from '../../../styles';
import * as custom from '../../../styles/Variables';
import { media } from '../../../styles/';

const ButtonContainerStyled = styled.div`
  ${media.handheld`
    margin: 0 ${custom.SPACE_medium};
  `}
`

class VideoUpdate extends React.Component {
  state = {
    errors: {},
    loading: false,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState } = this.props;
    this.setState({ loading: true }, () => {
      this.props.updateVideos(eventID, formValues, () => {
        this.setState({ loading: false });
      });
    });
  }

  render() {
    const { errors, loading } = this.state;
    const { handleSubmit, pristine } = this.props;

    if (loading) return <Spinner />

    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <FieldArray name="videos" error={errors.videos} component={FlexYoutube} />
        <ButtonContainerStyled className="flex pullRight">
          <ButtonStyled className="button--blue" disabled={pristine || loading}>Save Video</ButtonStyled>
        </ButtonContainerStyled>
      </FormStyle>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.event,
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { updateVideos }),
  reduxForm({
    form: 'updateVideos',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
)(VideoUpdate);