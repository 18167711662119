import styled from 'styled-components';
import { media } from '../styles';

const DashboardStyles = styled.div`
  /* Assign grid instructions to our parent grid container */
  .grid-container {
    display: grid;
    grid-template-columns: 1fr; /* Side nav is hidden on mobile */
    grid-template-rows: 50px 1fr 50px;
    grid-template-areas:
      'header'
      'main'
      'footer';
    height: 100vh;
  }
  
  /* Give every child element its grid name */
  .header {
    grid-area: header;
    background-color: white;
    border-bottom: 1px solid rgb(230, 235, 239);
  }

  .main-cards {
    column-count: 1;
    column-gap: 20px;
    margin: 20px;
  }

  .sidenav__list {
    padding: 0;
    margin-top: 50px;
    list-style-type: none;
  }

  .sidenav__list-item {
    padding: 20px 20px 20px 40px;
    color: #333333;
  }

  .sidenav__list-item.sub-item {
    padding: 8px 16px 8px 44px;
  }

  .sidenav__list-item.selected {
    color: #000000;
    background: red;
  }

  .sidenav__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #000000;
    cursor: pointer;
  }

  .header, .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }

  .main {
    grid-area: main;
    background-color: #f7fafc;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .main-header {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;
    height: 150px; /* Force our height since we don't have actual content yet */
    background-color: #e3e4e6;
    color: slategray;
  }

  .main-overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr)); /* Where the magic happens */
    grid-auto-rows: 94px;
    grid-gap: 20px;
    margin: 20px;
  }

  .main-content {
    margin: 16px;
    ${media.desktop`
      margin: 16px;
    }
  `}
  
  .overviewcard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #d3d3;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
    -webkit-column-break-inside: avoid;
    padding: 24px;
    box-sizing: border-box;
  }

  /* Force varying heights to simulate dynamic content */
  .card:first-child {
    height: 485px;
  }

  .card:nth-child(2) {
    height: 200px;
  }

  .card:nth-child(3) {
    height: 265px;
  }

  .footer {
    grid-area: footer;
    background-color: white;
    border-top: 1px solid rgb(230, 235, 239);
  }

  /* Hamburger menu icon, stays fixed on mobile for any possible scrolling */
  .menu-icon {
    position: fixed;
    display: flex;
    top: 5px;
    left: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    padding: 12px;
    background-color: #DADAE3;
  }

  /* Make room for the menu icon on mobile */
  .header__search {
    margin-left: 42px;
  }

  /* Mobile-first side nav styles */
  .sidenav {
    grid-area: sidenav;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 240px;
    position: fixed;
    overflow-y: auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    z-index: 2; /* Needs to sit above the hamburger menu icon */
    background: rgb(252, 252, 252);
    border-right: 1px solid rgb(230, 235, 239);
    transform: translateX(-245px);
    transition: all .6s ease-in-out;
  }

  /* The active class is toggled on hamburger and close icon clicks */
  .sidenav.active {
    transform: translateX(0);
  }

  /* Only visible on mobile screens */
  .sidenav__close-icon {
    position: absolute;
    visibility: visible;
    bottom: 16px;
    right: 12px;
    z-index: 1000;
    cursor: pointer;
    font-size: 20px;
    color: #333;

    display: flex;

    align-items: center;
    justify-content: center;
    border-radius: 50%;
 
    padding: 12px;
    background-color: #DADAE3;
  }

  .navList {
    padding: 0;
    margin: 0;
    list-style-type: none;

    .navlist--heading {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px; // custom
      padding: 13px 16px;
      text-transform: uppercase;
      font-size: 16px;
    }

    .navList--subheading {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding: 12px 16px;
      height: 32px;
      color: #333333;
      z-index: 10;
      font-size: 16px;
      text-transform: capitalize;

      /* .navList--subheading-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        width: 24px; // Keep spacing consistent
      } */

      .navList--subheading-title {
        margin: 0 15px;
        padding: 15px 0;
        width: 100%;
      }

      &:after {
        position: absolute;
        content: "";
        height: 6px;
        width: 6px;
        top: 24px;
        right: 25px;
        border-left: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        transform: rotate(225deg);
        transition: all .2s;
      }

      &.parent:after {
        display:none;
      }

      &:hover {
        cursor: pointer;
        background: rgba(22, 142, 234, 0.1);
      }

      &.navList--subheading-open {
        &:after {
          transform: rotate(315deg);
        }
      }
    }

    .subList {
      padding: 0;
      margin: 0;
      list-style-type: none;
      visibility: visible;
      overflow: hidden;
      max-height: 200px;
      transition: all .4s ease-in-out;

      .subList--item {
        text-transform: capitalize;
        padding: 16px 30px 16px 80px;

        &:first-child {
          padding-top: 15px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, .1);
          cursor: pointer;
        }
        &.selected {
          background: rgba(22, 142, 234, 0.1);
        }
      }

      &.subList--hidden {
        visibility: hidden;
        max-height: 0;
      }
    }
  }

  ${media.desktop`
    /* Show the sidenav */
    .grid-container {
      grid-template-columns: 240px 1fr; /* Show the side nav for non-mobile screens */
      grid-template-areas:
        "sidenav header"
        "sidenav main"
        "sidenav footer";
    }

    .header__search {
      margin-left: 0;
    }

    .sidenav {
      position: relative; /* Fixed position on mobile */
      transform: translateX(0);
    }

    .sidenav__close-icon {
      visibility: hidden;
    }
  `}
  /* Medium-sized screen breakpoint (tablet, 1050px) */
  ${media.desktop`
    /* Break out main cards into two columns */
    .main-cards {
      column-count: 2;
    }

  `}
`

export default DashboardStyles;
