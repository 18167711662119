import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';

import { fetchEvent, updateEvent } from '../../actions/eventActions';

import DateTimePickerField from '../utilities/formfields/DateTimePickerField';
import TextField from '../utilities/formfields/TextField';
import TextAreaField from '../utilities/formfields/TextAreaField';
import DropDownListField from '../utilities/formfields/DropDownField';
import UploadImageContainer from '../utilities/formfields/UploadImageContainer';

import Spinner from '../utilities/Spinner';
import ImageWrapper from '../utilities/ImageWrapper';

import { required } from '../../helpers/formValdation';

import { FormStyle, ButtonStyled, media } from '../../styles';

import CardHeader from '../utilities/card/CardHeader';

const GridLayout = styled.div`
  display: grid;
  grid-column-gap: 16px;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 3fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}
`

class EventEdit extends Component {
  state = {
    errors: {},
    formValue: {},
    pass_fees_on: null
  }

  componentDidMount() {
    this.props.fetchEvent(this.props.match.params.id, (formValues) => {
      this.props.initialize({
        ...formValues,
        published: { value: formValues.published ? true : false }
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  beforeSubmit = (formValues) => {
    const { id } = this.props.match.params;
    this.props.updateEvent(formValues, id, () => {
      this.props.history.push(`/account`);
    });
  }

  render() {
    const { handleSubmit, event: { event, loading } } = this.props;

    const { errors } = this.state;

    if (event === null || loading ) return <Spinner />
    return (
      <GridLayout>

        <FormStyle enctype="multipart/form-data" onSubmit={handleSubmit(this.beforeSubmit)}>
          <CardHeader title="Event Details" />
          <div className="padding--medium background-white border rounded-m shadow">
            <Field
              label="Event Title"
              type="text"
              name="name"
              error={errors.name}
              validate={required}
              component={TextField} />

            <Field
              label="Publish Event"
              name="published"
              type="text"
              data={[{
                name: "publish",
                value: true
              },
              {
                name: "draft",
                value: false
              }]}
              valueField="value"
              textField="name"
              error={errors.published}
              component={DropDownListField} />

            <Field
              label="Event Type"
              name="event_type"
              type="text"
              data={["workshop", "congress", "dance class", "social dance"]}
              error={errors.event_type}
              validate={required}
              component={DropDownListField} />


            {event.large_image &&
              <ImageWrapper>
                <img src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${event.large_image}.jpg`} alt="" />
              </ImageWrapper>
            }

            <UploadImageContainer imageType="Event" />

            <div className="flex-grid-half flex-justify-between">

              <Field
                label="Start Date"
                name="start_date"
                min={new Date()}
                type="date"
                showTime={true}
                formatDate="MM/DD/YY h:mmA"
                className="col"
                error={errors.start_date}
                validate={required}
                component={DateTimePickerField}
              />

              <Field
                label="End Date"
                name="end_date"
                min={new Date()}
                type="date"
                showTime={true}
                formatDate="MM/DD/YY h:mmA"
                className="col"
                error={errors.end_date}
                validate={required}
                component={DateTimePickerField}
              />
            </div>

            <div className="flex-grid-half flex-justify-between">
              <Field
                label="Tags - Choose up to 3"
                name="tags"
                className="col"
                error={errors.tags}
                validate={required}
                component={TextField} />

              <Field
                label="Location"
                type="text"
                name="location.formatted_address"
                className="col"
                error={errors.location}
                validate={required}
                component={TextField} />
            </div>

            <Field
              label="Event Description"
              type="text"
              name="desc"
              error={errors.desc}
              validate={required}
              component={TextAreaField} />
          </div>

          <div className="flex flex-column padding--small">
            <ButtonStyled className="button--blue" disabled={loading}>Update Event</ButtonStyled>
          </div>
        </FormStyle>
      </GridLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  event: state.event,
  auth: state.auth.user,
  errors: state.errors
})

export default compose(
  connect(mapStateToProps, { fetchEvent, updateEvent }), // bind account loading action creator
  reduxForm({
    form: 'editEvent', // a unique identifier for this form
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
  })

)(withRouter(EventEdit));