import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    width: 0;
  }
  100 {
    width: ${ props => props.width ? props.width : "0"};
  }
`;

const ProgressBarStyled = styled.div`
  background-color: #EDF1FA;
  border-radius: 8px;
  width: 100%;
  height: 14px;
  display: inline-block;

  .progress-value {
    background-color: #1E7FFF;
    transition: 0.3s all linear;
    border-radius: 8px;
    height: 14px;
    display: inline-block;
    animation: ${animation} 3s ease-in-out forwards;
  }
`

const ProgressBar = ({ width }) =>
  <ProgressBarStyled>
    <span className="progress-value" style={{ width: `${width}`}}></span>
  </ProgressBarStyled>

export default ProgressBar;