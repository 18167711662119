import {
  SESSION_LOADING,
  GET_SESSION,
  GET_SESSIONS
} from '../actions/types';

const INITIAL_STATE = {
  session: null,
  sessions: null,
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SESSION_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_SESSION:
      return {
        ...state,
        session: action.payload,
        loading: false
      };
    case GET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
        loading: false
      };
    default:
      return state;
  }
}