import React from 'react';
import { Field } from 'redux-form'

import DropDownListField from './DropDownField';

import { required } from '../../../helpers/formValdation';

import stripe_country_List from '../../../data/stripe_country_list';
import stripe_currency_code from '../../../data/stripe_currency_codes';

const PayoutCurrencyFields = ({ errors, recentOrders = [] }) => {

  const newArrayDataOfOjbect = Object.keys(stripe_country_List).map(key => {
    return stripe_country_List[key];
  })

  return (
    <React.Fragment>
      <Field
        label="Country you will be paid?"
        type="text"
        name="payout_country"
        placeholder="Please select a country"
        valueField="value"
        textField="name"
        validate={required}
        error={errors.payout_country}
        disabled={recentOrders.length > 0 ? true : false}
        data={newArrayDataOfOjbect}
        component={DropDownListField} />
      <Field
        label="Currency you will charge"
        type="text"
        name="payout_currency"
        placeholder="Please select currency"
        valueField="currency"
        textField="code"
        validate={required}
        error={errors.payout_currency}
        disabled={recentOrders.length > 0 ? true : false}
        data={stripe_currency_code}
        component={DropDownListField} />

      <p className="marginTop--small marginBottom--small text-small text-center">Once your first ticket has sold, the above information can't be changed.</p>
    </React.Fragment>
  )
}

export default PayoutCurrencyFields
