import React from 'react';
import styled from 'styled-components';
import { ButtonStyled, LinkStyled, media } from '../../../styles';

const ButtonGridLayout = styled.div`
  display: grid;
  grid-column-gap: 16px;
  justify-content:space-between;
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  `}

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}
`

const MailchimpActions = ({ isSyncing, isProcessing, openModal }) =>
  <ButtonGridLayout>
    <ButtonStyled className="button--red" onClick={openModal} disabled={isSyncing || isProcessing}>Disconnect Mailchimp</ButtonStyled>
    <LinkStyled className="button--blue-outline" href={"https://admin.mailchimp.com/"} disabled={isSyncing || isProcessing}>View Mailchimp account</LinkStyled>
  </ButtonGridLayout>

export default MailchimpActions;