import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { updateInstructor } from '../../actions/instructorActions';

import TextField from '../utilities/formfields/TextField';
import TextAreaField from '../utilities/formfields/TextAreaField';
import UploadImageContainer from '../utilities/formfields/UploadImageContainer';

import Spinner from '../utilities/Spinner';
import ImageCover from '../utilities/ImageCover';

import { FormStyle, ButtonStyled } from '../../styles';

class InstructorEdit extends Component {
  state = {
    errors: {}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors, updating: false });
    }
  }

  beforeSubmit = (formValues) => {
    this.setState({ loading: true }, () => {
      this.props.updateInstructor(formValues, (instructors) => {
        this.setState({ loading: false }, () => {
          this.props.handleClearState(instructors);
        });
      });
    })
  }

  render() {
    const { instructor, loading, handleSubmit } = this.props;
    const { errors: { name, company, country, desc } } = this.state;

    let socialInputs;

    if (loading) return <Spinner />
    return (
      <FormStyle enctype="multipart/form-data" onSubmit={handleSubmit(this.beforeSubmit)}>
        <div className="padding--small background-white border rounded-m shadow">
          <div className="flex flex-row flex-align-center flex-justify-between flex-column--onMobile">
            {instructor.large_image &&
              <ImageCover>
                <img src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${instructor.large_image}.jpg`} alt="profile image" />
              </ImageCover>}
            <UploadImageContainer imageType="instructor" />
          </div>

          <div className="flex-grid-half flex-justify-between">
            <Field
              label="Name"
              type="text"
              name="name"
              className="col"
              error={name}
              component={TextField} />
            <Field
              label="Company"
              type="text"
              name="company"
              className="col"
              error={company}
              component={TextField} />
          </div>

          {socialInputs}
          <Field
            label="Country"
            type="text"
            name="country"
            className="col"
            error={country}
            component={TextField} />
          <Field
            label="Bio"
            type="text"
            name="desc"
            error={desc}
            component={TextAreaField} />

          <div className="flex flex-row pullRight marginTop--small">
            <ButtonStyled type="submit" className="button--blue">Apply Changes</ButtonStyled>
          </div>
        </div>
      </FormStyle>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.instructor, // pull initial values from account reducer
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { updateInstructor }),
  reduxForm({
    enableReinitialize: true,
    form: 'editInstructor'
  })
)(InstructorEdit);