export const SET_CURRENT_USER = 'set_current_user';
export const GET_ERRORS = 'get_errors';
export const CLEAR_ERRORS = 'clear_errors';

export const BILLING_LOADING = 'billing_loading';
export const EVENT_LOADING = 'event_loading';
export const PROFILE_LOADING = 'profile_loading';
export const INSTRUCTOR_LOADING = 'instructor_loading';

export const GET_EVENT = 'get_event';
export const GET_EVENTS = 'get_events';
export const CREATE_EVENT = 'create_event';
export const DELETE_EVENT = 'delete_event';

export const GET_INSTRUCTOR = 'get_instructor';
export const GET_INSTRUCTORS = 'get_instructors';
export const DELETE_INSTRUCTOR = 'delete_instructor';

export const DISPATCH_MESSAGE = 'dispatch_message';
export const REMOVE_MESSAGE = 'remove_message';

export const GET_BALANCE = 'get_balance';
export const GET_PAYMENT = 'get_payment';
export const GET_PAYMENTS = 'get_payments';
export const GET_STRIPE = 'get_stripe';
export const CLEAR_STRIPE = 'clear_stripe';

export const GET_PROFILE = 'get_profile';
export const GET_PROFILES = 'get_profiles';
export const CLEAR_CURRENT_PROFILE = 'clear_current_profile';


export const SALE_LOADING = 'sale_loading';
export const GET_REVENUE = 'get_revenue';
export const GET_RECENT_ORDERS = 'get_recent_orders';
export const GET_TICKETS_TOTAL = 'get_tickets_total';
export const GET_TICKETS_TOTAL_BREAKDOWN = 'get_tickets_total_breakdown';

export const CREATE_DISCOUNT = 'create_discount';
export const GET_DISCOUNT = 'get_discount';
export const GET_DISCOUNTS = 'get_discounts';
export const DELETE_DISCOUNT = 'delete_discount';
export const DISCOUNT_LOADING = 'discount_loading';

export const GET_GUEST = 'get_guest';
export const GET_GUESTS = 'get_guests';
export const GUEST_LOADING = 'guest_loading';

export const GET_SESSION = 'get_session';
export const GET_SESSIONS = 'get_sessions';
export const SESSION_LOADING = 'session_loading';

export const GET_TICKET = 'get_ticket';
export const GET_TICKETS = 'get_tickets';
export const TICKET_LOADING = 'ticket_loading';

export const GET_TAX = 'get_tax';
export const GET_TAXES = 'get_taxes';
export const TAX_LOADING = 'tax_loading';

export const GET_AFFILIATES = 'get_affiliates';
export const DELETE_AFFILIATE = 'delete_affiliate';
export const AFFILIATE_LOADING = 'affiliate_loading';

// Mailchimp
export const AUDIENCE_LOADING = 'audience_loading';
export const GET_AUDIENCE = 'get_audience';
export const GET_AUDIENCE_ACTIVITY = 'get_audience_activity';
export const SYNC_AUDIENCE = 'sync_audience';