import styled from 'styled-components';
import * as custom from '../styles/Variables';

const NavStyles = styled.div`
  padding: 20px;
  position: relative;
  position: fixed;
  background: ${custom.BACKGROUND_COLOR_WHITE};
  height: 100%;
  top: 0;
  right: 0;
  width: 40%;
  min-width: 300px;
  bottom: 0;
  transform: translateX(100%);
  transition: all .6s ease-in-out;
  box-shadow: 0 5px 50px -5px rgba(0,0,0,0.1);
  z-index: 20;
  display: grid;
  grid-template-rows: auto 1fr auto;
  ${props => props.open && `transform: translateX(0);`};
  header {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    button {
      background: white;
      color: black;
      transform: translateX(100%);
      ${props => props.open && `transform: translateX(0);`};
    }
  }
  footer {
    height: 200px;
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 2rem;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: scroll;
  }
  .content > a {
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 2px;
    border-bottom: 1px solid #D9D9D9;
  }
`;

export default NavStyles;
