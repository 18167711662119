import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import styled from 'styled-components';

import { getEvent } from '../../../actions/eventActions';
import { fetchTickets, deleteTicket } from '../../../actions/ticketActions';

import TicketList from './TicketList';
import TicketView from './TicketView';
import TicketRemove from './TicketRemove';
import TicketCreate from './TicketCreate';
import TicketEdit from './TicketEdit';
import TicketCurrency from './TicketCurrency';

import Spinner from '../../utilities/Spinner';
import IconFilled from '../../utilities/IconFilled';

import { IoMdAdd, IoIosList } from 'react-icons/io'
import { FiDollarSign } from "react-icons/fi";

import { StyledCard, media } from '../../../styles';
import { toTitleCase } from '../../../helpers';

const GridLayout = styled.div`
  display: grid;
  grid-column-gap: 16px;
  margin: 0 auto;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 3fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  .item {
    display: flex;
    flex-direction: column;
  }
`

class TicketShow extends React.Component {
  state = {
    selectedView: "list",
    modalIsOpen: false,
    modalContent: "",
    modalHeight: "",
    event: {}
  }

  componentDidMount() {
    this.props.fetchTickets(this.props.match.params.id);
    this.props.getEvent(this.props.match.params.id, (data) => {
      this.setState({ event: data })
    });
  }

  openModal = (e) => {
    e.preventDefault()
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleModalSwitch = (e, ticket = {}) => {
    this.setState({
      ticketID: ticket._id,
      ticket: ticket,
      modalIsOpen: true,
      modalContent: e.currentTarget.dataset.modal,
      modalHeight: e.currentTarget.dataset.modalh
    });
  }

  handleViewChange = (e, ticket) => {
    this.setState({
      selectedView: e.currentTarget.dataset.view,
      ticket: ticket,
      modalIsOpen: false,
    });
  }

  handleClearState = () => {
    this.setState({
      selectedView: "list",
      modalIsOpen: false,
      modalContent: ""
    });
  }

  handleRemoveTicket = (ticket) => {
    this.props.deleteTicket(this.props.match.params.id, ticket, () => {
      this.handleClearState();
    });
  }

  renderView = (name, event, tickets) => {
    switch (name) {
      case 'list':
        return <TicketList
          event={event}
          tickets={tickets}
          handleModalSwitch={this.handleModalSwitch} />
      case 'create':
        return <TicketCreate
          event={event}
          eventID={this.props.match.params.id}
          handleClearState={this.handleClearState} />
      case 'edit':
        return <TicketEdit
          event={event}
          eventID={this.props.match.params.id}
          ticket={this.state.ticket}
          handleClearState={this.handleClearState} />
      case 'currency':
        return <TicketCurrency
          event={event}
          eventID={this.props.match.params.id}
          handleClearState={this.handleClearState} />
      default:
        break;
    }
  }

  renderModalContent = (name) => {
    switch (name) {
      case 'view':
        return <TicketView
          event={this.state.event}
          ticket={this.state.ticket}
          closeModal={this.closeModal}
          handleViewChange={this.handleViewChange}
          handleModalSwitch={this.handleModalSwitch} />
      case 'remove':
        return <TicketRemove
          event={this.state.event}
          ticket={this.state.ticket}
          closeModal={this.closeModal}
          handleRemoveTicket={this.handleRemoveTicket} />
      default:
        break;
    }
  }

  render() {
    const { event } = this.props.event;
    const { tickets } = this.props.ticket;
    const { selectedView, modalIsOpen, modalContent, modalHeight } = this.state;

    const customStyles = {
      content: {
        height: modalHeight, borderRadius: '4px'
      }
    };

    if (event === null || tickets === null || this.props.match.params.id === "") return <Spinner />

    return (
      <GridLayout>
        <div className="item">

          <div className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall paddingTop--small paddingBottom--small">

            <p className="text-grey-blue">{toTitleCase(selectedView)}</p>

            <div className="flex flex-row">
              <IconFilled
                isPointer={true}
                isHover={true}
                cHeight="32px"
                cWidth="32px"
                data-view="currency"
                className="marginRight--small"
                onClick={(e) => this.handleViewChange(e)}>
                <FiDollarSign size="1rem" />
              </IconFilled>

              <IconFilled
                isPointer={true}
                isHover={true}
                cHeight="32px"
                cWidth="32px"
                data-view="list"
                className="marginRight--small"
                onClick={(e) => this.handleViewChange(e)}>
                <IoIosList size="1rem" />
              </IconFilled>

              <IconFilled
                isPointer={true}
                isHover={true}
                cHeight="32px"
                cWidth="32px"
                data-view="create"
                onClick={(e) => this.handleViewChange(e)}>
                <IoMdAdd size="1rem" />
              </IconFilled>

            </div>

          </div>
          {this.renderView(selectedView, event, tickets)}
        </div>

        <Modal
          isOpen={modalIsOpen}
          className="modal"
          overlayClassName="overlay"
          style={customStyles}
          contentLabel="tickets">
          {this.renderModalContent(modalContent)}
        </Modal>
      </GridLayout>
    )
  }
}

const mapStateToProps = state => ({
  event: state.event,
  ticket: state.ticket
});

export default connect(mapStateToProps, { getEvent, fetchTickets, deleteTicket })(withRouter(TicketShow));


