import React from 'react';
import { FiEdit, FiTrash, FiX } from 'react-icons/fi';

import IconFilled from '../utilities/IconFilled';
import ImagePreview from '../utilities/ImagePreview';

const InstructorView = ({ instructor, closeModal, handleModalSwitch, handleView }) =>
  <div style={{ height: "100%" }} className="flex flex-column flex-justify-between padding--small">

    <div className="flex flex-row flex-align-center flex-justify-end">
      <IconFilled
        isPointer={true}
        isHover={true}
        cHeight="32px"
        cWidth="32px"
        data-view="edit"
        className="marginRight--small"
        onClick={(e) => handleView(e)}>
        <FiEdit size="1rem" />
      </IconFilled>

      <IconFilled
        isPointer={true}
        isHover={true}
        cHeight="32px"
        cWidth="32px"
        data-modalh=""
        data-modal="remove"
        className="marginRight--small"
        onClick={(e) => handleModalSwitch(e, instructor)}>
        <FiTrash size="1rem" />
      </IconFilled>

      <IconFilled
        isPointer={true}
        isHover={true}
        cHeight="32px"
        cWidth="32px">
        <FiX size="1rem" onClick={closeModal} />
      </IconFilled>
    </div>

    <div className="flex flex-row flex-align-center marginTop--medium">
      <ImagePreview className="marginRight--small">
        <img src={instructor.large_image && `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${instructor.large_image}.jpg`} alt="" />
      </ImagePreview>
      <div className="flex flex-column">
        <p>{instructor.name}</p>
        <p className="text-small">{instructor.company}</p>
        <p className="text-small">{instructor.country}</p>
      </div>
    </div>
    <div className="border-top marginTop--small paddingTop--small">
      <p className="text-small">{instructor.desc}</p>
    </div>
  </div>

export default InstructorView;