import axios from 'axios';

import {
  GET_ERRORS
} from './types';

export const sendPriceQuote = (formValues, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/mail/make-a-deal`, formValues);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const sendPromoCode = (formValues, callback) => async dispatch => {
  try {
    await axios.post(`/api/mail/send-promo-code`, formValues);
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};