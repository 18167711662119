import React from 'react';
import styled from 'styled-components';
import { media } from '../../../styles';
import { FiCheckCircle } from "react-icons/fi";

import Reports from "../images/reports.png";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  margin-bottom: 50px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 75px;
  align-items: center;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    margin-right: 24px;
    margin-left: 24px;
    grid-template-columns: 1fr;
  `}
`

const SectionTwo = () =>
  <GridLayout>
    <div>
      <img src={Reports} className="marginBottom--large" alt="" />
    </div>
    <div>
      <Title>Your event’s ticketing managing and reporting</Title>
      <SubTitle>All neatly organized in one place.</SubTitle>
      <ul>
        <ListItem>
          <FiCheckCircle size="24px" />
          Create as many ticket types and get paid faster.
          </ListItem>
        <ListItem>
          <FiCheckCircle size="24px" />
          Design your Workshop schedule and view it from anywhere.
          </ListItem>
        <ListItem>
          <FiCheckCircle size="24px" />
          Keep track of Ticket sales and all your orders.
          </ListItem>
      </ul>
    </div>
  </GridLayout>

export default SectionTwo;