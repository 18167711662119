import React from 'react';

import CardHeader from '../utilities/card/CardHeader';

import { StyledCard, GridStyledCard, FormStyle, ButtonStyled } from '../../styles';

import { formatPrice } from '../../helpers';
import { calculateRevenueTotal, calculateCommission, calculateTicketQty } from '../../helpers/affiliateCalculations';

const renderList = (aff) => {
  return (
    <React.Fragment>

      <CardHeader title={aff.event_info.name} />

      <StyledCard>

        <GridStyledCard className="flex-align-center">
          <p>{aff.event_info.name}</p>
          <div className="item">

            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Tickets Sold</p>
              <h3 className="text-number">{calculateTicketQty(aff.sale)}</h3>
            </div>

            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Revenue</p>
              <h3 className="text-number">{formatPrice(calculateRevenueTotal(aff.sale), aff.event_info.payout_currency.code, aff.event_info.payout_country.language)}</h3>
            </div>

            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Commission</p>
              <h3 className="text-number">{formatPrice(calculateCommission(aff.sale, aff), aff.event_info.payout_currency.code, aff.event_info.payout_country.language)}</h3>
            </div>
          </div>
        </GridStyledCard>
      </StyledCard>
      
      <StyledCard>
        <p>Get {aff.percent_off ? `${aff.percent_off}%` : `$${aff.amount_off}`} commission for every ticket sold.</p>
      </StyledCard>
      <StyledCard>
        <GridStyledCard>
          <p>Refer your friends and followers</p>
          <FormStyle>
            <div className="flex flex-row flex-align-center">
              <input
                id="unique_url"
                type="text"
                readOnly={true}
                value={`${process.env.NODE_ENV === 'production' ? 'https://www.attndnce.com' : 'localhost:3000'}/event/${aff.event_info._id}?affname=${aff.affiliate_name}&affid=${aff.affiliate_id}&refid=${aff.referrer_info._user}`} />
              <ButtonStyled className="marginLeft--xSmall" onClick={e => {
                e.preventDefault();
                /* Get the text field */
                var copyText = document.getElementById("unique_url");

                /* Select the text field */
                copyText.select();

                /* Copy the text inside the text field */
                document.execCommand("copy");

              }}>Copy</ButtonStyled>
            </div>
          </FormStyle>
        </GridStyledCard>
      </StyledCard>
    </React.Fragment>
  )
}

const AffiliateInfo = ({ affiliates }) => {
  if (affiliates.length === 0) return <div><p>You are not affiliated to an event.</p></div>
  else return affiliates.map(aff => { return renderList(aff) });
}

export default AffiliateInfo;