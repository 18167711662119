import React from 'react'
import styled from 'styled-components';

import { media } from "../../styles";

import Image from "../marketing/images/logo.png";

const LogoStyled = styled.div`
  span {
    color: #1d1d1d;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 8px;
    ${media.handheld`
      font-size: 20px;
      line-height: 32px;
    `}
  }
  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
`

const Logo = (props) => 
  <LogoStyled {...props} className="flex flex-row flex-align-center flex-justify-center">
    <img src={Image} width="24px" height="24px" alt="" />
    <span>ATTNDNCE</span>
  </LogoStyled>


export default Logo;