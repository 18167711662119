import {
  GET_TAX,
  GET_TAXES,
  TAX_LOADING
} from '../actions/types';

const INITIAL_STATE = {
  tax: null,
  taxes: null,
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TAX_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_TAX:
      return {
        ...state,
        tax: action.payload,
        loading: false
      };
    case GET_TAXES:
      return {
        ...state,
        taxes: action.payload,
        loading: false
      };
    default:
      return state;
  }
}