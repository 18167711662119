import React from 'react';

const Location = ({ location }) => {
  return (
    <React.Fragment>
      <h2>{location && location.formatted_address}</h2>
    </React.Fragment>
  )
}

export default Location;