import React from 'react';

const BreadCrumbs = ({page}) => {
  switch (page) {
    case 1:
      return <p><span className="text-bold text-blue">Select Tickets</span> > Review Order > Order Reciept</p>
    case 2:
      return <p>Select Tickets > <span className="text-bold text-blue">Review Order</span> > Order Reciept</p>
    case 3:
      return <p>Select Tickets > Review Order > <span className="text-bold text-blue">Order Receipt</span></p>
    default:
      break;
  }
}

export default BreadCrumbs;