import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { TiTicket } from "react-icons/ti";
import { FiUserCheck, FiUser, FiMail, FiClock, FiDownload } from "react-icons/fi";
import { getGuestList, manualConfirm } from '../../actions/guestActions';
import { getGuestListCSV } from '../../actions/billingActions';
import styled from 'styled-components';

import { toCsv, pivot } from '../../helpers';

import Spinner from '../../components/utilities/Spinner';
import IconFilled from '../../components/utilities/IconFilled';

import { fromNow, toTitleCase } from '../../helpers';

import { ButtonStyled, media, FormStyle } from '../../styles';
import isEmpty from '../../validation/isEmpty';

import * as custom from '../../styles/Variables';

const FormContainerStyled = styled.div`
  ${media.handheld`
    margin-left: 16px;
    margin-right: 16px;
  `}
`

const ListItemStyled = styled.div`
  border-bottom: 1px solid ${custom.COLOR_BORDER};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${media.handheld`
    margin-left: 16px;
    margin-right: 16px;
  `}

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: none;
  }
`

class EventGuests extends Component {
  state = {
    modalIsOpen: false,
    selectedGuest: {},
    fetchingCSV: false,
    guests: []
  }

  componentDidMount() {
    Modal.setAppElement('body');
    this.props.getGuestList(this.props.eventID, (guests) => {
      this.setState({ guests: guests }, () => {
        this.arrayHolder = guests;
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.guest.guests !== prevProps.guest.guests) {
      const { guests } = this.props.guest;
      this.setState({ guests: guests }, () => {;
        this.arrayHolder = guests;
      });
    }
  }

  openModal = (e, object) => {
    e.preventDefault();
    this.setState({ modalIsOpen: true, selectedGuest: object });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  handleCheckIn = (guestID) => {
    this.props.manualConfirm(guestID, () => {
      this.closeModal();
    });
  }

  handleClick = (e, eventID) => {
    e.preventDefault();
    this.setState({ fetchingCSV: true }, () => {
      this.props.getGuestListCSV(eventID, (array) => {
        toCsv(pivot(array));
        this.setState({ fetchingCSV: false });
      })
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  searchFilter = (e) => {
    let text = e.currentTarget.value;
    const newData = this.arrayHolder.filter(item => {
      const itemData = `${item.customer_info.first_name.toUpperCase()}`;
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });

    this.setState({ guests: newData }, () => {
      console.log(this.state.guests);
    });
  }

  renderList = (guests) => {
    return guests.map(guest =>
      <ListItemStyled
        key={guest._id}
        className="flex flex-row flex-align-center flex-justify-between background-white padding--small"
        onClick={e => this.openModal(e, guest)}>

        <div className="flex flex-column">
          <div className="flex flex-row">
            <p className="marginRight--xxSmall text-small text-grey">#{guest.order_number} </p>
            <p className="text-small">{toTitleCase(guest.customer_info.first_name)} {toTitleCase(guest.customer_info.last_name)}</p>
          </div>
          <p className="text-small">{guest.customer_info.email}</p>

          {guest.checked_in_status && guest.checked_in_status.checked_in ?
            <div className="marginTop--xSmall padding--xxSmall background-lightBlue rounded shadow">
              <p className="text-small text-bold text-blue text-uppercase text-spacing-1 text-center">
                checked in: {fromNow(guest.checked_in_status.checked_in_at)}
              </p>
            </div>
            :
            <div className="marginTop--xSmall padding--xxSmall background-inputFill rounded shadow">
              <p className="text-small text-bold text-grey text-uppercase text-spacing-1 text-center">
                has not checked in
            </p>
            </div>
          }
        </div>
        {guest.checked_in_status && guest.checked_in_status.checked_in ?
          <IconFilled cHeight="32px" cWidth="32px">
            <FiUserCheck size="1rem" style={{ color: "#4dbd74" }} />
          </IconFilled>
          :
          <IconFilled cHeight="32px" cWidth="32px">
            <FiUserCheck size="1rem" className="text-grey-blue" />
          </IconFilled>
        }
      </ListItemStyled>
    )
  }

  modalContent = () => {
    const { selectedGuest } = this.state;
    
    return (
      <div className="flex flex-column padding--medium">

        <div className="marginBottom--medium">
          <p className="marginRight--xxSmall text-grey-blue">Order #{selectedGuest.order_number}</p>
        </div>

        <div className="flex flex-row flex-align-center marginBottom--medium">
          <IconFilled cHeight="32px" cWidth="32px" className="marginRight--small">
            <FiUser size="1rem" />
          </IconFilled>
          <p className="text-small">{toTitleCase(selectedGuest.customer_info.first_name || "")} {toTitleCase(selectedGuest.customer_info.last_name || "")}</p>
        </div>
        <div className="flex flex-row flex-align-center marginBottom--medium">
          <IconFilled cHeight="32px" cWidth="32px" className="marginRight--small">
            <FiMail size="1rem" />
          </IconFilled>
          <p className="text-small">{selectedGuest.customer_info.email}</p>
        </div>
        {selectedGuest.checked_in_status && selectedGuest.checked_in_status.checked_in &&
          <div className="flex flex-row flex-align-center marginBottom--medium">
            <IconFilled cHeight="32px" cWidth="32px" className="marginRight--small">
              <FiClock size="1rem" />
            </IconFilled>
            <p className="text-pink text-small">{`checked in ${fromNow(selectedGuest.checked_in_status && selectedGuest.checked_in_status.checked_in_at)}`}</p>
          </div>
        }
        <div className="marginBottom--medium">
          <div className="marginBottom--medium">
            <p className="text-grey-blue">Order Items</p>
          </div>
          {(selectedGuest.order_info.order_items || []).map(item =>
            <div className="flex flex-row flex-align-center marginBottom--small" key={item._id}>
              <IconFilled cHeight="32px" cWidth="32px" className="marginRight--small">
                <TiTicket size="1rem" />
              </IconFilled>
              <p className="text-small"><span className="text-number">{item.quantity_sold}</span>x ({item.ticket_type}) {item.name}</p>
            </div>
          )}
        </div>
        {selectedGuest.checked_in_status && selectedGuest.checked_in_status.checked_in ?
          <div className="flex flex-row flex-align-center flex-justify-center padding--xSmall rounded-m border">
            <IconFilled cHeight="32px" cWidth="32px" className="marginRight--small">
              <FiUserCheck size="1rem" style={{ color: "#00c778" }} />
            </IconFilled>
            <p className="text-grey-blue text-small">Registered</p>
          </div>
          :
          <ButtonStyled
            className="button--blue"
            onClick={() => this.handleCheckIn(selectedGuest._id)}>
            check In
        </ButtonStyled>
        }
      </div>
    )
  }

  render() {
    // const { guests, loading } = this.props.guest;
    const { eventID } = this.props;
    const { guests } = this.state;

    let content;

    if (guests === null) { return <Spinner /> }

    if (guests.length > 0) { content = this.renderList(guests); }
    else { content = (<div className="flex flex-column flex-grow-1 flex-align-center flex-justify-center"><p>No Guests Found.</p></div>) }

    return (
      <React.Fragment>
        {/* {guests.length > 0 && <FormContainerStyled className="flex flex-row marginBottom--medium">
          <FormStyle className="flex flex-grow-1" onSubmit={e => this.handleSubmit(e)}>
            <input placeholder="Search by attendee name" type="text" onChange={(e) => this.searchFilter(e)} />
          </FormStyle>
        </FormContainerStyled>} */}
        {content}
        {guests.length > 0 && <FormContainerStyled className="flex flex-row flex-justify-center marginTop--small">
          <a href="#nowhere" className="flex flex-align-center text-blue" onClick={(e) => this.handleClick(e, eventID)}>
            <FiDownload className="marginRight--xSmall" />Download Full List
          </a>
        </FormContainerStyled>}
        <Modal
          isOpen={this.state.modalIsOpen}
          contentLabel="Guest Information"
          style={customStyles.content}
          className="modal"
          overlayClassName="overlay">
          <button className="close-button" onClick={this.closeModal}>Close Window</button>
          {!isEmpty(this.state.selectedGuest) && this.modalContent()}
        </Modal>
      </React.Fragment>
    )
  }
}

const customStyles = {
  content: {
    height: '', borderRadius: '12px'
  }
};

const mapStateToProps = state => ({ guest: state.guest });

export default connect(mapStateToProps, { getGuestList, getGuestListCSV, manualConfirm })(EventGuests);