import React from 'react'
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';

import { ButtonStyled } from '../../../styles';
import Spinner from '../../utilities/Spinner';

const renderList = (members, isSyncing, handleSync) => {
  if (members.length > 0) {
    return (
      <div className="marginBottom--medium padding--medium flex flex-column background-white rounded border shadow">
        {(members || []).map((member, index) => {
          const { customer_info: { first_name, last_name, email } } = member;
          return (

            <div key={index}className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small border-bottom">
              <div className="flex flex-column">
                <p>{first_name && first_name} {last_name && last_name}</p>
                <p className="text-small">{member._event ? member._event.name : "Event Not Found."}</p>
              </div>
              <p>{email && email}</p>
            </div>
          )
        }
        )}
        <div className="flex flex-row flex-align-center flex-justify-between">
          <p className="marginRight--small text-grey-blue">{members.length} Attendees will be synced to Mailchimp</p>
          <ButtonStyled className="button--blue" disabled={isSyncing} onClick={handleSync}>
            {isSyncing ?
              <div className="flex flex-align-center flex-justify-center">
                <FontAwesome size="2x" spin name="spinner" />
                <span className="marginLeft--small">Syncing with Mailchimp</span>
              </div> : "Sync with Mailchimp"
            }
          </ButtonStyled>
        </div>
      </div>
    )
  } else {
    return (
      <div className="marginBottom--small padding--medium flex flex-column background-white rounded border shadow">
        <p>No new attendees found.</p>
      </div>
    )
  }
}
const MailchimpMembers = ({ members, isLoading, isSyncing, handleSync }) => {
  if (members === null || isLoading) {
    return <Spinner />
  } else {
    return (
      <React.Fragment>
        <p className="marginRight--small text-grey-blue">Sync to Mailchimp</p>
        {renderList(members, isSyncing, handleSync)}
      </React.Fragment>
    )
  }
}

export default MailchimpMembers;