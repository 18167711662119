import axios from 'axios';
import {
  GET_ERRORS,
  GET_EVENT,
  GET_TICKET,
  GET_TICKETS,
  TICKET_LOADING,
} from './types'

export const createTicket = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/ticket/create/${eventID}`, formValue);
    dispatch({
      type: GET_TICKETS,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

export const updateTicket = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/ticket/edit/${eventID}`, formValue);
    dispatch({
      type: GET_TICKETS,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

export const deleteTicket = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/ticket/remove/${eventID}`, formValue);
    dispatch({
      type: GET_TICKETS,
      payload: res.data
    })
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
};

export const fetchTickets = (eventID, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/ticket/all/${eventID}`);
    dispatch({
      type: GET_TICKETS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_TICKETS,
      payload: []
    });
  }
};

export const updateCurrency = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/ticket/currency/${eventID}`, formValue);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

// Set loading state
export const setTicketLoading = () => {
  return {
    type: TICKET_LOADING
  };
};