import React from 'react';
import { DateTimePicker } from 'react-widgets'
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { friendlyDate } from '../../../helpers';
import 'react-widgets/dist/css/react-widgets.css';
import { FlexInputField } from '../../../styles';

moment.locale('en')
momentLocalizer(moment);

const DateTimePickerField = ({
  min,
  label,
  type,
  date = true,
  disabled,
  showTime = false,
  className,
  step= 0,
  formatDate = "MM/DD/YY",
  placeholder = friendlyDate(Date.now(), "MM/DD/YY"),
  input: { onChange, value },
  meta: { touched, error },
  error: apiError
}) =>
  <FlexInputField className={className}>
    <div className="flex flex-row flex-align-center flex-justify-between">
      <label>{label}</label>
      <p className={`${touched && error || apiError ? "text-error" : "text-error text-grey-blue"}`}>&nbsp;{touched && error || apiError ? error || apiError : "Required"}</p>
    </div>
    <DateTimePicker
      min={min}
      type={type}
      disabled={disabled}
      date={date}
      step={step}
      time={showTime}
      format={formatDate}
      onChange={onChange}
      placeholder={placeholder}
      value={!value ? null : new Date(value)}
    />
  </FlexInputField>

export default DateTimePickerField;