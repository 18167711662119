export default [
  {
    title: "Pay as you go",
    price: "65",
    ref: "pay_as_you_go",
    subtitle: "Flexible pricing to suit your needs.",
    type: "For Small Events",
    price_info: "per transaction",
    symbol: "¢", 
    link: "",
    image: "undraw_planet.svg",
    focused: false,
    difference: "We take a small fee for each transaction.",
    features: [
      "QR Mobile Check-In",
      "Sales Reporting",
      "Referral Program",
      "Workshop Scheduling",
      "Discount Codes",
      "Website Widget",
      "Custom Order Forms",
      "Beautiful Event Page",
      "Mailchimp Integration"
    ]
  },
  {
    title: "No Fees",
    price: "300",
    ref: "no_fees",
    subtitle: "Flat rate, everything included.",
    type: "For Large Events",
    price_info: "per event",
    symbol: "$",
    link: "Or let's make a deal",
    image: "undraw_bank.svg",
    focused: true,
    difference: "No fees. You keep all your profits.",
    features: [
      "QR Mobile Check-In",
      "Sales Reporting",
      "Referral Program",
      "Workshop Scheduling",
      "Discount Codes",
      "Website Widget",
      "Custom Order Forms",
      "Beautiful Event Page",
      "Mailchimp Integration"
    ]
  }
]