import React from 'react';
import styled from 'styled-components';

import { media, ButtonStyledLink, LinkStyled } from '../../../styles';

const Section = styled.div`
  background-image: url('https://res.cloudinary.com/dksc4gf1v/image/upload/v1569957890/assets/attndnce-hero.jpg');
  background-repeat: no-repeat;
  background-color: #10071B;
  position: relative;
  overflow: hidden;
  height: 110vh;

  ${media.handheld`
    background-size: 500%;
    background-position: -400px -150px;
  `}
  ${media.tablet`
    background-size: 400%;
    background-position: -500px -200px;
    height: 110vh;
  `}
  ${media.desktop`
    background-size: 200%;
    background-position: -400px -100px;
  `}
`

const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 224px;
  font-weight: 800;
  color: #FFFFFF;
  letter-spacing: 6px;
  line-height: 180px;
  text-shadow: 0 5px 20px rgba(25,0,96,1);
  ${media.handheld`
    font-size: 66px;
    line-height: 60px;
  `}
`

const SubTitle = styled.div`
  /* An All-In-One Event: */
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #FFFFFF;
  text-shadow: 0 2px 5px rgba(25,0,96,1);
  ${media.handheld`
    width: 100%;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 17px;
    line-height: 40px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-content: start;
  overflow: hidden;
  max-width: 1340px;
  margin: 0 auto;
  padding: 200px 16px;
  ${media.handheld`
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const ButtonGridLayout = styled.div`
  display: grid;
  grid-template-columns: 215px 215px;
  grid-column-gap: 20px;
  grid-template-rows: auto;
  align-items: center;
  justify-content: start;
  ${media.handheld`
    grid-template-columns: 1fr 1fr;
  `}
`

const ButtonText = styled.span`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  ${media.handheld`
    font-size: 16px;
  `}
`

const hero = () =>
  <Section>
    <GridLayout>
      <div>

        <Title role="heading" aria-level="1">UNLEASH YOUR EVENT</Title>

        <SubTitle className="marginTop--small marginBottom--xLarge">An All-In-One Event and Ticketing Platform.</SubTitle>

        <ButtonGridLayout>

          <ButtonStyledLink to="/sign-up" className="button--purple">

            <ButtonText className="text-white">Signup For Free</ButtonText>

          </ButtonStyledLink>

          <LinkStyled className="" href={`http://www.attndnce.com/event/5c88b3fd9df9930017910c2b`}>
            <ButtonText>View Demo</ButtonText>
          </LinkStyled>

        </ButtonGridLayout>

      </div>
    </GridLayout>

  </Section>

export default hero;
