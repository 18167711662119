import {
  GUEST_LOADING,
  GET_GUESTS
} from '../actions/types';

const INITIAL_STATE = {
  guests: null,
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GUEST_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_GUESTS:
      return {
        ...state,
        guests: action.payload,
        loading: false
      };
    default:
      return state;
  }
}