import axios from 'axios';
import {
  GET_EVENT,
  GET_BALANCE,
  GET_STRIPE,
  CLEAR_STRIPE,
  GET_PAYMENT,
  GET_ERRORS,
  BILLING_LOADING
} from './types'

export const createPayment = (token, order, eventID, quantity, collectedInfo, code, language, urlParams, callback) => async dispatch => {
  try {
    dispatch(setBillingLoading());
    console.log(localStorage.getItem("discountCode"));

    if (localStorage.getItem("discountCode") !== null) {
      token.discountCode = localStorage.getItem('discountCode');
    }

    token.order = order;
    token.eventID = eventID;
    token.quantity = quantity;
    token.collectedInfo = collectedInfo;
    token.code = code;
    token.language = language;
    
    if (urlParams) {
      token.urlParams = urlParams;
    }

    const res = await axios.post('/api/billing/stripe', token);
    
    if (localStorage.getItem("discountCode") !== null) localStorage.removeItem('discountCode');

    callback(res.data.order_id);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
}

export const billUserEventFee = (token, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/billing/charge/one-off-event`, token);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });

    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
}

export const getOrder = (id, callback) => async dispatch => {
  try {
    dispatch(setBillingLoading());
    const res = await axios.get(`/api/billing/stripe/order/${id}`);
    dispatch({
      type: GET_PAYMENT,
      payload: res.data
    });
    callback(res.data)
  } catch (err) {
    dispatch({
      type: GET_PAYMENT,
      payload: {}
    });
  }
}


export const createPaymentHooks = (token, order, eventID, quantity, orderForm, code, language, urlParams, callback) => async dispatch => {
  try {
    if (localStorage.getItem("discountCode") !== null) {
      token.discountCode = localStorage.getItem('discountCode');
    }

    token.order = order;
    token.eventID = eventID;
    token.quantity = quantity;
    token.orderForm = orderForm;
    token.code = code;
    token.language = language;

    if (urlParams) {
      token.urlParams = urlParams;
    }
    const res = await axios.post('/api/billing/stripe', token);
    if (localStorage.getItem("discountCode") !== null) localStorage.removeItem('discountCode');
    callback(res.data.order_number);
  } catch (err) {
    throw err.response.data;
  }
}

export const getOrderHooks = id => async dispatch => {
  try {
    const res = await axios.get(`/api/billing/stripe/order/${id}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export const getBalance = () => async dispatch => {
  try {
    dispatch(setBillingLoading());
    const res = await axios.get(`/api/billing/stripe/balance`);
    dispatch({
      type: GET_BALANCE,
      payload: res.data
    });
  }
  catch (err) {
    dispatch({
      type: GET_BALANCE,
      payload: {}
    });
  }
}

export const getStripe = () => async dispatch => {
  try {
    dispatch(setBillingLoading());
    const res = await axios.get(`/api/billing/stripe/account`);
    dispatch({
      type: GET_STRIPE,
      payload: res.data
    });
  }
  catch (err) {
    dispatch({
      type: GET_STRIPE,
      payload: {}
    });
  }
}

export const getCSV = (id, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/billing/csv/${id}`);
    callback(res.data);
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
}

export const getGuestListCSV = (id, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/billing/csv/event/${id}`);
    callback(res.data);
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    })
  }
}

// Set logged in user
export const clearStripe = () => {
  return {
    type: CLEAR_STRIPE
  }
}

// Set loading state
export const setBillingLoading = () => {
  return {
    type: BILLING_LOADING
  };
};