import React from 'react';
import styled from 'styled-components';
import { media } from '../../../styles';

import IPhone from "../images/iphone-checkin.png";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  margin-bottom: 50px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 75px;
  align-items: center;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    margin-right: 24px;
    margin-left: 24px;
    grid-template-columns: 1fr;
  `}
`

const SectionThree = () =>
  <GridLayout>
    <div>
      <Title>Manage all of your events on one platform</Title>
      <SubTitle>Stop using 4-5 separate platforms to manage your event. Attndnce has all the tools you need under one roof.</SubTitle>
    </div>
    <div>
      <img src={IPhone} className="marginBottom--large" alt="" />
    </div>
  </GridLayout>

export default SectionThree;