import React from 'react'
import styled from 'styled-components';
import { FiGlobe, FiTwitter, FiFacebook, FiInstagram, FiMail } from "react-icons/fi";


const ListItems = styled.ul`
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    font-family: 'source sans pro', sans-serif;
    font-size: 18px;
    color: #262626;
  }
`

const SocialLinks = ({ profile, fb = true, ig = true, tw = true, mail = true, website = true }) => {
  return (
    <ListItems>
      {website && profile.website &&
        <li>
          <FiGlobe size="24px" className="marginRight--small" />
          <a
            href={`https://${profile.website}`}
            target="_blank"
            rel="noopener noreferrer">
            Official site
          </a>
        </li>
      }
      {tw && profile && profile.social.twitter &&
        <li>
          <FiTwitter size="24px" className="marginRight--small" />
          <a
            href={`https://${profile.social.twitter}`}
            target="_blank"
            rel="noopener noreferrer">
            Twitter
          </a>
        </li>
      }
      {fb && profile && profile.social.facebook &&
        <li>
          <FiFacebook size="24px" className="marginRight--small" />
          <a
            href={`https://${profile.social.facebook}`}
            target="_blank"
            rel="noopener noreferrer">
            Facebook</a>
        </li>
      }
      {ig && profile && profile.social.instagram &&
        <li>
          <FiInstagram size="24px" className="marginRight--small" />
          <a
            href={`https://${profile.social.instagram}`}
            target="_blank"
            rel="noopener noreferrer">
            Instagram
          </a>
        </li>
      }
      {mail && profile && profile.social.email &&
        <li>
          <FiMail size="24px" className="marginRight--small" />
          <a
            href={`https://${profile.email}`}
            target="_blank"
            rel="noopener noreferrer">
            Email
          </a>
        </li>
      }
    </ListItems>
  )
}

export default SocialLinks;