import React from 'react';
import { injectGlobal } from 'styled-components';
import * as custom from '../styles/Variables';
import { media } from '../styles/';

import Spinner from '../components/utilities/Spinner';
import ScrollToTop from '../components/utilities/ScrollToTop';

import Header from './Header';
import Footer from './Footer';

import "../css/styles.css";
import "../css/util.css";
import "../css/form.css";
import "../css/margin.css";
import "../css/padding.css";
import "../css/typography.css";
import "../css/card.css";
import "../css/animations.css";
import "../css/flex-box.css";

injectGlobal`
  body, html {
    margin: 0!important;
    padding: 0!important;
    background: #f7fafc;
    height: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;

  }

  h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: ${custom.COLOR_DARK};
  }

  p, a, ul, li  {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: ${custom.COLOR_DARK};
  }

  h1 {
    font-size: ${custom.FONTSIZE_xxLarge};
    line-height: 48px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
  }

  h3 {
    font-size: 20px;
    line-height: 40px;
  }
  
  p {
    font-size: ${custom.FONTSIZE_medium};
    line-height: 1.8;
    word-break: break-word;
  }

  a {
    display: inline-block;
    font-size: 16px;
    line-height: 1.5;
    color: ${custom.COLOR_DARK};
    text-decoration: none;
    cursor: pointer;
  }

  ul, ol {
    padding: 0;
    li {
      font-size: ${custom.FONTSIZE_medium};
      line-height: 1.5;
      list-style-type: none;
    }
  }

  ol { margin: 0 }

  img {
    width: 100%;
  }
  
  .logo {
    margin: 0!important;
    font-family: 'Poppins', sans-serif;
    font-size: 20px!important;
    font-weight: 700!important;
    border-bottom: none!important;
    line-height: 44px;
    color: ${custom.COLOR_DARK}!important;
    letter-spacing: 2px;
    ${media.handheld`
      font-size: 20px;
    `}
  }
  
  .lead {
    font-size: ${custom.FONTSIZE_large};
    line-height: 1.8;
  }

  .bold {
    font-weight: 600;
  }

  .hideOnMobile {
    display: flex!important;
    ${media.handheld`
      display: none!important;
    `}
  }

  .showOnMobile {
    display: none!important;
    ${media.handheld`
      display: flex!important;
    `}
  }
`

export default class App extends React.Component {
  // state = {
  //   loading: true
  // }

  // componentDidMount = () => {
  //   fetch(`/api/wake-up`)
  //     .then(res => res.json())
  //     .then(() => {
  //       this.setState({ loading: false });
  //     })
  //     .catch(err => console.log(err))
  // }

  render = () => {
    const { children, location } = this.props;
    // const content = () => this.state.loading ? <Spinner /> : children;
    return (
      <ScrollToTop>
        {location.pathname !== "/account" && location.pathname !== "/account/g/welcome" && location.pathname !== "/" && location.pathname !== "/sign-up" && !location.pathname.includes("order-confirmed") && location.pathname !== "/l/free-signup" && <Header />}
        {children}
        {!location.pathname.includes("account") && !location.pathname.includes("event") && !location.pathname.includes("order-confirmed") && !location.pathname.includes("sign-up") &&!location.pathname.includes("login") && <Footer />}
      </ScrollToTop>
    )
  }
}
