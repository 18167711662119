import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import reducers from './reducers';

import jwt_decode from 'jwt-decode';
import { setCurrentUser, signout } from './actions/authActions';
import { clearCurrentProfile } from './actions/profileActions';
import setAuthToken from './components/utilities/setAuthToken';

import App from './components/App';
import Events from './components/event/Events';
import Login from './components/auth/Login';
import Signout from './components/auth/Signout';
import Dashboard from './components/Dashboard';
import EventPage from './components/event/show/EventPage';
import OrderReceipt from './components/checkout/OrderReceipt';
import EventCreate from './components/event/EventCreate';
import EventOptions from './components/event/EventOptions';
import EventGuests from './components/event/EventGuests';

import Page from './components/marketing/home-page/';
import SignupPage from './components/marketing/landing-pages/SignupPage';
import GoogleAdPage from './components/marketing/landing-pages/GoogleAdPage';
import PricingPage from './components/marketing/pricing-page/';
import FeaturePage from './components/marketing/features-page/';
import Tos from './components/marketing/Tos';
import Privacy from './components/marketing/Privacy';

import AccountEdit from './components/Dashboard/AccountEdit';
import ChangePassword from './components/Dashboard/ChangePassword';

import NotFound from './components/utilities/NotFound';
import PrivateRoute from './components/utilities/PrivateRoute';
import Confirm from './components/utilities/Confirm';

import CreateProfile from './components/create-profile/CreateProfile';
import EditProfile from './components/edit-profile/EditProfile';
import OrderForm from './components/checkout/OrderForm';
import EventDashboard from './components/event/EventDashboard';

// Use Redux Chrome Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
)

if (localStorage.token) {
  // Set auth token header auth
  setAuthToken(localStorage.token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.token);
  // Set user and isAuthenticated;
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(signout());
    // clear current profile
    store.dispatch(clearCurrentProfile());
    window.location.href = '/';
  }
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route render={({ location }) => (
          <App location={location}>
            <TransitionGroup component={null}>
              <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={{ enter: 300, exit: 300 }}>
                <Switch location={location}>
                  <Route path="/" exact component={Page} />
                  <Route path="/pricing" exact component={PricingPage} />
                  <Route path="/features" exact component={FeaturePage} />
                  <Route path="/tos" exact component={Tos} />
                  <Route path="/privacy" exact component={Privacy} />
                  <Route path="/email/confirm/:id" exact component={Confirm} />

                  <Route path="/l/free-signup" exact component={GoogleAdPage} />

                  <Route path="/sign-up" exact component={SignupPage} />
                  <Route path="/admin/login" exact component={Login} />
                  <Route path="/signout" exact component={Signout} />

                  <Route path="/order-confirmed/:id" exact component={OrderReceipt} />
                  <PrivateRoute path="/account" exact component={Dashboard} />
                  <PrivateRoute path="/account/g/welcome" exact component={Dashboard} />
                  <PrivateRoute path="/account/edit" exact component={AccountEdit} />
                  <PrivateRoute path="/account/change-password" exact component={ChangePassword} />
                  <PrivateRoute path="/account/event/:id" exact component={EventDashboard} />
                  
                  <PrivateRoute path="/profile/create-profile" exact component={CreateProfile} />
                  <PrivateRoute path="/profile/edit-profile" exact component={EditProfile} />
                  <Route path="/profile/:handle" exact component={Events} />

                  <PrivateRoute path="/event/create" exact component={EventCreate} />
                  <PrivateRoute path="/event/edit/:id" exact component={EventOptions} />
                  <PrivateRoute path="/event/guests/:id" exact component={EventGuests} />
                  <Route path="/event/checkout/:id" exact component={OrderForm} />
                  <Route path="/event/:id" exact component={EventPage} />
                  <Route component={NotFound} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </App>
        )} />
    </Router>
  </Provider>,
  document.querySelector('#root')
);

console.log('STRIPE KEY IS', process.env.REACT_APP_STRIPE_KEY);
console.log('Environment is', process.env.NODE_ENV);