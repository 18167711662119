import {
  SALE_LOADING,
  GET_REVENUE,
  GET_RECENT_ORDERS,
  GET_TICKETS_TOTAL,
  GET_TICKETS_TOTAL_BREAKDOWN
} from '../actions/types';

const INITIAL_STATE = {
  revenue: {},
  ticketTotal: {},
  ticketTotalBreakdown: {},
  recentOrders: []
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SALE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_REVENUE:
      return {
        ...state,
        revenue: action.payload
      }
    case GET_RECENT_ORDERS:
      return {
        ...state,
        recentOrders: action.payload
      }
    case GET_TICKETS_TOTAL:
      return {
        ...state,
        ticketTotal: action.payload
      }
    case GET_TICKETS_TOTAL_BREAKDOWN:
      return {
        ...state,
        ticketTotalBreakdown: action.payload
      }
    default:
      return state;
  }
}