import React from 'react';
import { Multiselect } from 'react-widgets';
import { FlexInputField } from '../../../styles';

class MultiSelectField extends React.Component {
  state = {
    value: [],
    disabled: []
  }

  render() {
    const { label, input, data, meta: { touched, error }, valueField, textField, isInfinite, className } = this.props
    return (
      <FlexInputField className={className}>
        <label>{label}</label>
        <Multiselect
          {...input}
          onBlur={() => input.onBlur()}
          data={data}
          textField={textField}
          valueField={valueField}
          placeholder="Choose from list"
          value={input.value || []} // requires the value to be an array
          onChange={items => {
            if (items.length !== 4 || isInfinite) {
              this.setState({ value: items })
              input.onChange(items)
            }
          }}
          disabled={this.state.disabled}
        />
        {touched && error && <p className="text-error">{error}</p>}
      </FlexInputField>
    )
  }
}

export default MultiSelectField;
