import React from 'react';
import styled from 'styled-components';

import { media } from '../../../styles';

import SignupForm from './SignupForm';

const Section = styled.div`
  position: relative;
  background-image: url('https://res.cloudinary.com/dksc4gf1v/image/upload/v1569957890/assets/attndnce-hero.jpg');
  background-repeat: no-repeat;
  background-color: #10071B;
  position: relative;
  overflow: hidden;
  height: 110vh;

  ${media.handheld`
    background-size: 500%;
    background-position: -400px -150px;
  `}
  ${media.tablet`
    background-size: 400%;
    background-position: -500px -200px;
    height: 110vh;
  `}
  ${media.desktop`
    background-size: 200%;
    background-position: -400px -100px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-content: start;
  overflow: hidden;
  max-width: 670px;
  margin: 0 auto;
  padding: 100px 16px;
`

const Hero = ({ title, subTitle, location, history, match }) =>
  <Section>
    <GridLayout>
      <SignupForm
        title={title}
        subTitle={subTitle}
        location={location}
        history={history}
        match={match}/>
    </GridLayout>
  </Section>

export default Hero;
