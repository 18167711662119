import React from 'react';

import { ButtonStyled } from '../../../styles';

const TicketRemove = ({ ticket, closeModal, handleRemoveTicket}) => {
  return (
    <div
      style={{ height: "100%" }}
      className="flex flex-column padding--medium rounded flex-grow-1">
      <div className="flex flex-grow-1 flex-align-center marginBottom--medium">
        <p>Are you sure you want to remove {ticket.name}?</p>
      </div>
      <div className="flex flex-row flex-justify-between">
        <ButtonStyled onClick={closeModal}>Cancel</ButtonStyled>
        <ButtonStyled className="button--red" onClick={() => handleRemoveTicket(ticket)}>Yes, I am sure</ButtonStyled>
      </div>
    </div>
  )
}

export default TicketRemove;