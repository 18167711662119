import React from 'react';
import styled from 'styled-components';

import { FormStyle, media, ButtonStyled } from '../../../styles';

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin-bottom: 16px;
  .column-grid {
    display: flex;
    width: 100%;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  `}
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: flex-end;
  & > button {
    width: 200px;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    & > button {
      width: auto;
    }
  `}
`

const options = [{
  id: 'gender-male',
  label: 'Male',
  value: 'male'
},
{
  id: 'gender-female',
  label: 'Female',
  value: 'female'
},
{
  id: 'gender-other',
  label: 'Prefer Not to Say',
  value: 'prefer not to say'
}]

const OrderFormPreview = ({ orderForm, isLoading }) => {

  const cfType = (question_prompt, question_type, question_options, additional_terms) => {
    switch (question_type) {
      case 'text':
        return (
          <div className="flex flex-column marginBottom--small">
            <input type="text" name={question_prompt} />
          </div>
        )
      case 'textarea':
        return (
          <div className="flex flex-column marginBottom--small">
            <textarea
              type="text"
              name="notes"
              placeholder="If you are buying multiple passes in the same order, Please mention them here. Starting with their Full Name, Email Address, Gender and T-Shirt Size of each participant." />
          </div>
        )
      case 'additional_terms':
        return (
          <div className="flex flex-column marginBottom--small">
            <textarea
              disabled={true}
              type="text"
              style={{ background: "#eeeeee", resize: 'none' }}
              value={additional_terms} />
            <label className="marginTop--xSmall">
              <input
                type="checkbox"
                name="accept_additional_terms" />
              I agree to the terms above
            </label>
          </div>
        )
      case 'radio':
        return (
          <div className="flex flex-column marginBottom--small">
            {question_options.map((option, index) =>
              <div
                key={index}
                className="flex flex-row flex-align-center">
                <input
                  id={index}
                  type='radio'
                  name={question_prompt}
                  value={option} />
                <label htmlFor={index}>{option}</label>
              </div>
            )}
          </div>
        )
      case 'dropdown':
        return (
          <div className="flex marginBottom--small">
            <select
              className="select-css"
              name={question_prompt}>
              <option value="" defaultValue="Select your option">Select your option</option>
              {question_options.map((opt, i) => <option key={i}>{opt}</option>)}
            </select>
          </div>
        )
      default:
        break;
    }
  }

  const renderCustomFields = (customFields) =>
    (customFields || []).map((cf, index) => {
      if (cf.include) {
        return (
          <div key={index}>
            <label>{cf.question_prompt}</label>
            {cfType(cf.question_prompt, cf.question_type, cf.question_options, cf.additional_terms)}
          </div>
        )
      } else {
        return;
      }
    })

  return (
    <FormStyle>
      <p className="text-grey-blue text-bold">Mandatory Fields</p>
      <div className="marginBottom--small padding--small border rounded">
        <TwoColumnGrid>
          <div className="column-grid flex-column">
            <label>First Name</label>
            <input type="text" name="first_name" placeholder="John" />
          </div>
          <div className="column-grid flex-column">
            <label>Last Name</label>
            <input type="text" name="last_name" placeholder="Doe" />
          </div>
        </TwoColumnGrid>
        <div className="flex flex-column marginBottom--small">
          <label>Email Address</label>
          <input type="text" name="email" placeholder="john.doe@gmail.com" />
        </div>
      </div>
      <p className="text-grey-blue text-bold">Additional Fields</p>
      <div className="marginBottom--small padding--small border rounded">

        <fieldset>
          {orderForm && orderForm.gender && orderForm.gender.include &&
            <div className="flex flex-column marginBottom--small">
              <label>Gender</label>
              {options.map(option => {
                return (
                  <div
                    key={option.id}
                    className='flex flex-row flex-align-center'>
                    <input
                      id={option.id}
                      type='radio'
                      name="gender"
                      value={option.value} />
                    <label htmlFor={option.id}>{option.label}</label>
                  </div>
                )
              })}
            </div>}
          {orderForm && orderForm.age && orderForm.age.include &&
            <div className="flex flex-column marginBottom--small">
              <label>Age</label>
              <input type="text" name="age" placeholder="25" />
            </div>}
          {orderForm && orderForm.notes && orderForm.notes.include &&
            <div className="flex flex-column marginBottom--small">
              <label>Add a Note</label>
              <textarea
                type="text"
                name="notes"
                placeholder="If you are buying multiple passes in the same order, Please mention them here. Starting with their Full Name, Email Address, Gender and T-Shirt Size of each participant." />
            </div>
          }
        </fieldset>
      </div>
      <p className="text-grey-blue text-bold">Custom Fields</p>
      <div className="marginBottom--small padding--small border rounded">
        {orderForm && orderForm.custom_fields.length > 0 && renderCustomFields(orderForm.custom_fields)}
      </div>
      <ButtonContainer>
        <div className="hideOnMobile">&nbsp;</div>
        <ButtonStyled className="button--green" disabled={true}>Pay Now $XX.XX</ButtonStyled>
      </ButtonContainer>
    </FormStyle>
  )
}
export default OrderFormPreview;