import React from 'react';
import ChangePassword from './ChangePassword';
import { ButtonStyled } from '../../styles';

class PasswordInfo extends React.Component {
  state = {
    showPasswordForm: false,
  }

  render() {
    const { showPasswordForm } = this.state;
    return (
      <div className="flex flex-column flex-align-start">
        {!showPasswordForm && <ButtonStyled onClick={() => { this.setState(prevState => ({ showPasswordForm: !prevState.showPasswordForm })); }}>
          Change Password
        </ButtonStyled>}
        {showPasswordForm && <ChangePassword history={this.props.history} />}
      </div>
    )
  }
}

export default PasswordInfo;