import React from 'react';
import { friendlyDate } from '../../../helpers';
import moment from 'moment';

const DatePart = ({start_date, end_date}) => {
  return (
    <React.Fragment>
      <p>{`${friendlyDate(start_date, "MMM DD")} - ${friendlyDate(end_date, "MMM DD, YYYY")}`}</p>
      <p>From {`${moment(start_date).format('h:mm A')} to ${moment(end_date).format('h:mm A')}`}</p>
    </React.Fragment>
  )

}

export default DatePart;