import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { updateCurrency } from '../../../actions/ticketActions';
import { getRecentOrders } from '../../../actions/saleActions';

import DropDownListField from '../../utilities/formfields/DropDownField';
import PayoutCurrencyFields from '../../utilities/formfields/PayoutCurrencyFields';

import Spinner from '../../utilities/Spinner';

import { required } from '../../../helpers/formValdation';

import { FormStyle, ButtonStyled } from '../../../styles';

class TicketCurrency extends React.Component {
  state = {
    errors: {},
    loading: false
  }

  componentDidMount() {
    const { event } = this.props;
    this.props.getRecentOrders(event._id, () => { return; });
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) this.setState({ errors: this.props.errors, loading: false });
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState } = this.props;
    this.setState({ loading: true }, () => {
      this.props.updateCurrency(eventID, formValues, () => {
        this.setState({ loading: false }, () => {
          handleClearState();
        });
      });
    });
  }

  render() {
    const { handleSubmit, sale: { recentOrders } } = this.props;
    const { errors, loading } = this.state;
    
    if (loading) return <Spinner />
    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <div className="padding--small background-white border rounded-m shadow">

          <Field
            label="Fees"
            type="text"
            name="pass_fees_on"
            textField="name"
            valueField="value"
            placeholder="What to do with Fees"
            data={[{
              name: 'Pass fees on',
              value: true
            }, {
              name: 'Absorb fees',
              value: false
            }]}
            validate={required}
            error={errors.pass_fees_on}
            component={DropDownListField} />

          <PayoutCurrencyFields errors={errors} recentOrders={recentOrders} />

          <ButtonStyled className="button--blue" disabled={recentOrders.length > 0}>Apply Changes</ButtonStyled>
        </div>
      </FormStyle>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.event,
  sale: state.sale,
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { updateCurrency, getRecentOrders }),
  reduxForm({
    form: 'ticketCurrency',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
)(TicketCurrency);