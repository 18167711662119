import React from 'react';
import { IoIosGlobe } from "react-icons/io";

const CurrencyInfo = ({ user, stripe }) =>
  <React.Fragment>
    <div className="flex flex-row flex-align-center marginBottom--small paddingBottom--small border-bottom">
      <div className="flex flex-align-center flex-justify-center marginRight--small">
        <IoIosGlobe />
      </div>
      <div className="flex flex-column">
        <p>Charging In</p>
        <p>Your event will accept {user.currency.code}.</p>
      </div>
    </div>
    <div className="flex flex-row flex-align-center marginBottom--small paddingBottom--small border-bottom">
      <div className="flex flex-align-center flex-justify-center marginRight--small">
        <IoIosGlobe />
      </div>
      <div className="flex flex-column">
        <p>Payout currency</p>
        <p>You will be paid out in <span className="text-uppercase">{stripe.default_currency && stripe.default_currency}.</span></p>
      </div>
    </div>
  </React.Fragment>

export default CurrencyInfo;