import React from 'react'
import styled from 'styled-components';
import { FormStyle, media } from '../../styles';
import isEmpty from '../../validation/isEmpty';

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin-bottom: 16px;
  .column-grid {
    display: flex;
    width: 100%;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  `}
`

const CheckoutFields = ({
  event: { order_form: { age, notes, gender } },
  orderForm,
  errors,
  touched,
  _handleUserInput,
  _handleBlur,
}) => {

  const options = [{
    id: 'gender-male',
    label: 'Male',
    value: 'male'
  },
  {
    id: 'gender-female',
    label: 'Female',
    value: 'female'
  },
  {
    id: 'gender-other',
    label: 'Other',
    value: 'other'
  }]

  const showRequiredLabel = (key) => {
    if (key && key.require) {
      return <p className="text-error text-grey-blue">Required</p>
    }
  }

  const cfType = (cf, index, errors) => {
    switch (cf.question_type) {
      case 'text':
        return (
          <div className="flex flex-column marginBottom--small">
            <div className="flex flex-row flex-align-center flex-justify-between">
              <label>{cf.question_prompt}</label>
              {touched.filter(key => key.includes(`custom_field_${index}`)).length > 0 && errors[`custom_field_${index}`] ? <p className="text-error">{errors[`custom_field_${index}`]}</p> : showRequiredLabel(cf)}
            </div>
            <input
              type="text"
              name={`custom_field_${index}`}
              onChange={(e) => _handleUserInput(e, index)}
              onBlur={_handleBlur} />
          </div>
        )
      case 'textarea':
        return (
          <div className="flex flex-column marginBottom--small">
            <div className="flex flex-row flex-align-center flex-justify-between">
              <label>{cf.question_prompt}</label>
              {touched.filter(key => key.includes(`custom_field_${index}`)).length > 0 && errors[`custom_field_${index}`] ? <p className="text-error">{errors[`custom_field_${index}`]}</p> : showRequiredLabel(cf)}
            </div>
            <textarea
              type="text"
              name={`custom_field_${index}`}
              onChange={(e) => _handleUserInput(e, index)}
              onBlur={_handleBlur} />
          </div>
        )
      case 'additional_terms':
        return (
          <div className="flex flex-column marginBottom--small">
            <div className="flex flex-row flex-align-center flex-justify-between">
              <label>{cf.question_prompt}</label>
              {touched.filter(key => key.includes(`custom_field_${index}`)).length > 0 && errors[`custom_field_${index}`] ? <p className="text-error">{errors[`custom_field_${index}`]}</p> : showRequiredLabel(cf)}
            </div>
            <textarea
              disabled={true}
              type="text"
              style={{ background: "#eeeeee", resize: 'none' }}
              value={cf.additional_terms} />
            <label className="marginTop--xSmall">
              <input
                type="checkbox"
                name={`custom_field_${index}`}
                onChange={(e) => _handleUserInput(e, index)}
                onBlur={_handleBlur} />
              I agree to the terms above
            </label>
          </div>
        )
      case 'radio':
        return (
          <div className="flex flex-column marginBottom--small">
            <div className="flex flex-row flex-align-center flex-justify-between">
              <label>{cf.question_prompt}</label>
              {touched.filter(key => key.includes(`custom_field_${index}`)).length > 0 && errors[`custom_field_${index}`] ? <p className="text-error">{errors[`custom_field_${index}`]}</p> : showRequiredLabel(cf)}
            </div>

            {cf.question_options.map((option, i) => {
              return (
                <div
                  key={option}
                  className="flex flex-row flex-align-center">
                  <input
                    id={i}
                    type='radio'
                    name={`custom_field_${index}`}
                    value={option}
                    onChange={(e) => _handleUserInput(e, index)}
                    onBlur={_handleBlur}
                    checked={option === orderForm.custom_fields[index][`response`]} />
                  <label htmlFor={index}>{option}</label>
                </div>
              )
            }

            )}
          </div>
        )
      case 'dropdown':
        return (
          <div className="flex flex-column marginBottom--small">
            <div className="flex flex-row flex-align-center flex-justify-between">
              <label>{cf.question_prompt}</label>
              {touched.filter(key => key.includes(`custom_field_${index}`)).length > 0 && errors[`custom_field_${index}`] ? <p className="text-error">{errors[`custom_field_${index}`]}</p> : showRequiredLabel(cf)}
            </div>
            <select
              className="select-css"
              name={`custom_field_${index}`}
              onChange={(e) => _handleUserInput(e, index)}
              onBlur={_handleBlur}>
              <option value="" defaultValue="Select your option">Select your option</option>
              {cf.question_options.map((opt, i) => <option key={i} value={opt}>{opt}</option>)}
            </select>
          </div>
        )
      default:
        break;
    }
  }

  const renderCustomFields = (customFields, errors) => {
    return (customFields || []).map((cf, index) => {
      if (cf.include) return <div key={index}>{cfType(cf, index, errors)}</div>
      else return;
    });
  }

  return (
    <React.Fragment>
      <div className="header">
        <h3 className="paddingBottom--small border-bottom">Buyer Information</h3>
      </div>
      <div className="content">
        <FormStyle>
          <TwoColumnGrid>
            <div className="column-grid flex-column">
              <div className="flex flex-row flex-align-center flex-justify-between">
                <label>First Name</label>
                {touched.filter(key => key.includes("first_name")).length > 0 && errors.first_name ? <p className="text-error">{errors.first_name}</p> : <p className="text-error text-grey-blue">Required</p>}
              </div>
              <input
                type="text"
                name="first_name"
                value={orderForm.first_name}
                onChange={_handleUserInput}
                onBlur={_handleBlur}
                placeholder="John" />
            </div>
            <div className="column-grid flex-column">
              <div className="flex flex-row flex-align-center flex-justify-between">
                <label>Last Name</label>
                {touched.filter(key => key.includes("last_name")).length > 0 && errors.last_name ? <p className="text-error">{errors.last_name}</p> : <p className="text-error text-grey-blue">Required</p>}
              </div>
              <input
                type="text"
                name="last_name"
                value={orderForm.last_name}
                onChange={_handleUserInput}
                onBlur={_handleBlur}
                placeholder="Doe" />
            </div>
          </TwoColumnGrid>

          <div className="flex flex-column marginBottom--small">
            <div className="flex flex-row flex-align-center flex-justify-between">
              <label>Email</label>
              {touched.filter(key => key.includes("email")).length > 0 && errors.email ? <p className="text-error">{errors.email}</p> : <p className="text-error text-grey-blue">Required</p>}
            </div>
            <input
              type="email"
              name="email"
              value={orderForm.email}
              onChange={_handleUserInput}
              onBlur={_handleBlur}
              placeholder="john.doe@gmail.com" />
          </div>

          {!isEmpty(gender) && gender.include &&
            <div className="marginBottom--small">
              <div className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall paddingBottom--xSmall border-bottom">
                <label>Gender</label>
                {touched.filter(key => key.includes("gender")).length > 0 && errors.gender ? <p className="text-error">{errors.gender}</p> : showRequiredLabel(gender)}
              </div>
              {options.map(option =>
                <div key={option.id} className='flex flex-row flex-align-center'>
                  <input
                    id={option.id}
                    type="radio"
                    name="gender"
                    value={option.value}
                    onChange={_handleUserInput}
                    onBlur={_handleBlur}
                    checked={option.value === orderForm.gender} />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              )}
            </div>
          }

          {!isEmpty(age) && age.include &&
            <div className="flex flex-column marginBottom--small">
              <div className="flex flex-row flex-align-center flex-justify-between">
                <label>Age</label>
                {touched.filter(key => key.includes("age")).length > 0 && errors.age ? <p className="text-error">{errors.age}</p> : showRequiredLabel(age)}
              </div>
              <input
                type="age"
                name="age"
                value={orderForm.age}
                onChange={_handleUserInput}
                onBlur={_handleBlur}
                placeholder="25" />
            </div>
          }

          {isEmpty(notes) && notes.include &&
            <div className="marginBottom--small">
              <div className="flex flex-row flex-align-center flex-justify-between">
                <label>Add a Note</label>
                <p className="text-error">{errors.notes}</p>
              </div>
              <textarea
                type="text"
                name="notes"
                placeholder="If you are buying multiple passes in the same order, Please mention them here. Starting with their: full name, email address, and gender."
                value={orderForm.notes}
                onChange={_handleUserInput}
                onBlur={_handleBlur} />
            </div>
          }

          {orderForm.custom_fields && orderForm.custom_fields.length > 0 &&
            <>
              <p className="text-grey-blue text-bold">Additional Fields</p>
              <div className="marginBottom--small padding--small border rounded">
                {orderForm.custom_fields && orderForm.custom_fields.length > 0 && renderCustomFields(orderForm.custom_fields, errors)}
              </div>
            </>
          }

        </FormStyle>
      </div>
    </React.Fragment>
  )
}

export default CheckoutFields;