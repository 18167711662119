import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles';
import { FiArrowRight, FiPlus, FiChevronUp } from 'react-icons/fi';

import faqs_list from '../../../data/marketing/faqs';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  width: 785px;
  margin: 0 auto;
  margin-bottom: 60px;
  ${media.handheld`
    grid-template-columns: 1fr;
    max-width: 100%;
    width: auto;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const Title = styled.div`
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  text-align: center;
  ${media.handheld`
    width: 100%;
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
  `}
`

const SubTitle = styled.div`
  margin-bottom: 16px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  text-align: center;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const SubHeadline = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 32px;
  color: #3A4557;
  line-height: 40px;
  margin-bottom: 40px;
  ${media.handheld`
    font-size: 40px;
    line-height: 50px;
  `}
`

const LinkStyled = styled.a`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  margin-bottom: 120px;
`

const SubText = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 18px;
  color: #262626;
  line-height: 38px;
`

const Faqs = () => {
  const [active, setActive] = useState(null);

  const toggleClass = (clicked) => {
    setActive(active === clicked ? null : clicked)
  }

  const moreLess = (clicked) => {
    if (active === clicked) { return <FiChevronUp size="24px" /> }
    else { return <FiPlus size="24px" /> }
  }


  const renderList = list => {
    return list.map((l, i) => {
      return (
        <div className="flex flex-column marginBottom--medium paddingBottom--medium border-bottom">
          <div className="flex flex-row flex-align-center flex-justify-between" onClick={() => toggleClass(i)}>
            <SubText>{l.question}</SubText>
            {moreLess(i)}
          </div>
          {active === i ?
            <p className="marginTop--small">{l.answer}</p>
            :
            <span></span>
          }
        </div>
      )
    })
  }
  return (
    <React.Fragment>
      <GridLayout>
        <Title>Payment processing fee</Title>
        <SubTitle>Stripe charges you a flat rate of 2.9% + 30¢ per successful charge.</SubTitle>
        <LinkStyled href={`https://stripe.com/en-ca/pricing`} target="_blank" rel="nofollow" className="flex flex-row flex-align-center flex-justify-center text-center text-blue">
          <FiArrowRight size="24px" className="marginRight--small" />
          <span>View Pricing</span>
        </LinkStyled>
        <SubHeadline>Frequently Asked Questions</SubHeadline>
        {renderList(faqs_list)}
      </GridLayout>
    </React.Fragment>
  )
}

export default Faqs;