import React from 'react'
import { friendlyDate } from '../../../helpers';
import moment from 'moment';


const DateStamp = ({start_date, end_date}) => {
  return (
    <div>
      <h3 className="important">{friendlyDate(start_date, "D")}</h3>
      <p>{friendlyDate(start_date, "MMM, YYYY")}</p>
      <p>{`${moment(start_date).format('h:mm A')} to ${moment(end_date).format('h:mm A')}`}</p>
    </div>
  )
}

export default DateStamp;