import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { getEventSales } from '../../../actions/eventSaleActions';

import ReportTableRow from './ReportTableRow';
import ErrorMessage from '../../utilities/ErrorMessage';

import "./table.css";

const SalesReportTable = ({ event, eventID }) => {
  const [eventSales, setEventSales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const data = await dispatch(getEventSales(eventID));
        
        setEventSales(data);
      } catch (err) {
        if (err.code === "account_invalid") { // Current Connected Stripe account matching acct_*** does not exist on Facilitator Stripe Account 
          setError({ accountInvalid: "The provided key 'sk_test_f6******************apLC' does not have access to account 'acct_1E4veJHqMnn0J8zd'(or that account does not exist). Application access may have been revoked." });
        } else {
          setError(err)
        }
      }
      setIsLoading(false);
    };
    runEffect();
  }, [dispatch]);

  return (
    <div>
      {Object.keys(error).length > 0 && <ErrorMessage showCloseButton={false} errors={error} />}

    <ol className="collection collection-container background-white rounded-m border padding--small">
      <li className="item item-container">
        <div className="attribute" data-name="#">#</div>
        <div className="attribute-container order-information">
          <div className="attribute-container order-id">
            <div className="attribute" data-name="Order Number">Order #</div>
            <div className="attribute" data-name="Purchased Date">Purchased Date</div>
          </div>
          <div className="attribute-container customer-information">
            <div className="attribute">Customer Name</div>
            <div className="attribute">Customer Email</div>
          </div>
        </div>
        <div className="attribute-container quantity">
          <div className="attribute">Order Qty</div>
          <div className="attribute">Extended Order</div>
        </div>
        <div className="attribute-container amount">
          <div className="attribute">Cost</div>
        </div>
        <div className="attribute-container tax">
          <div className="attribute">Taxes %</div>
          <div className="attribute">Tax</div>
        </div>
        <div className="attribute-container fees">
          <div className="attribute">Fee</div>
          <div className="attribute">Fee Details</div>
        </div>
        <div className="attribute">Net</div>
      </li>
      <ReportTableRow eventSales={eventSales} event={event} isLoading={isLoading} />
    </ol>
    </div>
  )
}

export default SalesReportTable;
