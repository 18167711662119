import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createEvent } from '../../actions/eventActions';

import EventCreateFirstPage from './EventCreateFirstPage';
import EventCreateSecondPage from './EventCreateSecondPage';

import Spinner from '../utilities/Spinner';

import { media } from '../../styles';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;

  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  .item {
    display: grid;
    grid-template-columns: 640px;
    grid-row-gap: 16px;

    align-items: center;
    justify-content: center;
    ${media.handheld`
      grid-template-columns: 100%;
    `}
  }
`
class EventCreate extends Component {
  state = {
    page: 1,
    errors: {},
    loading: false
  }

  componentWillUnmount() {
    localStorage.removeItem('image');
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        page: this.state.page - 1,
        errors: this.props.errors,
        loading: false
      });
    }
  }

  previousPage = () => {
    window.scrollTo(0, 0);
    this.setState({ page: this.state.page - 1 })
  }

  nextPage = () => {
    window.scrollTo(0, 0);
    this.setState({ page: this.state.page + 1 })
  }

  beforeSubmit = (formValues) => {
    this.setState({ loading: true }, () => {
      this.props.createEvent(formValues, (eventID) => {
        this.setState({ loading: false }, () => {
          this.props.history.push(`/account/event/${eventID}`);
        });
      });
    });
  }

  render() {
    const { onSubmit } = this.props;
    const { page, errors, loading } = this.state;

    if (loading) return <Spinner />
    return (
      <GridLayout>
        {page === 1 && <EventCreateFirstPage errors={errors} onSubmit={this.nextPage} />}

        {page === 2 && (
          <EventCreateSecondPage
            previousPage={this.previousPage}
            beforeSubmit={this.beforeSubmit}
            onSubmit={onSubmit} />
        )}
      </GridLayout>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(mapStateToProps, { createEvent })(EventCreate);