import React from 'react';
import AffiliateEmptyState from './AffiliateEmptyState';
import { StyledCard, GridStyledCard } from '../../../styles';

import { formatPrice } from '../../../helpers';
import isEmpty from '../../../validation/isEmpty';

const calculateAffiliateRevenueTotal = (referrers) => {
  let total = 0;
  if (referrers && referrers.length > 0) {
    referrers.map(ref => {
      (ref.sale || []).map(order => {
        total += order.total;
      });
    });
  }
  return total;
}

const calculateAffiliateTicketTotal = (referrers) => {
  let quantity = 0;
  if (referrers && referrers.length > 0) {
    referrers.map(ref => {
      (ref.sale || []).map(order => {
        order.items.reduce((prev, item) => {
          quantity += item.quantity_sold;
        }, 0);
      });
    });
  }
  return quantity;
}

const renderList = (affiliates, handleViewChange, code, language) =>
  affiliates.map(aff => {
    return (
      <StyledCard
        key={aff.affiliate_name}
        style={{ cursor: "pointer" }}
        data-view="view"
        onClick={(e) => handleViewChange(e, aff)}>
        <GridStyledCard>
          <div className="flex flex-column" style={{ justifySelf: "start" }}>
            <p className="text-small text-grey-blue">Affiliate name</p>
            <p>{aff.affiliate_name}</p>
          </div>
          <div className="item">
            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Referrers</p>
              <h3>{aff.referrers && aff.referrers.length > 0 && !isEmpty(aff.referrers[0].referrer_info) ? aff.referrers.length : 0}</h3>
            </div>
            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Tickets Sold</p>
              <h3>{calculateAffiliateTicketTotal(aff.referrers)}</h3>
            </div>
            <div className="padding--xSmall border rounded shadow">
              <p className="text-small">Total Revenue</p>
              <h3>{formatPrice(calculateAffiliateRevenueTotal(aff.referrers), code, language)}</h3>
            </div>
          </div>
        </GridStyledCard>
      </StyledCard>
    )
  })


const AffiliateList = ({ affiliates, handleViewChange, code, language }) => {
  if (affiliates && affiliates.length > 0) {
    return renderList(affiliates, handleViewChange, code, language)
  } else {
    return (
      <div className="flex flex-grid-thirds flex-justify-center flex-align-center">
        <div className="col">
          <AffiliateEmptyState handleViewChange={handleViewChange} />
        </div>
      </div>
    )
  }
}

export default AffiliateList;