import React from 'react';

import { ButtonStyled } from '../../styles';

const DiscountRemove = ({ discount, handleClearState, handleDeleteDiscount }) =>
  <div className="flex flex-column marginBottom--small padding--medium background-white border rounded-m shadow">
    <div className="flex flex-grow-1 flex-align-center marginBottom--medium">
      {discount.quantity_sold > 0 ? <p>Discount Code: {discount.code} has already been used. You cannot remove it at this time.</p> : <p>Are you sure you want to remove {discount.code}?</p>}
    </div>
    <div className="flex flex-row flex-justify-between">
      <ButtonStyled onClick={handleClearState}>Cancel</ButtonStyled>
      <ButtonStyled className="button--red" disabled={discount.quantity_sold > 0} onClick={() => handleDeleteDiscount(discount._id)}>Yes, I am sure</ButtonStyled>
    </div>
  </div>


export default DiscountRemove;