import axios from 'axios';
import {
  GUEST_LOADING,
  GET_GUESTS,
  GET_ERRORS
} from './types'

export const getGuestList = (eventID, callback) => async dispatch => {
  try {
    dispatch(setGuestLoading());
    const res = await axios.get(`/api/billing/stripe/guests/${eventID}`);
    dispatch({
      type: GET_GUESTS,
      payload: res.data
    });
    callback(res.data);
  }
  catch (err) {
    dispatch({
      type: GET_GUESTS,
      payload: []
    });
  }
}

export const manualConfirm = (guestID, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/billing/stripe/guest/update/${guestID}`);
    dispatch({
      type: GET_GUESTS,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

// Set loading state
export const setGuestLoading = () => {
  return {
    type: GUEST_LOADING
  };
};