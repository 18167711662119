import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IoMdAdd, IoIosList } from 'react-icons/io'

import { fetchAffiliates, fetchAffiliateNull, deleteAffiliate } from '../../../actions/affiliateActions';

import AffiliateList from './AffiliateList';
import AffiliateView from './AffiliateView';
import AffiliateCreate from './AffiliateCreate';
import AffiliateEdit from './AffiliateEdit';
import AffiliateRemove from './AffiliateRemove';
import ReferrerView from './ReferrerView';
import AffiliateEmptyState from './AffiliateEmptyState';

import Spinner from '../../utilities/Spinner';
import IconFilled from '../../utilities/IconFilled';
import CardHeader from '../../utilities/card/CardHeader';
import SnackBar from '../../utilities/snackbar/SnackBar';

import { StyledCard } from '../../../styles';
import { toTitleCase } from '../../../helpers';
import isEmpty from '../../../validation/isEmpty';

class AffiliateShow extends React.Component {
  state = {
    affiliates: [],
    aff: {},
    ref: {},
    selectedView: 'affiliate list'
  }

  componentDidMount() {
    this.props.fetchAffiliates(this.props.match.params.id, (affiliates) => {
      if (affiliates.length === 0) {
        this.props.fetchAffiliateNull(this.props.match.params.id, (affiliatesNull) => {
          this.setState({ affiliates: affiliatesNull });
        });
      } else {
        this.setState({ affiliates: affiliates });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { affiliates } = this.props.affiliate;
    const { affiliates: prevAffiliates } = prevProps.affiliate;
    if (affiliates && prevAffiliates) {
      if (!isEmpty(affiliates[0]) && !isEmpty(prevAffiliates[0])) {
        if (affiliates[0].amount_off !== prevAffiliates[0].amount_off) {
          this.fetchAffiliates();
        } else if (affiliates[0].percent_off !== prevAffiliates[0].percent_off) {
          this.fetchAffiliates();
        } else if (affiliates[0].affiliate_name !== prevAffiliates[0].affiliate_name) {
          this.fetchAffiliates();
        } else if (affiliates[0].note !== prevAffiliates[0].note) {
          this.fetchAffiliates();
        }
      }
    }
  }

  fetchAffiliates = () => {
    this.props.fetchAffiliates(this.props.match.params.id, (affiliates) => {
      if (affiliates.length === 0) {
        this.props.fetchAffiliateNull(this.props.match.params.id, (affiliatesNull) => {
          this.setState({ affiliates: affiliatesNull });
        });
      } else {
        this.setState({ affiliates: affiliates });
      }
    });
  }

  handleViewChange = (e, aff, ref) => {
    this.setState({
      ...!isEmpty(aff) && { aff: aff },
      ...!isEmpty(ref) && { ref: ref },
      selectedView: e.currentTarget.dataset.view
    });
  }

  handleClearState = () => {
    this.setState({
      selectedView: "affiliate list"
    });
  }

  handleCloseSnack = () => {
    this.setState({ message: null });
  }

  handleRemoveAffiliate = (aff) => {
    this.props.deleteAffiliate(aff._id, aff, ({ success_message }) => {
      this.fetchAffiliates();
      this.handleClearState();
      this.setState({ message: success_message });
    });
  }

  renderView = (name, affiliates) => {
    switch (name) {
      case 'affiliate list':
        return (
          <AffiliateList
            language={this.props.language}
            code={this.props.code}
            affiliates={affiliates}
            handleViewChange={this.handleViewChange} />
        )
      case 'view':
        return (
          <AffiliateView
            language={this.props.language}
            code={this.props.code}
            aff={this.state.aff}
            handleClearState={this.handleClearState}
            handleViewChange={this.handleViewChange} />
        )
      case 'create':
        return (
          <StyledCard>
            <AffiliateCreate
              eventID={this.props.match.params.id}
              handleClearState={this.handleClearState}
              handleViewChange={this.handleViewChange} />
          </StyledCard>
        )
      case 'edit':
        return (
          <StyledCard>
            <AffiliateEdit
              aff={this.state.aff}
              affID={this.state.aff._id}
              handleClearState={this.handleClearState}
              handleViewChange={this.handleViewChange} />
          </StyledCard>
        )
      case 'remove':
        return (
          <StyledCard>
            <AffiliateRemove
              aff={this.state.aff}
              affID={this.state.aff._id}
              handleViewChange={this.handleViewChange}
              handleRemoveAffiliate={this.handleRemoveAffiliate} />
          </StyledCard>
        )
      case 'referrer':
        return (
          <ReferrerView
            language={this.props.language}
            code={this.props.code}
            aff={this.state.aff}
            referrer={this.state.ref}
            eventID={this.props.match.params.id} />
        )
      default:
        return (
          <div className="flex flex-grid-thirds flex-justify-center flex-align-center">
            <div className="col">
              <AffiliateEmptyState handleViewChange={this.handleViewChange} />
            </div>
          </div>
        )
    }
  }

  render() {
    const { affiliates, selectedView } = this.state;

    if (affiliates === null || this.props.match.params.id === "") {
      return <Spinner />
    } else {
      return (
        <React.Fragment>
          <div className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall">

            <CardHeader title={toTitleCase(selectedView)} />

            <div className="flex flex-row">
              <IconFilled
                isPointer={true}
                isHover={true}
                cHeight="32px"
                cWidth="32px"
                data-view="affiliate list"
                className="marginRight--small"
                onClick={(e) => this.handleViewChange(e)}>
                <IoIosList size="1rem" />
              </IconFilled>

              <IconFilled
                isPointer={true}
                isHover={true}
                cHeight="32px"
                cWidth="32px"
                data-view="create"
                onClick={(e) => this.handleViewChange(e)}>
                <IoMdAdd size="1rem" />
              </IconFilled>

            </div>

          </div>
          {this.state.message && <SnackBar
            message={this.state.message}
            type="success"
            classes="marginBottom--small"
            handleCloseSnack={this.handleCloseSnack} />}
          {this.renderView(selectedView, affiliates)}
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = state => ({
  event: state.event,
  affiliate: state.affiliate
});

export default connect(mapStateToProps, { fetchAffiliates, fetchAffiliateNull, deleteAffiliate })(withRouter(AffiliateShow));


