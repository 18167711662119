import React from 'react'
import StripeCheckout from 'react-stripe-checkout';
import { withRouter } from 'react-router-dom';

import { formatPrice } from '../../../helpers';
import { ButtonStyled } from '../../../styles';

const Payments = ({
  loading,
  email,
  amount,
  handleToken
}) => {
  return (
    <StripeCheckout
      name="ATTNDNCE"
      description={`${formatPrice(amount, "USD", 'en-US')} will be paid to ATTNDNCE`}
      email={email}
      amount={amount}
      stripeKey={process.env.REACT_APP_STRIPE_KEY}
      token={handleToken}>
      <ButtonStyled disabled={loading} className="button--green rounded-m text-bold text-uppercase pullRight">Enter Credit Card Info</ButtonStyled>
    </StripeCheckout>
  )
}

export default withRouter(Payments);