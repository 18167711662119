import React from 'react';
import styled from 'styled-components';
import { media, ButtonStyledLink } from '../../../styles';
import { FiCheckCircle, FiArrowRight } from 'react-icons/fi';

import pricing_data from '../../../data/marketing/pricing';

const Title = styled.h1`
  max-width: 550px;
  margin-top: 60px;
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  text-align: center;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.h2`
  max-width: 550px;
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  text-align: center;
  ${media.handheld`
    width: auto;
    font-size: 16px;
    line-height: 26px;
  `}
`

const PriceTitle = styled.h3`
  margin-bottom: 16px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
  text-align: left;
  ${media.handheld`
    width: 100%;
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
  `}
`

const PriceType = styled.p`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #262626;
  text-align: left;
`

const Price = styled.div`
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 48px;
  color: #000000;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  max-width: 210px;
`

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(0,0,0,0.12), 0 -1px 1px 0 rgba(0,0,0,0.12);
  padding: 24px;
  border-radius: 8px;
  & > :first-child {
    border-right: 1px solid #EBEBEB;
    padding-right: 24px; 
    margin-right: 24px; 
    ${media.handheld`
      border-right: none;
      padding-right: 0; 
      margin-right: 0; 
    `}
  }
   ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  `}
`

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  ${media.handheld`
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 750px;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 60px;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  ${media.handheld`
    max-width: 100%;
    width: auto;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const PriceTable = () => {

  const renderTable = (pricing_data) => {
    return pricing_data.map(plan => {
      return (
        <div>
          {/* <img src={`../images/${plan.image}`}/> */}
          <PriceTitle>{plan.title}</PriceTitle>
          <PriceType>{plan.type}</PriceType>
          <div className="flex flex-align-start marginBottom--small paddingBottom--small border-bottom">
            <p className="marginRight--xSmall">{plan.symbol}</p>
            <Price className="marginRight--xSmall">{plan.price}</Price>
            <p>{plan.price_info}</p>
          </div>
          <div className="flex marginTop--small marginBottom--small">
            <p className="text-bold">{plan.difference}</p>
          </div>
          {/* <p className="text-small">{plan.subtext ? plan.subtext : <span>&nbsp;</span>}</p> */}

          {plan.features.map(f =>
            <div className="flex flex-row flex-align-center marginBottom--xSmall paddingBottom--xSmall border-bottom">
              <FiCheckCircle className="marginRight--xSmall text-green" />
              <p>{f}</p>
            </div>
          )}

          <ButtonContainer>
            <ButtonStyledLink to={`/sign-up?ref=pricing_table_${plan.ref}`} className="button--blue">
              <span>Signup For Free</span>
              <FiArrowRight size="24px" className="marginRight--small" />
            </ButtonStyledLink>
          </ButtonContainer>
        </div>
      )
    })
  }
  return (
    <React.Fragment>
      <Section>
        <Title>Find the plan that’s right for you.</Title>
        <SubTitle>Organizing an event is stressful enough. Put your festival on autopilot. Expect an easy setup with a powerful beast under the hood.</SubTitle>
      </Section>
      <GridLayout>
        <GridColumns>
          {renderTable(pricing_data)}
        </GridColumns>
      </GridLayout>
    </React.Fragment>
  )
}


export default PriceTable;