import React from 'react';
import { Field } from 'redux-form';
import { IoMdAdd, IoIosClose } from "react-icons/io";

import IconFilled from './IconFilled';
import CardHeader from './card/CardHeader';
import TextField from '../utilities/formfields/TextField';

import { StyledCard } from '../../styles';
import { required } from '../../helpers/formValdation';

const renderListElement = (fields, error) =>
  <React.Fragment>
    <CardHeader title="Video Integration" className="marginBottom--small">
      <a href="#nowhere" className="flex flex-align-center text-blue pullRight" onClick={() => fields.length <= 3 && fields.push()}><IoMdAdd className="marginRight--xSmall" />Youtube Videos</a>
    </CardHeader>

    {fields.map((video, index) => {
      return (
        <StyledCard key={index}>
          <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small">
            <p>Video {index + 1}</p>
            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              onClick={() => fields.remove(index)}>
              <IoIosClose size="1rem" />
            </IconFilled>

          </div>
          <Field
            type="text"
            name={video} //video[index]
            placeholder="https://www.youtube.com/watch?v=epWiSNsGPYc"
            error={(error || []).map(e => index === e.id && e.url)}
            validate={required}
            component={TextField} />
        </StyledCard>
      )
    })}
  </React.Fragment>

const TableYoutube = ({ fields, error }) =>
  renderListElement(fields, error);

export default TableYoutube;


