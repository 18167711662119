import React from 'react';
import styled from 'styled-components';
import { media } from '../../../styles';
import { FiCheckCircle } from "react-icons/fi";
import Event from "../images/create-event.png";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  margin-bottom: 50px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 75px;
  align-items: center;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    margin-left: 24px;
    margin-right: 24px;
  `}
`

const SectionOne = () =>
  <GridLayout>
    <div>
      <Title>Perfect for all <br /> kinds of Events</Title>
      <SubTitle>A single app. Built for classes, workshop congress, and festival events.</SubTitle>
      <ul>
        <ListItem>
          <FiCheckCircle size="24px" />
          Private Events and classes
          </ListItem>
        <ListItem>
          <FiCheckCircle size="24px" />
          Bootcamps and Workshops
          </ListItem>
        <ListItem>
          <FiCheckCircle size="24px" />
          Congress and Festivals
          </ListItem>
      </ul>
    </div>
    <div>
      <img src={Event} className="marginBottom--large" alt="" />
    </div>
  </GridLayout>

export default SectionOne;