import {
  GET_DISCOUNT,
  GET_DISCOUNTS,
  DELETE_DISCOUNT,
  DISCOUNT_LOADING
} from '../actions/types';

const INITIAL_STATE = {
  discount: {},
  discounts: [],
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case DISCOUNT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
        loading: false
      }
      case GET_DISCOUNTS:
      return {
        ...state,
        discounts: action.payload,
        loading: false
      }
      case DELETE_DISCOUNT:
      return {
        ...state,
        discounts: state.discounts.filter(discount => discount._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
}