import React from 'react';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';

import styled from 'styled-components';

const ErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.25rem;
  padding: 1rem;
  background: #f8d7da;
  border: 1px solid  #f8d7da;
  border-radius: 8px;
  overflow: hidden;

  ul li {
    list-style-type: none;
    color: #721c24;
  }
`

const Messages = (errors) => _.map(errors, (value, key) => <li key={key}>{value}</li>)

const ErrorMessage = ({ errors, handleErrorMessage, showCloseButton }) => {
  return (
    <ErrorStyled>
      <div className="flex flex-row flex-align-center flex-justify-between">
        <div className="flex flex-column"><ul>{Messages(errors)}</ul></div>
        {showCloseButton && <a onClick={() => handleErrorMessage()} style={{ lineHeight: '0' }}><FontAwesome name="window-close" size="2x" style={{ color: '#721c24' }} /></a>}
      </div>
    </ErrorStyled>
  )
}



export default ErrorMessage;