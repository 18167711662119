import React from 'react';
import styled from 'styled-components';

import { formatPrice, truncate, fromNow } from '../../../helpers';
import { StyledCard, media } from '../../../styles/';

import * as custom from '../../../styles/Variables';

const ListItemStyled = styled.div`
  /* margin-bottom: ${custom.SPACE_medium}; */
  /* padding-bottom: ${custom.SPACE_medium}; */
  border-bottom: 1px solid ${custom.COLOR_BORDER};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${media.handheld`
    margin-left: 16px;
    margin-right: 16px;
  `}

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* margin-bottom: 0; */
    /* padding-bottom: 0; */
    border-bottom: none;
  }
`

const TaxList = ({ event, taxes, handleModalSwitch }) => {
  const { payout_currency: { code }, payout_country: { language } } = event;
  if (taxes.length < 1) {
    return (
      <div style={{ height: "100vh" }} className="flex flex-column flex-align-center flex-justify-center paddingTop--small paddingBottom--small background-white border rounded-m shadow">
        <p>No taxes found.</p>
      </div>
    )
  }
  return (taxes || []).map(tax => {
    return (
      <ListItemStyled
        key={tax._id}
        className="flex flex-row flex-align-start flex-justify-between background-white padding--small"
        data-modal="view"
        style={{ cursor: "pointer" }}
        onClick={(e) => handleModalSwitch(e, tax)}>

        <div className="flex flex-column">
          <p className="marginRight--xSmall text-bold text-spacing-1">{truncate(tax.tax_name.toUpperCase(), 49)}</p>
          <p className="text-small">{tax.tax_percent}</p>
          <p className="text-small">{tax.tax_id}</p>
        </div>
      </ListItemStyled>
    )
  })
}

export default TaxList;