import React from 'react';
import { IoIosCheckmarkCircleOutline, IoIosInformationCircleOutline } from "react-icons/io";
import { FiAlertTriangle, FiAlertCircle, FiXCircle } from "react-icons/fi";

import styled from 'styled-components';

const SnackBarStyled = styled.div`
  &.snackbar--success {
    background: #CBDF9F;
    p, svg { color: "#7D9053"; }
  }
  &.snackbar--warning {
    color: #283E59;
    background: #ffa000;
    p, svg { color: "#FFFFFF"; }
  }
  &.snackbar--alert {
    background: #F9BCAD;
    p, svg { color: "#541E12"; }
  }
  &.snackbar--info {
    background: #E5F1FA;
    p, svg { color: "#0F427D"; }
  }
`

const renderSnackIcon = (name) => {
  switch (name) {
    case "error":
      return <FiAlertCircle size="24px" color="#541E12" className="marginRight--xSmall" />
    case "warning":
      return <FiAlertTriangle size="24px" color="#FFFFFF" className="marginRight--xSmall" />
    case "info":
      return <IoIosInformationCircleOutline size="24px" color="#0F427D" className="marginRight--xSmall" />
    case "success":
      return <IoIosCheckmarkCircleOutline size="24px" color="#7D9053" className="marginRight--xSmall" />
    default:
      break;
  }
}
const SnackBar = ({ message, type, handleCloseSnack, classes }) =>
  <SnackBarStyled className={`flex flex-row flex-align-center flex-justify-center flex-grow-1 padding--small snackbar--${type} rounded ${classes}`}>
    <div className="flex flex-row flex-align-center">
      {renderSnackIcon(type)}
      <p>{message}</p>
    </div>
    <FiXCircle size="24px" className="pullRight" onClick={handleCloseSnack} style={{ cursor: "pointer" }} />
  </SnackBarStyled>

export default SnackBar;