import React from 'react';
import { StyledCard, ButtonStyled } from '../../../styles';

const AffiliateEmptyState = ({ handleViewChange }) =>
  <StyledCard className="flex flex-column flex-align-center flex-justify-center text-center">
    <h3 className="marginBottom--small">Affiliate Program</h3>
    <p className="marginBottom--small">Create an affiliate program and set a referral fee that referrers earn for each ticket sale they generate. Use the unique affiliate URL (link) to invite referrers to join your affiliate program and when they do, they'll receive a unique event URL (link) to promote.</p>
    <ButtonStyled className="button--blue" data-view="create" onClick={(e) => handleViewChange(e)}>Create Affiliate</ButtonStyled>
  </StyledCard>

export default AffiliateEmptyState;