import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import moment from 'moment';

import { createSession } from '../../../actions/sessionActions';

import TextField from '../../utilities/formfields/TextField';
import TextAreaField from '../../utilities/formfields/TextAreaField';
import DropDownListField from '../../utilities/formfields/DropDownField';
import DateTimePickerField from '../../utilities/formfields/DateTimePickerField';

import { required } from '../../../helpers/formValdation';
import { friendlyDate } from '../../../helpers';

import { ButtonStyled, FormStyle } from '../../../styles';

class SessionCreate extends React.Component {
  state = {
    errors: {},
    loading: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState } = this.props;
    this.setState({ loading: true }, () => {
      this.props.createSession(eventID, formValues, (sessions) => { handleClearState(sessions); });
    })
  }

  render() {
    const { errors } = this.state;
    const { handleSubmit, closeModal } = this.props;
    
    let dateString = new Date();
    let m = moment(dateString, 'ddd MMM D YYYY HH:mm:ss ZZ');
    // Use moment(Date) if your input is a JS Date
    //var m = moment(date);
    m.set({h: parseInt("00",10), m: parseInt("00", 10)});
    // console.log(m.format());
    // console.log(m.toDate().toString());

    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <div className="padding--small background-white border rounded-m shadow">

          <Field
            label="Title"
            type="text"
            name="name"
            placeholder="Class Title"
            validate={required}
            component={TextField} />

          <div className="flex flex-grid-half flex-justify-between">
            <Field
              label="Session Type"
              type="text"
              name={`session_type`}
              placeholder="Registration, Bootcamp, Competition, Workshop, Social"
              className="col flex-grow-1"
              data={[
                'registration',
                'Bootcamp',
                'Competition',
                'Workshop',
                'Social'
              ]}
              validate={required}
              component={TextField} />
            <Field
              label="Tags"
              type="text"
              name={`tags`}
              placeholder="Salsa, Bachata, Kizomba, Beginner, Intermediate"
              className="col"
              data={[
                "beginner",
                "intermediate",
                "advance",
                'afro-latin',
                "Salsa",
                "Bachata",
                "Kizomba",
                'zouk',
                'tango',
                'samba',
                'cha-cha',
                'cuban-salsa',
                'flamenco'
              ]}
              validate={required}
              component={TextField} />
          </div>

          <Field
            label="Tracks"
            type="text"
            name="tracks"
            placeholder="A specific room at the venue."
            validate={required}
            component={TextField} />

          <Field
            min={new Date(m.format())}
            name={`start_date`}
            label="Start Date and Time"
            formatDate="MMM DD YYYY HH:mm"
            placeholder={friendlyDate(new Date(m.format()), "MMM DD YYYY HH:mm")}
            validate={required}
            showTime={true}
            date={true}
            component={DateTimePickerField} />

          <Field
            min={new Date(m.format())}
            name={`end_date`}
            label="End Date and Time"
            formatDate="MMM DD YYYY HH:mm"
            placeholder={friendlyDate(new Date(m.format()), "MMM DD YYYY HH:mm")}
            validate={required}
            showTime={true}
            date={true}
            component={DateTimePickerField} />

          <Field
            label="Description"
            name={`desc`}
            placeholder="3 nights of astonishing performances."
            className="flex-grow-1"
            validate={required}
            component={TextAreaField} />
          <div className="flex flex-row pullRight marginTop--small">
            <ButtonStyled className="button--blue">Add New Session</ButtonStyled>
          </div>
        </div>
      </FormStyle>
    )
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { createSession }),
  reduxForm({ form: 'createSession' })
)(SessionCreate);