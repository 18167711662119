import React from 'react';
import styled from 'styled-components';

const ImgUploadPreviewStyled = styled.div`
/* even more control with max-width */
  width: 64px;
  height: 64px;
  border-radius: 8px;

  & img {
    height: auto;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    object-fit: cover;
  }
`

const ImagePreview = ({ children, className }) =>
  <ImgUploadPreviewStyled className={className}>
    {children}
  </ImgUploadPreviewStyled>

export default ImagePreview;
