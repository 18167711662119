import axios from 'axios';
import {
  GET_ERRORS,
  GET_INSTRUCTORS,
  GET_EVENT,
  INSTRUCTOR_LOADING,
  DELETE_INSTRUCTOR
} from './types'

export const createInstructor = (formValues, callback) => async dispatch => {
  try {
    dispatch(setInstructorLoading());
    if (localStorage.getItem("image") !== null) formValues.image = localStorage.getItem('image');
    const res = await axios.post(`/api/instructor/create`, formValues);
    if (localStorage.getItem("image") !== null) localStorage.removeItem('image');
    dispatch({
      type: GET_INSTRUCTORS,
      payload: res.data
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const updateInstructor = (formValues, callback) => async dispatch => {
  try {

    if (localStorage.getItem("image") !== null) {
      formValues.image = localStorage.getItem('image');
    }

    const res = await axios.post(`/api/instructor/update`, formValues);

    if (localStorage.getItem("image") !== null) {
      localStorage.removeItem('image');
    }

    dispatch({
      type: GET_INSTRUCTORS,
      payload: res.data
    });

    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const addToEvent = (eventID, formValues, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/instructor/event/${eventID}`, formValues);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    callback({ success: "Successfully updated!" });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

// export const fetchInstructor = (id) => async dispatch => {
//   try {
//     const res = await axios.get(`/api/instructor/edit/${id}`);
//     dispatch({
//       type: GET_INSTRUCTOR,
//       payload: res.data
//     });
//   } catch (err) {
//     dispatch({
//       type: GET_ERRORS,
//       payload: err.response.data
//     });
//   }
// };

export const deleteInstructor = (instructorID, callback) => async dispatch => {
  try {
    await axios.delete(`/api/instructor/remove/${instructorID}`);
    dispatch({
      type: DELETE_INSTRUCTOR,
      payload: instructorID
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err
    })
  }
};

export const getInstructors = () => async dispatch => {
  try {
    dispatch(setInstructorLoading());
    const res = await axios.get(`/api/instructor/all`);
    dispatch({
      type: GET_INSTRUCTORS,
      payload: res.data
    });
    // callback(res.data.slug);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};


// Set loading state
export const setInstructorLoading = () => {
  return {
    type: INSTRUCTOR_LOADING
  };
};