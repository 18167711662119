import React from 'react'
import CheckoutFields from './CheckoutFields';
import CheckoutSubmit from './CheckoutSubmit';

const CheckoutForm = ({ 
  event,
  order,
  touched,
  orderForm,
  errors,
  formIsDisabled,
  authDiscountCode,
  _handleUserInput,
  _handleBlur,
  _previousPage,
  _handleToken
}) =>
  <React.Fragment>
    <CheckoutFields
      event={event}
      errors={errors}
      touched={touched}
      orderForm={orderForm}
      _handleUserInput={_handleUserInput}
      _handleBlur={_handleBlur} />
    <CheckoutSubmit
      event={event}
      order={order}
      orderForm={orderForm}
      formIsDisabled={formIsDisabled}
      authDiscountCode={authDiscountCode}
      _previousPage={_previousPage}
      _handleToken={_handleToken} />
  </React.Fragment>

export default CheckoutForm;