import React, { useState } from 'react';
import styled from 'styled-components';
import { FiArrowRight } from "react-icons/fi";

import { media, ButtonStyledLink } from '../../../styles'
import { FormStyle } from '../../../styles';
import { formatPrice } from '../../../helpers';
import Graph from './graph';

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
    &.large {
      text-align: center;
    }
  `}
  &.large {
    font-size: 56px;
    font-weight: 600;
    line-height: 79px;
    margin-bottom: 10px;
  }
`

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
  &.large {
    margin-bottom: 8px;
    ${media.handheld`
      text-align: center;
    `}
  }
`

const Text = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1rem;
  line-height: 32px;
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 50px;
  ${media.handheld`
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 450px 450px;
  grid-column-gap: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
  .item {
    display: flex;
    flex-direction: column;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const FormGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-template-rows: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  max-width: 210px;
  ${media.handheld`
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  `}
`

const Block = styled.div`
  /* Rectangle 24: */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #EFEFEF;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 10px;
  &.highlight {
    background: #EDF4FF;
  }
`

const StyledDiv = styled.div`
  background: #FFFFFF;
  box-shadow: 0 3px 12px 0 rgba(0,0,0,0.12), 0 -1px 1px 0 rgba(0,0,0,0.12);
  border-radius: 8px;
  margin-bottom: 24px;
  .header-block {
    padding: 16px 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #000000;
    p {
      color: white;
    }
  }
`

const Comparison = () => {
  const [ticketFee, setTicketFee] = useState(200);
  const [attendees, setAttendees] = useState(600);
  const [selectedPlan, setSelectedPlan] = useState("option1");

  const calculateStripe = () => {
    // stripe percent = ticket price * 2.9% stripe fee
    const stripePercent = ticketFee * 0.029;
    // stripe perTransaction  = ticket + 30¢
    const stripeFee = 0.3;
    // stripe fee = sPercent + sPerTransaction
    const stripeFeeTotal = stripePercent + stripeFee;
    // attndnce fee = ticket price * 2%
    const platformFee = 0.65;
    // platform fee = stripe fee + attndnce fee
    const feeTotal = stripeFeeTotal + platformFee;
    // total ticket fee = platform fee * total Attendees
    return ((feeTotal * attendees) + "").split(".")[0];
  }

  const calculateEventbrite = () => {
    // eventbrite percent = ticket price * 3.5% eventbrite fee
    const eventbritePercent = ticketFee * 0.035;
    // eventbrite perTransaction  = ticket + 1.59
    const eventbriteFee = 1.59;
    // eventbrite fee = sPercent + sPerTransaction
    const eventbriteFeeTotal = eventbritePercent + eventbriteFee;
    // attndnce fee = ticket price * 2.5%
    const processFee = ticketFee * 0.025;
    // platform fee = eventbrite fee + attndnce fee
    const feeTotal = eventbriteFeeTotal + processFee;
    // total ticket fee = platform fee * total Attendees
    return ((feeTotal * attendees) + "").split(".")[0];
  }

  return (
    <React.Fragment>
      <GridLayout>
        <Title className="text-center">Switching can save you thousands</Title>
        <SubTitle className="text-center">
          See how much money you will save when switching from Eventbrite.
      </SubTitle>
      </GridLayout>
      <GridColumns>
        <div className="item">
          <div>
            <StyledDiv>
              <div className="header-block">
                <p>Calculate your potential earnings</p>
              </div>
              <div className="padding--small">
                <div className="marginBottom--medium paddingBottom--medium border-bottom">
                  <Text>Comparison based on Eventbrite’s Professional package and include Eventbrite’s Payment Processing.</Text>
                </div>

                <FormStyle>
                  <p className="marginBottom--small text-bold">Select Pricing Plan</p>
                  <div className="flex flex-row flex-align-center flex-justify-around marginBottom--medium padding--small background-white border rounded-m">
                    <label className="flex flex-row flex-align-center">
                      <input style={{ marginTop: "0", marginBottom: "2px", marginRight: "10px" }}
                        type="radio"
                        value="option1"
                        checked={selectedPlan === 'option1'}
                        onChange={(e) => { setSelectedPlan(e.target.value) }} />
                      Pay As You Go
                      </label>

                    <label className="flex flex-row flex-align-center">
                      <input type="radio" value="option2"
                        checked={selectedPlan === 'option2'}
                        style={{ marginTop: "0", marginBottom: "2px", marginRight: "10px" }}
                        onChange={(e) => { setSelectedPlan(e.target.value) }} />
                      Free Fee
                      </label>
                  </div>

                  <FormGridLayout>
                    <label>Ticket Price</label>
                    <input type="number" style={{ textAlign: "right" }} value={ticketFee}
                      onChange={(e) => { setTicketFee(e.target.value) }} />
                  </FormGridLayout>

                  <FormGridLayout>
                    <label>Number of Guests</label>
                    <input type="number" style={{ textAlign: "right" }} value={attendees}
                      onChange={(e) => { setAttendees(e.target.value) }} />
                  </FormGridLayout>

                </FormStyle>
              </div>
            </StyledDiv>


            <Block>
              <p className="text-bold">Attndnce + CC Fees</p>
              <p className="text-number">{selectedPlan === "option1" ? `$${calculateStripe()}.00` : "$300.00"}</p>
            </Block>

            <Block>
              <p className="text-bold">Eventbrite + CC Fees</p>
              <p className="text-number">${calculateEventbrite() + ".00"}</p>
            </Block>

            <Block className="highlight">
              <p className="text-bold">Save <span className="text-number text-bold">{Math.floor((((calculateEventbrite() - (selectedPlan === "option1" ? calculateStripe() : 300)) / calculateEventbrite()) * 100))}%</span> more in Fees</p>
              {Math.floor((((calculateEventbrite() - (selectedPlan === "option1" ? calculateStripe() : 300)) / calculateEventbrite()) * 100)) < 0 ? <p className="text-number">0</p> :
                <p className="text-number"><span className="text-blue">${calculateEventbrite() - (selectedPlan === "option1" ? calculateStripe() : 300) + ".00"}</span></p>
              }
            </Block>

          </div>
        </div>
        <div className="item">
          <SubTitle className="large">Your estimated payout with Attndnce</SubTitle>
          {Math.floor((((calculateEventbrite() - (selectedPlan === "option1" ? calculateStripe() : 300)) / calculateEventbrite()) * 100)) < 0 ? <Title className="marginBottom--large text-bold text-center">Best to select Pay As You Go</Title> :
            <Title className="marginBottom--large text-bold text-number large">
              {selectedPlan === "option1" ? formatPrice(((ticketFee * attendees) - (calculateStripe())) * 100, "USD", "en-US") : formatPrice(((ticketFee * attendees * 100) - 30000), "USD", "en-US")}
            </Title>}
          <ButtonContainer>
            <ButtonStyledLink to="/sign-up" className="button--blue">
              <span>Signup For Free</span>
              <FiArrowRight size="24px" className="marginRight--small" />
            </ButtonStyledLink>
          </ButtonContainer>
        </div>

      </GridColumns>
      <Graph selectedPlan={selectedPlan} _calculateStripe={calculateStripe} _calculateEventbrite={calculateEventbrite} />
    </React.Fragment>
  );
}

export default Comparison;