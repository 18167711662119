import moment from 'moment';

export const startOf = (momentDate, type) => {
  return moment(momentDate).startOf(type).format()
};

export const endOf = (momentDate, type) => {
  return moment(momentDate).endOf(type).format()
};

export const subtractDay = (number) => {
  return moment().subtract(number, 'day')
};

