import axios from 'axios';
import setAuthToken from '../components/utilities/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
  SET_CURRENT_USER,
  GET_ERRORS
} from './types';

import { clearStripe } from './billingActions';
import { clearErrors } from './';

export const signup = (formProps, urlParams = {}, callback) => async dispatch => {
  try {
    formProps.urlParams = urlParams;
    formProps.language = window.navigator.language;

    const res = await axios.post(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/signup` : '/auth/signup', formProps, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      }
    });

    const { token, message } = res.data;

    dispatch({
      type: SET_CURRENT_USER,
      payload: token
    });
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(decoded));
    dispatch(clearErrors());
    callback(message);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const login = (formProps, urlParams = {}, callback) => async dispatch => {
  try {
    formProps.urlParams = urlParams;
    // let data = JSON.stringify({
    //   email: formProps.email,
    //   password: formProps.password,
    //   urlParams: urlParams
    // })
    console.log('formProps', formProps)
    const res = await axios.post(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/login` : '/auth/login', formProps, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
    console.log('res', res)
    // const res = await axios.post(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/login` : '/auth/login', formProps, {
    //   method: 'POST',
    //   mode: 'no-cors',
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json',
    //   }
    // });

    const { token, message } = res.data;
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(decoded));
    dispatch(clearErrors());
    callback(message);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
}

// Log user out
export const signout = () => dispatch => {
  // Remove token from locaStorage
  localStorage.removeItem('token');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch(clearStripe());
};

// Update current user first and last name
export const updateAccount = (formValues, callback) => async dispatch => {
  try {
    const res = await axios.post(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/update` : '/auth/update', formValues);
    const { token } = res.data;
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(decoded));
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const addMailchimpToUser = (authToken, callback) => async dispatch => {
  try {
    const decoded = await jwt_decode(authToken);
    const res = await axios.post(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/mailchimp/update-user` : '/auth/mailchimp/update-user', decoded);
    const { token, message } = res.data;
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const reDecoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(reDecoded));
    callback(message);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

// Update current user password
export const changePassword = (formValues, callback) => async dispatch => {
  try {
    await axios.post(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/change-password` : '/auth/change-password', formValues);
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const confirmEmail = (userID, callback) => async dispatch => {
  try {
    const res = await axios.get(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/email/confirm/${userID}` : `/auth/email/confirm/${userID}`);
    const { token } = res.data;
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(decoded));
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

export const resendConfirmationEmail = callback => async dispatch => {
  try {
    const res = await axios.get(process.env.NODE_ENV === "production" ? `https://blooming-brushlands-74914.herokuapp.com/auth/email/resend/confirm` : `/auth/email/resend/confirm`);
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}