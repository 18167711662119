import React from 'react';
import styled from 'styled-components';

import { StyledCard, media } from '../../../styles/';

import * as custom from '../../../styles/Variables';

import { truncate, friendlyDate } from '../../../helpers';

const ListItemStyled = styled.div`
  border-bottom: 1px solid ${custom.COLOR_BORDER};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: none;
  }
`

const SessionList = ({ sessions, handleModalSwitch }) => {
  if (sessions.length < 1) {
    return (
      <div style={{ height: "100vh" }} className="flex flex-column flex-align-center flex-justify-center">
        <p>No sessions found.</p>
      </div>
    )
  }
  return (sessions || []).map(session => {
    return (
      <ListItemStyled
        key={session._id}
        className="flex flex-row flex-align-center flex-justify-between marginBottom--small padding--small background-white border rounded-m shadow"
        data-modal="view"
        style={{ cursor: "pointer" }}
        onClick={(e) => handleModalSwitch(e, session)}>


        <div className="flex flex-column">
          <p>{truncate(session.name, 49)}</p>
          <p className="marginRight--xSmall text-small">{`${friendlyDate(session.start_date, "MMM DD")} - ${friendlyDate(session.end_date, "MMM DD, YYYY")}`}</p>
          <p className="text-small">From {`${friendlyDate(session.start_date, 'h:mm A')} to ${friendlyDate(session.end_date, 'h:mm A')}`}</p>
        </div>
        <div className="flex flex-row">
          {(session.tracks || []).map((tr, index) => {
            return (
              <div key={index} className="marginRight--xSmall padding--xxSmall border rounded-m">
                <p className="text-small">{tr}</p>
              </div>
            )
          })}
          <div className="padding--xSmall paddingTop--xxSmall paddingBottom--xxSmall border rounded-m">
            <p className="text-small">{session.session_type}</p>
          </div>
        </div>

      </ListItemStyled>
    )
  })
}

export default SessionList;