import React from 'react';
import styled from 'styled-components';
import { FiEdit, FiTrash } from 'react-icons/fi';

import isEmpty from '../../validation/isEmpty';
import IconFilled from '../utilities/IconFilled';
import { media } from '../../styles';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
`

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5eafa;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  ${media.handheld`
    justify-content: space-between
    > p {
      font-size: 12px;
    }
    div > * {
      width: 24px;
      height: 24px;
    }
    div > * > svg {
      width: 16px;
      height: 16px;
    }
  `}
`


const DiscountList = ({ handleViewChange, discounts }) => {
  if (discounts.length < 1) {
    return (
      <div style={{ height: "100vh" }}>
        <p>No discounts found.</p>
      </div>
    )
  }
  return (
    <div className="flex flex-column marginBottom--small padding--small background-white border rounded-m shadow">
      <GridLayout className="marginBottom--small paddingBottom--small border-bottom">
        <p className="text-grey-blue">Code</p>
        <p className="text-grey-blue">Amount</p>
        <p className="text-grey-blue">Quantity</p>
        <p className="text-grey-blue">Actions</p>
      </GridLayout>
      {(discounts || []).map(discount =>
        <GridRow key={discount.code}>
          <p>{discount.code}</p>
          {discount.amount_off ?
            <p>{!isEmpty(discount.amount_off) && '$'}{discount.amount_off}</p>
            :
            <p>{discount.percent_off}{!isEmpty(discount.percent_off) && '%'}</p>
          }
          <p>{discount.quantity_available > 0 ? `${discount.quantity_sold > 0 ? discount.quantity_sold : 0}/${discount.quantity_available}` : "∞"}</p>
          <div className="flex flex-row">
            <IconFilled
              className="marginRight--small"
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="edit"
              onClick={(e) => handleViewChange(e, discount)}>
              <FiEdit size="1rem" />
            </IconFilled>
            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="remove" 
              onClick={(e) => handleViewChange(e, discount)}>
              <FiTrash size="1rem" />
            </IconFilled>
          </div>
        </GridRow>
      )}
    </div>
  )
}

export default DiscountList;