import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import FontAwesome from 'react-fontawesome';

import { getDiscount, editDiscount } from '../../actions/discountActions';

import TextField from '../utilities/formfields/TextField';
import TextAreaField from '../utilities/formfields/TextAreaField';
import DateTimePickerField from '../utilities/formfields/DateTimePickerField';
import DropDownListField from '../utilities/formfields/DropDownField';

import { required } from '../../helpers/formValdation';

import { FormStyle, ButtonStyled } from '../../styles';

import isEmpty from '../../validation/isEmpty';

class DiscountEdit extends Component {
  state = {
    errors: {},
    formValue: {},
    loading: false
  }

  componentDidMount() {
    const { discountID } = this.props;
    this.props.getDiscount(discountID, (formValue) => {
      this.props.initialize({
        ...formValue,
        quantity_available: formValue.quantity_available > 0 ? formValue.quantity_available : null,
        is_unlimited: { value: isEmpty(formValue.quantity_available) ? true : false },
        does_expire: { value: !isEmpty(formValue.expire_date) ? true : false }
      })
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors, loading: false });
    }
  }

  handleClearAmount = () => {
    this.props.updateField('amount_off', '');
  }

  handleClearPercent = () => {
    this.props.updateField('percent_off', '');
  }

  handleClearQty = () => {
    if (this.props.isUnlimited) {
      this.props.updateField('quantity_available', '');
    }
  }

  beforeSubmit = (formValues) => {
    this.props.editDiscount(formValues, () => this.props.handleClearState());
  }

  render() {
    const {
      handleSubmit,
      amount_off,
      percent_off,
      is_unlimited,
      does_expire,
      quantity_available,
      quantity_sold
    } = this.props;

    const { errors, loading } = this.state;

    return (
      <div className="flex flex-column marginBottom--small padding--medium background-white border rounded-m shadow">

        <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
          <Field
            label="Code"
            type="text"
            name="code"
            placeholder="E.g. Members, Child, Senior, Military etc."
            error={errors.code}
            validate={required}
            component={TextField} />

          <div className="flex flex-grid-half flex-align-center flex-justify-between">
            <Field
              label="Amount"
              type="text"
              name="amount_off"
              placeholder="$10"
              className="col marginRight--small"
              onChange={this.handleClearPercent}
              error={errors.amount_off}
              validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : null}
              component={TextField} />

            <Field
              label="Percent"
              type="text"
              name="percent_off"
              placeholder="45%"
              className="col"
              onChange={this.handleClearAmount}
              error={errors.percent_off || percent_off > 100 && "Has to be less than or equal to 100%"}
              validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : null}
              component={TextField} />
          </div>

          <label className="marginTop--small">Uses</label>
          <div className="flex flex-grid-half flex-justify-between">
            <Field
              type="text"
              name="is_unlimited"
              placeholder="Please select use amount"
              className="col"
              valueField="value"
              textField="name"
              data={[{ name: 'limited to', value: false }, { name: 'unlimited', value: true }]}
              error={errors.limit}
              validate={required}
              disabled={quantity_sold > 0}
              onChange={this.handleClearQty}
              component={DropDownListField} />
            {!is_unlimited &&
              <Field
                type="text"
                name="quantity_available"
                placeholder="50"
                className="col"
                validate={isEmpty(quantity_available) && !is_unlimited ? required : null}
                disabled={quantity_sold > 0}
                error={errors.quantity_available}
                component={TextField} />
            }
          </div>
          <div className="marginTop--small marginBottom--small padding--small border-blue rounded-m background-lightBlue">
          {quantity_sold > 0 ? 
            <p>Discount code has been applied during checkout. At the moment, it is not possible to make changes to the fields above.</p> :
            <p>Changes to the field above will not be possible after the discount code has been applied to a purchase.</p>
          }
          </div>
          <label>Expires on</label>
          <div className="flex flex-grid-half flex-justify-between">
            <Field
              type="text"
              name="does_expire"
              placeholder="Please select expiration type"
              className="col"
              valueField="value"
              textField="name"
              data={[{
                name: 'Does not expire',
                value: false
              }, {
                name: 'Expires on specific date',
                value: true
              }]}
              validate={required}
              error={errors.does_expire}
              component={DropDownListField} />
            {does_expire &&
              <Field
                type="text"
                name="expire_date"
                placeholder="10/03/2019"
                className="col"
                validate={required}
                error={errors.expire_date}
                component={DateTimePickerField} />
            }
          </div>

          <Field
            label="Description"
            type="text"
            name="desc"
            placeholder="10% discount will be applied during checkout"
            error={errors.desc}
            validate={required}
            component={TextAreaField} />

          <div className="flex flex-row flex-align-center marginBottom--small">
            <Field
              name="is_active"
              type="checkbox"
              component="input" />
            <p className="marginRight--xSmall">Active</p>
          </div>
          <div className="flex flex-row marginTop--small pullRight">
            <ButtonStyled disabled={loading} className="flex flex-row flex-align-center pullRight button--blue">
              {loading && <FontAwesome spin name="spinner" className="marginRight--small text-white" />}
              Update Discount
            </ButtonStyled>
          </div>

        </FormStyle>
      </div>
    )

  }
}

const selector = formValueSelector('editDiscount');

const mapStateToProps = state => {
  return {
    errors: state.errors,
    discount: state.discount,
    code: selector(state, 'code'),
    amount_off: selector(state, 'amount_off'),
    percent_off: selector(state, 'percent_off'),
    is_unlimited: selector(state, 'is_unlimited.value'),
    does_expire: selector(state, 'does_expire.value'),
    quantity_available: selector(state, 'quantity_available'),
    quantity_sold: selector(state, 'quantity_sold')
  }
}

export default compose(
  connect(mapStateToProps, {
    getDiscount,
    editDiscount,
    updateField: (field, newValue) => change('editDiscount', field, newValue)
  }),
  reduxForm({
    form: 'editDiscount',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
)(DiscountEdit);