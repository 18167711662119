import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TiSocialFacebook } from "react-icons/ti";
import { FiInstagram } from "react-icons/fi";

import Logo from '../components/utilities/Logo';

import { FlexContainer, media } from '../styles';


const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 16px;
  box-shadow: 0 50vh 0 50vh #f7fafc;

  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    justify-items: center;
    padding: 50px 0 0 0;
    margin-right: 24px;
    margin-left: 24px;
    margin-bottom: 110px;
    
  `}
  .grid-child {
    width: 100%;
    ${media.handheld`
      margin-right: 24px;
      margin-left: 24px;
    `}
  }
  .grid-child:last-child {
    width: auto;
    margin-left: auto;
    ${media.handheld`
      width: 100%;
    `}
  }
`

const Text = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  color: #262626;
  line-height: 40px;
  ${media.handheld`
    font-size: 16px;
    line-height: 32px;
  `}
`

const LinkStyled = styled(Link)`
  display: block;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  margin-left: 0 auto;
  ${media.handheld`
    margin-left: 0;
    font-size: 16px;
    line-height: 32px;
  `}
`

const Footer = () =>
  <FlexContainer>
    <GridLayout>
      <div className="grid-child">
        <Link to={`/?ref=nav_logo`}>
          <Logo />
        </Link>
        <div className="marginBottom--large">
          <Text className="marginTop--small text-grey-blue">© 2020 Attndnce, Inc. All Rights Reserved.</Text>
          <Text className="text-grey-blue">Made with ♥︎ in CA</Text>
        </div>
        <div className="flex flex-row flex-align-center">
          <a href={`http://www.facebook.com/attndnce`}>
            <TiSocialFacebook className="marginRight--small" style={{ width: "32px", height: "32px" }} />
          </a>
          <a href={`http://www.instagram.com/attndnce`}>
            <FiInstagram style={{ width: "32px", height: "32px" }} />
          </a>
        </div>
      </div>

      <div className="grid-child flex flex-row flex-align-center">
        <LinkStyled to={`/pricing`} className="marginRight--xSmall">Pricing</LinkStyled> |
        <LinkStyled to={`/features`} className="marginRight--xSmall marginLeft--xSmall">Features</LinkStyled> |
        <LinkStyled to="/tos" className="marginRight--xSmall marginLeft--xSmall">Terms</LinkStyled> |
        <LinkStyled to="/privacy" className="marginLeft--xSmall">Privacy</LinkStyled>
      </div>
    </GridLayout>
  </FlexContainer>

export default Footer;