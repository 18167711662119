export default {
  "AU": {
    "name": "Australia",
    "native": "Australia",
    "phone": "61",
    "continent": "OC",
    "capital": "Canberra",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "AT": {
    "name": "Austria",
    "native": "Österreich",
    "phone": "43",
    "continent": "EU",
    "capital": "Vienna",
    "currency": "EUR",
    "languages": [
      "de"
    ]
  },
  "BE": {
    "name": "Belgium",
    "native": "België",
    "phone": "32",
    "continent": "EU",
    "capital": "Brussels",
    "currency": "EUR",
    "languages": [
      "nl",
      "fr",
      "de"
    ]
  },
  "CA": {
    "name": "Canada",
    "native": "Canada",
    "phone": "1",
    "continent": "NA",
    "capital": "Ottawa",
    "currency": "CAD",
    "languages": [
      "en",
      "fr"
    ]
  },
  "DK": {
    "name": "Denmark",
    "native": "Danmark",
    "phone": "45",
    "continent": "EU",
    "capital": "Copenhagen",
    "currency": "DKK",
    "languages": [
      "da"
    ]
  },
  "FI": {
    "name": "Finland",
    "native": "Suomi",
    "phone": "358",
    "continent": "EU",
    "capital": "Helsinki",
    "currency": "EUR",
    "languages": [
      "fi",
      "sv"
    ]
  },
  "FR": {
    "name": "France",
    "native": "France",
    "phone": "33",
    "continent": "EU",
    "capital": "Paris",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "DE": {
    "name": "Germany",
    "native": "Deutschland",
    "phone": "49",
    "continent": "EU",
    "capital": "Berlin",
    "currency": "EUR",
    "languages": [
      "de"
    ]
  },
  "HK": {
    "name": "Hong Kong",
    "native": "香港",
    "phone": "852",
    "continent": "AS",
    "capital": "City of Victoria",
    "currency": "HKD",
    "languages": [
      "zh",
      "en"
    ]
  },
  "IE": {
    "name": "Ireland",
    "native": "Éire",
    "phone": "353",
    "continent": "EU",
    "capital": "Dublin",
    "currency": "EUR",
    "languages": [
      "ga",
      "en"
    ]
  },
  "IT": {
    "name": "Italy",
    "native": "Italia",
    "phone": "39",
    "continent": "EU",
    "capital": "Rome",
    "currency": "EUR",
    "languages": [
      "it"
    ]
  },
  "JP": {
    "name": "Japan",
    "native": "日本",
    "phone": "81",
    "continent": "AS",
    "capital": "Tokyo",
    "currency": "JPY",
    "languages": [
      "ja"
    ]
  },
  "LU": {
    "name": "Luxembourg",
    "native": "Luxembourg",
    "phone": "352",
    "continent": "EU",
    "capital": "Luxembourg",
    "currency": "EUR",
    "languages": [
      "fr",
      "de",
      "lb"
    ]
  },
  "NL": {
    "name": "Netherlands",
    "native": "Nederland",
    "phone": "31",
    "continent": "EU",
    "capital": "Amsterdam",
    "currency": "EUR",
    "languages": [
      "nl"
    ]
  },
  "NZ": {
    "name": "New Zealand",
    "native": "New Zealand",
    "phone": "64",
    "continent": "OC",
    "capital": "Wellington",
    "currency": "NZD",
    "languages": [
      "en",
      "mi"
    ]
  },
  "NO": {
    "name": "Norway",
    "native": "Norge",
    "phone": "47",
    "continent": "EU",
    "capital": "Oslo",
    "currency": "NOK",
    "languages": [
      "no",
      "nb",
      "nn"
    ]
  },
  "PT": {
    "name": "Portugal",
    "native": "Portugal",
    "phone": "351",
    "continent": "EU",
    "capital": "Lisbon",
    "currency": "EUR",
    "languages": [
      "pt"
    ]
  },
  "SG": {
    "name": "Singapore",
    "native": "Singapore",
    "phone": "65",
    "continent": "AS",
    "capital": "Singapore",
    "currency": "SGD",
    "languages": [
      "en",
      "ms",
      "ta",
      "zh"
    ]
  },
  "ES": {
    "name": "Spain",
    "native": "España",
    "phone": "34",
    "continent": "EU",
    "capital": "Madrid",
    "currency": "EUR",
    "languages": [
      "es",
      "eu",
      "ca",
      "gl",
      "oc"
    ]
  },
  "SE": {
    "name": "Sweden",
    "native": "Sverige",
    "phone": "46",
    "continent": "EU",
    "capital": "Stockholm",
    "currency": "SEK",
    "languages": [
      "sv"
    ]
  },
  "CH": {
    "name": "Switzerland",
    "native": "Schweiz",
    "phone": "41",
    "continent": "EU",
    "capital": "Bern",
    "currency": "CHE,CHF,CHW",
    "languages": [
      "de",
      "fr",
      "it"
    ]
  },
  "GB": {
    "name": "United Kingdom",
    "native": "United Kingdom",
    "phone": "44",
    "continent": "EU",
    "capital": "London",
    "currency": "GBP",
    "languages": [
      "en"
    ]
  },
  "US": {
    "name": "United States",
    "native": "United States",
    "phone": "1",
    "continent": "NA",
    "capital": "Washington D.C.",
    "currency": "USD,USN,USS",
    "languages": [
      "en"
    ]
  }
}
