import React from 'react';
import styled from 'styled-components';

import { friendlyDate } from '../../helpers';

const GridColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`

const NoTicketSale = ({event}) =>
  <React.Fragment>
    <div className="header">
      <h3 className="marginBottom--small paddingBottom--small border-bottom">Choose your tickets</h3>
      <GridColumns className="paddingBottom--small border-bottom">
        <p>Event Date</p>
        <div className="text-right pullRight">
          <p>{`${friendlyDate(event.start_date, "MMM DD")} - ${friendlyDate(event.end_date, "MMM DD, YYYY")}`}</p>
          <p className="text-small">From {`${friendlyDate(event.start_date, "h:mm A")} to ${friendlyDate(event.end_date, "h:mm A")}`}</p>
        </div>
      </GridColumns>
    </div>
    <div className="content">
      <p>Ticket sales has ended for this event.</p>
    </div>
  </React.Fragment>

export default NoTicketSale;