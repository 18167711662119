import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";


const Headline = styled.div`
  h2 {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 30px;
    color: #4D4D4D;
    line-height: 40px;
  }
`
const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 960px;
  margin: 50px auto;
  ${media.handheld`
    margin-left: 24px;
    margin-right: 24px;
  `}
`

const ButtonLayout = styled.div`
  display: grid;
  grid-template-columns: 24px 24px;
  grid-column-gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px auto;

  .rounded {
    width: 24px;
    height: 24px;
    padding: 0;
    background: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }
`

const Thumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background: black;
  border-radius: 8px;
  height: 170px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.05);
      z-index: 300;
    }
  }
  
  h3 {
    font-family: "Poppins";
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 26px;
  }
  p {
    font-family: Roboto-Regular;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
  }
`

const ImagesLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-template-rows: 170px;
  width: 100%;
  transition: transform 800ms ease-in-out;
  ${media.handheld`
    grid-template-rows: auto;
  `}
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #333333;
  border-radius: 10px;
`

const Artists = ({ artists }) => {
  const [images, setImages] = useState([]);
  const [currentImageIdx, setCurrentImagIdx] = useState(0);

  const prevSlide = () => {
    // find out whether currentImageIdx eqals 0 and thus user reached beginning of carousel
    const resetToVeryBack = currentImageIdx === 0;

    const index = resetToVeryBack ? images.length - 1 : currentImageIdx - 1;

    // assign the logical index to current image index that will be used in render method
    setCurrentImagIdx(index);
  };

  const nextSlide = () => {
    // check if we need to start over from the first index
    const resetIndex = currentImageIdx === images.length - 1;

    const index = resetIndex ? 0 : currentImageIdx + 1;

    // assign the logical index to current image index that will be used in render method
    setCurrentImagIdx(index);
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    setImages(artists);
  }, [artists])

  // create a new array with 3 elements from the source images
  const activeImageSourcesFromState = images.slice(currentImageIdx, currentImageIdx + images.length); // images.length originally === 3

  // check the length of the new array (it’s less than 3 when index is at the end of the imagge sources array)
  const imageSourcesToDisplay = activeImageSourcesFromState.length < images.length // images.length originally === 3
    // if the imageSourcesToDisplay's length is lower than 3 images than append missing images from the beginning of the original array
    ? [...activeImageSourcesFromState, ...images.slice(0, images.length - activeImageSourcesFromState.length)]
    : activeImageSourcesFromState;

  const renderImages = (images) => {
    return images.map((image, index) =>
      <Thumbnail key={index}>
        <div style={{ position: "absolute", left: "16px", bottom: "16px" }}>
          <h3>{image.name}</h3>
          <p>{image.country}</p>
        </div>
        <Image src={image.large_image && `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${image.large_image}.jpg`} alt="" />
      </Thumbnail>
    )
  }

  return (
    <GridLayout>
      <Headline>
        <h2>Artists and DJs</h2>
        <p style={{ marginBottom: "10px" }}>Bachata is a type of latin dance and.</p>
      </Headline>

      <ImagesLayout>
        {renderImages(imageSourcesToDisplay)}
      </ImagesLayout>
      <ButtonLayout>
        <button className="rounded shadow-s" onClick={prevSlide}><FiChevronLeft size="16px" /></button>
        <button className="rounded shadow-s" onClick={nextSlide}><FiChevronRight size="16px" /></button>
      </ButtonLayout>
    </GridLayout>
  )
}

export default Artists;