import React, { useState } from 'react';
import styled from 'styled-components';
import { FiLayout, FiCalendar, FiUsers } from "react-icons/fi";

import { media } from '../../../styles';
import Event from "../images/scheduling.png";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
  `}
`

const SubTitle = styled.div`
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  align-items: center;
  padding: 24px;
  margin-bottom: 10px;
  background: #FAFAFA;
  border-radius: 8px;

  &.highlight {
    background: #E5F1FA;
  }
`

const ListItemHeadline = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 16px;
  align-items: center;
  p {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
  }
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 75px;
  align-items: center;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const SectionOne = () => {
  const [active, setActive] = useState("scheduling");

  const toggleClass = (clicked) => {
    setActive(active === clicked ? null : clicked)
  }

  return (
    <GridLayout>
      <div>
        <img src={Event} className="marginBottom--large" alt="" />
      </div>
      <div>
        <Title>Organize Your Events</Title>
        <SubTitle>Manage all your events in one place. Attndnce provides all the necessary tools to host and sell eTickets for your event.</SubTitle>
        <ul>
          <ListItem className={active === "beautiful-page" && "highlight"}>
            <ListItemHeadline onClick={() => toggleClass("beautiful-page")}>
              <FiLayout size="24px" />
              <p>Beautiful Event Landing Page</p>
            </ListItemHeadline>
            {active === "beautiful-page" ?
              <p className="marginTop--xSmall">Everything event-goers need to know all in one place, so they can read up, decide, and purchase.</p>
              :
              <div></div>
            }
          </ListItem>
          <ListItem className={active === "scheduling" && "highlight"}>
            <ListItemHeadline onClick={() => toggleClass("scheduling")}>
              <FiCalendar size="24px" />
              <p>Workshop Scheduling</p>
            </ListItemHeadline>
            {active === "scheduling" ?
              <p className="marginTop--xSmall">Easily create, share, and view your event's workshop class schedules on desktop and mobile.</p>
              :
              <div></div>
            }
          </ListItem>
          <ListItem className={active === "guest-list" && "highlight"}>
            <ListItemHeadline onClick={() => toggleClass("guest-list")}>
              <FiUsers size="24px" />
              <p>Print-out Guest List</p>
            </ListItemHeadline>
            {active === "guest-list" ?
              <p className="marginTop--xSmall">Take your Guest list on the road via the mobile app or export them into Google or Microsoft spreadsheets.</p>
              :
              <div></div>
            }
          </ListItem>
        </ul>
      </div>
    </GridLayout>

  )
}
export default SectionOne;