import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { createTax } from '../../../actions/taxActions';

import TextField from '../../utilities/formfields/TextField';

import Spinner from '../../utilities/Spinner';

import { required } from '../../../helpers/formValdation';

import { ButtonStyled, FormStyle } from '../../../styles';

class TaxCreate extends React.Component {
  state = {
    errors: {},
    loading: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState } = this.props;
    this.setState({ loading: true }, () => {
      this.props.createTax(eventID, formValues, () => {
        this.setState({ loading: false }, () => {
          handleClearState();
        });
      });
    });
  }

  render() {
    const { errors, loading, moreOptions } = this.state;
    const { handleSubmit } = this.props;

    if (loading) return <Spinner />

    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <div className="padding--medium background-white border rounded-m shadow">
          <div className="flex flex-grid-thirds flex-justify-between">
            <Field
              label="Tax name"
              type="text"
              name="tax_name"
              placeholder="GST, PST"
              className="col"
              validate={required}
              component={TextField} />
            <Field
              label="Tax percent"
              type="number"
              name="tax_percent"
              placeholder="5"
              className="col"
              validate={required}
              component={TextField} />
            <Field
              label="Tax id"
              type="text"
              name="tax_id"
              placeholder="123456789"
              className="col"
              validate={required}
              component={TextField} />
          </div>
          <div className="flex flex-row pullRight marginTop--small">
            <ButtonStyled className="button--blue">Add Tax</ButtonStyled>
          </div>
        </div>
      </FormStyle>
    )
  }
}

const mapStateToProps = state => ({
  errors: state.errors
});

export default compose(
  connect(mapStateToProps, { createTax }),
  reduxForm({ form: "createTax" })
)(TaxCreate);