import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, change} from 'redux-form';

import { updateAffiliate } from '../../../actions/affiliateActions';

import TextField from '../../utilities/formfields/TextField';
import TextAreaField from '../../utilities/formfields/TextAreaField';

import Spinner from '../../utilities/Spinner';

import { required } from '../../../helpers/formValdation';
import isEmpty from '../../../validation/isEmpty';

import { ButtonStyled, FormStyle } from '../../../styles';

class AffiliateEdit extends React.Component {
  state = {
    errors: {},
    loading: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleClearAmount = () => {
    this.props.updateField('amount_off', '');
  }

  handleClearPercent = () => {
    this.props.updateField('percent_off', '');
  }

  beforeSubmit = (formValues) => {
    const { affID, handleClearState } = this.props;
    this.setState({ loading: true }, () => {
      this.props.updateAffiliate(affID, formValues, () => {
        this.setState({ loading: false }, () => {
          handleClearState();
        });
      });
    });
  }

  render() {
    const { errors, loading } = this.state;
    const { handleSubmit, amount_off, percent_off } = this.props;

    if (loading) return <Spinner />

    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>
        <Field
          label="Affiliate Name"
          type="text"
          name="affiliate_name"
          placeholder="Bangkok, earlybird, Saori21"
          className="col"
          validate={required}
          component={TextField} />
        <Field
          label="Note"
          type="text"
          name="note"
          className="col"
          validate={required}
          component={TextAreaField} />
        <div className="flex flex-grid-half flex-align-center flex-justify-between">
          <Field
            label="Amount"
            type="text"
            name="amount_off"
            placeholder="$10"
            className="col marginRight--small"
            onChange={this.handleClearPercent}
            error={errors.amount_off}
            validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : null}
            component={TextField} />

          <Field
            label="Percent"
            type="text"
            name="percent_off"
            placeholder="45%"
            className="col"
            onChange={this.handleClearAmount}
            error={errors.percent_off || percent_off > 100 && "Has to be less than or equal to 100%"}
            validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : null}
            component={TextField} />

          {/* <div className="col marginTop--small">
            <div className="flex flex-row pullRight">
              <p className="marginRight--xSmall">Percent</p>
              <Field
                name="is_percent"
                component="input"
                type="checkbox" />
            </div>
          </div> */}

        </div>

        <div className="flex flex-row pullRight marginTop--small">
          <ButtonStyled className="button--blue">Apply Changes</ButtonStyled>
        </div>
      </FormStyle>
    )
  }
}

const selector = formValueSelector('affiliateEdit');

const mapStateToProps = (state, ownProps) => ({
  errors: state.errors,
  initialValues: ownProps.aff,
  amount_off: selector(state, 'amount_off'),
  percent_off: selector(state, 'percent_off')
});

export default compose(
  connect(mapStateToProps, {
    updateAffiliate, updateField: (field, newValue) => change('affiliateEdit', field, newValue)
 }),
  reduxForm({
    form: "affiliateEdit",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })
)(AffiliateEdit);