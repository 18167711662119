import React from 'react';
import FontAwesome from 'react-fontawesome';

import { LinkStyled } from '../../styles';

const NoStripe = () =>
  <React.Fragment>
    {process.env.NODE_ENV === 'production'
      ?
      <LinkStyled className="button--blue" href={`/auth/stripe/get/${localStorage.getItem('token').split(" ")[1]}`}>
        <FontAwesome name="cc-stripe" className="marginRight--small text-white" size="2x" />
        Connect to Stripe
      </LinkStyled>
      :
      <LinkStyled className="button--blue" href={`http://localhost:5000/auth/stripe/get/${localStorage.getItem('token').split(" ")[1]}`}>
        <FontAwesome name="cc-stripe" className="marginRight--small text-white" size="2x" />
        Connect to Stripe
      </LinkStyled>
    }
  </React.Fragment>

export default NoStripe;