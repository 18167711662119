import axios from 'axios';
import {
  TAX_LOADING,
  GET_TAX,
  GET_TAXES,
  GET_ERRORS
} from './types'

export const fetchTaxes = (eventID, callback) => async dispatch => {
  try {
    const res = await axios.get(`/api/tax/all/${eventID}`);
    dispatch({
      type: GET_TAXES,
      payload: res.data
    });
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
};

export const createTax = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/tax/create/${eventID}`, formValue);
    dispatch({
      type: GET_TAXES,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_TAXES,
      payload: []
    });
  }
}

export const updateTax = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/tax/edit/${eventID}`, formValue);
    dispatch({
      type: GET_TAXES,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_TAXES,
      payload: []
    });
  }
}

export const deleteTax = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/tax/remove/${eventID}`, formValue);
    dispatch({
      type: GET_TAXES,
      payload: res.data
    })
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err
    })
  }
};

// Set loading state
export const setTaxLoading = () => {
  return {
    type: TAX_LOADING
  };
};