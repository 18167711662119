import React from 'react';
import styled from 'styled-components';

import { media, ButtonStyled, FlexContainer } from '../../../styles';

const Title = styled.h1`
  max-width: 650px;
  margin-top: 60px;
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  text-align: center;
  ${media.handheld`
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.h2`
  max-width: 650px;
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  text-align: center;
  ${media.handheld`
    width: auto;
    font-size: 16px;
    line-height: 26px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 120px;
  align-items: center;
  justify-items: center;

  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const hero = () => {
  const scrollWin = (x, y) => {
    window.scrollBy(x, y);
  }

  return (

    <GridLayout>
      <Title>Top-Rated Features for your Next Event</Title>
      <SubTitle>Get the tools you need to get more done faster, impress your attendees, and earn more money on every event.</SubTitle>
      <div className="flex flex-column flex-align-center flex-justify-center">
        <ButtonStyled className="button--blue" onClick={() => scrollWin(0, 350)}>
          <span>Discover New Features</span>
        </ButtonStyled>
      </div>
    </GridLayout>
  )
}
export default hero;