import React from 'react'
import styled from 'styled-components';

import { FormStyle, ButtonStyled, media } from '../../styles';

const GridLayout = styled.div`
  display: grid;
  grid-column-gap: 16px;
  margin: 0 auto;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 3fr 1fr;
  `}

  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  .item {
    display: flex;
    flex-direction: column;
  }
`

const EventWidget = ({ event }) => {
  let iframe = `
    <div style="position: relative; overflow: hidden; padding-top: 100%;">
      <iframe
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
        "
        sandbox="allow-scripts allow-same-origin allow-forms"
        title="${event.name}"
        frameBorder="0"
        src="${process.env.NODE_ENV === 'production' ? `https://www.attndnce.com/event/checkout/${event._id}` : `http://127.0.0.1:3000/event/checkout/${event._id}`}">
      </iframe>
    </div>
  `;
  let content;
  if (event.tickets.length > 0) {
    content = (
      <React.Fragment>
        <p className="marginBottom--large">Integrate your ticketing service directly in your website HTML page wherever you want</p>
        <FormStyle>
          <label>Copy/paste the code below into your HTML page.</label>
          <textarea
            id="unique_url"
            rows="18"
            type="text"
            className="marginBottom--small"
            readOnly={true}>{iframe}</textarea>
          <ButtonStyled className="marginLeft--xSmall" onClick={e => {
            e.preventDefault();
            /* Get the text field */
            var copyText = document.getElementById("unique_url");

            /* Select the text field */
            copyText.select();

            /* Copy the text inside the text field */
            document.execCommand("copy");

          }}><span className="text-uppercase text-spacing-1">Copy the integration code</span></ButtonStyled>
        </FormStyle>
      </React.Fragment>
    )
  } else {
    content = (<p>Please add tickets to your event first.</p>)
  }
  return (
    <GridLayout>
      <div className="flex flex-column">
        {content}
      </div>
    </GridLayout>
  )
}

export default EventWidget;