import React from 'react'
import Nav from './Nav';
import Hero from './Hero';

const GoogleAdPage = (props) =>
  <div className="flex flex-column">
    <Nav />
    <Hero {...props}
      subTitle="Everything you need to sell tickets online"/>
  </div>

export default GoogleAdPage;