import { Link } from 'react-router-dom';
import styled from 'styled-components'
import * as custom from '../styles/Variables';

export const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  background: #FFFFFF;
  border-radius: ${custom.BORDER_RADIUS};
  border: none;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: ${custom.COLOR_DARK};
  transition: all 0.3s ease;  
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background: #f7f7f7;
      /* transform: translateY(-1px) */
    }
  }
  
  &:focus {
    outline: 0;
  }
  
  &:disabled,
  &[disabled] {
    background: #FFFFFF;
    color: ${custom.COLOR_GREY_LIGHT};
    cursor: default;
    &:hover {
      cursor: default;
      box-shadow: none;
      transform: scale(1.00);
    }
  }

  &.button--red {
    color: #ef5733;
    background: none;
    border: 1px solid #ef5733;
    box-shadow: none;

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: none;
        color: #ef5733;
      }
    }
    &[disabled] {
      opacity: 0.9;
      :hover {

      }
      background: none;
    }
  }

  &.button--blue {
    background: ${custom.COLOR_BLUE};
    color: #FFFFFF;

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: ${custom.COLOR_BLUE_DARK};
      }
    }
    &[disabled] {
      background: ${custom.COLOR_BLUE};
      opacity: 0.5;
      :hover {

      }
    }
  }

  &.button--blue-outline {
    color: ${custom.COLOR_BLUE};
    background: none;
    box-shadow: none;

    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: none;
        color: ${custom.COLOR_BLUE};
      }
    }
    &[disabled] {
      background: none;
      opacity: 0.9;
      :hover {

      }
    }
  }

  &.button--green {
    background: ${custom.COLOR_GREEN};
    color: #FFFFFF;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: ${custom.COLOR_GREEN};
        color: #FFFFFF;
      }
    }
    &[disabled] {
      background: rgba(0,199,120,.3);
      opacity: 0.9;
      :hover {
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
      }
    }
  }

  &.button--purple {
    background: ${custom.COLOR_PURPLE};
    color: #FFFFFF;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        background: ${custom.COLOR_PURPLE};
        color: #FFFFFF;
      }
    }
    &[disabled] {
      background: rgba(0,199,120,.3);
      opacity: 0.9;
      :hover {

      }
    }
  }

  &.button--small {
    padding: 0 ${custom.SPACE_medium};
    border-radius: ${custom.BORDER_RADIUS};
    font-size: ${custom.FONTSIZE_small};
  }

  &.offset-shadow {
    box-shadow: 8px 8px 0 #E8EBFA;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform: translateY(-1px)
      }
    }
  }
  &.offset-shadow--purple {
    box-shadow: 8px 8px 0 #8E1C9D;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform: translateY(-1px)
      }
    }
  }
`

export const ButtonStyledLink = ButtonStyled.withComponent(Link)

export const LinkStyled = ButtonStyled.withComponent('a')

export const LinkPillStyled = styled(Link)`
  padding: ${custom.SPACE_xSmall} ${custom.SPACE_medium};
  border-radius: ${custom.BORDER_RADIUS};
  border: 1px solid #EDF1FA; 
  font-size: ${custom.FONTSIZE_small};
  color: ${custom.COLOR_BLUE};
  text-align: center;
  transition: all 0.3s ease; 
   &:hover {
      background: #EDF1FA;
    }
`