import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { changePassword } from '../../actions/authActions';

import TextField from '../utilities/formfields/TextField';

import { required, passwordMatch } from '../../helpers/formValdation';

import { FormStyle, ButtonStyled } from '../../styles';

class ChangePassword extends Component {
  state = {
    errors: {}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors});
    }
  }

  beforeSubmit = (formValues) => {
    this.props.changePassword(formValues, () => {
      this.props.history.push('/account');
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { errors } = this.state;

    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)} autoComplete="off">
        <Field
          label="Current Password"
          name="currentPassword"
          type="password"
          placeholder="Current Password"
          className="marginBottom--small"
          validate={required}
          error={errors.currentPassword}
          component={TextField} />
        <Field
          label="New Password"
          name="password"
          type="password"
          placeholder="New Password"
          className="marginBottom--small"
          validate={required}
          error={errors.password}
          component={TextField} />
        <Field
          label="Confirm New Password"
          name="confirmPassword"
          type="password"
          placeholder="Confirm New Password"
          className="marginBottom--small"
          validate={[required, passwordMatch]}
          error={errors.confirmPassword}
          component={TextField} />
        <ButtonStyled className="button--blue marginTop--medium" type="submit">Update Password</ButtonStyled>
      </FormStyle>
  
    )
  }
}

const mapStateToProps = state => ({
  initialValues: state.auth.user, // pull initial values from account reducer
  errors: state.errors,
})

export default compose(
  connect(
    mapStateToProps,
    { changePassword } // bind account loading action creator
  ),
  reduxForm({
    enableReinitialize: true,
    form: 'changePassword' // a unique identifier for this form
  })
)(ChangePassword);
