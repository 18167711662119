import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { calculateTotal } from '../../helpers/calculations';
import isEmpty from '../../validation/isEmpty';
import { ButtonStyled } from '../../styles';

const OrderTotal = ({ order, event, authDiscountCode, _nextPage }) => {
  let content;
  let order_quantity = 0;

  const calculateSubTotal = (order) => {
    return order.reduce((prev, order) => {
      order_quantity += order.quantity;
      return prev + (order.quantity * order.ticket.price);
    }, 0);
  }

  const ticketReachedLimit = (order, tickets) => {
    const ticketsExceed = (tickets || []).map(obj => {
      let objectFound = order.filter(function (o) { return o.id === obj._id.toString(); });
      if (objectFound.length && obj.quantity_available < obj.quantity_sold + objectFound[0].quantity) {
        return objectFound[0];
      }
    });
    const filtered = ticketsExceed.filter(item => item !== undefined);
    return filtered.length > 0 ? true : false
  }

  if (!isEmpty(order)) {
    content = (
      <div className="flex flex-column flex-grow-3">
        <div className="flex flex-row flex-align-center">
          <p className="text-bold text-number marginRight--xSmall">
            {calculateTotal(calculateSubTotal(order), authDiscountCode, event.taxes, event.pass_fees_on, event.subscribed_payment_option.paid, event.payout_currency.code, event.payout_country.language, true)}
          </p>
          <TransitionGroup component={null} className="count">
            <CSSTransition classNames="count" key={order_quantity} timeout={{ enter: 250, exit: 250 }}>
              <p className="text-small">{order_quantity}</p>
            </CSSTransition>
          </TransitionGroup>
          <p className="text-small">x Items</p>
        </div>

        <div className="flex flex-row flex-align-items">
          <p className="text-bold text-small">
            <span>{event.taxes.length > 0 ? " Includes Taxes" : ""}</span> {event.taxes.length > 0 && event.pass_fees_on && "and"} <span>{event.pass_fees_on ? "Service Fees" : ""}</span>
          </p>
        </div>
      </div>
    )
  } 
  else {
    content = <p>Your order is empty.</p>
  }
  return (
    <React.Fragment>
      {content}
      <ButtonStyled
        className="button--blue rounded-m text-bold text-uppercase pullRight"
        disabled={order.length === 0 || ticketReachedLimit(order, event.tickets)}
        onClick={_nextPage}>
        Review Order
        </ButtonStyled>
    </React.Fragment>
  )
}

export default OrderTotal;