import React, { Component } from 'react';
import { Link } from 'react-router-dom';



import { FiArrowLeft } from "react-icons/fi";

import SessionShow from '../event/session/SessionShow';
import EventEdit from './EventEdit';


import { FlexContainer, Section } from '../../styles';
import InstructorShow from '../instructor/InstructorShow';

class EventOptions extends Component {
  state = {
    component: 'sessions',
  }

  handleSelectComponent = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    this.setState({ component: e.target.name });
  }

  renderSwitch = (component) => {
    switch (component) {
      case "sessions":
        return (
          <SessionShow />
        )
      case "edit":
        return (
          <EventEdit />
        )
      case "instructor":
        return (
          <InstructorShow />
        )
      default:
        return (
          <div>Choose one</div>
        )
    }
  }

  render() {
    const { component } = this.state;
    return (
      <React.Fragment>
        <Section className="background-white">
          <FlexContainer small>
            <div className="flex flex-row flex-align-center flex-justify-between marginTop--small marginBottom--small">
              <Link to="/account" className="flex flex-row flex-align-center">
                <FiArrowLeft className="marginRight--xSmall" style={{ width: '24px', height: '24px' }} />
                <span>Back</span>
              </Link>
              <div>
                <a
                  onClick={this.handleSelectComponent}
                  className={`${component === "edit" ? "selected" : ""} marginLeft--small marginRight--small`}
                  name="edit">Event</a>
                <a
                  onClick={this.handleSelectComponent}
                  className={`${component === "instructor" ? "selected" : ""} marginLeft--small marginRight--small`}
                  name="instructor">Instructor</a>
                <a
                  onClick={this.handleSelectComponent}
                  className={`${component === "sessions" ? "selected" : ""} marginLeft--small`}
                  name="sessions">Session</a>
              </div>
            </div>
          </FlexContainer>
        </Section>
        <FlexContainer small>
          {this.renderSwitch(component)}
        </FlexContainer>
      </React.Fragment>
    )
  }
}

export default EventOptions;