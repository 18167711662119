import {
  GET_INSTRUCTOR,
  GET_INSTRUCTORS,
  INSTRUCTOR_LOADING,
  DELETE_INSTRUCTOR
} from '../actions/types';

const INITIAL_STATE = {
  instructor: {},
  instructors: [],
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INSTRUCTOR_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_INSTRUCTOR:
      return {
        ...state,
        instructor: action.payload,
        loading: false
      }
    case GET_INSTRUCTORS:
      return {
        ...state,
        instructors: action.payload,
        loading: false
      }
    case DELETE_INSTRUCTOR:
      return {
        ...state,
        instructors: state.instructors.filter(instructor => instructor._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
}