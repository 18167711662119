import React from 'react';

import Nav from './Nav';
import Hero from './Hero';

const SignupPage = (props) =>
  <div className="flex flex-column">
    <Nav />
    <Hero {...props} />
  </div>

export default SignupPage;