import {
  GET_BALANCE,
  GET_STRIPE,
  CLEAR_STRIPE,
  GET_PAYMENT,
  GET_PAYMENTS,
  BILLING_LOADING,
} from '../actions/types';

const INITIAL_STATE = {
  balance: {},
  stripe: {},
  payment: null,
  payments: null,
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case BILLING_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_BALANCE:
      return {
        ...state,
        balance: action.payload,
        loading: false
      }
    case GET_PAYMENT:
      return {
        ...state,
        payment: action.payload,
        loading: false
      }
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
        loading: false
      }
    case GET_STRIPE:
      return {
        ...state,
        stripe: action.payload,
        loading: false
      }
    case CLEAR_STRIPE:
      return {
        ...state,
        stripe: null,
        balance: null,
        payment: null,
        payments: null
      }
    default:
      return state;
  }
}