import React from 'react';

import EditProfile from '../edit-profile/EditProfile';
import CreateProfile from '../create-profile/CreateProfile';

import Spinner from '../utilities/Spinner';
import { StyledCard } from '../../styles';

const ProfileForm = ({ profile, loading = false, history }) => {
  let content;
  if (profile === null || loading) return <Spinner />
  else {
    if (Object.keys(profile).length > 0) {
      content = <EditProfile history={history} />
    } else {
      content = <CreateProfile history={history} />
    }
  }
  return (
    <StyledCard>
      {content}
    </StyledCard>
  )
}

export default ProfileForm;