import React from 'react';
import { FlexContainer, StyledCard } from '../../styles';

const Privacy = () =>
  <FlexContainer>

    <StyledCard>
      <div className="marginBottom--small">
        <h2 className="marginBottom--small bold">Privacy Policy</h2>
        <p>Your privacy is important to us. It is ATTNDNCE's policy to respect your privacy regarding any information we may collect from you across our website, http://attndnce.com, and other sites we own and operate.</p>
      </div>
      <div className="marginBottom--small">
        <p className="marginBottom--small">We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:</p>
        <ul>
          <li>- Communicate with you;</li>
          <li>- Screen our orders for potential risk or fraud; and</li>
          <li>- When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
        </ul>
      </div>
      <div className="marginBottom--small">
        <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
      </div>
      <div className="marginBottom--small">
        <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>
      </div>
      <div className="marginBottom--small">
        <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
      </div>
      <div className="marginBottom--small">
        <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
      </div>
      <div className="marginBottom--small">
        <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
      </div>
      <div className="marginBottom--small">
        <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us. <a href="mailto:support@attndnce.com?subject=inquiry">Email</a></p>
      </div>
      <p>This policy is effective as of 28 February 2019.</p>
    </StyledCard>
  </FlexContainer>

export default Privacy;