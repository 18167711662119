import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiArrowRight } from "react-icons/fi";

import { media } from '../../../styles';

import Event from "../images/event-page.png";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 24px;
    margin-left: 24px;
  }
`

const ImageContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
`

const LinkStyled = styled(Link)`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const SectionMain = () =>

  <GridLayout>
    <ImageContainer>
      <img src={Event} alt="" />
    </ImageContainer>
    <div>
      <Title>Attndnce's Core Features</Title>
      <SubTitle>Organizing an event is stressful enough. Put your festival on autopilot. Expect an easy setup with a powerful beast under the hood.</SubTitle>
      <LinkStyled to={`/features`} className="flex flex-row flex-align-center flex-justify-center text-center text-blue">
        <FiArrowRight size="24px" className="marginRight--small hideOnMobile" />
        <span>Browse Attndnce’s features</span>
      </LinkStyled>
    </div>
  </GridLayout>

export default SectionMain;