// Font Updated
export const FONTSIZE_x3Large = '2rem';     //32px
export const FONTSIZE_xxLarge = '1.75rem';   //28px
export const FONTSIZE_xLarge = '1.50rem';    //24px
export const FONTSIZE_large = '1.25rem';     //20px
export const FONTSIZE_medium = '1rem';       //16px
export const FONTSIZE_small = '0.75rem';    //12px
export const FONTSIZE_xSmall = '0.5rem';    //8px
export const FONTSIZE_xxSmall = '0.25rem';   //4px

// Sizing
export const SPACE_x3Large = '2rem';    //32px
export const SPACE_xxLarge = '1.75rem';  //28px
export const SPACE_xLarge = '1.50rem';  //24px
export const SPACE_large = '1.25rem';   //20px
export const SPACE_medium = '1rem';     //16px
export const SPACE_small = '0.5rem';    //8px
export const SPACE_xSmall = '0.25rem';  //4px

// Colors
export const COLOR_BLACK = '#1d1d1d';
export const COLOR_GREY_LIGHT = '#F8F9FA';
export const COLOR_GREY = '#E8E8E8';
export const COLOR_BLUE = '#368CFD';
export const COLOR_BLUE_DARK = '#1E7FFF';
export const COLOR_GREEN = '#00c778';
export const COLOR_PINK = '#FF0063';
export const COLOR_PURPLE = '#CC2EE0';

export const COLOR_PLACEHOLDER = '#EEEEEE';
export const COLOR_PLACEHOLDER_TEXT = '#979797';
export const COLOR_TEXT = '#000000';
export const COLOR_DARK = '#333333';
export const COLOR_BORDER = '#e5eafa';

export const BACKGROUND_COLOR = '#F4F7F9';
export const BACKGROUND_COLOR_WHITE = '#FFFFFF';

export const BORDER_RADIUS = '8px';
export const BORDER_RADIUS_LARGE = '16px';

export const BOX_SHADOW = '0 8px 16px 0 rgba(18, 38, 63, 0.15)';
export const BOX_SHADOW_TIGHT = '1px 1px 5px rgba(0,0,0,.13)';
export const BOX_SHADOW_TIGHTER = '0 1px 2px rgba(25,25,25,0.2)';
// export const BOX_SHADOW_DARK = '0 .5rem 1rem rgba(18,38,63,.15)';

export const DESKTOP_BREAKPOINT = '700px';

export const ZINDEX =  {
  modal: 10000,
  overlay: 8000,
  dropdown: 7000,
  header: 6000,
  footer: 5000
}