import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { setCurrentUser } from './authActions';

import setAuthToken from '../components/utilities/setAuthToken';

import {
  GET_ERRORS,
  GET_AUDIENCE,
  AUDIENCE_LOADING,
  GET_AUDIENCE_ACTIVITY,
} from './types'

export const fetchAudience = callback => async dispatch => {
  try {
    const res = await axios.get(`/api/mailchimp/audience/list`);
    dispatch({
      type: GET_AUDIENCE,
      payload: res.data
    });
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const createAudience = (formValues, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/mailchimp/audience/create`, formValues);
    const { token, message } = res.data;
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(decoded));
    callback(message);
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

export const syncAudience = (formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/mailchimp/audience/sync`, formValue);
    const { token, message } = res.data;
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(decoded));
    callback(message);
  }
  catch (err) {
    dispatch({
      type: GET_AUDIENCE,
      payload: []
    });
  }
} 

export const getAudienceActivity  = callback => async dispatch => {
  try {
    const res = await axios.get(`/api/mailchimp/audience/activity`);
    dispatch({
      type: GET_AUDIENCE_ACTIVITY,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

export const removeMailchimp  = callback => async dispatch => {
  try {
    const res = await axios.delete(`/api/mailchimp/disconnect`);
    const { token, message } = res.data;
    // Save to localStorage
    localStorage.setItem('token', token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = await jwt_decode(token);
    // set current user
    dispatch(setCurrentUser(decoded));
    callback(message);
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
}

// Set loading state
export const setAudienceLoading = () => {
  return {
    type: AUDIENCE_LOADING
  };
};