import React from 'react'
import { ButtonStyled, LinkStyled, media } from '../../styles';
import styled from 'styled-components';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FiArrowLeft } from "react-icons/fi";
import isEmpty from '../../validation/isEmpty';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  h1 {
    /* Email Marketing: */
    font-family: "Poppins";
    font-weight: 700;
    font-size: 48px;
    color: #3B3B3B;
    letter-spacing: 1px;
  }
  h2 {
    /* Features and benefit: */
    font-family: "Poppins";
    font-weight: 700;
    font-size: 32px;
    color: #012939;
    line-height: 52px;
  }
  h3 {
    /* Find more business w: */
    font-family: "Poppins";
    font-weight: 400;
    font-size: 20px;
    color: #3B3B3B;
    line-height: 32px;
  }
`

const SubGridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5eafa;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  ${media.handheld`
    justify-content: space-between
    > p {
      font-size: 12px;
    }
    div > * {
      width: 24px;
      height: 24px;
    }
    div > * > svg {
      width: 16px;
      height: 16px;
    }
  `}
`

class AppMarketplace extends React.Component {
  state = {
    toggle: false
  }
  
  handleToggle = () => {
    this.setState({
      toggle: !this.state.toggle
    })
  }

  render() {
    const { user } = this.props;
    return (
      <GridLayout>
        <div>
          {this.state.toggle ?
            <React.Fragment>
              <div className="flex flex-row flex-align-center marginBottom--medium" onClick={() => this.handleToggle()} style={{ cursor: "pointer" }}>
                <FiArrowLeft className="marginRight--xSmall" size="2rem" style={{ width: "32px" }} /><p>Back</p>
              </div>
              <div className="marginBottom--large">
                <h1 className="text-bold marginBottom--xSmall">Email Marketing</h1>
                <h3>Find more business with email marketing campaigns, postcards, social ads, and more— powered by Mailchimp. Connect your Attdndnce and Mailchimp accounts to automate your marketing.</h3>
              </div>

            <div className="marginBottom--large">
              <h2 className="marginBottom--small">Features and benefits</h2>
              <ul>
                <li className="flex-row flex-align-center marginBottom--small">
                  <IoIosCheckmarkCircle size="20px" color="#39CC6C" className="marginRight--xSmall" />
                  <span>Sync your attendees to your Mailchimp email list.</span>
                </li>
                <li className="flex-row flex-align-center marginBottom--small">
                  <IoIosCheckmarkCircle size="20px" color="#39CC6C" className="marginRight--xSmall" />
                  <span>More ticket sales with ready-to-run campaigns; send in just one click.</span></li>
                <li className="flex-row flex-align-center marginBottom--small">
                  <IoIosCheckmarkCircle size="20px" color="#39CC6C" className="marginRight--xSmall" />
                  <span>Build custom one-off or advanced email/postcard drip campaigns.</span></li>
                <li className="flex-row flex-align-center marginBottom--small">
                  <IoIosCheckmarkCircle size="20px" color="#39CC6C" className="marginRight--xSmall" />
                  <span>Reach new customers with Facebook and Instagram ads</span></li>
              </ul>
            </div>
            <div className="flex flex-row marginBottom--small">
                <LinkStyled
                  className="marginRight--small button--blue"
                  href={`https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=918343096879&redirect_uri=${process.env.NODE_ENV === 'production' ? "https://www.attndnce.com/auth/mailchimp/callback" : "http://127.0.0.1:5000/auth/mailchimp/callback"}`}>
                  Connect an existing account
                </LinkStyled>
              <LinkStyled className="button--blue-outline" href="https://login.mailchimp.com/signup/">Sign up for Mailchimp</LinkStyled>
            </div>
            <p className="text-small text-grey-blue">*Mailchimp account required. Free and paid plans available, depending on your needs. Print and send postcards for as little as 75¢/card, cost varies by country and quantity needed.</p>
            </React.Fragment>
            :
            <SubGridLayout>
              <div className="background-white rounded shadow-spread padding--small">
                <h3>Mailchimp</h3>
                <p className="text-grey-blue marginBottom--small"><em>Unlimited</em></p>
                <p className="marginBottom--small">Find more business with email marketing campaigns, postcards, social ads, and more—powered by Mailchimp. Connect your Attndnce and Mailchimp accounts to automate your marketing.</p>
                {!isEmpty(user.mailchimp) ?
                  <div className="flex flex-row flex-align-center flex-justify-center padding--small background-white border rounded-m">
                    <p className="text-grey">Already Connected</p>
                  </div>
                :
                <ButtonStyled className="button--blue-outline" onClick={() => this.handleToggle()}>Get Started</ButtonStyled>
                }

              </div>
            </SubGridLayout>
          }
        </div>
        <div>
          
        </div>
      </GridLayout>
    )
  }
}

export default AppMarketplace;