import React from 'react';
import styled from 'styled-components';

import mastercard from '../marketing/images/mastercard.png';
import visa from '../marketing/images/visa.png';
import americanexpress from '../marketing/images/americanexpress.png';
import powered_by_stripe from '../marketing/images/powered_by_stripe.png';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 116px 52px 52px 52px; 
  grid-column-gap: 4px;
  align-items: center;
  justify-content: center;
  max-height: 52px;
  overflow: hidden;
  > * {
    margin: 0 auto;
  }
`

const CCBox = ({ noText = false }) =>
  <div className="flex flex-row flex-column--onMobile flex-align-center flex-justify-center">
    {!noText && <p className="padding--small text-small text-center">All transactions are secure and encrypted. Payment information is never stored.</p>}
    <GridLayout>
      <img
        src={powered_by_stripe}
        style={{ width: 'auto', height: '32px' }} alt="" />
      <img
        src={mastercard}
        style={{ width: 'auto', height: '32px' }} alt="" />
      <img
        src={visa}
        style={{ width: 'auto', height: '32px' }} alt="" />
      <img
        src={americanexpress}
        style={{ width: 'auto', height: '32px' }} alt="" />
    </GridLayout>
  </div>

export default CCBox;