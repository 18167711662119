import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FiArrowLeft } from "react-icons/fi";

import * as custom from '../../styles/Variables';
import { media } from '../../styles';

export const LinkStyled = styled(Link)`
  padding: ${ custom.SPACE_medium} ${custom.SPACE_small} ${custom.SPACE_medium } 0;
  ${
  media.handheld`
    padding-left: ${custom.SPACE_medium};
  `}
`

const BackArrow = ({title}) =>
  <LinkStyled to="/account" className="flex flex-row flex-align-center">
    <FiArrowLeft className="marginRight--xSmall" style={{ width: "32px", height: "32px" }} />
    {title && title}
  </LinkStyled>
export default BackArrow;