import React from 'react'
import { FormStyle, ButtonStyled } from '../../styles'

const DiscountForm = ({ 
  discount_code,
  authDiscountCode,
  _submitDiscountCode,
  _handleDiscountCode
}) => {
  return (
    <div className="flex flex-column marginBottom--small paddingBottom--small">
      <FormStyle
        className="flex flex-row flex-align-center flex-justify-between"
        onSubmit={_submitDiscountCode}>
        <input
          name="code"
          type="text"
          placeholder="Discount Code"
          className="flex-grow-1 marginRight--xSmall"
          value={discount_code}
          onChange={_handleDiscountCode} />
        <ButtonStyled>Apply</ButtonStyled>
      </FormStyle>
      <p className="marginTop--xSmall text-green text-small">{Object.keys(authDiscountCode).length > 0 && "Discount applied"}</p>
    </div>
  )
};

export default DiscountForm;