import React from 'react';
import { FiX, FiEdit, FiTrash } from 'react-icons/fi'
import IconFilled from '../../utilities/IconFilled';

import { formatPrice, truncate, fromNow } from '../../../helpers';

const TicketView = ({ event, ticket, closeModal, handleModalSwitch, handleViewChange }) => {
  const { payout_currency: { code }, payout_country: { language } } = event;

  return (
    <div style={{ height: "100%" }} className="flex flex-column flex-justify-between padding--small">

      <div className="flex flex-row flex-align-center flex-justify-end">
        <IconFilled
          isPointer={true}
          isHover={true}
          cHeight="32px"
          cWidth="32px"
          data-view="edit"
          className="marginRight--small"
          onClick={(e) => handleViewChange(e, ticket)}>
          <FiEdit size="1rem" />
        </IconFilled>

        <IconFilled
          isPointer={true}
          isHover={true}
          cHeight="32px"
          cWidth="32px"
          data-modal="remove"
          className="marginRight--small"
          onClick={(e) => handleModalSwitch(e, ticket)}>
          <FiTrash size="1rem" />
        </IconFilled>

        <IconFilled
          isPointer={true}
          isHover={true}
          cHeight="32px"
          cWidth="32px"
          onClick={closeModal}>
          <FiX size="1rem" />
        </IconFilled>
      </div>

      <div className="flex flex-row flex-align-center flex-justify-between marginTop--medium">
        <div className="flex flex-column">
          <p className="marginRight--xSmall text-bold text-spacing-1">{truncate(ticket.name.toUpperCase(), 49)}</p>
          <p className="text-small">Released {fromNow(ticket.start_date)}</p>
        </div>

        <div className="flex flex-column">
          <p className="text-number text-right">{formatPrice(ticket.price, code, language)}</p>
          <p className="text-small text-right">Incl. Tax and Service Fees</p>
        </div>
      </div>
      <div className="border-top marginTop--small paddingTop--small">
        <p className="text-small text-grey">{ticket.desc}</p>
      </div>
    </div>

  )
}
export default TicketView;