import React from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi'

import CardHeader from '../../utilities/card/CardHeader';

import IconFilled from '../../utilities/IconFilled';

import { StyledCard, GridStyledCard, FormStyle, ButtonStyled } from '../../../styles';

import { formatPrice } from '../../../helpers';
import { calculateRevenueTotal, calculateCommission, calculateTicketQty } from '../../../helpers/affiliateCalculations';
import isEmpty from '../../../validation/isEmpty';

const renderReferrers = (affiliate, handleViewChange, language, code) => {
  if (affiliate.referrers.length > 0 && !isEmpty(affiliate.referrers[0].referrer_info)) {
    return (affiliate.referrers || []).map((ref, index) => {
      return (
        <StyledCard key={index}>
          <GridStyledCard
            key={index}
            style={{ cursor: "pointer" }}
            data-view="referrer"
            onClick={(e) => handleViewChange(e, affiliate, ref)}>

            <div className="flex flex-column flex-justify-between" style={{ justifySelf: "start", width: "100%" }}>
              <div className="flex flex-row flex-justify-between marginBottom--xxSmall paddingBottom--xxSmall border-bottom">
                <p className="text-small">Name:</p><p className="text-small"> {ref.referrer_info.first_name}</p>
              </div>
              <div className="flex flex-row flex-justify-between marginBottom--xxSmall paddingBottom--xxSmall border-bottom">
                <p className="text-small">Email:</p><p className="text-small"> {ref.referrer_info.email}</p>
              </div>
              <div className="flex flex-row flex-justify-between">
                <p className="text-small">Paid?</p><p className="text-small"> {ref.referrer_info.has_been_paid ? "Has been paid" : "Not paid-out"}</p>
              </div>
            </div>
            <div className="item">

              <div className="padding--xSmall border rounded shadow">
                <p className="text-small">Tickets Sold</p>
                <h3>{calculateTicketQty(ref.sale)}</h3>
              </div>
              <div className="padding--xSmall border rounded shadow">
                <p className="text-small">Revenue</p>
                <h3>{formatPrice(calculateRevenueTotal(ref.sale), code, language)}</h3>
              </div>
              <div className="padding--xSmall border rounded shadow">
                <p className="text-small">Commission</p>
                <h3 className="text-number">{formatPrice(calculateCommission(ref.sale, affiliate), code, language)}</h3>
              </div>
            </div>
          </GridStyledCard>
        </StyledCard>
      )
    })
  } else {
    return (
      <StyledCard>
        <p>Once a referrer signs up with Attndnce. You will see their stats here.</p>
      </StyledCard>
    )
  }
}

const AffiliateView = ({ aff, handleViewChange, language, code }) => {
  return (
    <React.Fragment>
      <StyledCard>
        <div className="flex flex-row flex-align-center flex-justify-between">
          <div className="flex flex-column">
            <p className="text-small text-grey-blue">Affiliate name</p>
            <p>{aff.affiliate_name}</p>
          </div>
          <div className="flex flex-row">
            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="edit"
              className="marginRight--small"
              onClick={(e) => handleViewChange(e, aff)}>
              <FiEdit size="1rem" />
            </IconFilled>

            <IconFilled
              isPointer={true}
              isHover={true}
              cHeight="32px"
              cWidth="32px"
              data-view="remove"
              onClick={(e) => handleViewChange(e, aff)}>
              <FiTrash size="1rem" />
            </IconFilled>
          </div>
        </div>
      </StyledCard>

      <StyledCard>
        <p>Affiliates will receive {aff.percent_off ? `${aff.percent_off}%` : `$${aff.amount_off}`} commission for every ticket sold.</p>
      </StyledCard>
      
      <CardHeader title="Note" />
      <StyledCard>
        <p>{aff.note}</p>
      </StyledCard>
      <CardHeader title="Invite affiliates" />
      <StyledCard>

        {/* <p className="text-grey marginBottom--xSmall">The affiliate fee for this program is 10.00% of ticket sales. </p> */}
        <FormStyle style={{ width: "100%" }}>
          <div className="flex flex-row flex-align-center">
            <label>Unique URL</label>
            <input
              id="unique_url"
              type="text"
              readOnly={true}
              value={`${process.env.NODE_ENV === 'production' ? 'https://www.attndnce.com' : 'localhost:3000'}/sign-up?affname=${aff.affiliate_name}&affid=${aff._id}`} />
            <ButtonStyled className="marginLeft--xSmall" onClick={e => {
              e.preventDefault();
              /* Get the text field */
              var copyText = document.getElementById("unique_url");

              /* Select the text field */
              copyText.select();

              /* Copy the text inside the text field */
              document.execCommand("copy");

            }}>Copy Link</ButtonStyled>
          </div>
        </FormStyle>
      </StyledCard>
      <CardHeader title="Referrers" />
      {renderReferrers(aff, handleViewChange, language, code)}

    </React.Fragment>
  )
}
export default AffiliateView;