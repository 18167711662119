import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';

import { signup } from '../../../actions/authActions';

import { required, passwordMatch } from '../../../helpers/formValdation';
import { setCookie, getCookie } from '../../../helpers/cookies';

import TextField from '../../utilities/formfields/TextField'

import { FormStyle, ButtonStyled, media } from '../../../styles';


const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
  color: #3B3B3B;
  letter-spacing: 1px;
  line-height: 68px;
  ${media.handheld`
    font-size: 24px;
    line-height: 50px;
  `}
`

const SubTitle = styled.div`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #3B3B3B;
  text-align: center;
  line-height: 32px;

  ${media.handheld`
    width: 100%;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 26px;
  `}
`

const ButtonText = styled.span`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  ${media.handheld`
    font-size: 16px;
  `}
`

class SignupForm extends Component {
  state = {
    errors: {}
  }
  
  componentDidMount() {
    const { location, history, auth } = this.props;
    let path = "/account";

    if (auth.isAuthenticated) {
      history.push({
        pathname: path
      })
    }

    if (location.search) {
      setCookie('affiliations', location.search, 365);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.errors).length > 0) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit = (formValues) => {
    const { history } = this.props;
    let parsedURLParams = {};
    let path = "/account";
    
    // check if url params exists
    parsedURLParams = queryString.parse(this.props.location.search);
    
    if (this.props.location && this.props.location.pathname === "/l/free-signup") {
      path = "/account/g/welcome";
    }

    // check if cookie for event exists
    if (getCookie('affiliations')) {
      parsedURLParams = queryString.parse(getCookie('affiliations'));
    }

    this.props.signup(formValues, parsedURLParams, (message) => {
      if (getCookie('affiliations')) {
        setCookie('affiliations', "", 0);
      }
      history.push({
        pathname: path,
        state: { message: message }
      });
    });
  };

  render() {
    const { handleSubmit, title, subTitle } = this.props;
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="marginBottom--medium padding--medium background-white border rounded-m shadow-spread">
          {/* <Title className="text-center">START YOUR FREE TRIAL</Title> */}
          <Title className="text-center">{title ? title : "UNLEASH YOUR EVENT"}</Title>
          <SubTitle className="marginBottom--medium text-center">{subTitle ? subTitle : "Get all the features and support."}</SubTitle>
          <FormStyle onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
            <Field
              name="first_name"
              type="text"
              placeholder="Full Name"
              validate={required}
              error={errors.first_name}
              component={TextField} />
            <Field
              name="email"
              type="text"
              placeholder="Email Address"
              error={errors.email}
              validate={required}
              component={TextField} />
            <Field
              name="password"
              type="password"
              placeholder="Create a Password"
              validate={required}
              error={errors.password}
              component={TextField} />
            <Field
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              validate={[required, passwordMatch]}
              error={errors.confirmPassword}
              component={TextField} />
            <p className="text-small text-grey-blue text-center">By continuing Signup, you are agreeing to our <Link to="/tos" className="text-grey-blue text-small">Terms of Service</Link> and <Link to="/privacy" className="text-grey-blue text-small">Privacy Policy</Link>.</p>

            <ButtonStyled id="submitButton" className="button--purple offset-shadow--purple marginTop--small marginBottom--small text-uppercase"><ButtonText>Start Free Trial</ButtonText></ButtonStyled>
          </FormStyle>
        </div>
        <p className="text-white text-center">Already have an account? <Link to="/admin/login" className="text-white text-center">Login</Link> ➞</p>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default compose(
  connect(mapStateToProps, { signup }),
  reduxForm({ form: 'signup' })
)(SignupForm);