import {
  GET_AFFILIATES,
  DELETE_AFFILIATE,
  AFFILIATE_LOADING
} from '../actions/types';

const INITIAL_STATE = {
  affiliates: null,
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AFFILIATE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_AFFILIATES:
      return {
        ...state,
        affiliates: action.payload,
        loading: false
      };
    case DELETE_AFFILIATE:
      return {
        ...state,
        affiliates: state.affiliates.filter(aff => aff._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
}