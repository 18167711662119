import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';

import { createAffiliate } from '../../../actions/affiliateActions';

import TextField from '../../utilities/formfields/TextField';
import TextAreaField from '../../utilities/formfields/TextAreaField';

import Spinner from '../../utilities/Spinner';

import { required } from '../../../helpers/formValdation';
import isEmpty from '../../../validation/isEmpty';

import { ButtonStyled, FormStyle } from '../../../styles';

class AffiliateCreate extends React.Component {
  state = {
    errors: {},
    loading: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleClearAmount = (e, newValue, previousValue, name) => {
    this.props.updateField('amount_off', '');
  }

  handleClearPercent = (e, newValue, previousValue, name) => {
    this.props.updateField('percent_off', '');
  }

  beforeSubmit = (formValues) => {
    const { eventID, handleClearState } = this.props;
    this.setState({ loading: true }, () => {
      this.props.createAffiliate(eventID, formValues, () => {
        this.setState({ loading: false }, () => {
          handleClearState();
        });
      });
    });
  }

  render() {
    const { loading, errors } = this.state;
    const { handleSubmit, amount_off, percent_off } = this.props;

    if (loading) return <Spinner />

    return (
      <FormStyle onSubmit={handleSubmit(this.beforeSubmit)}>

        <Field
          label="Affiliate Name"
          type="text"
          name="affiliate_name"
          placeholder="Bangkok, earlybird, Saori21"
          className="col"
          validate={required}
          component={TextField} />
        <Field
          label="Note"
          type="text"
          name="note"
          className="col"
          validate={required}
          component={TextAreaField} />
        <div className="flex flex-grid-half flex-align-center flex-justify-between">

          <Field
            label="Amount"
            type="text"
            name="amount_off"
            placeholder="$10"
            className="col"
            error={errors.amount_off}
            validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : undefined}
            onChange={this.handleClearPercent}
            component={TextField} />

          <Field
            label="Percent"
            type="text"
            name="percent_off"
            placeholder="45%"
            className="col"
            error={errors.amount_off || percent_off && amount_off > 100 && "Has to be less than or equal to 100%"}
            validate={isEmpty(amount_off) && isEmpty(percent_off) ? required : undefined}
            onChange={this.handleClearAmount}
            component={TextField} />

        </div>
        <div className="flex flex-row pullRight marginTop--small">
          <ButtonStyled className="button--blue">Add New Affiliate</ButtonStyled>
        </div>
      </FormStyle>
    )
  }
}

const selector = formValueSelector('createAffiliate')

const mapStateToProps = state => ({
  errors: state.errors,
  amount_off: selector(state, 'amount_off'),
  percent_off: selector(state, 'percent_off')
});

export default compose(
  connect(mapStateToProps, { createAffiliate,
    updateField: (field, newValue) => change('createAffiliate', field, newValue)
  }),
  reduxForm({ form: "createAffiliate" })
)(AffiliateCreate);