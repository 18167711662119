import React, { useEffect } from 'react';
import Modal from 'react-modal';
import IconFilled from '../../utilities/IconFilled';
import { FiX } from "react-icons/fi";
import { friendlyDate } from '../../../helpers';


const SessionModal = ({ modalIsOpen, closeModal, session = {} }) => {
  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Placeholder"
      className="modal"
      overlayClassName="overlay">
      <div
        style={{ height: "100%" }}
        className="flex flex-column flex-justify-between padding--small paddingBottom--large paddingTop--small rounded flex-grow-1">
        <div className="flex flex-column">
          <div className="flex flex-row pullRight">
            <IconFilled isPointer={true} isHover={true} cHeight="32px" cWidth="32px">
              <FiX ize="1rem" onClick={closeModal} />
            </IconFilled>
          </div>
          <h3>{session.name}</h3>
          <p className="marginBottom--small text-small">{`${friendlyDate(session.start_date, 'ddd, MMM DD ')} ${friendlyDate(session.start_date, 'HH:mm')} - ${friendlyDate(session.end_date, 'HH:mma')}`}</p>

          <div className="flex flex-row">
            {(session.tracks || []).map((tr, index) => {
              return (
                <div className="marginRight--xSmall" key={index}>
                  <p className="text-small">{tr}</p>
                </div>
              )
            })
            }
          </div>
          <p className="text-small">{session.session_type}</p>
        </div>
      </div>
    </Modal>
  )
}

export default SessionModal;