import {
  AUDIENCE_LOADING,
  GET_AUDIENCE,
  GET_AUDIENCE_ACTIVITY,
} from '../actions/types';

const INITIAL_STATE = {
  audience: [],
  activity: [], 
  loading: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUDIENCE_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_AUDIENCE_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
        loading: false
      };
    case GET_AUDIENCE:
      return {
        ...state,
        audience: action.payload,
        loading: false
      };
    default:
      return state;
  }
}