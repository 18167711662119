import styled from 'styled-components'
import { media } from './Media';
import * as custom from './Variables';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${ props => props.marginTopBottom ? "72px" : "0" } auto!important;
  width: 100%;
  max-width: ${ props => props.small ? "850px" : "1340px" };
  position: relative;
  ${media.handheld`
    margin: 0!important;
  `}
`

export const Main =  styled.div`
  flex: 3;
  min-height: 100vh;

  ${media.handheld`
    margin: 0 ${custom.SPACE_medium};
  `}
`

export const SideBar = styled.div`
  flex: 1;
  ${media.handheld`
    margin: 0 ${custom.SPACE_medium};
  `}
`

export const Section = styled.div`
  position: relative;
  overflow: ${ props => props.overFlowNone ? "none" : "hidden" };
  background: ${ props => props.bgColor && props.bgColor };
  ${media.handheld`
    margin-bottom: 0;
    padding-left: ${custom.SPACE_medium};
    padding-right: ${custom.SPACE_medium};
    background: ${custom.BACKGROUND_COLOR_WHITE};
    border: none;
  `}
`

export const SkewedBox = styled.div`
  /* https://bootsnipp.com/snippets/5MXdE */
	padding: 100px 0;
  transform: skew(0deg,-5deg) translateY(-115px);
  background: ${ props => props.bgColor ? props.bgColor : custom.BACKGROUND_COLOR_WHITE };
`

export const SkewedBoxContainer = styled.div`
	padding: 100px 200px;
  transform: skew(0deg,5deg);
  ${media.handheld`
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`

export const FlexInputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`