import axios from 'axios';
import {
  SESSION_LOADING,
  GET_SESSION,
  GET_SESSIONS,
  GET_ERRORS
} from './types'

export const fetchSessions = (eventID, callback) => async dispatch => {
  try {
    // dispatch(setSessionLoading());
    const res = await axios.get(`/api/session/all/${eventID}`);
    dispatch({
      type: GET_SESSIONS,
      payload: res.data
    });
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: []
    });
  }
};

export const createSession = (eventID, formValue, callback) => async dispatch => {
  try {
    // dispatch(setSessionLoading());
    const res = await axios.post(`/api/session/create/${eventID}`, formValue);
    dispatch({
      type: GET_SESSIONS,
      payload: res.data
    });

    console.log('actions res data', res.data)
    callback(res.data);
  }
  catch (err) {
    dispatch({
      type: GET_SESSIONS,
      payload: []
    });
  }
}

export const updateSession = (eventID, formValue, callback) => async dispatch => {
  try {
    // dispatch(setSessionLoading());
    const res = await axios.post(`/api/session/edit/${eventID}`, formValue);
    dispatch({
      type: GET_SESSIONS,
      payload: res.data
    });

    // const filtered = res.data.filter(session => session._id === formValue._id);
    // callback(filtered[0], formValue._id);
    callback(res.data);
  }
  catch (err) {
    dispatch({
      type: GET_SESSIONS,
      payload: []
    });
  }
}

export const deleteSession = (eventID, formValue, callback) => async dispatch => {
  try {
    console.log(eventID, formValue, callback);
    const res = await axios.post(`/api/session/remove/${eventID}`, formValue);
    console.log('returned', res.data);
    dispatch({
      type: GET_SESSIONS,
      payload: res.data
    })
    callback(res.data);
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err
    })
  }
};

// Set loading state
export const setSessionLoading = () => {
  return {
    type: SESSION_LOADING
  };
};