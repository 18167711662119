import React from 'react';
import styled from 'styled-components';
import * as custom from '../../styles/Variables';

const CircleStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ props => props.cWidth ? props.cWidth : "60px" };
  height: ${ props => props.cHeight ? props.cHeight : "60px" };
  background: ${props => props.backgroundColor ? props.backgroundColor : "#F4F7F9" };
  border-radius: 32px;
  border: 1px solid ${custom.COLOR_BORDER};
  cursor: ${ props => props.isPointer ? "pointer" : "default" };

  &:hover {
    background-color:  ${ props => props.isHover ? "rgba(0, 0, 0, 0.09);" : "none" };
    transition: background-color .5s
  }
  > * {
    width: 24px;
    height: 24px;
  }
`

const IconFilled = (props) =>
  <CircleStyle
    {...props}
    isHover={props.isHover}
    isPointer={props.isPointer}
    cWidth={props.cWidth}
    cHeight={props.cHeight}
    backgroundColor={props.backgroundColor}>
    {props.children}
  </CircleStyle>

export default IconFilled;