import React from 'react'
import { FiLock } from "react-icons/fi";

import DiscountForm from './DiscountForm';
import CCBox from "../utilities/CCBox";

import isEmpty from '../../validation/isEmpty';
import { formatPrice, getSymbolFromCurrency, isAfter } from '../../helpers';
import {
  calculateSubTotal,
  calculateDiscount,
  calculateTax,
  calculateTotalFees,
  calculateTotal
} from '../../helpers/calculations';

const getSubTotal = (order) => {
  let count = 0;
  let ticketNumber = 0;
  const subTotal = order.reduce((prev, { ticket, quantity }) => {
    count = quantity;
    ticketNumber = ticketNumber += quantity;
    return prev + (count * ticket.price);
  }, 0);

  return subTotal > 0 ? subTotal : 0;
}

const OrderSummary = ({
  event: { end_date, taxes, pass_fees_on, subscribed_payment_option, user },
  order,
  error,
  discount_code,
  authDiscountCode,
  _handleDiscountCode,
  _removeDiscountCode,
  _submitDiscountCode
}) => {
  let content;
  if (!isEmpty(order)) {
    content = (
      <React.Fragment>
        <div className="marginBottom--small border-bottom">
          {order.map((item, index) =>
            <div key={index} className="flex flex-row flex-align-center flex-justify-between marginBottom--xSmall paddingBottom--xSmall">
              <p className="text-small text-spacing-1">{item.quantity} x ({item.ticket.ticket_type}) {item.ticket.name}</p>
              <p className="text-small text-number text-spacing-1">{formatPrice(item.ticket.price, user.currency.code, user.language)}</p>
            </div>
          )}
        </div>

        {Object.keys(authDiscountCode).length > 0 &&
          <div className="flex flex-column flex-justify-between marginBottom--small paddingBottom--small">
            <div className="flex flex-row flex-align-center flex-justify-between ">
              <p>Discount Applied ({authDiscountCode.amount_off ? `${getSymbolFromCurrency(user.currency.code) + authDiscountCode.amount_off} OFF` : `${authDiscountCode.percent_off}% OFF`})</p>
              <p className="text-pink">-{calculateDiscount(getSubTotal(order), authDiscountCode, user.currency.code, user.language, true)}</p>
            </div>
            <div className="flex">
              <p className="text-blue text-small" onClick={_removeDiscountCode}>Remove</p>
            </div>
          </div>
        }

        <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small">
          <p>Sub-Total</p>
          <p className="text-number">{calculateSubTotal(getSubTotal(order), authDiscountCode, user.currency.code, user.language, true)}</p>
        </div>

        <ul>
          {(taxes || []).map((tax, index) =>
            <li key={index} className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small">
              <span>{`${tax.tax_name} ${tax.tax_percent}%`}</span>
              <span className="text-number">{calculateTax(getSubTotal(order), authDiscountCode, tax, user.currency.code, user.language, true)}</span>
            </li>
          )}
        </ul>

        {pass_fees_on &&
          <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small">
            <p>Service Fees</p>
            <p className="text-number">{calculateTotalFees(getSubTotal(order), authDiscountCode, taxes, pass_fees_on, subscribed_payment_option.paid, user.currency.code, user.language, true)}</p>
          </div>
        }
        {taxes && pass_fees_on && subscribed_payment_option &&
          <div className="flex flex-row flex-align-center flex-justify-between">
            <p className="text-bold">Total</p>
            <p className="text-large text-bold text-number">
              {calculateTotal(getSubTotal(order), authDiscountCode, taxes, pass_fees_on, subscribed_payment_option.paid, user.currency.code, user.language, true)}
            </p>
          </div>
        }
      </React.Fragment>
    )
  }
  else {
    content = (
      <div className="flex flex-row flex-align-center flex-justify-between marginBottom--small paddingBottom--small">
        {isAfter(end_date) ? "" : <p>Please choose a pass to continue.</p>}
      </div>
    )
  }
  return (
    <React.Fragment>
      <div className="header">
        <h3 className="paddingBottom--small border-bottom">Order Summary</h3>
      </div>
      <div className="content">
        <DiscountForm
          error={error}
          discount_code={discount_code}
          authDiscountCode={authDiscountCode}
          _submitDiscountCode={_submitDiscountCode}
          _handleDiscountCode={_handleDiscountCode} />
        {content}
      </div>
      <div className="footer-side">
        <CCBox noText="true" />
        <div className="flex flex-row flex-align-center flex-justify-center marginTop--small">
          <FiLock className="marginRight--xSmall text-green" />
          <p className="text-small text-center">Secure SSL Payment via Stripe</p>
        </div>
        <p className="text-small text-grey-blue">All transactions are secure and encrypted. Payment information is never stored.</p>
      </div>
    </React.Fragment>
  )
}

export default OrderSummary;