import React from 'react'
import styled from 'styled-components';

import { truncate, formatPrice } from '../../helpers';

import { media } from '../../styles';

const TicketItemLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'ticket-main'
    'description'
  grid-row-gap: 16px;
  align-items: center;
  .ticket-main {
    display: grid;
    grid-template-columns: 60% 40%;;
    .ticket-price {
      display: grid;
      grid-template-columns: 1fr 52px;
      grid-column-gap: 16px;
      ${media.handheld`
        grid-template-columns: 1fr;
      `}
    }
  }
`

const TicketItem = ({ taxes, ticket, index, currencyInfo: { currency: { code }, language }, activeIndex, selected_qty, _moreLess, _toggleClass, _handleCartOrder }) =>
  <TicketItemLayout className="paddingTop--small paddingBottom--small border-bottom">
    <div className="ticket-main">
      <div className="flex flex-column">
        <p className="flex flex-row flex-align-center text-bold" onClick={() => {_toggleClass(index) }}>{ticket.name}{_moreLess(index)}</p>
        {activeIndex !== index && <p className="ticket-item-excerpt text-small text-grey">{truncate(ticket.desc, 45)}...</p>}
      </div>
      <div className="ticket-price">
        <div className="flex flex-column flex-justify-center text-right">
          <h3><span className="text-number">{formatPrice(ticket.price, code, language)}</span></h3>
          <p className="text-grey-blue text-small">({formatPrice(ticket.price, code, language)} + fees{taxes.length > 0 ? ", including taxes" : ""})</p>
        </div>
        <div className="flex flex-column flex-justify-center">
          <select className="select-css pullRight" value={selected_qty} name={ticket.name} onChange={(e) => _handleCartOrder(e, ticket)}>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
      </div>
    </div>
    <div className="description marginTop--small">
      {activeIndex === index &&
      <p className={`ticket-item-desc text-small text-grey`}>
        {ticket.desc}
      </p>
      }
    </div>
  </TicketItemLayout>

export default TicketItem;