import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../styles';
import { FiBarChart2, FiShare2, FiMail } from "react-icons/fi";

import Event from "../images/email-syncing.png";

const Title = styled.div`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 56px;
  line-height: 79px;
  margin-bottom: 10px;
  ${media.handheld`
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  `}
`

const SubTitle = styled.div`
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  color: #262626;
  line-height: 38px;
  ${media.handheld`
    font-size: 16px;
    line-height: 26px;
  `}
`

const ListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  align-items: center;
  padding: 24px;
  margin-bottom: 10px;
  background: #FAFAFA;
  border-radius: 8px;

  &.highlight {
    background: #E5F1FA;
  }
`

const ListItemHeadline = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 16px;
  align-items: center;
  margin-bottom: 8px;
  p {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
  }
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 75px;
  align-items: center;
  margin-bottom: 120px;
  & > div {
    display: grid;
    align-items: center;
    justify-content: center;
  }
  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-right: 24px;
    margin-left: 24px;
  `}
`

const SectionThree = () => {
  const [active, setActive] = useState("email");

  const toggleClass = (clicked) => {
    setActive(active === clicked ? null : clicked)
  }

  return (

    <GridLayout>
      <div>
        <img src={Event} className="marginBottom--large" alt="" />
      </div>
      <div>
        <Title>Grow Your Event</Title>
        <SubTitle>Watch the numbers grow when you provide a great user experience for your customers.</SubTitle>
        <ul>
          <ListItem className={active === "reporting" && "highlight"}>
            <ListItemHeadline onClick={() => toggleClass("reporting")}>
              <FiBarChart2 size="24px" />
              <p>Reporting</p>
            </ListItemHeadline>
            {active === "reporting" ?
              <p className="marginTop--xSmall">Keep track of your event's revenue,ticket sales, and overall progress with daily, weekly, and monthly summaries.</p>
              :
              <div></div>
            }
          </ListItem>
          <ListItem className={active === "referral" && "highlight"}>
            <ListItemHeadline onClick={() => toggleClass("referral")}>
              <FiShare2 size="24px" />
              <p>Referral Program</p>
            </ListItemHeadline>
            {active === "referral" ?
              <p className="marginTop--xSmall">Create an affiliate program and set a referral fee that referrers earn for each ticket sale they generate.</p>
              :
              <div></div>
            }
          </ListItem>
          <ListItem className={active === "email" && "highlight"}>
            <ListItemHeadline onClick={() => toggleClass("email")}>
              <FiMail size="24px" />
              <p>Email Marketing</p>
            </ListItemHeadline>
            {active === "email" ?
              <p className="marginTop--xSmall">Find more business with email marketing campaigns, postcards, social ads, and more— powered by Mailchimp.</p>
              :
              <div></div>
            }
          </ListItem>
        </ul>
      </div>
    </GridLayout>

  )
}
export default SectionThree;