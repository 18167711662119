import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TiSocialFacebook } from "react-icons/ti";
import { FiInstagram } from "react-icons/fi";

import { FlexContainer, ButtonStyledLink, media } from '../../styles';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: auto;
  grid-template-rows: auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  position: absolute;
  top: 0;
  z-index: 100;

  ${media.handheld`
    grid-template-columns: 1fr 1fr;
    padding: 20px 0;
  `}

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const Logo = styled.div`
  color: #FFFFFF;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 8px;
  text-align: center;
  ${media.handheld`
    margin-left: 16px;
    font-size: 20px;
    line-height: 32px;
  `}
`

const ButtonText = styled.span`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1px;
  ${media.handheld`
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
  `}
`

const Nav = () =>
<FlexContainer>
  <GridLayout>
    <div className="flex flex-row flex-align-center hideOnMobile">
      <a href={`http://www.facebook.com/attndnce`}>
        <TiSocialFacebook className="marginRight--small text-white" style={{ width: "32px", height: "32px" }} />
      </a>
      <a href={`http://www.instagram.com/attndnce`}>
        <FiInstagram className="text-white" style={{ width: "32px", height: "32px" }} />
      </a>
    </div>
    <div className="flex flex-column flex-justify-center">
      <Link to={`/?ref=nav_logo`}><Logo>ATTNDNCE</Logo></Link>
    </div>
    <div className="flex flex-row flex-align-center flex-justify-end marginRight--small">
      <Link to={'/admin/login'} className="marginRight--small text-bold text-white">
        <ButtonText style={{ textShadow: '0 2px 5px rgba(25,0,96,1)' }}>login</ButtonText>
      </Link>
      <ButtonStyledLink to="/sign-up" className="rounded-m">
        <ButtonText className="hideOnMobile offset">Signup for Free</ButtonText>
        <ButtonText className="showOnMobile">Signup</ButtonText>
      </ButtonStyledLink>      
    </div>
  </GridLayout>
</FlexContainer>


export default Nav;