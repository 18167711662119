import axios from 'axios';

import {
  GET_ERRORS,
  GET_EVENT,
  GET_EVENTS,
  EVENT_LOADING,
  DELETE_EVENT
} from './types'

import { clearErrors } from './';

export const createEvent = (formValues, callback) => async dispatch => {
  try {
    dispatch(setEventLoading());
    if (localStorage.getItem("image") !== null) formValues.image = window.localStorage.getItem("image");
    const res = await axios.post(`/api/event/create`, formValues);
    if (localStorage.getItem("image") !== null) window.localStorage.removeItem("image");
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    callback(res.data._id);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const updateEvent = (formValues, id, callback) => async dispatch => {
  try {
    dispatch(setEventLoading());
    if (localStorage.getItem("image") !== null) formValues.image = localStorage.getItem('image');
    const res = await axios.post(`/api/event/edit/${id}`, formValues);
    if (localStorage.getItem("image") !== null)localStorage.removeItem('image');
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    dispatch(clearErrors());
    callback();
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const fetchEvent = (id, callback) => async dispatch => {
  try {
    dispatch(setEventLoading());
    const res = await axios.get(`/api/event/edit/${id}`);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    callback(res.data)
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data
    });
  }
};

export const deleteEvent = id => async dispatch => {
  try {
    await axios.delete(`/api/event/remove/${id}`);
    dispatch({
      type: DELETE_EVENT,
      payload: id
    })
  }
  catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err
    })
  }
};

export const getEvents = () => async dispatch => {
  try {
    dispatch(setEventLoading());
    const res = await axios.get(`/api/event/all`);
    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_EVENTS,
      payload: []
    });
  }
};

export const getPublicEvents = (userID) => async dispatch => {
  try {
    dispatch(setEventLoading());
    const res = await axios.get(`/api/event/all/public/${userID}`);
    dispatch({
      type: GET_EVENTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_EVENTS,
      payload: []
    });
  }
};

export const getEventHook = (eventID) => async dispatch => {
  try {
    dispatch(setEventLoading());
    const res = await axios.get(`/api/event/${eventID}`);
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_EVENTS,
      payload: {}
    });
  }
};

export const getEvent = (id, callback) => async dispatch => {
  try {
    dispatch(setEventLoading());
    const res = await axios.get(`/api/event/${id}`);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    callback(res.data);
  } catch (err) {
    dispatch({
      type: GET_EVENTS,
      payload: {}
    });
  }
};

export const updateOrderForm = (eventID, formValue, callback) => async dispatch => {
  try {
    const res = await axios.post(`/api/event/order-form/${eventID}`, formValue);
    dispatch({
      type: GET_EVENT,
      payload: res.data
    });
    callback();
  }
  catch (err) {
    dispatch({
      type: GET_EVENT,
      payload: []
    });
  }
};

// Set loading state
export const setEventLoading = () => {
  return {
    type: EVENT_LOADING
  };
};